import { createTheme } from '@material-ui/core/styles';

const portalTheme = createTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: 'Lato !important',
    },

    palette: {
        primary: {
            main: '#000',
        },

        secondary: {
            main: '#B49759',
            contrastText: '#fff',
        },
    },
});

export default portalTheme;
