import { useEffect, useRef } from 'react';

// useEffect but skip the first render
const useDidUpdateEffect = (fn: () => void, dependencies: ReadonlyArray<any>) => {
    const didMountRef = useRef(false);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);
};

export default useDidUpdateEffect;
