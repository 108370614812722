import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../Session';
import { PurchaseTransactionStage, useGetPurchaseTransactionsQuery, UserType, DownloadFileType } from '../../api';
import ListPagination from '../../components/ListPagination';
import { useLoading } from '../../components/LoadingProvider';
import { LocationHistory } from '../../components/LocationHistory';
import {
    PurchaseTransactionsHeader,
    PurchaseTransactionsBody,
    PurchaseTransactionsFooter,
    FilterDialog,
    DownloadListFn,
} from './components';
import FilterMenu from './components/FilterMenu';

type ListContext = {
    search: string;
    page: number;
};

export type PurchaseTransactionsListProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

export type TransactionFilter = {
    fromCreated: Date | null;
    toCreated: Date | null;
    fromHandover: Date | null;
    toHandover: Date | null;
    stage: 'all' | PurchaseTransactionStage;
    showArchived: boolean;
};

export const defaultTransactionFilter: TransactionFilter = {
    stage: 'all',
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    showArchived: false,
};

const pageSize = 9;

const PurchaseTransactionsList = ({ downloadPurchaseTransactionsFile }: PurchaseTransactionsListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUser = useCurrentUser();
    const { resetPurchaseTransactionsFilter = true } = (useLocation().state || {}) as LocationHistory;

    const defaultTransactionFilterLS = useMemo(
        () =>
            !resetPurchaseTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`PurchaseTransactionsListLS-${currentUser.id}`)
                  ) as TransactionFilter) || defaultTransactionFilter
                : defaultTransactionFilter,
        [resetPurchaseTransactionsFilter, currentUser.id]
    );

    useEffect(() => {
        if (resetPurchaseTransactionsFilter) {
            localStorage.removeItem(`PurchaseTransactionsListLS-${currentUser.id}`);
        }
    }, [resetPurchaseTransactionsFilter, currentUser.id]);

    const [filter, setFilter] = useState<TransactionFilter>(defaultTransactionFilterLS);
    const setFilterAndPersist = useCallback(
        (data: TransactionFilter) => {
            setFilter(data);
            localStorage.setItem(`PurchaseTransactionsListLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setFilter, currentUser.id]
    );

    const { attach } = useLoading();

    const [{ page, search }, setListContext] = useState<ListContext>({ search: '', page: 0 });
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const queryFilter = useMemo(
        () => ({
            stages: filter.stage === 'all' ? null : [filter.stage],
            fromCreated: filter.fromCreated,
            toCreated: filter.toCreated,
            fromHandover: filter.fromHandover,
            toHandover: filter.toHandover,
            showArchived: filter.showArchived,
        }),
        [filter]
    );

    const variables = useMemo(
        () => ({
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                search,
                ...queryFilter,
            },
            sort: {
                stagePriority: currentUser.type === UserType.Approver ? PurchaseTransactionStage.PendingApproval : null,
            },
        }),
        [page, queryFilter, currentUser.type, search]
    );

    const { data, refetch } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables,
    });

    const refetchList = useCallback(() => refetch(variables), [refetch, variables]);

    const setValues = useCallback(
        (filter: TransactionFilter) => {
            setFilterAndPersist({
                ...filter,
            });
        },
        [setFilterAndPersist]
    );

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext(state => ({ ...state, search: searchValue }));
        },
        [setListContext]
    );

    const count = data?.purchaseTransactions?.count || 0;
    const purchaseTransactions = data?.purchaseTransactions?.items || [];

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        ...queryFilter,
                    },
                    sort: {
                        stagePriority:
                            currentUser.type === UserType.Approver ? PurchaseTransactionStage.PendingApproval : null,
                    },
                });
            };

            attach(execute());
        },
        [attach, currentUser.type, downloadPurchaseTransactionsFile, queryFilter, search]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <PurchaseTransactionsHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                refreshFn={refetchList}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <PurchaseTransactionsBody purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <PurchaseTransactionsFooter />}
        </>
    );
};

export default PurchaseTransactionsList;
