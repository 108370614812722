import { Button, InputProps } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { useField } from 'formik';
import { useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NameOnlyFileDataFragment } from '../../../api';
import { useAlert } from '../../../components/AlertProvider';
import { useFlutter } from '../../../components/FlutterProvider';
import { useLoading } from '../../../components/LoadingProvider';
import { getExtension, getAcceptExtensions } from '../../../utilities/file';

export interface ProfilePictureButtonProps extends Omit<InputProps, 'value' | 'onChange'> {
    maxSize?: number;
    allowedExtensions?: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadPhotoButton: {
            position: 'absolute',
            width: theme.spacing(13),
            [theme.breakpoints.down('sm')]: {
                top: '-30px',
            },
        },
        uploadPhotoLabel: {
            flexDirection: 'column',
        },
    })
);

const ProfilePictureButton = ({ name, maxSize, allowedExtensions, ...props }: ProfilePictureButtonProps) => {
    const { t } = useTranslation(['errors']);
    const { show } = useAlert();
    const [, , { setValue }] = useField<File | NameOnlyFileDataFragment>(name);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const styles = useStyles();
    const { disabled } = props;
    const { deviceFingerPrint, uploadImage } = useFlutter();
    const { attach } = useLoading();

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.currentTarget.files[0];
            if (maxSize !== undefined && file.size > maxSize) {
                // display error message
                show('error', t('errors:largeFileSize', { fileSize: maxSize / 1000 / 1000 }));

                return;
            }

            if (allowedExtensions !== undefined) {
                const extension = getExtension(file.name).toLowerCase();

                if (!allowedExtensions.some(allowedExtension => allowedExtension === extension)) {
                    // display error message
                    show('error', t('errors:invalidFileType'));

                    return;
                }
            }

            // update value
            setValue(file);

            // then reset value
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
        },
        [setValue, maxSize, show, t, allowedExtensions]
    );

    const accept = useMemo(
        () => (allowedExtensions ? getAcceptExtensions(allowedExtensions).join(',') : ''),
        [allowedExtensions]
    );

    const onUploadFile = useCallback(() => {
        if (deviceFingerPrint) {
            const execute = async () => {
                const data = await uploadImage({
                    allowedMultiple: false,
                    allowedExtensions,
                });
                setValue(data?.[0]);
            };

            attach(execute());
        } else {
            fileInputRef.current.click();
        }
    }, [fileInputRef, deviceFingerPrint, allowedExtensions, attach, uploadImage, setValue]);

    return (
        <>
            <Button
                className={styles.uploadPhotoButton}
                classes={{ label: styles.uploadPhotoLabel }}
                color="secondary"
                disabled={disabled}
                onClick={onUploadFile}
            >
                <PhotoLibraryIcon />
                {t('profilePage:changePhoto')}
            </Button>
            <input ref={fileInputRef} accept={accept} onChange={onChange} style={{ display: 'none' }} type="file" />
        </>
    );
};

export default ProfilePictureButton;
