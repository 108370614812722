import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { DownloadFileType, PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { useLoading } from '../../../components/LoadingProvider';
import { PurchaseOrSalesTransactionTabType } from '../../SaleTransactions';
import {
    SaleTransactionsHeader,
    SaleTransactionsBody,
    DownloadSaleListFn,
    SaleTransactionsFooter,
} from '../../SaleTransactions/components';
import FilterDialog from './SalesFilterDialog';
import FilterMenu from './SalesFilterMenu';
import { SalesTransactionFilter } from './VtTransactions';

export type SalesHandoverTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadSalesTransactionsFile: DownloadSaleListFn;
    filter: SalesTransactionFilter;
    setFilterValue: (value: SalesTransactionFilter) => void;
    refreshFn?: () => Promise<unknown>;
    currentTab: PurchaseOrSalesTransactionTabType;
};

const SalesHandoverTab = ({
    search,
    currentTab,
    saleTransactions,
    page,
    pageSize,
    count,
    filter,
    searchOnChange,
    setActivePage,
    downloadSalesTransactionsFile,
    setFilterValue,
    refreshFn,
}: SalesHandoverTabProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [showFilterDialog, setShowFilterDialog] = useState(false);

    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const { attach } = useLoading();

    const queryFilter = useMemo(
        () => ({
            fromCreated: filter.fromCreated,
            toCreated: filter.toCreated,
            fromHandover: filter.fromHandover,
            toHandover: filter.toHandover,
            awardedDealer: filter.awardedDealer === 'all' ? null : filter.awardedDealer,
            stages: filter.stage === 'all' ? null : [filter.stage],
            showArchived: filter.showArchived,
        }),
        [filter]
    );

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        ...queryFilter,
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadSalesTransactionsFile, queryFilter, search]
    );

    const setValues = useCallback(
        (value: SalesTransactionFilter) => {
            setFilterValue(value);
        },
        [setFilterValue]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <SaleTransactionsHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                refreshFn={refreshFn}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <SaleTransactionsBody currentTab={currentTab} saleTransactions={saleTransactions} hideAwardedBitAmount />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <SaleTransactionsFooter />}
        </>
    );
};

export default SalesHandoverTab;
