import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useConfirmResetPasswordMutation, useRequestResetPasswordMutation } from '../../api';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { OtpConfigProps } from '../../routes/LoginRoute';
import { useRuntimeConfig } from '../../runtimeConfig';
import { useHandleError } from '../../utilities/handleErrors';
import OTPPage, { OTPFormValues } from '../OTPPage';

export type LoginPageValidationProps = {
    mobilePhone: string;
    next: (token: string) => void;
    otpConfig: OtpConfigProps;
};

const ResetPasswordValidation = ({ mobilePhone: number, next, otpConfig }: LoginPageValidationProps) => {
    const [token, setToken] = useState<string | null>(null);
    const { countryCode } = useRuntimeConfig();

    const [requestOTP] = useRequestResetPasswordMutation();
    const [confirmOTP] = useConfirmResetPasswordMutation();

    const mobilePhone = useMemo(() => ({ internationalCode: countryCode, number }), [number, countryCode]);

    const resendCode = useCallback(
        (sendByEmail?: boolean) => {
            requestOTP({ variables: { mobilePhone, sendOtpByEmail: sendByEmail } })
                .then(({ data }) => {
                    setToken(data.requestResetPassword.token);
                    otpConfig?.setEmail(data.requestResetPassword.email);
                })
                .catch(console.warn);
        },
        [setToken, requestOTP, mobilePhone, otpConfig]
    );

    const handleSubmit = useHandleError(
        async ({ code }: OTPFormValues) => {
            const { data } = await confirmOTP({ variables: { token, code: code.join(''), mobilePhone } });
            next(data.confirmResetPassword);
        },
        [confirmOTP, mobilePhone, token]
    );

    useEffect(() => {
        if (token === null) {
            resendCode();
        }
    }, [token, resendCode]);

    return <OTPPage handleSubmit={handleSubmit} otpConfig={otpConfig} resendCode={resendCode} />;
};

export default withErrorBoundary(ResetPasswordValidation);
