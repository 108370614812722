import { Grid, Typography, Box } from '@material-ui/core';
import { isNil, isBoolean } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullPurchaseTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import FileList from '../../../../components/attachments/FileList';
import useViewTransactionStyles from './useViewTransactionStyles';

export type HandoverTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const HandoverTab = ({ purchaseTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const viewTransactionStyles = useViewTransactionStyles();
    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.handoverDetails')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.personResponsible')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.personResponsible.fullName}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.targetHandoverDate')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.handover.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.targetHandoverTime')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.targetHandoverDateTime
                                        ? t('common:formats.time', {
                                              date: new Date(purchaseTransaction.handover.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.handoverLocation')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.handoverLocation || t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.actualHandoverDate')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.actualHandoverDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.handover.actualHandoverDate),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.purchaseAgreementNumber')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.purchaseAgreementNumber
                                        ? purchaseTransaction.vehicle.purchaseAgreementNumber
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.saleAgreementNumber')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.saleAgreementNumber
                                        ? purchaseTransaction.vehicle.saleAgreementNumber
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.handover.targetSetOfKeys
                                        ? purchaseTransaction.handover.targetSetOfKeys
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.targetHandbook')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {isNil(purchaseTransaction.handover.targetHandbook) && t('common:blankValue')}
                                    {!isNil(purchaseTransaction.handover.targetHandbook) &&
                                        (purchaseTransaction.handover.targetHandbook
                                            ? t('common:yes')
                                            : t('common:no'))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.setOfKeys')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.setOfKeys
                                        ? purchaseTransaction.vehicle.setOfKeys
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {!isBoolean(purchaseTransaction.vehicle.ownerHandbookCollected) &&
                                        t('common:blankValue')}
                                    {isBoolean(purchaseTransaction.vehicle.ownerHandbookCollected) &&
                                        (purchaseTransaction.vehicle.ownerHandbookCollected
                                            ? t('common:yes')
                                            : t('common:no'))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box>
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.handoverRemarks')}
                                </Typography>
                                <Typography>
                                    {purchaseTransaction.handover.remarks || t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box>
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.vpaAttachment')}
                                </Typography>
                                {purchaseTransaction.handover.vpaAttachment ? (
                                    <FileList files={[purchaseTransaction.handover.vpaAttachment]} />
                                ) : (
                                    <Typography>{t('common:blankValue')}</Typography>
                                )}
                            </Box>
                        </Grid>

                        {/* SC is not allowed */}
                        {currentUser.type !== UserType.SaleConsultant && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:handover.otherAttachments')}
                                    </Typography>
                                    {purchaseTransaction.handover.attachments.length > 0 ? (
                                        <FileList files={purchaseTransaction.handover.attachments} />
                                    ) : (
                                        <Typography>{t('common:blankValue')}</Typography>
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
