import React, { useEffect, useState } from 'react';
import { useGetSaleTransactionsQuery } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { SaleTransactionsBody } from '../../SaleTransactions/components';

const pageSize = 9;

const MyBids = () => {
    const [page, setPage] = useState(0);

    const { data } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                onlySelf: true,
            },
        },
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const count = data?.saleTransactions?.count || 0;
    const saleTransactions = data?.saleTransactions?.items || [];

    return (
        <>
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount hideAwardedDealer />
            <ListPagination
                activePage={page + 1}
                count={count}
                pageSize={pageSize}
                setActivePage={(newPage: number) => setPage(newPage)}
            />
        </>
    );
};

export default MyBids;
