import { AppBar, Box, Link, Toolbar, Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../useCommonStyles';
import usePublic from '../../utilities/usePublic';
import portalTheme from './portalTheme';
import usePortalStyles from './usePortalStyles';

export type PortalLayoutProps = {
    children: ReactElement | null;
    onBack?: () => void;
};

const PortalLayout = ({ children, onBack }: PortalLayoutProps): ReactElement => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();
    const classes = usePortalStyles();

    const eurokarsPreOwned = usePublic('assets/images/eurokarsPreOwned.svg');

    return (
        <MuiThemeProvider theme={portalTheme}>
            <div className={classes.root}>
                {onBack && (
                    <AppBar>
                        <Toolbar className={classes.toolBar}>
                            <Link className={classes.linkBack} color="secondary" component="button" onClick={onBack}>
                                <ArrowBackIosIcon />
                                <Typography className={commonStyles.onlyDesktopAndTablet}>
                                    {t('common:portalBackButton')}
                                </Typography>
                            </Link>
                            <Box
                                className={commonStyles.onlyDesktopAndTablet}
                                display="flex"
                                justifyContent="center"
                                position="absolute"
                                width="100%"
                            >
                                <img alt="logo" src={eurokarsPreOwned} />
                            </Box>
                        </Toolbar>
                    </AppBar>
                )}
                {children}
            </div>
        </MuiThemeProvider>
    );
};

export default PortalLayout;
