import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { omit } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    UpdateUserMutationVariables,
    useUpdateUserMutation,
    NameOnlyFileDataFragment,
    useUploadProfilePictureMutation,
} from '../../../api';
import { useAlert } from '../../../components/AlertProvider';
import { useHandleError } from '../../../utilities/handleErrors';
import ProfileContainer from './ProfileContainer';
import ProfileUpdateBody, { ProfileUpdateBodyProps } from './ProfileUpdateBody';
import ProfileUpdateFooter from './ProfileUpdateFooter';
import ProfileUpdateHeader, { ProfileHeaderProps } from './ProfileUpdateHeader';

export type ProfileUpdateProps = ProfileHeaderProps & ProfileUpdateBodyProps;

export type ProfileUpdateFormValues = UpdateUserMutationVariables['fields'] & {
    id: string;
    profilePicture: NameOnlyFileDataFragment | File;
};

const validate = (values: ProfileUpdateFormValues) => {};

const ProfileUpdate = ({ goToView, user }: ProfileUpdateProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation(['common']);
    const { show } = useAlert();

    const [updateUser] = useUpdateUserMutation();
    const [uploadProfilePicture] = useUploadProfilePictureMutation();

    const onSubmit = useHandleError(
        async ({ id, profilePicture, ...fields }: ProfileUpdateFormValues) => {
            let updateProfilePic = false;
            if (profilePicture && profilePicture instanceof File) {
                await uploadProfilePicture({
                    variables: { userId: id, file: profilePicture },
                });

                updateProfilePic = true;
            }

            await updateUser({
                variables: { id, fields, activityOption: { addProfilePictureActivity: updateProfilePic } },
            });

            show('success', t('common:successfulMessage.updatesSaved'));
            goToView();
        },
        [updateUser, goToView]
    );

    const initialValues = useMemo((): ProfileUpdateFormValues => {
        const values: ProfileUpdateFormValues = {
            id: user.id,
            fullName: user.fullName,
            email: user.email,
            type: user.type,
            mobilePhone: omit(['__typename'], user.mobilePhone),
            profilePicture: user.profilePicture,
        };

        switch (user.__typename) {
            case 'DealerUser':
                return {
                    ...values,
                    business: omit(['__typename'], user.business),
                };

            case 'SaleConsultantUser':
                return {
                    ...values,
                    brand: user.brand,
                };

            default:
                return values;
        }
    }, [user]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <ProfileUpdateHeader goToView={goToView} />
                    <Box ml={isSmall ? 2 : 0} mr={isSmall ? 2 : 0} position="relative">
                        <ProfileContainer>
                            <ProfileUpdateBody user={user} />
                        </ProfileContainer>
                        {isSmall && <ProfileUpdateFooter goToView={goToView} />}
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileUpdate;
