import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useGeneralConditionOptions = () => {
    const { t } = useTranslation(['generalConditionOptions']);

    return useMemo(() => {
        const labels = [t('generalConditionOptions:bodyOnly'), t('generalConditionOptions:wholecar')];

        return labels.map(label => ({ value: label, label }));
    }, [t]);
};

export default useGeneralConditionOptions;
