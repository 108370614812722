export const maxDealCount = 3;
export const topDealCount = 3;
export const maxBiddingEditableRecord = 10;
export const valuationPhotoDirName = 'valuationPhotos';
export const handoverAttachmentDirName = 'handoverAttachments';
export const profilePicturesDirName = 'profilePictures';
export const vpaAttachmentDirName = 'vpaAttachment';
export const salesTransactionHandoverAttachmentDirName = 'salesTransactionHandoverAttachments';
export const salesTransactionPhotosDirName = 'salesTransactionPhotos';
export const vsaAttachmentDirName = 'vsaAttachment';
export const maxHandoverAttachments = 10;
export const maxValuationPhotos = 10;
export const imageFileExtensions: string[] = ['jpg', 'jpeg', 'png'];
export const pdfFileExtension = 'pdf';
export const maxUploadFileSize = 10; // MB
export const maxSalesTransactionHandoverAttachments = 10;
export const contactPhone = '(65) 6603 6117';
export const contactEmail = 'tradeinpreowned@eurokars.com.sg';
export const maxSalesTransactionPhotos = 10;
export const cardDefaultImage = 'assets/vehicleLogos/others.svg';
export const purchaseTransactionTimeout = 30;
export const minPasswordLength = 12;
export const frontImageSize = { width: 400, height: 400 };
