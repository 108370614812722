import { Dialog, Slide, Transitions, Box, Typography, Button, DialogTitle, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import BooleanCheckbox from '../../../components/fields/BooleanCheckbox';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import usePurchaseTransactionStateFilterOptoins from '../../../utilities/usePurchaseTransactionStateFilterOptoins';
import useFilterMenuStyles from '../../PurchaseTransactions/components/useFilterMenuStyles';
import { PurchaseTransactionFilter } from './VtTransactions';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (value: PurchaseTransactionFilter) => void;
    filter: PurchaseTransactionFilter;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterMenu = ({ open, setOpen, setValues, filter }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterMenuStyles();
    const currentUser = useCurrentUser();

    const statuses = usePurchaseTransactionStateFilterOptoins();

    const onSubmit = useCallback(
        (values: PurchaseTransactionFilter) => {
            setValues(values);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('purchaseTransactionsPage:filterBy')}</Typography>

                <IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={filter} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <Box className={styles.content} display="flex" flexDirection="column">
                            {[UserType.ValuationTeam, UserType.SaleConsultant].includes(currentUser.type) && (
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('purchaseTransactionsPage:state')}
                                        name="stage"
                                        options={statuses}
                                    />
                                </Box>
                            )}
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:filter.fromCreated')}
                                    maxDate={values?.toCreated || undefined}
                                    name="fromCreated"
                                    placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('fromCreated', null)}
                                underline="none"
                            >
                                {t('purchaseTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:filter.toCreated')}
                                    minDate={values?.fromCreated || undefined}
                                    name="toCreated"
                                    placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('toCreated', null)}
                                underline="none"
                            >
                                {t('purchaseTransactionsPage:button.resetDate')}
                            </Link>
                            {currentUser.type === UserType.ValuationTeam && (
                                <>
                                    <Box mt={3} width="100%">
                                        <OutlinedDatePickerField
                                            color="secondary"
                                            label={t('purchaseTransactionsPage:filter.fromHandover')}
                                            maxDate={values?.toHandover || undefined}
                                            name="fromHandover"
                                            placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                        />
                                    </Box>
                                    <Link
                                        className={commonStyles.displayFlex}
                                        color="secondary"
                                        component="a"
                                        onClick={() => setFieldValue('fromHandover', null)}
                                        underline="none"
                                    >
                                        {t('purchaseTransactionsPage:button.resetDate')}
                                    </Link>
                                    <Box mt={3} width="100%">
                                        <OutlinedDatePickerField
                                            color="secondary"
                                            label={t('purchaseTransactionsPage:filter.toHandover')}
                                            minDate={values?.fromHandover || undefined}
                                            name="toHandover"
                                            placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                        />
                                    </Box>
                                    <Link
                                        className={commonStyles.displayFlex}
                                        color="secondary"
                                        component="a"
                                        onClick={() => setFieldValue('toHandover', null)}
                                        underline="none"
                                    >
                                        {t('purchaseTransactionsPage:button.resetDate')}
                                    </Link>
                                </>
                            )}

                            <Box mt={3} width="100%">
                                <BooleanCheckbox
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:filter.showArchived')}
                                    name="showArchived"
                                    placeholder={t('purchaseTransactionsPage:filter.showArchived')}
                                />
                            </Box>
                        </Box>
                        <Box className={styles.buttonContainer}>
                            <Button
                                className={styles.applyButton}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:filter.applyFilters')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterMenu;
