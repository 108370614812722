import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import ValuationForm from './ValuationForm';

const ValuationTab = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.valuation')} defaultExpanded>
                    <ValuationForm />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default ValuationTab;
