import { Paper, Box, FormControl, Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: theme.spacing(10),
        },
    })
);

const CreateBiddingSessionFooter = () => {
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    return (
        <Box bottom={0} position="fixed" width="100%">
            <Paper className={styles.paper} elevation={3}>
                <Box alignItems="center" display="flex" height="100%" ml={3} mr={3}>
                    <FormControl fullWidth>
                        <Button color="secondary" type="submit" variant="contained">
                            {t('common:save')}
                        </Button>
                    </FormControl>
                </Box>
            </Paper>
        </Box>
    );
};

export default CreateBiddingSessionFooter;
