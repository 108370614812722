import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import PortalLayout from '../layouts/PortalLayout';
import { ResetPasswordValidation, ResetPasswordPage } from '../pages/ResetPassword';
import { OtpConfigProps } from './LoginRoute';

type LocationState = { mobilePhone?: string };

const ResetPasswordRoute = () => {
    const history = useHistory();
    const { mobilePhone } = useLocation<LocationState>().state || {};
    const [token, setToken] = useState('');
    const [sendOtpByEmail, setSendOtpByEmail] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');

    const onBack = useCallback(() => history.push('/signIn', { mobilePhone }), [history, mobilePhone]);

    useEffect(() => {
        if (!mobilePhone) {
            // move to login page
            history.push('/signIn');
        }
    }, [mobilePhone, history]);

    if (!mobilePhone) {
        // nothing to render
        return null;
    }

    const toggleOtpType = useCallback(() => setSendOtpByEmail(prev => !prev), [setSendOtpByEmail]);
    const otpConfig: OtpConfigProps = {
        sendByEmail: sendOtpByEmail,
        toggle: toggleOtpType,
        email: otpEmail,
        setEmail: setOtpEmail,
    };

    return (
        <PortalLayout onBack={onBack}>
            {token ? (
                <ResetPasswordPage token={token} />
            ) : (
                <ResetPasswordValidation mobilePhone={mobilePhone} next={setToken} otpConfig={otpConfig} />
            )}
        </PortalLayout>
    );
};

export default ResetPasswordRoute;
