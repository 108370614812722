import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../useCommonStyles';
import onCloseDialog from '../utilities/constants/onCloseDialog';

export type ConfirmDialogProps = {
    show?: boolean;
    setHideConfirmDialog: () => void;
    setContinueConfirmDialog: () => void;
    title: string;
    body: string;
    cancelBtn?: string;
    continueBtn?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
    })
);

const ConfirmDialog = ({
    show,
    setHideConfirmDialog,
    setContinueConfirmDialog,
    title,
    body,
    cancelBtn,
    continueBtn,
}: ConfirmDialogProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(commonStyles.secondaryBgColor, styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary2}>{title}</Typography>
                    </Box>
                    <Typography className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>{body}</Typography>
                </Box>

                <Box display="flex" mt={3}>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={setHideConfirmDialog}
                        size="large"
                        variant="outlined"
                    >
                        {cancelBtn || t('common:cancel')}
                    </Button>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={setContinueConfirmDialog}
                        size="large"
                        variant="outlined"
                    >
                        {continueBtn || t('common:continue')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDialog;
