import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type useHistoricalReferenceFilterOptionsProps = {
    make: string;
    model: string;
    registrationDate: string;
};

const useHistoricalReferenceFilterOptions = ({
    make,
    model,
    registrationDate,
}: useHistoricalReferenceFilterOptionsProps) => {
    const { t } = useTranslation(['common', 'historicalReferenceFilterOptions']);

    return useMemo(() => {
        const options = [
            {
                value: t('historicalReferenceFilterOptions:make.value'),
                label: t('historicalReferenceFilterOptions:make.label', { make }),
            },
            {
                value: t('historicalReferenceFilterOptions:model.value'),
                label: t('historicalReferenceFilterOptions:model.label', { model }),
            },
            {
                value: t('historicalReferenceFilterOptions:registration.value'),
                label: t('historicalReferenceFilterOptions:registration.label', { registrationDate }),
            },
        ];

        return options;
    }, [t, make, model, registrationDate]);
};

export default useHistoricalReferenceFilterOptions;
