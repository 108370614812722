import { Box, Button, Dialog, DialogContent, FormControl, Typography } from '@material-ui/core';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../../useCommonStyles';
import onCloseDialog from '../../../utilities/constants/onCloseDialog';
import usePublic from '../../../utilities/usePublic';

export type ResetPasswordSuccessDialogProps = { show?: boolean };

const ResetPasswordSuccessDialog = ({ show }: ResetPasswordSuccessDialogProps) => {
    const { t } = useTranslation(['resetPasswordPage']);
    const commonStyles = useCommonStyles();
    const history = useHistory();

    const verified = usePublic('assets/icons/verified.svg');

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={commonStyles.secondaryBgColor}>
                <Box mb={4} ml={2} mr={2} mt={2} textAlign="center">
                    <img alt="verified-icon" src={verified} />
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary2}>
                            {t('resetPasswordPage:successDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>
                        {t('resetPasswordPage:successDialog.body')}
                    </Typography>
                    <Box mt={3}>
                        <FormControl fullWidth>
                            <Button
                                className={cs(commonStyles.primary2, commonStyles.primary2Border)}
                                onClick={() => history.push('/signIn')}
                                size="large"
                                variant="outlined"
                            >
                                {t('resetPasswordPage:successDialog.button')}
                            </Button>
                        </FormControl>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ResetPasswordSuccessDialog;
