import { Drawer, Grid, Box, Button, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useUpdateTransactionToCompleteMutation } from '../../../../api';
import { useAlert } from '../../../../components/AlertProvider';
import BooleanCheckbox from '../../../../components/fields/BooleanCheckbox';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import useViewTransactionStyles from './useViewTransactionStyles';

export type FormValues = {
    setOfKeys: number;
    ownerHandbookCollected: boolean;
    handoverRemarks: string;
};

export type CompleteTransactionDrawerProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    open: boolean;
    onClose: () => void;
};

const formValidator = validators.compose(
    validators.requiredNumber('setOfKeys'),
    validators.validInteger('setOfKeys'),
    validators.requiredString('handoverRemarks')
);

const CompleteTransactionDrawer = ({ purchaseTransaction, open, onClose }: CompleteTransactionDrawerProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const [updateToComplete] = useUpdateTransactionToCompleteMutation();
    const viewTransactionStyles = useViewTransactionStyles();
    const { show } = useAlert();

    const initialValues: FormValues = {
        setOfKeys: purchaseTransaction.vehicle.setOfKeys,
        ownerHandbookCollected: !!purchaseTransaction.vehicle.ownerHandbookCollected,
        handoverRemarks: purchaseTransaction.handover.remarks,
    };

    const onSubmit = useHandleError(
        async ({ setOfKeys, ownerHandbookCollected, handoverRemarks }: FormValues) => {
            await updateToComplete({
                variables: {
                    transactionId: purchaseTransaction.id,
                    setOfKeys,
                    ownerHandbookCollected,
                    handoverRemarks,
                },
            });

            show('success', t('purchaseTransactionsPage:successfulMessage.transactionCompleted'));
            onClose();
        },
        [updateToComplete]
    );

    const validate = useValidator(formValidator);

    return (
        <Drawer anchor="bottom" onClose={onClose} open={open}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {() => (
                    <Form>
                        <Box p={2}>
                            <Grid spacing={2} container>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={viewTransactionStyles.label}>
                                            {t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                                        </Typography>
                                        <Typography className={viewTransactionStyles.content}>
                                            {purchaseTransaction.handover.targetSetOfKeys
                                                ? purchaseTransaction.handover.targetSetOfKeys
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={viewTransactionStyles.label}>
                                            {t('purchaseTransactionsPage:handover.targetHandbook')}
                                        </Typography>
                                        <Typography className={viewTransactionStyles.content}>
                                            {isNil(purchaseTransaction.handover.targetHandbook) &&
                                                t('common:blankValue')}
                                            {!isNil(purchaseTransaction.handover.targetHandbook) &&
                                                (purchaseTransaction.handover.targetHandbook
                                                    ? t('common:yes')
                                                    : t('common:no'))}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                                        name="setOfKeys"
                                        placeholder={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <BooleanCheckbox
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                                        name="ownerHandbookCollected"
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:handover.handoverRemarks')}
                                        name="handoverRemarks"
                                        placeholder={t('purchaseTransactionsPage:handover.handoverRemarks')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button color="secondary" type="submit" variant="contained">
                                            {t('purchaseTransactionsPage:button.complete')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default CompleteTransactionDrawer;
