import FlutterAction from '../FlutterAction';

export type FlutterUploadFile = {
    fileName: string;
    extension: string;
    // base64 content, ex: data:image/jpeg;base64,/9j/4AAQSkZJ
    fileContent: string;
    mimeType: string;
};
export type UploadType = 'pick_file' | 'pick_image';
type Response = { files: FlutterUploadFile[]; success: boolean; uploadType: UploadType };

export type UploadFileContext = {
    allowedExtensions: string[];
    allowedMultiple: boolean;
};

const uploadFileHandler = async (response: Response) => {
    const files = response.files?.map(async f => {
        const blob = await (await fetch(f.fileContent)).blob();
        const file = new File([blob], f.fileName, { type: f.mimeType });

        return file;
    });

    return Promise.all(files);
};

const pickFileAction = new FlutterAction<
    ({ allowedExtensions, allowedMultiple }: UploadFileContext) => void,
    Response,
    File[]
>('pick_file', uploadFileHandler);

const pickImageAction = new FlutterAction<
    ({ allowedExtensions, allowedMultiple }: UploadFileContext) => void,
    Response,
    File[]
>('pick_image', uploadFileHandler);

export { pickFileAction, pickImageAction };
