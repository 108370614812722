import { Button, FormControl, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { BiddingStatus, FullSaleTransactionDataFragment, UserType } from '../../../../api';
import BiddingDrawer from './BiddingDrawer';

export type BiddingBlockProps = {
    transaction: FullSaleTransactionDataFragment;
};

const BiddingBlock = ({ transaction }: BiddingBlockProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const user = useCurrentUser();
    const [open, setOpen] = useState(false);

    const { latestBiddingSessionEntry, latestBiddingSession } = transaction;

    if (user.type !== UserType.Dealer) {
        // only dealer can big
        return null;
    }

    if (!latestBiddingSession || latestBiddingSession.status !== BiddingStatus.Current || !latestBiddingSessionEntry) {
        // session not yet started
        return null;
    }

    if (!latestBiddingSessionEntry.remainDealerBiddingEntryCount) {
        // no more bidding
        return null;
    }

    return (
        <Grid xs={12} item>
            <FormControl fullWidth>
                <Button color="secondary" onClick={() => setOpen(true)} variant="contained">
                    {t('saleTransactionsPage:button.bid')}
                </Button>
            </FormControl>
            <BiddingDrawer onClose={() => setOpen(false)} open={open} transaction={transaction} />
        </Grid>
    );
};

export default BiddingBlock;
