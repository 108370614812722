import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import usePublic from '../../../../utilities/usePublic';

export type AcceptValuationConfirmDialogProps = {
    show?: boolean;
    resolve: (result: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
        body: {
            whiteSpace: 'pre-line',
        },
    })
);

const DuplicateTransactionsConfirmDialog = ({ show, resolve }: AcceptValuationConfirmDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const exclamation = usePublic('assets/icons/exclamation.svg');

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(commonStyles.secondaryBgColor, styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <img alt="exclamation-icon" src={exclamation} />
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary2}>
                            {t('purchaseTransactionsPage:duplicateTransactionsDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={cs(styles.body, commonStyles.smallFontSize, commonStyles.primary2)}>
                        {t('purchaseTransactionsPage:duplicateTransactionsDialog.body')}
                    </Typography>
                </Box>

                <Box display="flex" mt={3}>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={() => resolve(true)}
                        size="large"
                        variant="outlined"
                    >
                        {t('purchaseTransactionsPage:acceptDialog.button.cancel')}
                    </Button>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={() => resolve(false)}
                        size="large"
                        variant="outlined"
                    >
                        {t('purchaseTransactionsPage:acceptDialog.button.continue')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DuplicateTransactionsConfirmDialog;
