import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useViewTransactionStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#717073',
        },
        content: {
            marginLeft: 'auto',
            textAlign: 'right',
        },
        duplicateTransactionLabel: {
            color: '#fff',
            backgroundColor: '#ff0000',
            padding: '0px 5px',
            borderRadius: '5px',
            height: 'fit-content',
        },
    })
);

export default useViewTransactionStyles;
