import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryPeriodType } from './useDeliveryPeriodOptions';

const useDeliveryPeriod = (deliveryPeriod: number) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return useMemo(() => {
        switch (deliveryPeriod) {
            case DeliveryPeriodType.Bids6Months3:
                return t('purchaseTransactionsPage:filter.bids6Months3');

            case DeliveryPeriodType.Bids4Months2:
                return t('purchaseTransactionsPage:filter.bids4Months2');

            case DeliveryPeriodType.IndentOrder:
                return t('purchaseTransactionsPage:filter.indentOrder');

            default:
                return t('purchaseTransactionsPage:filter.immediate1month');
        }
    }, [deliveryPeriod, t]);
};

export default useDeliveryPeriod;
