import { Box, Button, FormControl } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AccountUpdateFields,
    FullUserDataFragment,
    useEnableAccountMutation,
    useDisableAccountMutation,
    useApproveAccountMutation,
    useRejectAccountMutation,
    UserActivationStage,
    UserType,
    useUpdateUserMutation,
} from '../../../api';
import Dialog from '../../../components/Dialog';
import { useHandleError } from '../../../utilities/handleErrors';
import useBrandOptions, { defaultOtherBrandOption } from '../../../utilities/useBrandOptions';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import UserEditForm from '../Forms/UserEditForm';

export type UserEditDialogProps = {
    user: FullUserDataFragment;
    open: boolean;
    onClose: () => void;
    onUpdates: () => void;
};

export type UserEditDialogForm = {
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    brand: { main: string; other?: string };
    mobilePhone: { internationalCode: string; number: string };
    type: UserType;
    business?: {
        name: string;
        address: string;
        registrationNumber: string;
    };
};

const formValidator = validators.compose(
    validators.requiredString('email'),
    validators.validEmail('email'),
    validators.requiredString('fullName'),
    validators.requiredString('type'),
    validators.requiredString('fullName'),
    validators.requiredString('mobilePhone.number'),

    // validation for sale consultant
    validators.only(
        ({ type }) => type === UserType.SaleConsultant,
        validators.compose(
            validators.requiredString('brand.main'),
            validators.only(
                ({ brand: { main } }) => main === defaultOtherBrandOption,
                validators.requiredString('brand.other')
            )
        )
    ),

    // validation for dealer
    validators.only(
        ({ type }) => type === UserType.Dealer,
        validators.compose(
            validators.requiredString('business.name'),
            validators.requiredString('business.address'),
            validators.requiredString('business.registrationNumber')
        )
    )
);

const UserEditDialog = ({ user, onClose, open, onUpdates }: UserEditDialogProps) => {
    const { t } = useTranslation(['usersPage', 'common']);
    const userTypes = useBrandOptions();

    const [updateUser] = useUpdateUserMutation();

    const validate = useValidator(formValidator);

    const [enable] = useEnableAccountMutation();
    const [disable] = useDisableAccountMutation();
    const [approve] = useApproveAccountMutation();
    const [reject] = useRejectAccountMutation();

    const onSubmit = useHandleError(
        async ({ id, ...rest }: UserEditDialogForm) => {
            const updateFields: AccountUpdateFields = {
                email: rest.email,
                fullName: rest.fullName,
                mobilePhone: { ...rest.mobilePhone },
                type: rest.type,
                brand: rest.brand.other || rest.brand.main,
                business: { ...rest.business },
            };

            if (updateFields.type !== UserType.Dealer) {
                // remove business
                updateFields.business = undefined;
            }

            const variables = { userId: id };

            // TODO: use a function that can be reused later
            // const updateUserFn = useCallback(async (stage) => {
            //     const updateUserAsync = async () => {
            //         await updateUser({ variables: { id, fields: updateFields, activityOption: { stage } } });
            //     }

            // or
            // await updateUser({ variables: { id, fields: updateFields, activityOption: { stage } } });

            //     await updateUserAsync();
            // }, [id, updateFields, updateUser]);

            // update user activation stage
            if (user.activationStage === UserActivationStage.New) {
                switch (rest.activationStage) {
                    case UserActivationStage.Active:
                        await updateUser({
                            variables: { id, fields: updateFields, activityOption: { stage: rest.activationStage } },
                        });
                        await approve({ variables });
                        break;
                    case UserActivationStage.Rejected:
                        await updateUser({
                            variables: { id, fields: updateFields, activityOption: { stage: rest.activationStage } },
                        });
                        await reject({ variables });
                        break;
                    default:
                        await updateUser({
                            variables: { id, fields: updateFields },
                        });
                        break;
                }
            } else if (user.activationStage !== rest.activationStage) {
                switch (rest.activationStage) {
                    case UserActivationStage.Active:
                        await updateUser({
                            variables: { id, fields: updateFields, activityOption: { stage: rest.activationStage } },
                        });
                        await enable({ variables });
                        break;
                    case UserActivationStage.Disabled:
                        await updateUser({
                            variables: { id, fields: updateFields, activityOption: { stage: rest.activationStage } },
                        });
                        await disable({ variables });
                        break;
                    default:
                        await updateUser({
                            variables: { id, fields: updateFields },
                        });
                        break;
                }
            } else {
                await updateUser({
                    variables: { id, fields: updateFields },
                });
            }

            // then go back from where we came
            await onUpdates();

            onClose();
        },
        [updateUser, onClose, enable, disable]
    );

    const getBrandFieldValue = useCallback(
        (brandName: string) =>
            userTypes.find(type => brandName === type.value)
                ? { main: brandName }
                : { main: defaultOtherBrandOption, other: brandName },
        [userTypes]
    );

    const initialValues = useMemo(
        () =>
            user
                ? {
                      id: user.id,
                      email: user.email,
                      fullName: user.fullName,
                      mobilePhone: { ...user.mobilePhone },
                      type: user.type,
                      activationStage: user.activationStage,
                      brand: user.__typename === 'SaleConsultantUser' ? getBrandFieldValue(user.brand) : '',
                      business: user.__typename === 'DealerUser' ? { ...user.business } : undefined,
                  }
                : {},
        [getBrandFieldValue, user]
    );

    if (!user) {
        return null;
    }

    return (
        <Dialog onCloseClick={onClose} open={open} title={t('usersPage:editDialog.title')}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {({ isSubmitting }) => (
                    <Form>
                        <UserEditForm user={user} />
                        <Box alignItems="center" display="flex" mt={2}>
                            <FormControl fullWidth>
                                <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                                    {t('common:save')}
                                </Button>
                            </FormControl>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default UserEditDialog;
