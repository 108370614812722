import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { FieldArray, useFormikContext } from 'formik';
import { getOr } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import {
    maxValuationPhotos,
    maxUploadFileSize,
    pdfFileExtension,
    imageFileExtensions,
} from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { DealerInformation, UserType, ValuatedValuation } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import { AttachmentButton, FrontPhotoManager } from '../../../../components/attachments';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useGeneralConditionOptions from '../../../../utilities/useGeneralConditionOptions';
import DealerInformationItem from './DealerInformationItem';
import ValuatedValuationItem from './ValuatedValuationItem';
import { PurchaseTransactionFormValues } from '.';

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const ValuationForm = () => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const currentUser = useCurrentUser();
    const { values } = useFormikContext<PurchaseTransactionFormValues>();
    const generalConditionOptions = useGeneralConditionOptions();
    const commonStyles = useCommonStyles();

    return (
        <Grid spacing={2} container>
            {(values?.valuation?.valuatedValuations || []).length > 0 && (
                <Grid xs={12} item>
                    <Accordion title={t('purchaseTransactionsPage:accordionTitle.valuationHistory')} noBorder>
                        <Grid spacing={1} container>
                            <FieldArray
                                name="valuation.valuatedValuations"
                                render={() =>
                                    getOr([], 'valuation.valuatedValuations', values).map(
                                        (value: ValuatedValuation, index: number) => (
                                            <ValuatedValuationItem
                                                key={`valuatedValuations-${index.toString()}`}
                                                name={`valuation.valuatedValuations[${index}]`}
                                            />
                                        )
                                    )
                                }
                            />
                        </Grid>
                    </Accordion>
                </Grid>
            )}
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={
                        currentUser.type === UserType.SaleConsultant ||
                        (currentUser.type === UserType.ValuationTeam &&
                            values.handover?.personResponsible !== currentUser.id)
                    }
                    label={t('purchaseTransactionsPage:valuation.latestValuation')}
                    name="valuation.latestValuation"
                    placeholder={t('purchaseTransactionsPage:valuation.latestValuation')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={currentUser.type !== UserType.Admin}
                    label={t('purchaseTransactionsPage:valuation.actualValuation')}
                    name="valuation.actualValuation"
                    placeholder={t('purchaseTransactionsPage:valuation.actualValuation')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    name="valuation.valuatedBy"
                    placeholder={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    disabled
                />
            </Grid>
            {[UserType.Admin, UserType.ValuationTeam, UserType.Approver, UserType.SaleConsultant].includes(
                currentUser.type
            ) && (
                <Grid xs={12} item>
                    <OutlinedSelectField
                        color="secondary"
                        disabled={currentUser.type === UserType.SaleConsultant}
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillSecondary,
                            },
                        }}
                        label={t('purchaseTransactionsPage:valuation.generalCondition')}
                        name="valuation.generalCondition"
                        options={generalConditionOptions}
                        placeholder={t('purchaseTransactionsPage:valuation.generalCondition')}
                    />
                </Grid>
            )}

            {[UserType.Admin, UserType.ValuationTeam, UserType.Approver].includes(currentUser.type) && (
                <FieldArray
                    name="valuation.dealerInformations"
                    render={() =>
                        getOr([], 'valuation.dealerInformations', values).map(
                            (value: DealerInformation, index: number) => (
                                <DealerInformationItem
                                    key={`dealerInformation-${index.toString()}`}
                                    index={index}
                                    name={`valuation.dealerInformations[${index}]`}
                                />
                            )
                        )
                    }
                />
            )}

            {/* SC is not allowed */}
            {currentUser.type !== UserType.SaleConsultant && (
                <Grid xs={12} item>
                    <FrontPhotoManager attachmentFieldName="valuation.photos" name="frontPagePhoto">
                        {renderPrefix => (
                            <AttachmentButton
                                allowedExtensions={allowedExtensions}
                                buttonText={t('common:upload')}
                                icon={<PublishIcon fontSize="small" />}
                                label={t('purchaseTransactionsPage:valuation.photos')}
                                max={maxValuationPhotos}
                                maxSize={maxFileSizeInKB}
                                name="valuation.photos"
                                renderPrefix={renderPrefix}
                                uploadFileType="pick_image"
                                multiple
                            />
                        )}
                    </FrontPhotoManager>
                </Grid>
            )}
        </Grid>
    );
};

export default ValuationForm;
