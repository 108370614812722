import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum DeliveryPeriodType {
    Immediate1month = 1,
    Bids4Months2 = 2,
    Bids6Months3 = 3,
    IndentOrder = 4,
}

const useDeliveryPeriod = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return useMemo(
        () => [
            {
                value: DeliveryPeriodType.Bids6Months3,
                label: t('purchaseTransactionsPage:filter.bids6Months3'),
            },
            {
                value: DeliveryPeriodType.Bids4Months2,
                label: t('purchaseTransactionsPage:filter.bids4Months2'),
            },
            {
                value: DeliveryPeriodType.Immediate1month,
                label: t('purchaseTransactionsPage:filter.immediate1month'),
            },
            {
                value: DeliveryPeriodType.IndentOrder,
                label: t('purchaseTransactionsPage:filter.indentOrder'),
            },
        ],
        [t]
    );
};

export default useDeliveryPeriod;
