import { IconButton, Box, Typography, Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MobileAppBar from '../../../components/MobileAppBar';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';

export type ProfileHeaderProps = {
    goToEdition: () => void;
};

const ProfileHeader = ({ goToEdition }: ProfileHeaderProps) => {
    const { t } = useTranslation(['profilePage, common']);
    const classes = useBasicStyles();
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MobileAppBar>
                <IconButton color="secondary" onClick={history.goBack}>
                    <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                </IconButton>
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography className={commonStyles.fontWeightBold} color="secondary">
                        {t('profilePage:title')}
                    </Typography>
                </Box>
                <IconButton color="secondary" onClick={goToEdition}>
                    <EditIcon />
                </IconButton>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={classes.title} display="flex">
            <Typography>{t('profilePage:title')}</Typography>
            <Box ml="auto">
                <Button color="secondary" onClick={goToEdition} size="large" variant="contained">
                    {t('common:edit')}
                </Button>
            </Box>
        </Box>
    );
};

export default ProfileHeader;
