import { Dialog, Slide, Transitions, Box, Typography, Button, DialogTitle, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionFilter } from '..';
import { useCurrentUser } from '../../../Session';
import { UserType, useGetAwardedDealersQuery } from '../../../api';
import BooleanCheckbox from '../../../components/fields/BooleanCheckbox';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import useSalesTransactionStateFilterOptoins from '../../../utilities/useSalesTransactionStateFilterOptoins';
import useFilterMenuStyles from './useFilterMenuStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (value: TransactionFilter) => void;
    filter: TransactionFilter;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterMenu = ({ open, setOpen, setValues, filter }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterMenuStyles();
    const currentUser = useCurrentUser();

    const statuses = useSalesTransactionStateFilterOptoins();

    const onSubmit = useCallback(
        (values: TransactionFilter) => {
            setValues(values);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    const { data: awardedDealersData } = useGetAwardedDealersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const awardedDealerOptions = useMemo(
        () => [
            { value: 'all', label: t('saleTransactionsPage:filter.all') },
            ...(awardedDealersData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        ],
        [awardedDealersData, t]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('saleTransactionsPage:filterBy')}</Typography>

                <IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={filter} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <Box className={styles.content} display="flex" flexDirection="column">
                            <Box mt={3} width="100%">
                                <OutlinedSelectField
                                    color="secondary"
                                    inputProps={{
                                        classes: {
                                            icon: commonStyles.fillSecondary,
                                        },
                                    }}
                                    label={t('saleTransactionsPage:state')}
                                    name="stage"
                                    options={statuses}
                                />
                            </Box>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.fromCreated')}
                                    maxDate={values?.toCreated || undefined}
                                    name="fromCreated"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('fromCreated', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.toCreated')}
                                    minDate={values?.fromCreated || undefined}
                                    name="toCreated"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('toCreated', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                            {currentUser.type === UserType.Admin && (
                                <>
                                    <Box mt={3} width="100%">
                                        <OutlinedDatePickerField
                                            color="secondary"
                                            label={t('saleTransactionsPage:filter.fromHandover')}
                                            maxDate={values?.toHandover || undefined}
                                            name="fromHandover"
                                            placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                        />
                                    </Box>
                                    <Link
                                        className={commonStyles.displayFlex}
                                        color="secondary"
                                        component="a"
                                        onClick={() => setFieldValue('fromHandover', null)}
                                        underline="none"
                                    >
                                        {t('saleTransactionsPage:button.resetDate')}
                                    </Link>
                                    <Box mt={3} width="100%">
                                        <OutlinedDatePickerField
                                            color="secondary"
                                            label={t('saleTransactionsPage:filter.toHandover')}
                                            minDate={values?.fromHandover || undefined}
                                            name="toHandover"
                                            placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                        />
                                    </Box>
                                    <Link
                                        className={commonStyles.displayFlex}
                                        color="secondary"
                                        component="a"
                                        onClick={() => setFieldValue('toHandover', null)}
                                        underline="none"
                                    >
                                        {t('saleTransactionsPage:button.resetDate')}
                                    </Link>
                                </>
                            )}
                            <Box mt={3} width="100%">
                                <OutlinedSelectField
                                    color="secondary"
                                    inputProps={{
                                        classes: {
                                            icon: commonStyles.fillSecondary,
                                        },
                                    }}
                                    label={t('saleTransactionsPage:filter.awardedDealer')}
                                    name="awardedDealer"
                                    options={awardedDealerOptions}
                                />
                            </Box>

                            <Box mt={3} width="100%">
                                <BooleanCheckbox
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.showArchived')}
                                    name="showArchived"
                                    placeholder={t('saleTransactionsPage:filter.showArchived')}
                                />
                            </Box>
                        </Box>
                        <Box className={styles.buttonContainer}>
                            <Button
                                className={styles.applyButton}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('saleTransactionsPage:filter.applyFilters')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterMenu;
