import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { PreviewUserDataFragment } from '../../../../../api';
import useCommonStyles from '../../../../../useCommonStyles';

export type UsersRowProps = {
    dealer: PreviewUserDataFragment;
    checkbox: JSX.Element;
};

const CreateSessionDealerListingItem = ({ dealer, checkbox }: UsersRowProps) => {
    const commonStyles = useCommonStyles();

    // TODO: extract dealer data type from userDataFragment and remove this function
    // export type PreviewDealerUserDataFragment = Extract<PreviewUserDataFragment, { __typename: 'DealerUser' }>;
    const getAdditionalInfo = useCallback((userInfo: PreviewUserDataFragment) => {
        switch (userInfo.__typename) {
            case 'DealerUser':
                return (
                    <>
                        {userInfo.business.name}, {userInfo.business.registrationNumber}, {userInfo.business.address}
                    </>
                );
            default:
                return '';
        }
    }, []);

    return (
        <TableRow className={commonStyles.tableRow}>
            <TableCell>{checkbox}</TableCell>
            <TableCell>{dealer.fullName}</TableCell>
            <TableCell>{dealer.email}</TableCell>
            <TableCell>{dealer.mobilePhone.number}</TableCell>
            <TableCell>{dealer.type}</TableCell>
            <TableCell>{getAdditionalInfo(dealer)}</TableCell>
        </TableRow>
    );
};

export default CreateSessionDealerListingItem;
