import { Box, Grid, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PreviewPurchaseTransactionDataFragment } from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import useCommonStyles from '../../../useCommonStyles';
import useDeliveryPeriod from '../../../utilities/useDeliveryPeriod';
import usePurchaseTransactionStatus from '../../../utilities/usePurchaseTransactionStatus';
import useVehicleLogo from '../../../utilities/useVehicleLogo';
import { PurchaseOrSalesTransactionTabType } from '../../SaleTransactions';

export type Vehicle = {
    model: string;
    registrationNo: string;
    kilometer: number;
};

export type PurchaseTransaction = {
    id: string;
    stage: string;
    vehicle: Vehicle;
    updatedAt: Date;
    createdAt: Date;
};

export type PurchaseTransactionsItemProps = {
    purchaseTransaction: PreviewPurchaseTransactionDataFragment;
    currentTab: PurchaseOrSalesTransactionTabType;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
            '& .MuiGrid-item:nth-last-child(-n+2)': {
                opacity: '0.6',
            },
        },
        label: {
            marginRight: theme.spacing(1),
        },
        redBackground: {
            backgroundColor: 'red',
        },
        yellowBackground: {
            backgroundColor: '#E6B50D',
        },
    })
);

const PurchaseTransactionsItem = ({ purchaseTransaction, currentTab }: PurchaseTransactionsItemProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const history = useHistory();
    const vehicleLogo = useVehicleLogo(purchaseTransaction.vehicle.make);
    const purchaseTransactionStatus = usePurchaseTransactionStatus(purchaseTransaction.stage);
    const { pathname } = useLocation();

    const deliveryPeriod = useDeliveryPeriod(purchaseTransaction.handover.deliveryPeriod);
    const {
        vehicle: { opcCashRebateAmount, opcCashRebateEligibility, opcCashRebateEligibilityExpiryDate },
    } = purchaseTransaction;

    const cardbackgroundColor = useMemo(() => {
        const isOpcValid = opcCashRebateAmount || opcCashRebateEligibility || opcCashRebateEligibilityExpiryDate;
        const isRegistrationDateDifferent = !dayjs(purchaseTransaction.vehicle.originalRegistrationDate).isSame(
            purchaseTransaction.vehicle.firstRegistrationDate,
            'day'
        );

        // if both 'OPC' and 'Original Registration date is not the same as First Registration date',
        // apply OPC display for PT card title
        if (isOpcValid) {
            return styles.redBackground;
        }

        return isRegistrationDateDifferent ? styles.yellowBackground : '';
    }, [
        opcCashRebateAmount,
        opcCashRebateEligibility,
        opcCashRebateEligibilityExpiryDate,
        purchaseTransaction.vehicle.originalRegistrationDate,
        purchaseTransaction.vehicle.firstRegistrationDate,
        styles.redBackground,
        styles.yellowBackground,
    ]);

    const historyState: LocationHistory = useMemo(
        () => ({
            from: pathname,
            resetPurchaseTransactionsFilter: false,
            transactionApprovalTab: 'purchaseTransactions',
            myTransactionsTab: currentTab,
        }),
        [pathname, currentTab]
    );

    return (
        <Grid lg={4} md={6} sm={12} xs={12} item>
            <Card elevation={0}>
                <CardActionArea
                    onClick={() => {
                        history.replace(pathname, historyState);
                        history.push(`/purchaseTransaction/${purchaseTransaction.id}`);
                    }}
                >
                    <CardContent className={styles.content}>
                        <Box className={cs(styles.header, cardbackgroundColor)}>
                            <Box display="flex">
                                <Typography className={commonStyles.primary2}>
                                    {purchaseTransaction.vehicle.make}&nbsp;{purchaseTransaction.vehicle.model}
                                </Typography>
                                <Typography className={commonStyles.transactionStatus}>
                                    {purchaseTransactionStatus}
                                </Typography>
                                <ChevronRightIcon color="secondary" />
                            </Box>
                            <Typography className={commonStyles.primary2}>
                                {purchaseTransaction.vehicle.number}
                            </Typography>
                        </Box>
                        <Box alignItems="center" className={styles.body} display="flex">
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <Typography className={commonStyles.smallFontSize}>
                                        {t('common:formats.longDate', {
                                            date: new Date(purchaseTransaction.vehicle.originalRegistrationDate),
                                        })}
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        {purchaseTransaction.vehicle.mileage.toLocaleString()}
                                        &nbsp;
                                        {t('common:shortKilometer')}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                            {t('purchaseTransactionsPage:handover.deliveryPeriod')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>{deliveryPeriod}</Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography
                                            className={cs(commonStyles.smallFontSize, styles.label)}
                                            color="secondary"
                                        >
                                            {t('purchaseTransactionsPage:label.latestValidation')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>
                                            {t('common:currency')}{' '}
                                            {(
                                                purchaseTransaction.valuation?.latestValuatedValuation?.value || 0
                                            ).toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                            {t('purchaseTransactionsPage:handover.personResponsible')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>
                                            {purchaseTransaction.handover?.personResponsible?.fullName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {purchaseTransaction?.handover?.assignPersonResponsibleAt && (
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                                {t('purchaseTransactionsPage:handover.purchaserAssignedOn')}:
                                            </Typography>
                                            <Typography className={commonStyles.smallFontSize}>
                                                {t('common:formats.longDateTime', {
                                                    date: new Date(
                                                        purchaseTransaction.handover.assignPersonResponsibleAt
                                                    ),
                                                })}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}

                                {purchaseTransaction.valuation?.latestValuatedValuation?.valuatedBy &&
                                    purchaseTransaction.handover?.personResponsible?.id !==
                                        purchaseTransaction.valuation?.latestValuatedValuation?.valuatedBy?.id && (
                                        <Grid xs={12} item>
                                            <Box display="flex">
                                                <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                                    {t('purchaseTransactionsPage:valuation.valuatedBy')}:
                                                </Typography>
                                                <Typography className={commonStyles.smallFontSize}>
                                                    {
                                                        purchaseTransaction.valuation?.latestValuatedValuation
                                                            ?.valuatedBy?.fullName
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}

                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                            {t('purchaseTransactionsPage:transactionDetails.assignedSC')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>
                                            {purchaseTransaction.saleConsultant?.fullName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                            {t('purchaseTransactionsPage:label.createdOn')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>
                                            {t('common:formats.longDateTime', {
                                                date: new Date(purchaseTransaction.createdAt),
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                            {t('purchaseTransactionsPage:label.updatedOn')}:
                                        </Typography>
                                        <Typography className={commonStyles.smallFontSize}>
                                            {t('common:formats.longDateTime', {
                                                date: new Date(purchaseTransaction.updatedAt),
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default PurchaseTransactionsItem;
