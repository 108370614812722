import { Dialog, Slide, Transitions, Link, Box, Typography, Button } from '@material-ui/core';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BiddingFilter, defaultBiddingFilter } from '..';
import MobileAppBar from '../../../components/MobileAppBar';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import useCommonStyles from '../../../useCommonStyles';
import useFilterDialogStyles from '../../SaleTransactions/components/useFilterDialogStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (value: BiddingFilter) => void;
    filter: BiddingFilter;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterDialog = ({ open, setOpen, setValues, filter }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterDialogStyles();

    const onSubmit = useCallback(
        (values: BiddingFilter) => {
            setValues(values);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open} fullScreen>
            <Formik initialValues={filter} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                    <>
                        <MobileAppBar>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setOpen(false)}
                                underline="none"
                            >
                                {t('common:cancel')}
                            </Link>
                            <Box display="flex" justifyContent="center" width="100%">
                                <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                                    {t('common:filter')}
                                </Typography>
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() =>
                                    Object.entries(defaultBiddingFilter).forEach(([key, value]) => {
                                        setFieldValue(key, value);
                                    })
                                }
                                underline="none"
                            >
                                {t('common:reset')}
                            </Link>
                        </MobileAppBar>
                        <Form>
                            <Box className={styles.content} display="flex" flexDirection="column">
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.fromBidOpeningOn')}
                                        maxDate={values?.toBidOpeningOn || undefined}
                                        name="fromBidOpeningOn"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.toBidOpeningOn')}
                                        minDate={values?.fromBidOpeningOn || undefined}
                                        name="toBidOpeningOn"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.fromBidClosingOn')}
                                        maxDate={values?.toBidClosingOn || undefined}
                                        name="fromBidClosingOn"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.toBidClosingOn')}
                                        minDate={values?.fromBidClosingOn || undefined}
                                        name="toBidClosingOn"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                            </Box>
                            <Box className={styles.appltFilters}>
                                <Box alignSelf="center" margin="auto" width="100%">
                                    <Button
                                        className={styles.applyButton}
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:filter.applyFilters')}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterDialog;
