import { Box, Typography, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { cardDefaultImage } from '../../../../../server/schema/constants';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';
import usePurchaseTransactionStatus from '../../../../utilities/usePurchaseTransactionStatus';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';

export type VehicleInfoProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        registrationNo: {
            opacity: '0.6',
        },
        carImage: {
            height: theme.spacing(30),
            marginBottom: theme.spacing(2),
            borderRadius: theme.spacing(1),
        },
        statusLogo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        transactionStatus: {
            paddingTop: theme.spacing(1),
        },
    })
);

const VehicleInfo = ({ purchaseTransaction }: VehicleInfoProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const carLogo = useVehicleLogo(purchaseTransaction.vehicle.make);
    const defaultImage = usePublic(cardDefaultImage);
    const purchaseTransactionStatus = usePurchaseTransactionStatus(purchaseTransaction.stage);

    return (
        <>
            {!isSmall && (
                <CardMedia
                    className={styles.carImage}
                    image={purchaseTransaction.frontPagePhoto?.signedUrl || defaultImage}
                />
            )}
            <Box alignItems="center" display="flex">
                <Box>
                    <Box display="flex">
                        <Box>
                            <Typography className={commonStyles.primary}>
                                {purchaseTransaction.vehicle.make} {purchaseTransaction.vehicle.model}
                            </Typography>
                            <Typography className={cs(commonStyles.primary, styles.registrationNo)}>
                                {purchaseTransaction.vehicle.number}
                            </Typography>
                        </Box>

                        <Box className={styles.statusLogo}>
                            <Typography className={commonStyles.transactionStatus}>
                                {purchaseTransactionStatus}
                            </Typography>
                            <Typography className={styles.transactionStatus}>
                                <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default VehicleInfo;
