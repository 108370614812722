import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    CreatePurchaseTransactionMutationVariables,
    FullPurchaseTransactionDataFragment,
    useUpdatePurchaseTransactionMutation,
    useRemoveValuationPhotoMutation,
    useRemoveHandoverAttachmentMutation,
    useRemoveVpaAttachmentMutation,
    useUploadValuationPhotoMutation,
    useUploadHandoverAttachmentMutation,
    useUploadVpaAttachmentMutation,
    useUpdatePurchaseTransactionFrontPagePhotoMutation,
    UserType,
    NameOnlyFileDataFragment,
    PurchaseTransactionStage,
    DealerInformationFields,
    ValuatedValuation,
    NameOnlyUserDataFragment,
} from '../../../../api';
import { useAlert } from '../../../../components/AlertProvider';
import FileViewerProvider from '../../../../components/FileViewerProvider';
import { diffUploads } from '../../../../utilities/file';
import { useHandleError } from '../../../../utilities/handleErrors';
import { DeliveryPeriodType } from '../../../../utilities/useDeliveryPeriodOptions';
import useGeneralConditionOptions from '../../../../utilities/useGeneralConditionOptions';
import useHandoverLocationOptions, {
    defaultOtherLocationOption,
} from '../../../../utilities/useHandoverLocationOptions';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import EditPurchaseTransactionBody from './EditPurchaseTransactionBody';
import EditPurchaseTransactionHeader from './EditPurchaseTransactionHeader';

export type PurchaseTransactionFormValues = {
    handover: {
        targetHandoverDate: Date;
        targetHandoverTime: Date;
        handoverLocationField: { main: string; other?: string };
        attachments: (NameOnlyFileDataFragment | File)[];
        vpaAttachments: (NameOnlyFileDataFragment | File)[];
    };
    valuation: {
        valuatedValuations: (Omit<ValuatedValuation, 'valuatedBy'> & { valuatedBy?: NameOnlyUserDataFragment })[];
        photos: (NameOnlyFileDataFragment | File)[];
        latestValuation?: number;
        valuatedBy?: string;
        generalCondition?: string;
        dealerInformations: DealerInformationFields[];
    };
    frontPagePhoto?: NameOnlyFileDataFragment | File;
} & Omit<CreatePurchaseTransactionMutationVariables['fields'], 'valuation'>;

export type EditPurchaseTransactionProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    goToView: () => void;
};

const computeInitialValues = (
    values: FullPurchaseTransactionDataFragment,
    handoverLocationOptions: Array<{ value: string; label: string }>,
    defaultOtherLocationOption: string
): PurchaseTransactionFormValues => {
    const {
        id,
        identifier,
        stage,
        saleConsultant,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        closedAt,
        closedBy,
        handover,
        valuation,
        activities,
        lastActivity,
        frontPagePhotoSourceId,
        duplicateTransactions,
        archived,
        ...fields
    } = values;
    const { handoverLocation, personResponsible, vpaAttachment, ...handoverFields } = handover;
    const { targetHandoverDateTime } = handover;
    const { vehicleDiagramComments, latestValuatedValuation, ...valuationFields } = valuation;
    const handoverLocationOption = handoverLocationOptions.find(option => option.value === handoverLocation);

    const handoverLocationField = {
        main: handoverLocationOption ? handoverLocationOption.value : defaultOtherLocationOption,
        other: !handoverLocationOption ? handoverLocation : '',
    };

    return {
        ...fields,
        handover: {
            targetHandoverDate: targetHandoverDateTime ? dayjs(targetHandoverDateTime).toDate() : null,
            targetHandoverTime: targetHandoverDateTime ? dayjs(targetHandoverDateTime).toDate() : null,
            handoverLocationField: handoverLocation ? handoverLocationField : { main: '' },
            personResponsible: personResponsible.id,
            vpaAttachments: vpaAttachment ? [vpaAttachment] : [],
            ...handoverFields,
        },
        saleConsultantId: saleConsultant?.id,
        valuation: {
            ...valuationFields,
            valuatedBy: latestValuatedValuation?.valuatedBy?.fullName,
            latestValuation: latestValuatedValuation?.value,
        },
        // switch it with the original document
        frontPagePhoto: valuation.photos.find(document => document.id === frontPagePhotoSourceId),
    };
};

const computePayload = (
    values: PurchaseTransactionFormValues
): CreatePurchaseTransactionMutationVariables['fields'] => {
    const { frontPagePhoto, handover, valuation, ...fields } = values;

    const {
        targetHandoverDate,
        targetHandoverTime,
        handoverLocationField,
        handoverLocation,
        attachments,
        vpaAttachments,
        ...handoverFields
    } = handover;

    const { valuatedBy, valuatedValuations, photos, ...valuationFields } = valuation;

    let targetHandoverDateTimeNew: Date = null;

    if (targetHandoverDate && targetHandoverTime) {
        targetHandoverDateTimeNew = dayjs(targetHandoverDate)
            .set('hour', dayjs(targetHandoverTime).hour())
            .set('minute', dayjs(targetHandoverTime).minute())
            .toDate();
    }

    return {
        handover: {
            ...handoverFields,
            handoverLocation: handoverLocationField.other || handoverLocationField.main,
            targetHandoverDateTime: targetHandoverDateTimeNew,
        },
        valuation: {
            ...valuationFields,
            valuatedValuations: valuatedValuations.map(valuation => ({
                ...valuation,
                valuatedBy: valuation.valuatedBy?.id,
            })),
        },
        ...fields,
    };
};

const EditPurchaseTransaction = ({ purchaseTransaction, goToView }: EditPurchaseTransactionProps) => {
    const generalConditionOptions = useGeneralConditionOptions();

    const [updatePurchaseTransaction] = useUpdatePurchaseTransactionMutation();
    const [removeValuationPhotoMutation] = useRemoveValuationPhotoMutation();
    const [removeHandoverAttachmentMutation] = useRemoveHandoverAttachmentMutation();
    const [removeVpaAttachmentMutation] = useRemoveVpaAttachmentMutation();
    const [uploadValuationPhotoMutation] = useUploadValuationPhotoMutation();
    const [uploadHandoverAttachmentMutation] = useUploadHandoverAttachmentMutation();
    const [uploadVpaAttachmentMutation] = useUploadVpaAttachmentMutation();
    const [updatePurchaseTransactionFrontPagePhotoMutation] = useUpdatePurchaseTransactionFrontPagePhotoMutation();
    const currentUser = useCurrentUser();
    const { t } = useTranslation(['common']);
    const { show } = useAlert();

    const initialFrontPagePhotoId = purchaseTransaction.frontPagePhotoSourceId;
    const initialAttachments = purchaseTransaction.handover.attachments;
    const initialPhotos = purchaseTransaction.valuation.photos;
    const initialVpaAttachments = purchaseTransaction.handover.vpaAttachment
        ? [purchaseTransaction.handover.vpaAttachment]
        : [];

    const onSubmit = useHandleError(
        async (values: PurchaseTransactionFormValues) => {
            const attachmentsDiff = diffUploads(values.handover.attachments, initialAttachments);
            const photosDiff = diffUploads(values.valuation.photos, initialPhotos);
            const vpaAttachmentsDiff = diffUploads(values.handover.vpaAttachments, initialVpaAttachments);
            const fields = computePayload(values);
            const { frontPagePhoto } = values;

            await Promise.all([
                ...photosDiff.removedUploads.map(async file => {
                    await removeValuationPhotoMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...attachmentsDiff.removedUploads.map(async file => {
                    await removeHandoverAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...vpaAttachmentsDiff.removedUploads.map(async file => {
                    await removeVpaAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...photosDiff.newUploads.map(async file => {
                    await uploadValuationPhotoMutation({
                        variables: {
                            purchaseTransactionId: purchaseTransaction.id,
                            file,
                            isFrontPagePhoto: frontPagePhoto === file,
                        },
                    });
                }),
                ...attachmentsDiff.newUploads.map(async file => {
                    await uploadHandoverAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, file },
                    });
                }),
                ...vpaAttachmentsDiff.newUploads.map(async file => {
                    await uploadVpaAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, file },
                    });
                }),
            ]);

            if (!(frontPagePhoto instanceof File) && frontPagePhoto?.id !== initialFrontPagePhotoId) {
                await updatePurchaseTransactionFrontPagePhotoMutation({
                    variables: { purchaseTransactionId: purchaseTransaction.id, frontPagePhotoId: frontPagePhoto?.id },
                });
            }

            await updatePurchaseTransaction({
                variables: { transactionId: purchaseTransaction.id, fields },
            });

            show('success', t('common:successfulMessage.updatesSaved'));
            goToView();
        },
        [purchaseTransaction, goToView, initialPhotos, initialAttachments, initialFrontPagePhotoId]
    );

    const handoverLocationOptions = useHandoverLocationOptions();
    const initialValues = useMemo(
        () => computeInitialValues(purchaseTransaction, handoverLocationOptions, defaultOtherLocationOption),
        [purchaseTransaction, handoverLocationOptions]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                // validation for admin
                validators.only(
                    () => currentUser.type === UserType.Admin,
                    validators.compose(
                        validators.requiredString('vehicle.number'),
                        validators.requiredString('customer.ownerIdType'),
                        validators.requiredString('customer.ownerId'),
                        validators.requiredDate('vehicle.intendedDeregistrationDate'),
                        validators.requiredString('vehicle.make'),
                        validators.requiredString('vehicle.model'),
                        validators.requiredString('vehicle.primaryColour'),
                        validators.requiredNumber('vehicle.manufacturingYear'),
                        validators.requiredString('vehicle.engineNumber'),
                        validators.requiredString('vehicle.chassisNumber'),
                        validators.requiredString('vehicle.maximumPowerOutput'),
                        validators.requiredNumber('vehicle.openMarketValue'),
                        validators.requiredDate('vehicle.originalRegistrationDate'),
                        validators.requiredDate('vehicle.firstRegistrationDate'),
                        validators.requiredNumber('vehicle.transferCount'),
                        validators.requiredNumber('vehicle.actualARFPaid'),
                        validators.requiredDate('vehicle.coeExpiryDate'),
                        validators.requiredString('vehicle.coeCategory'),
                        validators.requiredNumber('vehicle.coePeriodYear'),
                        validators.requiredString('saleConsultantId'),
                        validators.only(
                            ({ vehicle: { parfEligibility } }) => parfEligibility === true,
                            validators.compose(validators.requiredString('vehicle.parfEligibilityDate'))
                        ),
                        validators.only(
                            () => purchaseTransaction.stage === PurchaseTransactionStage.Completed,
                            validators.requiredNumber('vehicle.setOfKeys')
                        )
                    )
                ),

                // make target handover time mandatory is the date is given
                validators.only(
                    values => !!get('handover.targetHandoverDate', values),
                    validators.requiredDate('handover.targetHandoverTime')
                ),

                // make target handover date mandatory is the time is given
                validators.only(
                    values => !!get('handover.targetHandoverTime', values),
                    validators.requiredDate('handover.targetHandoverDate')
                ),

                validators.only(
                    () =>
                        currentUser.type === UserType.Admin ||
                        currentUser.type === UserType.ValuationTeam ||
                        currentUser.type === UserType.Approver,
                    validators.compose(
                        validators.requiredNumber('vehicle.mileage'),
                        validators.requiredString('vehicle.importMethod'),
                        validators.requiredNumber('valuation.latestValuation')
                    )
                ),

                validators.only(
                    () =>
                        currentUser.type === UserType.Admin ||
                        currentUser.type === UserType.ValuationTeam ||
                        currentUser.type === UserType.Approver ||
                        currentUser.type === UserType.SaleConsultant,
                    validators.compose(validators.requiredString('handover.personResponsible'))
                ),

                validators.only(
                    () => purchaseTransaction.stage === PurchaseTransactionStage.FinalValuation,
                    validators.compose(
                        validators.only(
                            () =>
                                [UserType.Admin, UserType.ValuationTeam, UserType.Approver].includes(currentUser.type),
                            validators.compose(
                                validators.requiredString('vehicle.purchaseAgreementNumber'),
                                validators.requiredStringOptionValues(
                                    'valuation.generalCondition',
                                    generalConditionOptions.map(option => option.value)
                                ),
                                validators.requiredBoolean('handover.targetHandbook'),
                                validators.requiredNumber('handover.targetSetOfKeys')
                            )
                        ),

                        validators.only(
                            () =>
                                currentUser.type === UserType.Admin ||
                                currentUser.type === UserType.SaleConsultant ||
                                currentUser.type === UserType.ValuationTeam ||
                                currentUser.type === UserType.Approver,
                            validators.compose(
                                validators.requiredDate('handover.targetHandoverTime'),
                                validators.requiredString('handover.handoverLocationField.main'),
                                validators.only(
                                    ({ handover: { handoverLocationField } }) =>
                                        handoverLocationField.main === defaultOtherLocationOption,
                                    validators.compose(
                                        validators.requiredString('handover.handoverLocationField.other')
                                    )
                                )
                            )
                        )
                    )
                ),
                validators.only(
                    // for SC dealerInformations should be only editable when PT status = initial valuation
                    () =>
                        purchaseTransaction.stage === PurchaseTransactionStage.InitialValuation &&
                        currentUser.type !== UserType.SaleConsultant,
                    validators.forEach(
                        'valuation.dealerInformations',
                        validators.compose(validators.requiredString('name'), validators.requiredNumber('price'))
                    )
                ),

                validators.only(
                    () => purchaseTransaction.stage === PurchaseTransactionStage.Completed,
                    validators.compose(validators.requiredString('adminRemark'))
                ),

                validators.only(
                    ({ handover: { deliveryPeriod } }) => deliveryPeriod === DeliveryPeriodType.IndentOrder,
                    validators.requiredString('handover.deliveryPeriodIndentOrder')
                )
            ),
        [currentUser, purchaseTransaction, generalConditionOptions]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <FileViewerProvider>
                        <EditPurchaseTransactionHeader goToView={goToView} />
                        <EditPurchaseTransactionBody purchaseTransaction={purchaseTransaction} />
                    </FileViewerProvider>
                </Form>
            )}
        </Formik>
    );
};

export default EditPurchaseTransaction;
