import { Grid, Typography, Box, Link, Button, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import dayjs from 'dayjs';
import { isNil } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    UserType,
    useUpdateTransactionToPendingAcceptanceMutation,
    useUpdateTransactionToHandoverMutation,
    useUpdateTransactionToPendingApprovalMutation,
    useUpdateTransactionToFinalValuationMutation,
    PurchaseTransactionStage,
    FullPurchaseTransactionDataFragment,
    PreviewPurchaseTransactionDataFragment,
    useTriggerPendingAcceptanceNotificationMutation,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import { useAlert } from '../../../../components/AlertProvider';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import FileList from '../../../../components/attachments/FileList';
import useCommonStyles from '../../../../useCommonStyles';
import ErrorLatestValuationDialog from './ErrorLatestValuationDialog';
import ErrorRequestForApprovalDialog from './ErrorRequestForApprovalDialog';
import ErrorSendForValuationDialog from './ErrorSendForValuationDialog';
import ValuatedValuationItem from './ValuatedValuationItem';
import ValuationHistoric from './ValuationHistoric';
import useViewTransactionStyles from './useViewTransactionStyles';

export type ValuationTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    openCancelTransactionDialog: () => void;
    showConfirmDialog: () => void;
    goToVehicleDetails: () => void;
    openCompleteDrawer: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeStageGridItem: {
            [theme.breakpoints.up('sm')]: {
                order: 1,
            },
        },
        errorText: {
            color: theme.palette.error.main,
        },
        cancelTransactionLabel: {
            color: '#A5A5A5',
        },
        sendForAcceptance: {
            order: 0,
        },
        orderZero: {
            order: 0,
        },
    })
);

const ValuationTab = ({
    purchaseTransaction,
    openCancelTransactionDialog,
    showConfirmDialog,
    goToVehicleDetails,
    openCompleteDrawer,
}: ValuationTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const [showSendForValuationConfirmDialog, setShowSendForValuationConfirmDialog] = useState(false);
    const [showSendForAcceptanceConfirmDialog, setShowSendForAcceptanceConfirmDialog] = useState(false);
    const [showRequestRequoteConfirmDialog, setShowRequestRequoteConfirmDialog] = useState(false);
    const [showUpdateToCancelConfirmDialog, setShowUpdateToCancelConfirmDialog] = useState(false);
    const [showRequestApprovalConfirmDialog, setShowRequestApprovalConfirmDialog] = useState(false);
    const [showApproveConfirmDialog, setShowApproveConfirmDialog] = useState(false);
    const [showRejectConfirmDialog, setShowRejectConfirmDialog] = useState(false);
    const [showSendForAcceptanceEditDialog, setShowSendForAcceptanceEditDialog] = useState(false);
    const [showRequestApprovalEditDialog, setShowRequestApprovalEditDialog] = useState(false);
    const [showSendForValuationEditDialog, setShowSendForValuationEditDialog] = useState(false);

    const { show } = useAlert();

    const commonStyles = useCommonStyles();
    const viewTransactionStyles = useViewTransactionStyles();
    const styles = useStyles();
    const currentUser = useCurrentUser();

    const variables = { transactionId: purchaseTransaction.id };
    const [updateToPendingAcceptance] = useUpdateTransactionToPendingAcceptanceMutation({ variables });
    const [updateToFinalValuation] = useUpdateTransactionToFinalValuationMutation({ variables });
    const [updateToPendingApproval] = useUpdateTransactionToPendingApprovalMutation({ variables });
    const [updateToHandover] = useUpdateTransactionToHandoverMutation({ variables });
    const [triggerPendingAcceptanceNotification] = useTriggerPendingAcceptanceNotificationMutation({ variables });

    const onUpdateToFinalValuation = useCallback(() => {
        updateToFinalValuation();
        setShowRejectConfirmDialog(false);
        show('success', t('purchaseTransactionsPage:successfulMessage.transactionRejected'));
    }, [show, t, updateToFinalValuation]);

    const onUpdateToHandover = useCallback(() => {
        updateToHandover();
        setShowApproveConfirmDialog(false);
        show('success', t('purchaseTransactionsPage:successfulMessage.transactionApproved'));
    }, [show, t, updateToHandover]);

    const onUpdateToCancel = useCallback(() => {
        setShowUpdateToCancelConfirmDialog(false);
        openCancelTransactionDialog();
    }, [openCancelTransactionDialog]);

    const onUpdateToPendingAcceptance = useCallback(
        (updatedPurchaseTransaction?: PreviewPurchaseTransactionDataFragment, alwaysShowPopup?: boolean) => {
            const purchaseTransactionForUpdate = updatedPurchaseTransaction ?? purchaseTransaction;
            const isInValidDealerPrice = purchaseTransactionForUpdate.valuation.dealerInformations.some(
                value => !value.name || (!value.price && value.price !== 0)
            );

            setShowSendForAcceptanceConfirmDialog(false);

            if (
                !alwaysShowPopup &&
                purchaseTransactionForUpdate.valuation.latestValuatedValuation?.value &&
                !isInValidDealerPrice
            ) {
                updateToPendingAcceptance();
                show('success', t('purchaseTransactionsPage:successfulMessage.pendingAcceptance'));
                setShowSendForAcceptanceEditDialog(false);
            } else {
                switch (purchaseTransaction.stage) {
                    case PurchaseTransactionStage.PendingAcceptance:
                        triggerPendingAcceptanceNotification();
                        break;

                    case PurchaseTransactionStage.InitialValuation:
                        setShowSendForAcceptanceEditDialog(true);
                        break;

                    default:
                        // nothing to do
                        break;
                }
            }
        },
        [purchaseTransaction, show, t, updateToPendingAcceptance, triggerPendingAcceptanceNotification]
    );

    const onUpdateToPendingApproval = useCallback(
        (updatedPurchaseTransaction?: FullPurchaseTransactionDataFragment, alwaysShowPopup?: boolean) => {
            let isValidToChangePendingApproval = true;
            const purchaseTransactionForUpdate = updatedPurchaseTransaction ?? purchaseTransaction;

            if (!purchaseTransactionForUpdate.vehicle.purchaseAgreementNumber) {
                isValidToChangePendingApproval = false;
            }

            if (!purchaseTransactionForUpdate.handover.vpaAttachment) {
                isValidToChangePendingApproval = false;
            }

            if (
                !(
                    purchaseTransactionForUpdate.valuation.photos &&
                    purchaseTransactionForUpdate.valuation.photos.length > 0
                )
            ) {
                isValidToChangePendingApproval = false;
            }

            if (!purchaseTransactionForUpdate.handover.handoverLocation) {
                isValidToChangePendingApproval = false;
            }

            if (!purchaseTransactionForUpdate.valuation.generalCondition) {
                isValidToChangePendingApproval = false;
            }

            if (isNil(purchaseTransactionForUpdate.handover.targetHandbook)) {
                isValidToChangePendingApproval = false;
            }

            if (!purchaseTransactionForUpdate.handover.targetSetOfKeys) {
                isValidToChangePendingApproval = false;
            }

            setShowRequestApprovalConfirmDialog(false);
            if (!alwaysShowPopup && isValidToChangePendingApproval) {
                updateToPendingApproval();
                show('success', t('purchaseTransactionsPage:successfulMessage.pendingApproval'));
                setShowRequestApprovalEditDialog(false);
            } else {
                setShowRequestApprovalEditDialog(true);
            }
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [
            purchaseTransaction.handover.handoverLocation,
            purchaseTransaction.handover.targetHandbook,
            purchaseTransaction.handover.targetSetOfKeys,
            purchaseTransaction.handover.vpaAttachment,
            purchaseTransaction.valuation.generalCondition,
            purchaseTransaction.valuation.photos,
            purchaseTransaction.vehicle.purchaseAgreementNumber,
            /* eslint-enable react-hooks/exhaustive-deps */
            purchaseTransaction,
            show,
            t,
            updateToPendingApproval,
        ]
    );

    const valuationHistory = useMemo(
        () =>
            [...(purchaseTransaction.valuation?.valuatedValuations || [])]
                // display latest valuatedValuations first
                .sort((a, b) =>
                    !!a.valuatedOn && !!b.valuatedOn && dayjs(a.valuatedOn).isBefore(dayjs(b.valuatedOn)) ? 1 : -1
                ),
        [purchaseTransaction.valuation?.valuatedValuations]
    );

    return (
        <Grid spacing={2} container>
            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {/* Only show for initial valuation
                        stage === 'Initial Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.InitialValuation && (
                        <>
                            <Grid className={cs(styles.changeStageGridItem, styles.sendForAcceptance)} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => setShowSendForAcceptanceConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.sendForAcceptance')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorLatestValuationDialog
                                purchaseTransaction={purchaseTransaction}
                                setHideDialog={() => setShowSendForAcceptanceEditDialog(false)}
                                setOkDialog={updatedPurchaseTransaction => {
                                    onUpdateToPendingAcceptance(updatedPurchaseTransaction);
                                }}
                                show={showSendForAcceptanceEditDialog}
                                title="Please fill below field(s):"
                            />
                        </>
                    )}

                    {/* Only show for Final Valuation
                        stage === 'Final Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.FinalValuation && (
                        <Grid className={cs(styles.changeStageGridItem, styles.orderZero)} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={commonStyles.removeBoxShadow}
                                    color="secondary"
                                    onClick={() => setShowRequestApprovalConfirmDialog(true)}
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.requestApproval')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Only show for Handover
                        stage === 'Handover'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.Handover && (
                        <Grid className={cs(styles.changeStageGridItem, styles.orderZero)} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={commonStyles.removeBoxShadow}
                                    color="secondary"
                                    onClick={openCompleteDrawer}
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.complete')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}
                </>
            )}

            {purchaseTransaction.stage === PurchaseTransactionStage.InitialValuation &&
                purchaseTransaction.saleConsultant?.id === currentUser.id &&
                currentUser.type === UserType.SaleConsultant && (
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                color="secondary"
                                onClick={() => setShowSendForValuationConfirmDialog(true)}
                                variant="outlined"
                            >
                                {t('purchaseTransactionsPage:button.sendForValuation')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}

            {purchaseTransaction.stage === PurchaseTransactionStage.PendingAcceptance &&
                purchaseTransaction.saleConsultant?.id === currentUser.id && (
                    <>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={commonStyles.removeBoxShadow}
                                    color="secondary"
                                    onClick={showConfirmDialog}
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.acceptValuation')}
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    color="secondary"
                                    onClick={() => setShowRequestRequoteConfirmDialog(true)}
                                    variant="outlined"
                                >
                                    {t('purchaseTransactionsPage:button.requestRequote')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </>
                )}

            {purchaseTransaction.stage === PurchaseTransactionStage.PendingAcceptance &&
                purchaseTransaction.handover.personResponsible?.id === currentUser.id &&
                currentUser.type === UserType.ValuationTeam && (
                    <Grid className={cs(styles.changeStageGridItem, styles.sendForAcceptance)} xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                className={commonStyles.removeBoxShadow}
                                color="secondary"
                                onClick={() => setShowSendForAcceptanceConfirmDialog(true)}
                                variant="outlined"
                            >
                                {t('purchaseTransactionsPage:button.sendForAcceptance')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}

            {currentUser.type === UserType.Approver && (
                <>
                    {/* Only show for Pending Approval
                        stage === 'Pending Approval'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.PendingApproval && (
                        <>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => setShowApproveConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.approve')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        color="secondary"
                                        onClick={() => setShowRejectConfirmDialog(true)}
                                        variant="outlined"
                                    >
                                        {t('purchaseTransactionsPage:button.reject')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {/* Only show for Final Valuation
                        stage === 'Final Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.FinalValuation && (
                        <Grid className={cs(styles.changeStageGridItem, styles.orderZero)} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={commonStyles.removeBoxShadow}
                                    color="secondary"
                                    onClick={() => setShowRequestApprovalConfirmDialog(true)}
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.requestApproval')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}
                </>
            )}

            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.valuation')} defaultExpanded>
                    <Grid spacing={1} container>
                        {(purchaseTransaction.valuation?.valuatedValuations || []).length > 0 && (
                            <Grid xs={12} item>
                                <Accordion
                                    title={t('purchaseTransactionsPage:accordionTitle.valuationHistory')}
                                    noBorder
                                >
                                    <Grid spacing={1} container>
                                        {valuationHistory.map((valuatedValuation, index) => (
                                            <ValuatedValuationItem
                                                key={`valuatedValuation-${index.toString()}`}
                                                valuatedValuation={valuatedValuation}
                                            />
                                        ))}
                                    </Grid>
                                </Accordion>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:valuation.latestValuation')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {/* eslint-disable max-len */}
                                    {purchaseTransaction.valuation?.latestValuatedValuation?.value
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.valuation.latestValuatedValuation.value.toLocaleString()}`
                                        : t('common:blankValue')}
                                    {/* eslint-disable max-len */}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:valuation.actualValuation')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {/* eslint-disable max-len */}
                                    {purchaseTransaction.valuation?.latestValuatedValuation?.value &&
                                    purchaseTransaction.stage === PurchaseTransactionStage.Completed
                                        ? `${t('common:currency')}
                                            ${purchaseTransaction.valuation.latestValuatedValuation.value.toLocaleString()}`
                                        : t('common:blankValue')}
                                    {/* eslint-disable max-len */}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:valuation.valuatedBy')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.valuation?.latestValuatedValuation?.valuatedBy?.fullName
                                        ? purchaseTransaction.valuation.latestValuatedValuation.valuatedBy.fullName
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        {[UserType.Admin, UserType.ValuationTeam, UserType.Approver, UserType.SaleConsultant].includes(
                            currentUser.type
                        ) && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:valuation.generalCondition')}
                                    </Typography>
                                    <Typography className={viewTransactionStyles.content}>
                                        {purchaseTransaction.valuation?.generalCondition
                                            ? purchaseTransaction.valuation?.generalCondition
                                            : t('common:blankValue')}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}

                        {[UserType.Admin, UserType.ValuationTeam, UserType.Approver].includes(currentUser.type) &&
                            (purchaseTransaction.valuation?.dealerInformations || []).map((value, index: number) => (
                                <React.Fragment key={`dealerInformations-${index.toString()}`}>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={viewTransactionStyles.label}>
                                                {t('purchaseTransactionsPage:valuation.dealer', {
                                                    index: index + 1,
                                                })}
                                            </Typography>
                                            <Typography className={viewTransactionStyles.content}>
                                                {value.name ? value.name : t('common:blankValue')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={viewTransactionStyles.label}>
                                                {t('purchaseTransactionsPage:valuation.price')}
                                            </Typography>
                                            <Typography className={viewTransactionStyles.content}>
                                                {value.price
                                                    ? `${t('common:currency')}${value.price.toLocaleString()}`
                                                    : t('common:blankValue')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            ))}

                        {currentUser.type !== UserType.SaleConsultant && (
                            <>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={viewTransactionStyles.label}>
                                            {t('purchaseTransactionsPage:valuation.vehicleDiagram')}
                                        </Typography>
                                        <Link
                                            className={cs(commonStyles.autoMarginLeft, commonStyles.displayFlex)}
                                            color="secondary"
                                            onClick={goToVehicleDetails}
                                            underline="none"
                                        >
                                            {t('purchaseTransactionsPage:seeMore')}
                                            <ChevronRightIcon fontSize="small" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box mt={2}>
                                        <Typography className={viewTransactionStyles.label}>
                                            {t('purchaseTransactionsPage:valuation.photos')}
                                        </Typography>
                                        {purchaseTransaction.valuation.photos.length > 0 ? (
                                            <FileList files={purchaseTransaction.valuation.photos} />
                                        ) : (
                                            <Typography className={viewTransactionStyles.content}>
                                                {t('purchaseTransactionsPage:valuation.noPhotos')}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Accordion>
            </Grid>

            {(currentUser.type === UserType.ValuationTeam || currentUser.type === UserType.Approver) && (
                <Grid className={styles.changeStageGridItem} xs={12} item>
                    <ValuationHistoric purchaseTransaction={purchaseTransaction} />
                </Grid>
            )}

            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {/* Only show when stage is not "To Handover"
                        stage !== 'To Handover'
                    */}
                    {purchaseTransaction.stage !== PurchaseTransactionStage.Handover &&
                        purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                        purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled && (
                            <Grid lg={12} md={12} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        classes={{ label: styles.cancelTransactionLabel }}
                                        onClick={() => setShowUpdateToCancelConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.cancelTransaction')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        )}
                </>
            )}
            {currentUser.type === UserType.Approver &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Handover &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled && (
                    <Grid sm={12} xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                className={commonStyles.removeBoxShadow}
                                classes={{ label: styles.cancelTransactionLabel }}
                                onClick={() => setShowUpdateToCancelConfirmDialog(true)}
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:button.cancelTransaction')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}

            {currentUser.type === UserType.Admin &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled && (
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                className={commonStyles.removeBoxShadow}
                                classes={{ label: styles.cancelTransactionLabel }}
                                onClick={() => setShowUpdateToCancelConfirmDialog(true)}
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:button.cancelTransaction')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}

            <ConfirmDialog
                body={t('purchaseTransactionsPage:sendForValuationDialog.body')}
                setContinueConfirmDialog={() => {
                    setShowSendForValuationEditDialog(true);
                    setShowSendForValuationConfirmDialog(false);
                }}
                setHideConfirmDialog={() => setShowSendForValuationConfirmDialog(false)}
                show={showSendForValuationConfirmDialog}
                title={t('purchaseTransactionsPage:sendForValuationDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:sendForAcceptanceDialog.body')}
                setContinueConfirmDialog={() => onUpdateToPendingAcceptance(null, true)}
                setHideConfirmDialog={() => setShowSendForAcceptanceConfirmDialog(false)}
                show={showSendForAcceptanceConfirmDialog}
                title={t('purchaseTransactionsPage:sendForAcceptanceDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:requestRequoteDialog.body')}
                setContinueConfirmDialog={() => {
                    setShowSendForValuationEditDialog(true);
                    setShowRequestRequoteConfirmDialog(false);
                }}
                setHideConfirmDialog={() => setShowRequestRequoteConfirmDialog(false)}
                show={showRequestRequoteConfirmDialog}
                title={t('purchaseTransactionsPage:requestRequoteDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:updateForCancelDialog.body')}
                setContinueConfirmDialog={onUpdateToCancel}
                setHideConfirmDialog={() => setShowUpdateToCancelConfirmDialog(false)}
                show={showUpdateToCancelConfirmDialog}
                title={t('purchaseTransactionsPage:updateForCancelDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:requestForApprovalDialog.body')}
                setContinueConfirmDialog={() => onUpdateToPendingApproval(null, true)}
                setHideConfirmDialog={() => setShowRequestApprovalConfirmDialog(false)}
                show={showRequestApprovalConfirmDialog}
                title={t('purchaseTransactionsPage:requestForApprovalDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:approveDialog.body')}
                setContinueConfirmDialog={onUpdateToHandover}
                setHideConfirmDialog={() => setShowApproveConfirmDialog(false)}
                show={showApproveConfirmDialog}
                title={t('purchaseTransactionsPage:approveDialog.title')}
            />
            <ConfirmDialog
                body={t('purchaseTransactionsPage:rejectDialog.body')}
                setContinueConfirmDialog={onUpdateToFinalValuation}
                setHideConfirmDialog={() => setShowRejectConfirmDialog(false)}
                show={showRejectConfirmDialog}
                title={t('purchaseTransactionsPage:rejectDialog.title')}
            />

            <ErrorRequestForApprovalDialog
                purchaseTransaction={purchaseTransaction}
                setHideDialog={() => setShowRequestApprovalEditDialog(false)}
                setOkDialog={updatedPurchaseTransaction => {
                    onUpdateToPendingApproval(updatedPurchaseTransaction);
                    setShowRequestApprovalEditDialog(false);
                    show('success', t('purchaseTransactionsPage:successfulMessage.pendingApproval'));
                }}
                show={showRequestApprovalEditDialog}
                title="Please fill below field(s):"
            />

            <ErrorSendForValuationDialog
                purchaseTransaction={purchaseTransaction}
                setHideDialog={() => setShowSendForValuationEditDialog(false)}
                setOkDialog={() => {
                    setShowSendForValuationEditDialog(false);
                    show(
                        'success',
                        purchaseTransaction.stage === PurchaseTransactionStage.PendingAcceptance
                            ? t('purchaseTransactionsPage:successfulMessage.requestRequote') // Request Requote
                            : t('purchaseTransactionsPage:successfulMessage.sendForValuation')
                    );
                }}
                show={showSendForValuationEditDialog}
                title="Please fill below field(s):"
            />
        </Grid>
    );
};

export default ValuationTab;
