import { Grid, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CreatePurchaseTransactionFormValues } from '..';
import { useCurrentUser } from '../../../../Session';
import { PurchaseTransactionStage, UserType } from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useImportMethodOptions from '../../../../utilities/useImportMethodOptions';
import useYesNoOptions from '../../../../utilities/useYesNoOptions';

export type VehicleDetailsFormProps = {
    dataManually?: boolean;
    onCreation?: boolean;
    onEdition?: boolean;
    stage?: PurchaseTransactionStage;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adornmentRoot: {
            '& > p': {
                color: theme.palette.secondary.main,
            },
        },
    })
);

const VehicleDetailsForm = ({ dataManually, onCreation, onEdition, stage }: VehicleDetailsFormProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const yesNoOptions = useYesNoOptions();
    const currentUser = useCurrentUser();
    const { values } = useFormikContext<CreatePurchaseTransactionFormValues>();
    const { vehicle } = values;
    const styles = useStyles();
    const importMethodOptions = useImportMethodOptions();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.vehicleNo')}
                    name="vehicle.number"
                    placeholder={t('purchaseTransactionsPage:vehicle.vehicleNo')}
                />
            </Grid>
            {!onCreation && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        disabled={
                            !(
                                currentUser.type === UserType.SaleConsultant &&
                                stage === PurchaseTransactionStage.InitialValuation
                            )
                        }
                        endAdornment={
                            <InputAdornment classes={{ root: styles.adornmentRoot }} position="end">
                                {t('common:shortKilometer')}
                            </InputAdornment>
                        }
                        label={t('purchaseTransactionsPage:vehicle.mileage')}
                        name="vehicle.mileage"
                        placeholder={t('purchaseTransactionsPage:vehicle.mileage')}
                        type="number"
                    />
                </Grid>
            )}
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:owner.ownerIdType')}
                    name="customer.ownerIdType"
                    placeholder={t('purchaseTransactionsPage:owner.ownerIdType')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:owner.ownerId')}
                    name="customer.ownerId"
                    placeholder={t('purchaseTransactionsPage:owner.ownerId')}
                />
            </Grid>
            {!onCreation && (
                <>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            label={t('purchaseTransactionsPage:owner.ownerName')}
                            name="customer.ownerName"
                            placeholder={t('purchaseTransactionsPage:owner.ownerName')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            label={t('purchaseTransactionsPage:owner.modelInterested')}
                            name="customer.modelInterested"
                            placeholder={t('purchaseTransactionsPage:owner.modelInterested')}
                        />
                    </Grid>
                </>
            )}
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:vehicle.vehicleToBeExported')}
                    name="vehicle.beExported"
                    options={yesNoOptions}
                    placeholder={t('purchaseTransactionsPage:vehicle.vehicleToBeExported')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.intendedDeregistrationDate')}
                    name="vehicle.intendedDeregistrationDate"
                    placeholder={t('purchaseTransactionsPage:vehicle.intendedDeregistrationDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.vehicleMake')}
                    name="vehicle.make"
                    placeholder={t('purchaseTransactionsPage:vehicle.vehicleMake')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.vehicleModel')}
                    name="vehicle.model"
                    placeholder={t('purchaseTransactionsPage:vehicle.vehicleModel')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.primaryColour')}
                    name="vehicle.primaryColour"
                    placeholder={t('purchaseTransactionsPage:vehicle.primaryColour')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.secondaryColour')}
                    name="vehicle.secondaryColour"
                    placeholder={t('purchaseTransactionsPage:vehicle.secondaryColour')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.manufacturingYear')}
                    name="vehicle.manufacturingYear"
                    placeholder={t('purchaseTransactionsPage:vehicle.manufacturingYear')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.engineNo')}
                    name="vehicle.engineNumber"
                    placeholder={t('purchaseTransactionsPage:vehicle.engineNo')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.chassisNo')}
                    name="vehicle.chassisNumber"
                    placeholder={t('purchaseTransactionsPage:vehicle.chassisNo')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.maxPowerOutput')}
                    name="vehicle.maximumPowerOutput"
                    placeholder={t('purchaseTransactionsPage:vehicle.maxPowerOutput')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.openMarketValue')}
                    name="vehicle.openMarketValue"
                    placeholder={t('purchaseTransactionsPage:vehicle.openMarketValue')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.originalRegistrationDate')}
                    name="vehicle.originalRegistrationDate"
                    placeholder={t('purchaseTransactionsPage:vehicle.originalRegistrationDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.firstRegistrationDate')}
                    name="vehicle.firstRegistrationDate"
                    placeholder={t('purchaseTransactionsPage:vehicle.firstRegistrationDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.transferCount')}
                    name="vehicle.transferCount"
                    placeholder={t('purchaseTransactionsPage:vehicle.transferCount')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.actualArfPaid')}
                    name="vehicle.actualARFPaid"
                    placeholder={t('purchaseTransactionsPage:vehicle.actualArfPaid')}
                    type="number"
                />
            </Grid>
            {(onEdition ||
                dataManually ||
                vehicle.opcCashRebateEligibility ||
                vehicle.opcCashRebateEligibilityExpiryDate ||
                vehicle.opcCashRebateAmount) && (
                <>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                            label={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibility')}
                            name="vehicle.opcCashRebateEligibility"
                            placeholder={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibility')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedDatePickerField
                            color="secondary"
                            disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                            label={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                            name="vehicle.opcCashRebateEligibilityExpiryDate"
                            placeholder={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                            label={t('purchaseTransactionsPage:vehicle.opcCashRebateAmount')}
                            name="vehicle.opcCashRebateAmount"
                            placeholder={t('purchaseTransactionsPage:vehicle.opcCashRebateAmount')}
                            type="number"
                        />
                    </Grid>
                </>
            )}
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:vehicle.parfEligibility')}
                    name="vehicle.parfEligibility"
                    options={yesNoOptions}
                    placeholder={t('purchaseTransactionsPage:vehicle.parfEligibility')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                    name="vehicle.parfEligibilityDate"
                    placeholder={t('purchaseTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.parfRebateValue')}
                    name="vehicle.parfRebateAmount"
                    placeholder={t('purchaseTransactionsPage:vehicle.parfRebateValue')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.coeExpiryDate')}
                    name="vehicle.coeExpiryDate"
                    placeholder={t('purchaseTransactionsPage:vehicle.coeExpiryDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.coeCategory')}
                    name="vehicle.coeCategory"
                    placeholder={t('purchaseTransactionsPage:vehicle.coeCategory')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.coePeriod')}
                    name="vehicle.coePeriodYear"
                    placeholder={t('purchaseTransactionsPage:vehicle.coePeriod')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.qpPaid')}
                    name="vehicle.qpPaid"
                    placeholder={t('purchaseTransactionsPage:vehicle.qpPaid')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.pqpPaid')}
                    name="vehicle.pqpPaid"
                    placeholder={t('purchaseTransactionsPage:vehicle.pqpPaid')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.coeRebateAmount')}
                    name="vehicle.coeRebateAmount"
                    placeholder={t('purchaseTransactionsPage:vehicle.coeRebateAmount')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.totalRebateAmount')}
                    name="vehicle.totalRebateAmount"
                    placeholder={t('purchaseTransactionsPage:vehicle.totalRebateAmount')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!dataManually || (!onCreation && currentUser.type !== UserType.Admin)}
                    label={t('purchaseTransactionsPage:vehicle.ltaMessage')}
                    name="vehicle.ltaMessage"
                    placeholder={t('purchaseTransactionsPage:vehicle.ltaMessage')}
                />
            </Grid>
            {!onCreation && (
                <Grid xs={12} item>
                    <OutlinedSelectField
                        color="secondary"
                        disabled={
                            !(
                                currentUser.type === UserType.SaleConsultant &&
                                stage === PurchaseTransactionStage.InitialValuation
                            )
                        }
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillSecondary,
                            },
                        }}
                        label={t('purchaseTransactionsPage:vehicle.importMethod')}
                        name="vehicle.importMethod"
                        options={importMethodOptions}
                        placeholder={t('purchaseTransactionsPage:vehicle.importMethod')}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default VehicleDetailsForm;
