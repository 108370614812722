import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BiddingEntryDataFragment, useGetUserQuery } from '../../../../api';
import ContentWithLabel from '../../../../components/ContentWithLabel';

export type ViewBiddingEntryProps = {
    entry: BiddingEntryDataFragment;
    index: number;
};

const ViewBiddingEntry = ({ entry, index }: ViewBiddingEntryProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const { data: userQuery } = useGetUserQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            id: entry.dealer.id,
        },
    });

    const getDealerBusinessName = useMemo(() => {
        switch (userQuery?.user?.__typename) {
            case 'DealerUser':
                return userQuery?.user?.business?.name;
        }

        return '';
    }, [userQuery?.user]);

    return (
        <Grid spacing={1} container>
            <Grid xs={12} item>
                <h4>{t('saleTransactionsPage:sessionDetails.topBids.index', { index: index + 1 })}</h4>
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel content={entry.id} label={t('saleTransactionsPage:sessionDetails.topBids.entryId')} />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={getDealerBusinessName}
                    label={t('saleTransactionsPage:sessionDetails.topBids.dealer')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={`${t('common:currency')}${entry.amount.toLocaleString()}`}
                    label={t('saleTransactionsPage:sessionDetails.topBids.amount')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={t('common:formats:longDateTime', { date: new Date(entry.placedOn) })}
                    label={t('saleTransactionsPage:sessionDetails.topBids.date')}
                />
            </Grid>
        </Grid>
    );
};

export default ViewBiddingEntry;
