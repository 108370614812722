import { useCallback, useEffect, useState } from 'react';
import { useGetPendingApprovalsQuery } from '../../api';
import ListPagination from '../../components/ListPagination';
import { ApprovalsTable, ApprovalsHeader } from './components';

type ListContext = {
    search: string;
    page: number;
};

const pageSize = 10;

const Approvals = () => {
    const [{ page, search }, setListContext] = useState<ListContext>({ search: '', page: 0 });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const { data, refetch } = useGetPendingApprovalsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            search,
        },
    });

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext(state => ({ ...state, search: searchValue }));
        },
        [setListContext]
    );

    const count = data?.users?.count || 0;
    const users = data?.users?.items || [];

    const onUpdates = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <>
            <ApprovalsHeader search={search} searchOnChange={searchOnChange} />
            <ApprovalsTable onUpdates={onUpdates} users={users} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default Approvals;
