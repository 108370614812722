import { Grid, Typography, Box } from '@material-ui/core';
import cs from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullPurchaseTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import useCommonStyles from '../../../../useCommonStyles';
import useDeliveryPeriod from '../../../../utilities/useDeliveryPeriod';
import { DeliveryPeriodType } from '../../../../utilities/useDeliveryPeriodOptions';
import useViewTransactionStyles from './useViewTransactionStyles';

export type VehicleTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleTab = ({ purchaseTransaction }: VehicleTabProps) => {
    const currentUser = useCurrentUser();
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const viewTransactionStyles = useViewTransactionStyles();
    const commonStyles = useCommonStyles();

    const deliveryPeriod = useDeliveryPeriod(purchaseTransaction.handover?.deliveryPeriod);
    const isRegistrationDateDifferent = useMemo(
        () =>
            !dayjs(purchaseTransaction.vehicle.originalRegistrationDate).isSame(
                purchaseTransaction.vehicle.firstRegistrationDate,
                'day'
            ),
        [purchaseTransaction.vehicle.originalRegistrationDate, purchaseTransaction.vehicle.firstRegistrationDate]
    );

    const isRegistrationDateGreaterOrEqual2023 = useMemo(() => {
        // Only applicable to Admin’s, Valuator’s and Approver’s view.
        if (![UserType.Admin, UserType.Approver, UserType.ValuationTeam].includes(currentUser.type)) {
            return false;
        }

        return (
            dayjs(purchaseTransaction.vehicle.originalRegistrationDate).year() >= 2023 ||
            dayjs(purchaseTransaction.vehicle.firstRegistrationDate).year() >= 2023
        );
    }, [
        currentUser.type,
        purchaseTransaction.vehicle.originalRegistrationDate,
        purchaseTransaction.vehicle.firstRegistrationDate,
    ]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.vehicleNo')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.number}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.mileage')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.mileage.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:owner.ownerIdType')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.customer.ownerIdType}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:owner.ownerId')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.customer.ownerId}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:owner.ownerName')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.customer.ownerName
                                        ? purchaseTransaction.customer.ownerName
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:owner.modelInterested')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.customer.modelInterested
                                        ? purchaseTransaction.customer.modelInterested
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:handover.deliveryPeriod')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>{deliveryPeriod}</Typography>
                            </Box>
                        </Grid>

                        {purchaseTransaction.handover.deliveryPeriod === DeliveryPeriodType.IndentOrder && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:handover.deliveryPeriodIndentOrder')}
                                    </Typography>
                                    <Typography className={viewTransactionStyles.content}>
                                        {purchaseTransaction.handover.deliveryPeriodIndentOrder}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.intendedDeregistrationDate')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {t('common:formats.shortDate', {
                                        date: new Date(purchaseTransaction.vehicle.intendedDeregistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.vehicleMake')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.make}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.vehicleModel')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.model}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.primaryColour')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.primaryColour}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.secondaryColour')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.secondaryColour}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.manufacturingYear')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.manufacturingYear}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.openMarketValue')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {t('common:currency') +
                                        purchaseTransaction.vehicle.openMarketValue.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedTop]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(viewTransactionStyles.label, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('purchaseTransactionsPage:vehicle.originalRegistrationDate')}
                                </Typography>
                                <Typography
                                    className={cs(viewTransactionStyles.content, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('common:formats.shortDate', {
                                        date: new Date(purchaseTransaction.vehicle.originalRegistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(viewTransactionStyles.label, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('purchaseTransactionsPage:vehicle.firstRegistrationDate')}
                                </Typography>
                                <Typography
                                    className={cs(viewTransactionStyles.content, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('common:formats.shortDate', {
                                        date: new Date(purchaseTransaction.vehicle.firstRegistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.transferCount')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.transferCount.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(viewTransactionStyles.label, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('purchaseTransactionsPage:vehicle.actualArfPaid')}
                                </Typography>
                                <Typography
                                    className={cs(viewTransactionStyles.content, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('common:currency') + purchaseTransaction.vehicle.actualARFPaid.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        {(purchaseTransaction.vehicle.opcCashRebateEligibility ||
                            purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate ||
                            purchaseTransaction.vehicle.opcCashRebateAmount) && (
                            <>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(viewTransactionStyles.label, commonStyles.redText)}>
                                            {t('purchaseTransactionsPage:vehicle.opcCashRebateEligibility')}
                                        </Typography>
                                        <Typography className={cs(viewTransactionStyles.content, commonStyles.redText)}>
                                            {purchaseTransaction.vehicle.opcCashRebateEligibility}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(viewTransactionStyles.label, commonStyles.redText)}>
                                            {t('purchaseTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                                        </Typography>
                                        <Typography className={cs(viewTransactionStyles.content, commonStyles.redText)}>
                                            {purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                ? t('common:formats.shortDate', {
                                                      date: new Date(
                                                          purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                      ),
                                                  })
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(viewTransactionStyles.label, commonStyles.redText)}>
                                            {t('purchaseTransactionsPage:vehicle.opcCashRebateAmount')}
                                        </Typography>
                                        <Typography className={cs(viewTransactionStyles.content, commonStyles.redText)}>
                                            {purchaseTransaction.vehicle.opcCashRebateAmount
                                                ? `${t(
                                                      'common:currency'
                                                  )}${purchaseTransaction.vehicle.opcCashRebateAmount.toLocaleString()}`
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}

                        <Grid
                            className={cs({
                                [commonStyles.borderedBottom]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(viewTransactionStyles.label, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('purchaseTransactionsPage:vehicle.parfRebateValue')}
                                </Typography>
                                <Typography
                                    className={cs(viewTransactionStyles.content, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {purchaseTransaction.vehicle.parfRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.vehicle.parfRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.coeExpiryDate')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {t('common:formats.shortDate', {
                                        date: new Date(purchaseTransaction.vehicle.coeExpiryDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.coeCategory')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.coeCategory}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.totalRebateAmount')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.totalRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.vehicle.totalRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.ltaMessage')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.ltaMessage
                                        ? purchaseTransaction.vehicle.ltaMessage
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.importMethod')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.importMethod
                                        ? purchaseTransaction.vehicle.importMethod
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Accordion>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.vehicleOtherDetails')}>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.vehicleToBeExported')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.beExported ? t('common:yes') : t('common:no')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.engineNo')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.engineNumber}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.chassisNo')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.chassisNumber}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.maxPowerOutput')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.maximumPowerOutput}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.parfEligibility')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.parfEligibility ? t('common:yes') : t('common:no')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.parfEligibilityDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.vehicle.parfEligibilityDate),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.coePeriod')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.coePeriodYear.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.qpPaid')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.qpPaid
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.vehicle.qpPaid.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.pqpPaid')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.pqpPaid
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.vehicle.pqpPaid.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={viewTransactionStyles.label}>
                                    {t('purchaseTransactionsPage:vehicle.coeRebateAmount')}
                                </Typography>
                                <Typography className={viewTransactionStyles.content}>
                                    {purchaseTransaction.vehicle.coeRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${purchaseTransaction.vehicle.coeRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
