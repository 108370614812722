import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    imageFileExtensions,
    maxSalesTransactionHandoverAttachments,
    maxUploadFileSize,
    pdfFileExtension,
} from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import {
    FullSaleTransactionDataFragment,
    NameOnlyFileDataFragment,
    useRemoveSalesTransactionHandoverAttachmentMutation,
    useRemoveVsaAttachmentMutation,
    UserType,
    useUploadSalesTransactionHandoverAttachmentMutation,
    useUploadVsaAttachmentMutation,
} from '../../../../api';
import { AttachmentButton } from '../../../../components/attachments';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import { diffUploads } from '../../../../utilities/file';
import { useHandleError } from '../../../../utilities/handleErrors';
import useHandoverLocationOptions, {
    defaultOtherLocationOption,
} from '../../../../utilities/useHandoverLocationOptions';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

export type ErrorRequestForApprovalDialogProps = {
    show?: boolean;
    setHideDialog: () => void;
    setOkDialog: (
        vsaAttachment?: FullSaleTransactionDataFragment['handover']['vsaAttachment'],
        handoverLocation?: string
    ) => void;
    title: string;
    cancelBtn?: string;
    okBtn?: string;
    saleTransaction: FullSaleTransactionDataFragment;
};

type RequestForApprovalEditFormValues = {
    handover: {
        vsaAttachment: (NameOnlyFileDataFragment | File)[];
        handoverLocationField: { main: string; other?: string };
        attachments: (NameOnlyFileDataFragment | File)[];
    };
};

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            width: '100%',
        },
    })
);

const ErrorRequestForApprovalDialog = ({
    show,
    setHideDialog,
    setOkDialog,
    title,
    cancelBtn,
    okBtn,
    saleTransaction,
}: ErrorRequestForApprovalDialogProps) => {
    const [removeVsaAttachmentMutation] = useRemoveVsaAttachmentMutation();
    const [uploadVsaAttachmentMutation] = useUploadVsaAttachmentMutation();
    const [removeHandoverAttachmentMutation] = useRemoveSalesTransactionHandoverAttachmentMutation();
    const [uploadHandoverAttachmentMutation] = useUploadSalesTransactionHandoverAttachmentMutation();

    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const handoverLocationOptions = useHandoverLocationOptions();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const {
        handover: { vsaAttachment, attachments, handoverLocation },
    } = saleTransaction;

    const handoverLocationOption = handoverLocationOptions.find(option => option.value === handoverLocation);
    const handoverLocationField = useMemo(
        () => ({
            main: handoverLocationOption ? handoverLocationOption.value : defaultOtherLocationOption,
            other: !handoverLocationOption ? handoverLocation : '',
        }),
        [handoverLocationOption, handoverLocation]
    );

    const handover = useMemo(
        () => ({
            vsaAttachment: vsaAttachment ? [vsaAttachment] : [],
            attachments: attachments || [],
            handoverLocationField: handoverLocation ? handoverLocationField : { main: '' },
        }),
        [vsaAttachment, handoverLocation, handoverLocationField, attachments]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.validAttachments('handover.vsaAttachment', t('common:formErrors.required')),
                validators.validAttachments('handover.attachments', t('common:formErrors.required')),

                validators.requiredValue('handover.handoverLocationField.main'),
                validators.only(
                    ({
                        handover: {
                            handoverLocationField: { main },
                        },
                    }) => main === defaultOtherLocationOption,
                    validators.requiredString('handover.handoverLocationField.other')
                )
            ),
        [t]
    );
    const validate = useValidator(formValidator);
    const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;
    const allowedPdfImageExtensions = [pdfFileExtension, ...imageFileExtensions];

    const initialValues = useMemo(
        () => ({
            handover,
        }),
        [handover]
    );

    const onSubmit = useHandleError(
        async (values: RequestForApprovalEditFormValues) => {
            const { handover: handoverFormData } = values;

            const vpaAttachmentsDiff = diffUploads(handoverFormData.vsaAttachment, handover.vsaAttachment);
            const attachmentsDiff = diffUploads(handoverFormData.attachments, handover.attachments);
            const vsaAttachments: FullSaleTransactionDataFragment['handover']['vsaAttachment'][] = [];
            await Promise.all([
                ...vpaAttachmentsDiff.removedUploads.map(async file => {
                    await removeVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...attachmentsDiff.removedUploads.map(async file => {
                    await removeHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...vpaAttachmentsDiff.newUploads.map(async file => {
                    const {
                        data: {
                            saleTransaction: {
                                handover: { vsaAttachment },
                            },
                        },
                    } = await uploadVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                    vsaAttachments.push(vsaAttachment);
                }),
                ...attachmentsDiff.newUploads.map(async file => {
                    await uploadHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                }),
            ]);

            const handoverLocationFormData =
                handoverFormData.handoverLocationField.other || handoverFormData.handoverLocationField.main;

            // get latest vsaAttachment object and handoverLocation
            setOkDialog(vsaAttachments.pop(), handoverLocationFormData);
        },
        [setOkDialog, handover.vsaAttachment]
    );

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="sm"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.leftTextAlign)}>
                            {title}
                        </Typography>
                    </Box>
                    <Box mb={1} mt={1}>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                            {({
                                handleSubmit,
                                setFieldValue,
                                values: {
                                    handover: { handoverLocationField },
                                },
                            }) => {
                                useEffect(() => {
                                    if (handoverLocationField.main !== defaultOtherLocationOption) {
                                        // remove other location
                                        setFieldValue('handover.handoverLocationField.other', '');
                                    }
                                }, [handoverLocationField, setFieldValue]);

                                return (
                                    <Form>
                                        <Grid spacing={2} container>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <AttachmentButton
                                                    allowedExtensions={allowedPdfImageExtensions}
                                                    buttonText={t('common:upload')}
                                                    icon={<PublishIcon fontSize="small" />}
                                                    label={t('saleTransactionsPage:handover.vsaAttachment')}
                                                    max={1}
                                                    maxSize={maxFileSizeInKB}
                                                    name="handover.vsaAttachment"
                                                    uploadFileType="pick_file"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid spacing={2} container>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <AttachmentButton
                                                    allowedExtensions={allowedPdfImageExtensions}
                                                    buttonText={t('common:upload')}
                                                    icon={<PublishIcon fontSize="small" />}
                                                    label={t('saleTransactionsPage:handover.otherAttachments')}
                                                    max={maxSalesTransactionHandoverAttachments}
                                                    maxSize={maxFileSizeInKB}
                                                    name="handover.attachments"
                                                    uploadFileType="pick_file"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid spacing={2} container>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedSelectField
                                                    color="secondary"
                                                    disabled={
                                                        currentUser.type !== UserType.Admin &&
                                                        currentUser.type !== UserType.SaleConsultant &&
                                                        currentUser.type !== UserType.Approver &&
                                                        currentUser.type !== UserType.ValuationTeam
                                                    }
                                                    inputProps={{
                                                        classes: {
                                                            icon: commonStyles.fillSecondary,
                                                        },
                                                    }}
                                                    label={t('saleTransactionsPage:handover.targetHandoverLocation')}
                                                    name="handover.handoverLocationField.main"
                                                    options={handoverLocationOptions}
                                                    placeholder={t(
                                                        'saleTransactionsPage:handover.targetHandoverLocation'
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        {handoverLocationField.main === defaultOtherLocationOption && (
                                            <Grid spacing={2} container>
                                                <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                    <OutlinedInputField
                                                        color="secondary"
                                                        disabled={
                                                            currentUser.type !== UserType.Admin &&
                                                            currentUser.type !== UserType.Approver &&
                                                            currentUser.type !== UserType.ValuationTeam
                                                        }
                                                        label={t('saleTransactionsPage:handover.otherLocation')}
                                                        name="handover.handoverLocationField.other"
                                                        placeholder={t('saleTransactionsPage:handover.otherLocation')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Box display="flex" mt={3}>
                                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                                <Box mr={1}>
                                                    <Button
                                                        className={styles.button}
                                                        onClick={setHideDialog}
                                                        size="large"
                                                        variant="outlined"
                                                    >
                                                        {cancelBtn || t('common:cancel')}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                                <Box>
                                                    <Button
                                                        className={styles.button}
                                                        color="secondary"
                                                        onClick={() => handleSubmit()}
                                                        size="large"
                                                        variant="contained"
                                                    >
                                                        {okBtn || t('common:ok')}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorRequestForApprovalDialog;
