import { Grid } from '@material-ui/core';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedPasswordField from '../../../../components/fields/OutlinedPasswordField';
import { passwordAdornment, personAdornment } from '../../../../components/fields/adornments';
import usePortalStyles from '../../../../layouts/PortalLayout/usePortalStyles';
import useCommonStyles from '../../../../useCommonStyles';

const LoginFormFields = () => {
    const commonStyles = useCommonStyles();
    const portalStyles = usePortalStyles();
    const { t } = useTranslation(['loginPage']);

    return (
        <>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="mobilePhone.number"
                    placeholder={t('loginPage:fields.usernamePlaceholder')}
                    startAdornment={personAdornment}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedPasswordField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="password"
                    placeholder={t('loginPage:fields.passwordPlaceholder')}
                    startAdornment={passwordAdornment}
                />
            </Grid>
        </>
    );
};

export default LoginFormFields;
