import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullNotificationDataFragment } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import NotificationRow from './NotificationRow';

export type NotificationBodyProps = {
    notices: FullNotificationDataFragment[];
};

const NotificationBody = ({ notices }: NotificationBodyProps) => {
    const { t } = useTranslation(['notifications']);
    const commonStyles = useCommonStyles();

    return (
        <Box mb={3}>
            <Table className={commonStyles.table}>
                <TableHead>
                    <TableRow className={commonStyles.tableRow}>
                        <TableCell>{t('notifications:list.transactionNo')}</TableCell>
                        <TableCell>{t('notifications:list.dateTime')}</TableCell>
                        <TableCell>{t('notifications:list.userMobile')}</TableCell>
                        <TableCell>{t('notifications:list.notificationType')}</TableCell>
                        <TableCell>{t('notifications:list.channel')}</TableCell>
                        <TableCell>{t('notifications:list.notificationDetail')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notices.map(notice => (
                        <NotificationRow key={notice.id} notice={notice} />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default NotificationBody;
