import { Box, Fab, Typography, useMediaQuery, Link, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import { useFlutter } from '../../../components/FlutterProvider';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type PurchaseTransactionsHeaderProps = {
    search: string;
    searchOnChange: (searchValue: string) => void;
    setShowFilterDialog: (value: boolean) => void;
    setShowFilterMenu: (value: boolean) => void;
    downloadPdf?: () => void;
    downloadExcel?: () => void;
    refreshFn?: () => Promise<unknown>;
};

const PurchaseTransactionsHeader = ({
    search,
    searchOnChange,
    setShowFilterDialog,
    setShowFilterMenu,
    downloadPdf,
    downloadExcel,
    refreshFn,
}: PurchaseTransactionsHeaderProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const history = useHistory();
    const currentUser = useCurrentUser();
    const { capabilities } = useFlutter();
    const { hasOneMonitoring } = capabilities;

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const canCreate =
        [UserType.Admin, UserType.ValuationTeam, UserType.Approver].includes(currentUser.type) ||
        (currentUser.type === UserType.SaleConsultant && hasOneMonitoring);

    return (
        <>
            <Box alignItems="center" className={commonStyles.title} display="flex">
                {!isSmall && <Typography>{t('purchaseTransactionsPage:title')}</Typography>}
                <Box ml="auto" width={isSmall ? '100%' : 'auto'}>
                    <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
                </Box>
                <Box className={commonStyles.buttonGroup}>
                    {!isSmall && (
                        <Button
                            color="secondary"
                            onClick={() => setShowFilterMenu(true)}
                            size="large"
                            variant="contained"
                        >
                            {t('purchaseTransactionsPage:filterBy')} <ExpandMoreIcon fontSize="small" />
                        </Button>
                    )}

                    {
                        // eslint-disable-next-line max-len
                        !isSmall &&
                            currentUser.type !== UserType.SaleConsultant &&
                            currentUser.type !== UserType.Dealer && (
                                <>
                                    <Button color="secondary" onClick={downloadExcel} size="large" variant="contained">
                                        <GetAppIcon fontSize="small" /> {t('common:exportInExcel')}
                                    </Button>
                                    <Button color="secondary" onClick={downloadPdf} size="large" variant="contained">
                                        <GetAppIcon fontSize="small" /> {t('common:exportInPdf')}
                                    </Button>
                                </>
                            )
                    }
                    {!isSmall && (
                        <Button color="secondary" onClick={refreshFn} size="large" variant="contained">
                            <RefreshIcon fontSize="small" /> {t('common:refresh')}
                        </Button>
                    )}
                    {!isSmall && canCreate && (
                        <Button
                            color="secondary"
                            onClick={() => history.push('/purchaseTransaction/create')}
                            size="large"
                            variant="contained"
                        >
                            <AddIcon fontSize="small" /> {t('common:create')}
                        </Button>
                    )}
                </Box>
            </Box>
            {isSmall && (
                <Box mt={3}>
                    <Box alignItems="center" display="flex">
                        <Box mr="auto">
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setShowFilterDialog(true)}
                                underline="none"
                            >
                                {t('purchaseTransactionsPage:filterBy')} <ExpandMoreIcon fontSize="small" />
                            </Link>
                        </Box>
                        <Box className={commonStyles.buttonGroup}>
                            {
                                // eslint-disable-next-line max-len
                                isSmall &&
                                    currentUser.type !== UserType.SaleConsultant &&
                                    currentUser.type !== UserType.Dealer && (
                                        <Button
                                            color="secondary"
                                            onClick={downloadPdf}
                                            size="small"
                                            variant="contained"
                                        >
                                            {
                                                // eslint-disable-next-line max-len
                                                <>
                                                    <GetAppIcon
                                                        className={commonStyles.normalFontSize}
                                                        fontSize="small"
                                                    />{' '}
                                                    {t('common:pdf')}
                                                </>
                                            }
                                        </Button>
                                    )
                            }
                            <Button color="secondary" onClick={refreshFn} size="small" variant="contained">
                                <RefreshIcon className={commonStyles.normalFontSize} fontSize="small" />{' '}
                                {t('common:refresh')}
                            </Button>
                        </Box>
                    </Box>

                    {canCreate && (
                        <Box bottom="1rem" position="fixed" right="2rem" zIndex={100}>
                            <Fab
                                color="secondary"
                                onClick={() => history.push('/purchaseTransaction/create')}
                                size="large"
                            >
                                <AddIcon />
                            </Fab>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default PurchaseTransactionsHeader;
