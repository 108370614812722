import { Container, Box, Grid } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { ResetPasswordSuccessDialog, ResetPasswordHeader, ResetPasswordTitle, ResetPasswordForm } from './components';

export type ResetPasswordPageProps = {
    token: string;
};

const ResetPasswordPage = ({ token }: ResetPasswordPageProps) => {
    const [completed, setCompleted] = useState(false);

    const next = useCallback(() => setCompleted(true), [setCompleted]);

    return (
        <>
            <ResetPasswordSuccessDialog show={completed} />
            <Container maxWidth="xs">
                <Box pt={25}>
                    <Grid spacing={2} container>
                        <ResetPasswordHeader />
                        <ResetPasswordTitle />
                        <ResetPasswordForm next={next} token={token} />
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default withErrorBoundary(ResetPasswordPage);
