/* eslint-disable max-len, camelcase */
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
import { DocumentNode } from 'graphql';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: string | Date;
    JSONObject: any;
    ObjectID: string;
    Upload: File;
};

export type AccountRegistrationBusinessFields = {
    name: Scalars['String'];
    address: Scalars['String'];
    registrationNumber: Scalars['String'];
};

export type AccountRegistrationFields = {
    type: UserType;
    fullName: Scalars['String'];
    password: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    brand?: Maybe<Scalars['String']>;
    business?: Maybe<AccountRegistrationBusinessFields>;
    sendOtpByEmail?: Maybe<Scalars['Boolean']>;
};

export type AccountUpdateBusinessFields = {
    name: Scalars['String'];
    address: Scalars['String'];
    registrationNumber: Scalars['String'];
};

export type AccountUpdateFields = {
    type: UserType;
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    business?: Maybe<AccountUpdateBusinessFields>;
    brand?: Maybe<Scalars['String']>;
};

export type Activity = {
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
};

export type ActivityLogFilterField = {
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
};

export enum ActivityType {
    Creation = 'Creation',
    Valuation = 'Valuation',
    PurchaseUpdate = 'PurchaseUpdate',
    PurchaseStageUpdate = 'PurchaseStageUpdate',
    PurchaseStageBySystemUpdate = 'PurchaseStageBySystemUpdate',
    Bidding = 'Bidding',
    SaleUpdate = 'SaleUpdate',
    SaleStageUpdate = 'SaleStageUpdate',
    SaleStageUpdateBySystem = 'SaleStageUpdateBySystem',
    UserUpdate = 'UserUpdate',
    UserStageUpdate = 'UserStageUpdate',
    UserStageUpdateBySystem = 'UserStageUpdateBySystem',
}

export type AdminUser = User & {
    __typename?: 'AdminUser';
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export type ApproverUser = User & {
    __typename?: 'ApproverUser';
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export type AuthenticateResult = {
    __typename?: 'AuthenticateResult';
    token: Scalars['String'];
    email?: Maybe<Scalars['String']>;
};

export type AwardedNoticeNotification = Notification & {
    __typename?: 'AwardedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    saleTransactions: Array<SaleTransaction>;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type Bidding = {
    __typename?: 'Bidding';
    id: Scalars['ObjectID'];
    sessionName: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    startPeriod: Scalars['DateTime'];
    endPeriod: Scalars['DateTime'];
    status: BiddingStatus;
    biddingSaleTransactions: Array<BiddingSaleTransaction>;
    dealerIds: Array<Scalars['ObjectID']>;
};

export type BiddingActivity = Activity & {
    __typename?: 'BiddingActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    biddingValue: Scalars['Float'];
    stage: SaleTransactionStage;
    diff: Scalars['JSONObject'];
};

export type BiddingEntry = {
    __typename?: 'BiddingEntry';
    id: Scalars['ObjectID'];
    amount: Scalars['Int'];
    placedOn: Scalars['DateTime'];
    dealerId: Scalars['ObjectID'];
    dealer: User;
};

export type BiddingEntryFields = {
    id: Scalars['ObjectID'];
    amount: Scalars['Int'];
};

export type BiddingFilterField = {
    search?: Maybe<Scalars['String']>;
    status?: Maybe<BiddingStatus>;
};

export type BiddingPage = {
    __typename?: 'BiddingPage';
    items: Array<Bidding>;
    count: Scalars['Int'];
};

export type BiddingSaleTransaction = {
    __typename?: 'BiddingSaleTransaction';
    saleTransactionId: Scalars['ObjectID'];
    saleTransaction: SaleTransaction;
    remainDealerBiddingEntryCount: Scalars['Int'];
    biddingEntries: Array<BiddingEntry>;
    topBiddingEntries: Array<BiddingEntry>;
    awardedBiddingEntry?: Maybe<BiddingEntry>;
    lastUserBid?: Maybe<BiddingEntry>;
    isInTop: Scalars['Boolean'];
    closed: Scalars['Boolean'];
    closedAt?: Maybe<Scalars['DateTime']>;
};

export enum BiddingStatus {
    Upcoming = 'Upcoming',
    Current = 'Current',
    Past = 'Past',
    Cancelled = 'Cancelled',
}

export type BiometricDeviceSetting = DeviceSetting & {
    __typename?: 'BiometricDeviceSetting';
    type: DeviceSettingsType;
    deviceFingerprint: Scalars['String'];
    enabledOn: Scalars['DateTime'];
};

export type CreationActivity = Activity & {
    __typename?: 'CreationActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
};

export type Customer = {
    __typename?: 'Customer';
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    ownerIdType: Scalars['String'];
    ownerId: Scalars['String'];
    ownerName?: Maybe<Scalars['String']>;
    modelInterested?: Maybe<Scalars['String']>;
};

export type CustomerFields = {
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    ownerIdType: Scalars['String'];
    ownerId: Scalars['String'];
    ownerName?: Maybe<Scalars['String']>;
    modelInterested?: Maybe<Scalars['String']>;
};

export type DealerBusiness = {
    __typename?: 'DealerBusiness';
    name: Scalars['String'];
    address: Scalars['String'];
    registrationNumber: Scalars['String'];
};

export type DealerInformation = {
    __typename?: 'DealerInformation';
    name: Scalars['String'];
    price?: Maybe<Scalars['Int']>;
};

export type DealerInformationFields = {
    name: Scalars['String'];
    price?: Maybe<Scalars['Int']>;
};

export type DealerUser = User & {
    __typename?: 'DealerUser';
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    business: DealerBusiness;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export type DeviceSetting = {
    type: DeviceSettingsType;
    deviceFingerprint: Scalars['String'];
    enabledOn: Scalars['DateTime'];
};

export type DeviceSettingField = {
    isEnabledFingerPrint: Scalars['Boolean'];
    isEnabledFaceID: Scalars['Boolean'];
    isEnabledPush: Scalars['Boolean'];
    deviceFingerprint: Scalars['String'];
    registrationToken: Scalars['String'];
};

export enum DeviceSettingsType {
    Biometric = 'Biometric',
    Push = 'Push',
}

export enum DownloadFileType {
    Pdf = 'Pdf',
    Excel = 'Excel',
}

export type Handover = {
    __typename?: 'Handover';
    personResponsible: User;
    targetHandoverDateTime?: Maybe<Scalars['DateTime']>;
    handoverLocation?: Maybe<Scalars['String']>;
    actualHandoverDate?: Maybe<Scalars['DateTime']>;
    remarks?: Maybe<Scalars['String']>;
    attachments: Array<UploadedFile>;
    vpaAttachment?: Maybe<UploadedFile>;
    deliveryPeriod: Scalars['Int'];
    assignPersonResponsibleAt?: Maybe<Scalars['DateTime']>;
    targetSetOfKeys?: Maybe<Scalars['Int']>;
    targetHandbook?: Maybe<Scalars['Boolean']>;
    deliveryPeriodIndentOrder?: Maybe<Scalars['String']>;
};

export type HandoverDateChangeNoticeNotification = Notification & {
    __typename?: 'HandoverDateChangeNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type HandoverFields = {
    personResponsible: Scalars['ObjectID'];
    targetHandoverDateTime?: Maybe<Scalars['DateTime']>;
    handoverLocation?: Maybe<Scalars['String']>;
    actualHandoverDate?: Maybe<Scalars['DateTime']>;
    remarks?: Maybe<Scalars['String']>;
    vehicleSalesAgreementNo?: Maybe<Scalars['String']>;
    deliveryPeriod: Scalars['Int'];
    targetSetOfKeys?: Maybe<Scalars['Int']>;
    targetHandbook?: Maybe<Scalars['Boolean']>;
    deliveryPeriodIndentOrder?: Maybe<Scalars['String']>;
};

export type HandoverForApprovalRequestFields = {
    targetHandoverDateTime?: Maybe<Scalars['DateTime']>;
    handoverLocation: Scalars['String'];
    targetSetOfKeys?: Maybe<Scalars['Int']>;
    targetHandbook?: Maybe<Scalars['Boolean']>;
};

export type HandoverForAwardingFields = {
    targetHandoverDateTime?: Maybe<Scalars['DateTime']>;
    vehicleSalesAgreementNo?: Maybe<Scalars['String']>;
    personResponsible?: Maybe<Scalars['ObjectID']>;
    manualSalesDealer?: Maybe<Scalars['String']>;
    manualSalesPrice?: Maybe<Scalars['Float']>;
};

export type InitValuationFields = {
    internalRemarks: Scalars['String'];
    vehicle: InitValuationVehicleFields;
    handover: InitValuationHandoverFields;
};

export type InitValuationHandoverFields = {
    personResponsible: Scalars['ObjectID'];
    deliveryPeriod: Scalars['Int'];
};

export type InitValuationVehicleFields = {
    importMethod: Scalars['String'];
    mileage: Scalars['Int'];
};

export type MobilePhone = {
    __typename?: 'MobilePhone';
    internationalCode: Scalars['String'];
    number: Scalars['String'];
};

export type MobilePhoneInput = {
    internationalCode: Scalars['String'];
    number: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /**
     * Register a new account
     *
     * When successful, it will return a OTP token to be used for approval
     */
    registerNewAccount: Scalars['String'];
    /**
     * Confirm the registration of a new account
     *
     * When successful it will return the user document
     */
    confirmNewAccountRegistration: User;
    /**
     * Authenticate
     *
     * When successful, it will return the token for OTP approval
     */
    authenticate: AuthenticateResult;
    /**
     * Authenticate with token
     *
     * When successful, it will return the token and a new single sign in token
     */
    authenticateWithToken: UserSession;
    /** Refresh token */
    refreshToken: Scalars['String'];
    /**
     * Get single sign in token
     *
     * Restrictions: logged in
     */
    getSingleSignInToken?: Maybe<Scalars['String']>;
    /**
     * Confirm the registration of a new account
     *
     * When successful it will return the user document and token
     */
    confirmAuthentication: UserSession;
    /**
     * Update an account
     *
     * When successfull it will return the user document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active only
     */
    updateAccount?: Maybe<User>;
    /**
     * Upload profile picture
     *
     * Restrictions: logged in, active only
     */
    uploadProfilePicture?: Maybe<User>;
    /**
     * Enable an account
     *
     * When successfull it will return the user document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active admin only
     */
    enableAccount?: Maybe<User>;
    /**
     * Disable an account
     *
     * When successfull it will return the user document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active admin only
     */
    disableAccount?: Maybe<User>;
    /**
     * Approve an account
     *
     * When successfull it will return the user document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active admin only
     */
    approveAccount?: Maybe<User>;
    /**
     * Reject a new account
     *
     * When successfull it will return the user document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active admin only
     */
    rejectAccount?: Maybe<User>;
    /**
     * Request OTP for reset password
     *
     * When successful, it will return a OTP token to be used for approval
     */
    requestResetPassword: RequestPasswordResult;
    /**
     * Confirm Reset Password OTP
     *
     * When successful it will return the token to reset the password
     */
    confirmResetPassword: Scalars['String'];
    /**
     * Update Password from the reset flow
     *
     * When successful it will return the user document
     */
    consumeResetPassword: User;
    /**
     * Create Purchase Transaction
     *
     * When successful, it will return Transaction
     */
    createPurchaseTransaction: PurchaseTransaction;
    /**
     * Create Purchase Transaction
     *
     * When successful, it will return Transaction
     */
    createSaleTransaction: SaleTransaction;
    /**
     * Clone Purchase Transaction - 'Create New'
     *
     * When successful, it will return Transaction
     */
    cloneSaleTransactionCreateNew: SaleTransaction;
    /**
     * Update Transaction to Initial Valuation
     *
     * When successful, it will return Transaction
     */
    updateTransactionToInitialValuation?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Pending Acceptance
     *
     * When successful, it will return Transaction
     */
    updateTransactionToPendingAcceptance?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Final Valuation
     *
     * When successful, it will return Transaction
     */
    updateTransactionToFinalValuation?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Complete
     *
     * When successful, it will return Transaction
     */
    updateTransactionToPendingApproval?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Handover
     *
     * When successful, it will return Transaction
     */
    updateTransactionToHandover?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Complete
     *
     * When successful, it will return Transaction
     */
    updateTransactionToComplete?: Maybe<PurchaseTransaction>;
    /**
     * Update Transaction to Cancel
     *
     * When successful, it will return Transaction
     */
    updateTransactionToCancel?: Maybe<PurchaseTransaction>;
    /**
     * Update Purchase Transaction
     *
     * When successful, it will return Transaction
     */
    updatePurchaseTransaction?: Maybe<PurchaseTransaction>;
    /**
     * Create Bidding
     *
     * When successful, it will return Bidding
     *
     * Restrictions: logged in, active admin only
     */
    createBidding: Bidding;
    /**
     * Update Bidding
     *
     * When successful, it will return Bidding
     *
     * Restrictions: logged in, active admin only
     */
    updateBidding?: Maybe<Bidding>;
    /**
     * Cancel Bidding
     *
     * When successful, it will return Bidding
     *
     * Restrictions: logged in, active admin only
     */
    cancelBidding?: Maybe<Bidding>;
    /**
     * Add Bidding Entry
     *
     * When successful, it will return Bidding
     *
     * Restrictions: logged in, active dealer only
     */
    addBiddingEntry?: Maybe<SaleTransaction>;
    /**
     * Update awarded bidding entry
     *
     * When successfull it will return the bidding document. If the user does not exist, null is returned.
     *
     * Restrictions: logged in, active admin only
     */
    updateAwardedBiddingEntry?: Maybe<Bidding>;
    /**
     * Update Sale Transaction
     *
     * When successful, it will return Sale Transaction
     */
    updateSaleTransaction: SaleTransaction;
    /**
     * Update Sale Transaction to ForHandover
     *
     * When successful, it will return Transaction
     */
    updateSaleTransactionToForHandover?: Maybe<SaleTransaction>;
    /**
     * Update Sale Transaction to Completed
     *
     * When successful, it will return Transaction
     */
    updateSaleTransactionToCompleted?: Maybe<SaleTransaction>;
    /**
     * Update Sale Transaction to Closed
     *
     * When successful, it will return Transaction
     */
    updateSaleTransactionToClosed?: Maybe<SaleTransaction>;
    /**
     * Update Sale Transaction to Pending
     *
     * When successful, it will return Transaction
     */
    updateSaleTransactionToPending?: Maybe<SaleTransaction>;
    /**
     * Update Sale Transaction to Pending Approval
     *
     * When successful, it will return Sale Transaction
     */
    updateSaleTransactionToPendingApproval?: Maybe<SaleTransaction>;
    /**
     * Toggle Biometric authentication for a device
     *
     * Restrictions: logged in
     */
    toggleBiometric: User;
    /**
     * Enable Push notifications
     *
     * Restrictions: logged in
     */
    enablePush: User;
    /**
     * Update registration token
     *
     * Restrictions: logged in
     */
    updateRegistrationToken: User;
    /**
     * Disable Push notifications
     *
     * Restrictions: logged in
     */
    disablePush: User;
    /**
     * Add vehicle diagram comment
     *
     * Restrictions: logged in, active admin and valuation team only
     */
    addVehicleDiagramComment?: Maybe<PurchaseTransaction>;
    /**
     * Remove vehicle diagram comment
     *
     * Restrictions: logged in, active admin and valuation team only
     */
    removeVehicleDiagramComment?: Maybe<PurchaseTransaction>;
    /**
     * Update front page photo
     *
     * Restrictions: logged in
     */
    updatePurchaseTransactionFrontPagePhoto?: Maybe<PurchaseTransaction>;
    /**
     * Upload valuation photo
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadValuationPhoto?: Maybe<PurchaseTransaction>;
    /**
     * Upload handover attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadHandoverAttachment?: Maybe<PurchaseTransaction>;
    /**
     * Upload vehicle purchase agreement attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadVpaAttachment?: Maybe<PurchaseTransaction>;
    /**
     * Remove valuation photo
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeValuationPhoto?: Maybe<PurchaseTransaction>;
    /**
     * Remove handover attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeHandoverAttachment?: Maybe<PurchaseTransaction>;
    /**
     * Remove vehicle purchase agreement attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeVpaAttachment?: Maybe<PurchaseTransaction>;
    /**
     * Upload sales transaction handover attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadSalesTransactionHandoverAttachment?: Maybe<SaleTransaction>;
    /**
     * Upload vehicle sales agreement attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadVsaAttachment?: Maybe<SaleTransaction>;
    /**
     * Update front page photo
     *
     * Restrictions: logged in
     */
    updateSalesTransactionFrontPagePhoto?: Maybe<SaleTransaction>;
    /**
     * Upload sales transaction photo
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    uploadSalesTransactionPhoto?: Maybe<SaleTransaction>;
    /**
     * Remove sales transaction photo
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeSalesTransactionPhoto?: Maybe<SaleTransaction>;
    /**
     * Remove sales transaction handover attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeSalesTransactionHandoverAttachment?: Maybe<SaleTransaction>;
    /**
     * Remove vehicle sales agreement attachment
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    removeVsaAttachment?: Maybe<SaleTransaction>;
    /**
     * Update purchase for request approval
     *
     * Restrictions: logged in, approver and valuation team only
     */
    updatePurchaseTransactionForApprovalRequest?: Maybe<PurchaseTransaction>;
    /**
     * Update purchase transaction for acceptance
     *
     * Restrictions: logged in, approver and valuation team only
     */
    updatePurchaseTransactionForAcceptance?: Maybe<PurchaseTransaction>;
    /**
     * Update purchase for request approval
     *
     * Restrictions: logged in, approver and valuation team only
     */
    updateSalesTransactionForAwarding?: Maybe<SaleTransaction>;
    /**
     * Update purchase for initial valuation
     *
     * Restrictions: logged in, valuation team only
     */
    updatePurchaseTransactionForInitValuation?: Maybe<PurchaseTransaction>;
    /**
     * Trigger pending acceptance notification
     *
     * Restrictions: logged in
     */
    triggerPendingAcceptanceNotification?: Maybe<PurchaseTransaction>;
};

export type MutationRegisterNewAccountArgs = {
    fields?: Maybe<AccountRegistrationFields>;
};

export type MutationConfirmNewAccountRegistrationArgs = {
    code: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
};

export type MutationAuthenticateArgs = {
    mobilePhone: MobilePhoneInput;
    password: Scalars['String'];
    sendOtpByEmail?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticateWithTokenArgs = {
    deviceFingerprint: Scalars['String'];
    token: Scalars['String'];
};

export type MutationGetSingleSignInTokenArgs = {
    deviceFingerprint: Scalars['String'];
};

export type MutationConfirmAuthenticationArgs = {
    code: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
    deviceFingerprint?: Maybe<Scalars['String']>;
};

export type MutationUpdateAccountArgs = {
    userId: Scalars['ObjectID'];
    fields: AccountUpdateFields;
    activityOption?: Maybe<UserUpdateActivityInput>;
};

export type MutationUploadProfilePictureArgs = {
    userId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationEnableAccountArgs = {
    userId: Scalars['ObjectID'];
};

export type MutationDisableAccountArgs = {
    userId: Scalars['ObjectID'];
};

export type MutationApproveAccountArgs = {
    userId: Scalars['ObjectID'];
    activityOption?: Maybe<UserUpdateActivityInput>;
};

export type MutationRejectAccountArgs = {
    userId: Scalars['ObjectID'];
    activityOption?: Maybe<UserUpdateActivityInput>;
};

export type MutationRequestResetPasswordArgs = {
    mobilePhone: MobilePhoneInput;
    sendOtpByEmail?: Maybe<Scalars['Boolean']>;
};

export type MutationConfirmResetPasswordArgs = {
    code: Scalars['String'];
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
};

export type MutationConsumeResetPasswordArgs = {
    token: Scalars['String'];
    password: Scalars['String'];
};

export type MutationCreatePurchaseTransactionArgs = {
    fields: NewPurchaseTransactionFields;
};

export type MutationCreateSaleTransactionArgs = {
    fields: NewSaleTransactionFields;
};

export type MutationCloneSaleTransactionCreateNewArgs = {
    salesTransactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToInitialValuationArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToPendingAcceptanceArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToFinalValuationArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToPendingApprovalArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToHandoverArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateTransactionToCompleteArgs = {
    transactionId: Scalars['ObjectID'];
    setOfKeys: Scalars['Int'];
    ownerHandbookCollected: Scalars['Boolean'];
    handoverRemarks: Scalars['String'];
};

export type MutationUpdateTransactionToCancelArgs = {
    transactionId: Scalars['ObjectID'];
    cancelReason: Scalars['String'];
};

export type MutationUpdatePurchaseTransactionArgs = {
    transactionId: Scalars['ObjectID'];
    fields: NewPurchaseTransactionFields;
};

export type MutationCreateBiddingArgs = {
    fields: NewBiddingFields;
};

export type MutationUpdateBiddingArgs = {
    biddingId: Scalars['ObjectID'];
    fields: UpdateBiddingFields;
};

export type MutationCancelBiddingArgs = {
    biddingId: Scalars['ObjectID'];
};

export type MutationAddBiddingEntryArgs = {
    biddingId: Scalars['ObjectID'];
    saleTransactionId: Scalars['ObjectID'];
    amount: Scalars['Int'];
};

export type MutationUpdateAwardedBiddingEntryArgs = {
    biddingId: Scalars['ObjectID'];
    saleTransactionId: Scalars['ObjectID'];
    biddingEntryId: Scalars['ObjectID'];
};

export type MutationUpdateSaleTransactionArgs = {
    transactionId: Scalars['ObjectID'];
    fields: NewSaleTransactionFields;
};

export type MutationUpdateSaleTransactionToForHandoverArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateSaleTransactionToCompletedArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateSaleTransactionToClosedArgs = {
    transactionId: Scalars['ObjectID'];
};

export type MutationUpdateSaleTransactionToPendingArgs = {
    transactionId: Scalars['ObjectID'];
    stage: SaleTransactionStage;
};

export type MutationUpdateSaleTransactionToPendingApprovalArgs = {
    transactionId: Scalars['ObjectID'];
    handoverLocation?: Maybe<Scalars['String']>;
};

export type MutationToggleBiometricArgs = {
    deviceFingerprint: Scalars['String'];
    state: Scalars['Boolean'];
};

export type MutationEnablePushArgs = {
    deviceFingerprint: Scalars['String'];
    registrationToken: Scalars['String'];
};

export type MutationUpdateRegistrationTokenArgs = {
    deviceFingerprint: Scalars['String'];
    registrationToken: Scalars['String'];
};

export type MutationDisablePushArgs = {
    deviceFingerprint: Scalars['String'];
};

export type MutationAddVehicleDiagramCommentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    fields: VehicleDiagramCommentFields;
};

export type MutationRemoveVehicleDiagramCommentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    vehicleDiagramCommentID: Scalars['ObjectID'];
};

export type MutationUpdatePurchaseTransactionFrontPagePhotoArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    frontPagePhotoId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUploadValuationPhotoArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isFrontPagePhoto: Scalars['Boolean'];
};

export type MutationUploadHandoverAttachmentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadVpaAttachmentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationRemoveValuationPhotoArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationRemoveHandoverAttachmentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationRemoveVpaAttachmentArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationUploadSalesTransactionHandoverAttachmentArgs = {
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadVsaAttachmentArgs = {
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUpdateSalesTransactionFrontPagePhotoArgs = {
    salesTransactionId: Scalars['ObjectID'];
    frontPagePhotoId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUploadSalesTransactionPhotoArgs = {
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isFrontPagePhoto: Scalars['Boolean'];
};

export type MutationRemoveSalesTransactionPhotoArgs = {
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationRemoveSalesTransactionHandoverAttachmentArgs = {
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationRemoveVsaAttachmentArgs = {
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
};

export type MutationUpdatePurchaseTransactionForApprovalRequestArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    fields?: Maybe<PurchaseTransactionForApprovalRequestFields>;
};

export type MutationUpdatePurchaseTransactionForAcceptanceArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    fields?: Maybe<PurchaseTransactionForAcceptanceFields>;
};

export type MutationUpdateSalesTransactionForAwardingArgs = {
    salesTransactionId: Scalars['ObjectID'];
    fields?: Maybe<SalesTransactionForAwardingFields>;
};

export type MutationUpdatePurchaseTransactionForInitValuationArgs = {
    purchaseTransactionId: Scalars['ObjectID'];
    fields?: Maybe<InitValuationFields>;
};

export type MutationTriggerPendingAcceptanceNotificationArgs = {
    transactionId: Scalars['ObjectID'];
};

export type NewBiddingFields = {
    sessionName: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    startPeriod: Scalars['DateTime'];
    endPeriod: Scalars['DateTime'];
    saleTransactionIds: Array<Scalars['ObjectID']>;
    dealerIds: Array<Scalars['ObjectID']>;
};

export type NewPurchaseTransactionFields = {
    saleConsultantId?: Maybe<Scalars['ObjectID']>;
    internalRemarks?: Maybe<Scalars['String']>;
    customer: CustomerFields;
    vehicle: VehicleFields;
    handover: HandoverFields;
    valuation?: Maybe<ValuationFields>;
    adminRemark?: Maybe<Scalars['String']>;
    cancelReason?: Maybe<Scalars['String']>;
};

export type NewSaleTransactionFields = {
    purchaseTransactionId?: Maybe<Scalars['ObjectID']>;
    internalRemarks?: Maybe<Scalars['String']>;
    vehicle?: Maybe<SaleTransactionVehicleFields>;
    handover?: Maybe<SalesTransactionHandoverFields>;
    adminRemark?: Maybe<Scalars['String']>;
};

export type Notification = {
    id: Scalars['ObjectID'];
    type: NotificationType;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export enum NotificationChannel {
    Sms = 'SMS',
    Push = 'Push',
    Email = 'Email',
}

export type NotificationFilterField = {
    search?: Maybe<Scalars['String']>;
    type?: Maybe<NotificationType>;
};

export type NotificationPage = {
    __typename?: 'NotificationPage';
    items: Array<Notification>;
    count: Scalars['Int'];
};

export enum NotificationType {
    SignUpApproval = 'SignUpApproval',
    SignUpReject = 'SignUpReject',
    PendingValuationNotice = 'PendingValuationNotice',
    PendingRequoteNotice = 'PendingRequoteNotice',
    PendingAcceptanceNotice = 'PendingAcceptanceNotice',
    HandoverDateChangeNotice = 'HandoverDateChangeNotice',
    PurchaseTransactionAmendedNotice = 'PurchaseTransactionAmendedNotice',
    UpcomingBiddingNotice = 'UpcomingBiddingNotice',
    AwardedNotice = 'AwardedNotice',
    PurchaseTransactionApprovalNotice = 'PurchaseTransactionApprovalNotice',
    PurchaseTransactionApprovedNotice = 'PurchaseTransactionApprovedNotice',
    PurchaseTransactionValuationAcceptedNotice = 'PurchaseTransactionValuationAcceptedNotice',
    PurchaseTransactionRejectedNotice = 'PurchaseTransactionRejectedNotice',
    SalesTransactionApprovalNotice = 'SalesTransactionApprovalNotice',
    SalesTransactionApprovedNotice = 'SalesTransactionApprovedNotice',
    SalesTransactionRejectedNotice = 'SalesTransactionRejectedNotice',
    UnawardNotice = 'UnawardNotice',
}

export type Page = {
    limit: Scalars['Int'];
    offset: Scalars['Int'];
};

export type PendingAcceptanceNoticeNotification = Notification & {
    __typename?: 'PendingAcceptanceNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PendingRequoteNoticeNotification = Notification & {
    __typename?: 'PendingRequoteNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PendingValuationNoticeNotification = Notification & {
    __typename?: 'PendingValuationNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PurchaseStageUpdateActivity = Activity & {
    __typename?: 'PurchaseStageUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
};

export type PurchaseStageUpdateBySystemActivity = Activity & {
    __typename?: 'PurchaseStageUpdateBySystemActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
};

export type PurchaseTransaction = {
    __typename?: 'PurchaseTransaction';
    id: Scalars['ObjectID'];
    identifier: Scalars['String'];
    stage: PurchaseTransactionStage;
    saleConsultantId?: Maybe<Scalars['ObjectID']>;
    saleConsultant?: Maybe<User>;
    internalRemarks?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy: User;
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<User>;
    closedAt?: Maybe<Scalars['DateTime']>;
    closedBy?: Maybe<User>;
    customer: Customer;
    vehicle: PurchaseTransactionVehicle;
    valuation?: Maybe<Valuation>;
    handover?: Maybe<Handover>;
    frontPagePhoto?: Maybe<UploadedFile>;
    frontPagePhotoSourceId?: Maybe<Scalars['ObjectID']>;
    salesTransactionId?: Maybe<Scalars['ObjectID']>;
    activities: Array<Activity>;
    lastActivity?: Maybe<Activity>;
    duplicateTransactions: Array<PurchaseTransaction>;
    adminRemark?: Maybe<Scalars['String']>;
    cancelReason?: Maybe<Scalars['String']>;
    archived: Scalars['Boolean'];
};

export type PurchaseTransactionAmendedNoticeNotification = Notification & {
    __typename?: 'PurchaseTransactionAmendedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PurchaseTransactionApprovalNoticeNotification = Notification & {
    __typename?: 'PurchaseTransactionApprovalNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PurchaseTransactionApprovedNoticeNotification = Notification & {
    __typename?: 'PurchaseTransactionApprovedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PurchaseTransactionFilterField = {
    search?: Maybe<Scalars['String']>;
    searchFields?: Maybe<Array<Scalars['String']>>;
    stages?: Maybe<Array<PurchaseTransactionStage>>;
    createdAt?: Maybe<Scalars['DateTime']>;
    fromCreated?: Maybe<Scalars['DateTime']>;
    toCreated?: Maybe<Scalars['DateTime']>;
    handoverAt?: Maybe<Scalars['DateTime']>;
    fromHandover?: Maybe<Scalars['DateTime']>;
    toHandover?: Maybe<Scalars['DateTime']>;
    make?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    originalRegistrationDate?: Maybe<Scalars['DateTime']>;
    onlySelf?: Maybe<Scalars['Boolean']>;
    isForNewSalesTransaction?: Maybe<Scalars['Boolean']>;
    showArchived?: Maybe<Scalars['Boolean']>;
};

export type PurchaseTransactionForAcceptanceFields = {
    valuation?: Maybe<ValuationForAcceptanceFields>;
};

export type PurchaseTransactionForApprovalRequestFields = {
    handover: HandoverForApprovalRequestFields;
    vehicle: VehicleForApprovalRequestFields;
    valuation?: Maybe<ValuationForApprovalRequestFields>;
};

export type PurchaseTransactionPage = {
    __typename?: 'PurchaseTransactionPage';
    items: Array<PurchaseTransaction>;
    count: Scalars['Int'];
};

export type PurchaseTransactionRejectedNoticeNotification = Notification & {
    __typename?: 'PurchaseTransactionRejectedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export enum PurchaseTransactionStage {
    InitialValuation = 'InitialValuation',
    PendingAcceptance = 'PendingAcceptance',
    FinalValuation = 'FinalValuation',
    PendingApproval = 'PendingApproval',
    Handover = 'Handover',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
}

export type PurchaseTransactionValuationAcceptedNoticeNotification = Notification & {
    __typename?: 'PurchaseTransactionValuationAcceptedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    purchaseTransaction: PurchaseTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type PurchaseTransactionVehicle = Vehicle & {
    __typename?: 'PurchaseTransactionVehicle';
    number: Scalars['String'];
    beExported: Scalars['Boolean'];
    intendedDeregistrationDate: Scalars['DateTime'];
    make: Scalars['String'];
    model: Scalars['String'];
    primaryColour: Scalars['String'];
    manufacturingYear: Scalars['Int'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    maximumPowerOutput: Scalars['String'];
    openMarketValue: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transferCount: Scalars['Int'];
    actualARFPaid: Scalars['Float'];
    parfEligibility: Scalars['Boolean'];
    parfEligibilityDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount?: Maybe<Scalars['Int']>;
    coeExpiryDate: Scalars['DateTime'];
    coeCategory: Scalars['String'];
    coePeriodYear: Scalars['Int'];
    qpPaid?: Maybe<Scalars['Float']>;
    coeRebateAmount?: Maybe<Scalars['Float']>;
    mileage: Scalars['Int'];
    purchaseAgreementNumber: Scalars['String'];
    saleAgreementNumber: Scalars['String'];
    secondaryColour?: Maybe<Scalars['String']>;
    opcCashRebateEligibility?: Maybe<Scalars['String']>;
    opcCashRebateEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    opcCashRebateAmount?: Maybe<Scalars['Float']>;
    pqpPaid?: Maybe<Scalars['Float']>;
    ltaMessage?: Maybe<Scalars['String']>;
    activities?: Maybe<Array<Maybe<Activity>>>;
    isOneMotoringData: Scalars['Boolean'];
    totalRebateAmount?: Maybe<Scalars['Float']>;
    importMethod: Scalars['String'];
    setOfKeys?: Maybe<Scalars['Int']>;
    ownerHandbookCollected?: Maybe<Scalars['Boolean']>;
};

export type PurchaseUpdateActivity = Activity & {
    __typename?: 'PurchaseUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    diff: Scalars['JSONObject'];
};

export type PushDeviceSetting = DeviceSetting & {
    __typename?: 'PushDeviceSetting';
    type: DeviceSettingsType;
    deviceFingerprint: Scalars['String'];
    enabledOn: Scalars['DateTime'];
};

export type Query = {
    __typename?: 'Query';
    /**
     * Get a user by ID
     *
     * Restrictions: logged in, active admin only
     */
    getUser?: Maybe<User>;
    /**
     * Get logged in user document
     *
     * Restrictions: logged in
     */
    getSelf: User;
    /**
     * List users
     *
     * Restrictions: logged in
     */
    listUsers: UserPage;
    /**
     * Get Purchase Transaction List
     *
     * It will return Purchase Transaction List
     *
     * Restrictions: logged in
     */
    listPurchaseTransactions: PurchaseTransactionPage;
    /**
     * Get a single purchase transaction by its ID
     *
     * Restrictions: logged in
     */
    getPurchaseTransaction?: Maybe<PurchaseTransaction>;
    /**
     * Get SaleTransactions List
     *
     * It will return Sale Transaction List
     *
     * Restrictions: logged in
     */
    listSaleTransactions: SaleTransactionPage;
    /**
     * Get a single sale transaction by its ID
     *
     * Restrictions: logged in
     */
    getSaleTransaction?: Maybe<SaleTransaction>;
    /**
     * Get Bidding List
     *
     * It will return Bidding List
     *
     * Restrictions: logged in
     */
    listBiddings: BiddingPage;
    /**
     * Get a single bidding by its ID
     *
     * Restrictions: logged in
     */
    getBidding?: Maybe<Bidding>;
    /**
     * Get Notification List
     *
     * It will return Sale Notification List
     *
     * Restrictions: logged in
     */
    listNotifications: NotificationPage;
    /**
     * Check Duplicate Transaction
     *
     * When there is duplicat transaction for the vehicle,  it will return false. Otherwise, will return true.
     */
    checkDuplicateTransaction: Scalars['Boolean'];
    /**
     * Generate file for purchase transactions
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    generatePurchaseTransactionsFile?: Maybe<Scalars['String']>;
    /**
     * Generate file for sales transactions
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    generateSalesTransactionsFile?: Maybe<Scalars['String']>;
    /**
     * Generate file for purchase transaction detail
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    generatePurchaseTransactionDetailFile?: Maybe<Scalars['String']>;
    /**
     * Generate file for sale transaction detail
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    generateSalesTransactionDetailFile?: Maybe<Scalars['String']>;
    /**
     * Generate file for bidding sale transactions
     *
     * Restrictions: logged in, active admin, approver and valuation team only
     */
    generateBiddingSalesTransactionsFile?: Maybe<Scalars['String']>;
    /**
     * Export SaleTransactions List in Excel
     *
     * It will return Sale Transaction List
     *
     * Restrictions: logged in
     */
    exportExcelForActivityLogs: Scalars['String'];
    /**
     * Check Duplicate Mobile Phone
     *
     * When there is duplicat mobile phone,  it will return true. Otherwise, will return false.
     */
    checkDuplicateMobileNumber: Scalars['Boolean'];
};

export type QueryGetUserArgs = {
    userId: Scalars['ObjectID'];
};

export type QueryListUsersArgs = {
    activationStage?: Maybe<UserActivationStage>;
    type?: Maybe<UserType>;
    page: Page;
    search?: Maybe<Scalars['String']>;
    onlyAwardedDealer?: Maybe<Scalars['Boolean']>;
};

export type QueryListPurchaseTransactionsArgs = {
    filter?: Maybe<PurchaseTransactionFilterField>;
    page: Page;
    sort?: Maybe<SortPurchaseTransactionField>;
};

export type QueryGetPurchaseTransactionArgs = {
    transactionId: Scalars['ObjectID'];
};

export type QueryListSaleTransactionsArgs = {
    filter?: Maybe<SaleTransactionFilterField>;
    page: Page;
    sort?: Maybe<SortSaleTransactionField>;
};

export type QueryGetSaleTransactionArgs = {
    transactionId: Scalars['ObjectID'];
};

export type QueryListBiddingsArgs = {
    filter?: Maybe<BiddingFilterField>;
    page: Page;
};

export type QueryGetBiddingArgs = {
    biddingId: Scalars['ObjectID'];
};

export type QueryListNotificationsArgs = {
    filter?: Maybe<NotificationFilterField>;
    page: Page;
};

export type QueryCheckDuplicateTransactionArgs = {
    chassisNumber?: Maybe<Scalars['String']>;
};

export type QueryGeneratePurchaseTransactionsFileArgs = {
    downloadFileType: DownloadFileType;
    filter?: Maybe<PurchaseTransactionFilterField>;
    sort?: Maybe<SortPurchaseTransactionField>;
};

export type QueryGenerateSalesTransactionsFileArgs = {
    downloadFileType: DownloadFileType;
    filter?: Maybe<SaleTransactionFilterField>;
    sort?: Maybe<SortSaleTransactionField>;
};

export type QueryGeneratePurchaseTransactionDetailFileArgs = {
    transactionId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
};

export type QueryGenerateSalesTransactionDetailFileArgs = {
    transactionId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
};

export type QueryGenerateBiddingSalesTransactionsFileArgs = {
    biddingId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
};

export type QueryExportExcelForActivityLogsArgs = {
    filter?: Maybe<ActivityLogFilterField>;
};

export type QueryCheckDuplicateMobileNumberArgs = {
    userId: Scalars['ObjectID'];
    mobilePhone: MobilePhoneInput;
};

export type RequestPasswordResult = {
    __typename?: 'RequestPasswordResult';
    token: Scalars['String'];
    email?: Maybe<Scalars['String']>;
};

export type SaleConsultantUser = User & {
    __typename?: 'SaleConsultantUser';
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    brand: Scalars['String'];
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export type SaleStageUpdateActivity = Activity & {
    __typename?: 'SaleStageUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    stage: SaleTransactionStage;
};

export type SaleStageUpdateBySystemActivity = Activity & {
    __typename?: 'SaleStageUpdateBySystemActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    stage: SaleTransactionStage;
};

export type SaleTransaction = {
    __typename?: 'SaleTransaction';
    id: Scalars['ObjectID'];
    identifier: Scalars['String'];
    stage: SaleTransactionStage;
    purchaseTransactionId?: Maybe<Scalars['ObjectID']>;
    purchaseTransaction?: Maybe<PurchaseTransaction>;
    internalRemarks?: Maybe<Scalars['String']>;
    vehicle: SalesTransactionVehicle;
    createdAt: Scalars['DateTime'];
    createdBy: User;
    updatedAt: Scalars['DateTime'];
    updatedBy?: Maybe<User>;
    biddingSessions: Array<Bidding>;
    awardedBiddingEntry?: Maybe<BiddingEntry>;
    latestBiddingSession?: Maybe<Bidding>;
    latestBiddingSessionEntry?: Maybe<BiddingSaleTransaction>;
    handover?: Maybe<SalesTransactionHandover>;
    frontPagePhoto?: Maybe<UploadedFile>;
    frontPagePhotoSourceId?: Maybe<Scalars['ObjectID']>;
    photos: Array<UploadedFile>;
    activities: Array<Activity>;
    lastActivity?: Maybe<Activity>;
    adminRemark?: Maybe<Scalars['String']>;
    archived: Scalars['Boolean'];
    cloned: Scalars['Boolean'];
    originalSalesTransactionId?: Maybe<Scalars['ObjectID']>;
    originalSalesTransaction?: Maybe<SaleTransaction>;
};

export type SaleTransactionFilterField = {
    search?: Maybe<Scalars['String']>;
    searchFields?: Maybe<Array<Scalars['String']>>;
    stages?: Maybe<Array<SaleTransactionStage>>;
    createdAt?: Maybe<Scalars['DateTime']>;
    fromCreated?: Maybe<Scalars['DateTime']>;
    toCreated?: Maybe<Scalars['DateTime']>;
    handoverAt?: Maybe<Scalars['DateTime']>;
    fromHandover?: Maybe<Scalars['DateTime']>;
    toHandover?: Maybe<Scalars['DateTime']>;
    dealerId?: Maybe<Scalars['ObjectID']>;
    onlySelf?: Maybe<Scalars['Boolean']>;
    forNewBiddingSessions?: Maybe<Scalars['Boolean']>;
    awardedDealer?: Maybe<Scalars['ObjectID']>;
    bidOpeningOn?: Maybe<Scalars['DateTime']>;
    fromBidOpeningOn?: Maybe<Scalars['DateTime']>;
    toBidOpeningOn?: Maybe<Scalars['DateTime']>;
    bidClosingOn?: Maybe<Scalars['DateTime']>;
    fromBidClosingOn?: Maybe<Scalars['DateTime']>;
    toBidClosingOn?: Maybe<Scalars['DateTime']>;
    showArchived?: Maybe<Scalars['Boolean']>;
};

export type SaleTransactionPage = {
    __typename?: 'SaleTransactionPage';
    items: Array<SaleTransaction>;
    count: Scalars['Int'];
};

export enum SaleTransactionStage {
    New = 'New',
    UpcomingBidding = 'UpcomingBidding',
    BiddingOpen = 'BiddingOpen',
    ForAwarding = 'ForAwarding',
    ForHandover = 'ForHandover',
    Completed = 'Completed',
    Pending = 'Pending',
    Closed = 'Closed',
    PendingApproval = 'PendingApproval',
}

export type SaleTransactionVehicleFields = {
    number: Scalars['String'];
    beExported: Scalars['Boolean'];
    intendedDeregistrationDate: Scalars['DateTime'];
    make: Scalars['String'];
    model: Scalars['String'];
    primaryColour: Scalars['String'];
    manufacturingYear: Scalars['Int'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    maximumPowerOutput: Scalars['String'];
    openMarketValue: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transferCount: Scalars['Int'];
    actualARFPaid: Scalars['Float'];
    parfEligibility: Scalars['Boolean'];
    parfEligibilityDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount?: Maybe<Scalars['Int']>;
    coeExpiryDate: Scalars['DateTime'];
    coeCategory: Scalars['String'];
    coePeriodYear: Scalars['Int'];
    qpPaid?: Maybe<Scalars['Float']>;
    coeRebateAmount?: Maybe<Scalars['Float']>;
    mileage: Scalars['Int'];
    secondaryColour?: Maybe<Scalars['String']>;
    opcCashRebateEligibility?: Maybe<Scalars['String']>;
    opcCashRebateEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    opcCashRebateAmount?: Maybe<Scalars['Float']>;
    pqpPaid?: Maybe<Scalars['Float']>;
    ltaMessage?: Maybe<Scalars['String']>;
    totalRebateAmount?: Maybe<Scalars['Float']>;
    importMethod: Scalars['String'];
    setOfKeys?: Maybe<Scalars['Int']>;
    ownerHandbookCollected?: Maybe<Scalars['Boolean']>;
    generalCondition?: Maybe<Scalars['String']>;
    dealerInformations: Array<DealerInformationFields>;
};

export type SaleUpdateActivity = Activity & {
    __typename?: 'SaleUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    diff: Scalars['JSONObject'];
};

export type SalesTransactionApprovalNoticeNotification = Notification & {
    __typename?: 'SalesTransactionApprovalNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    salesTransaction: SaleTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type SalesTransactionApprovedNoticeNotification = Notification & {
    __typename?: 'SalesTransactionApprovedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    salesTransaction: SaleTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type SalesTransactionForAwardingFields = {
    handover: HandoverForAwardingFields;
};

export type SalesTransactionHandover = {
    __typename?: 'SalesTransactionHandover';
    personResponsible?: Maybe<User>;
    targetHandoverDateTime: Scalars['DateTime'];
    handoverLocation?: Maybe<Scalars['String']>;
    actualHandoverDate?: Maybe<Scalars['DateTime']>;
    vehicleSalesAgreementNo?: Maybe<Scalars['String']>;
    remarks?: Maybe<Scalars['String']>;
    attachments: Array<UploadedFile>;
    vsaAttachment?: Maybe<UploadedFile>;
    manualSalesDealer?: Maybe<Scalars['String']>;
    manualSalesPrice?: Maybe<Scalars['Float']>;
};

export type SalesTransactionHandoverFields = {
    personResponsible?: Maybe<Scalars['ObjectID']>;
    targetHandoverDateTime: Scalars['DateTime'];
    handoverLocation?: Maybe<Scalars['String']>;
    actualHandoverDate?: Maybe<Scalars['DateTime']>;
    remarks?: Maybe<Scalars['String']>;
    vehicleSalesAgreementNo?: Maybe<Scalars['String']>;
    manualSalesDealer?: Maybe<Scalars['String']>;
    manualSalesPrice?: Maybe<Scalars['Float']>;
};

export type SalesTransactionRejectedNoticeNotification = Notification & {
    __typename?: 'SalesTransactionRejectedNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    salesTransaction: SaleTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type SalesTransactionVehicle = Vehicle & {
    __typename?: 'SalesTransactionVehicle';
    number: Scalars['String'];
    beExported: Scalars['Boolean'];
    intendedDeregistrationDate: Scalars['DateTime'];
    make: Scalars['String'];
    model: Scalars['String'];
    primaryColour: Scalars['String'];
    manufacturingYear: Scalars['Int'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    maximumPowerOutput: Scalars['String'];
    openMarketValue: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transferCount: Scalars['Int'];
    actualARFPaid: Scalars['Float'];
    parfEligibility: Scalars['Boolean'];
    parfEligibilityDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount?: Maybe<Scalars['Int']>;
    coeExpiryDate: Scalars['DateTime'];
    coeCategory: Scalars['String'];
    coePeriodYear: Scalars['Int'];
    qpPaid?: Maybe<Scalars['Float']>;
    coeRebateAmount?: Maybe<Scalars['Float']>;
    mileage: Scalars['Int'];
    secondaryColour?: Maybe<Scalars['String']>;
    opcCashRebateEligibility?: Maybe<Scalars['String']>;
    opcCashRebateEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    opcCashRebateAmount?: Maybe<Scalars['Float']>;
    pqpPaid?: Maybe<Scalars['Float']>;
    ltaMessage?: Maybe<Scalars['String']>;
    activities?: Maybe<Array<Maybe<Activity>>>;
    totalRebateAmount?: Maybe<Scalars['Float']>;
    importMethod: Scalars['String'];
    setOfKeys?: Maybe<Scalars['Int']>;
    ownerHandbookCollected?: Maybe<Scalars['Boolean']>;
    generalCondition?: Maybe<Scalars['String']>;
    dealerInformations: Array<DealerInformation>;
};

export type SignUpApprovalNotification = Notification & {
    __typename?: 'SignUpApprovalNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type SignUpRejectNotification = Notification & {
    __typename?: 'SignUpRejectNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type SortPurchaseTransactionField = {
    stagePriority?: Maybe<PurchaseTransactionStage>;
};

export type SortSaleTransactionField = {
    stagePriority?: Maybe<SaleTransactionStage>;
};

export type UnawardNoticeNotification = Notification & {
    __typename?: 'UnawardNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    salesTransaction: SaleTransaction;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type UpcomingBiddingNoticeNotification = Notification & {
    __typename?: 'UpcomingBiddingNoticeNotification';
    id: Scalars['ObjectID'];
    type: NotificationType;
    saleTransactions: Array<SaleTransaction>;
    user: User;
    createdAt: Scalars['DateTime'];
    channels: Array<NotificationChannel>;
};

export type UpdateBiddingFields = {
    sessionName: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    biddingEntries?: Maybe<Array<BiddingEntryFields>>;
    saleTransactionId?: Maybe<Scalars['ObjectID']>;
};

export type UploadedFile = {
    __typename?: 'UploadedFile';
    id: Scalars['ObjectID'];
    filename: Scalars['String'];
    etag: Scalars['String'];
    size: Scalars['Int'];
    objectName: Scalars['String'];
    uploadedAt: Scalars['DateTime'];
    signedUrl: Scalars['String'];
};

export type User = {
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export enum UserActivationStage {
    New = 'New',
    Active = 'Active',
    Disabled = 'Disabled',
    Rejected = 'Rejected',
}

export type UserPage = {
    __typename?: 'UserPage';
    items: Array<User>;
    count: Scalars['Int'];
};

export type UserSession = {
    __typename?: 'UserSession';
    token: Scalars['String'];
    signInToken?: Maybe<Scalars['String']>;
    user: User;
};

export type UserStageUpdateActivity = Activity & {
    __typename?: 'UserStageUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    stage: UserActivationStage;
    author: User;
};

export type UserStageUpdateBySystemActivity = Activity & {
    __typename?: 'UserStageUpdateBySystemActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    stage: UserActivationStage;
};

export enum UserType {
    SaleConsultant = 'SaleConsultant',
    ValuationTeam = 'ValuationTeam',
    Dealer = 'Dealer',
    Admin = 'Admin',
    Approver = 'Approver',
}

export type UserUpdateActivity = Activity & {
    __typename?: 'UserUpdateActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    diff: Scalars['JSONObject'];
};

export type UserUpdateActivityInput = {
    addActivity?: Maybe<Scalars['Boolean']>;
    addProfilePictureActivity?: Maybe<Scalars['Boolean']>;
    stage?: Maybe<UserActivationStage>;
};

export type ValuatedValuation = {
    __typename?: 'ValuatedValuation';
    value: Scalars['Float'];
    valuatedBy?: Maybe<User>;
    valuatedOn?: Maybe<Scalars['DateTime']>;
};

export type ValuatedValuationFields = {
    value: Scalars['Float'];
    valuatedBy?: Maybe<Scalars['ObjectID']>;
    valuatedOn?: Maybe<Scalars['DateTime']>;
};

export type Valuation = {
    __typename?: 'Valuation';
    valuatedValuations: Array<ValuatedValuation>;
    latestValuatedValuation?: Maybe<ValuatedValuation>;
    generalCondition?: Maybe<Scalars['String']>;
    vehicleDiagramComments: Array<VehicleDiagramComment>;
    photos: Array<UploadedFile>;
    dealerInformations: Array<DealerInformation>;
};

export type ValuationActivity = Activity & {
    __typename?: 'ValuationActivity';
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
    type: ActivityType;
    author: User;
    valuationValue: Scalars['Float'];
};

export type ValuationFields = {
    valuatedValuations: Array<ValuatedValuationFields>;
    latestValuation?: Maybe<Scalars['Float']>;
    valuatedBy?: Maybe<Scalars['ObjectID']>;
    generalCondition?: Maybe<Scalars['String']>;
    dealerInformations: Array<DealerInformationFields>;
};

export type ValuationForAcceptanceFields = {
    latestValuation: Scalars['Float'];
    dealerInformations: Array<DealerInformationFields>;
    generalCondition: Scalars['String'];
};

export type ValuationForApprovalRequestFields = {
    generalCondition?: Maybe<Scalars['String']>;
};

export type ValuationTeamUser = User & {
    __typename?: 'ValuationTeamUser';
    id: Scalars['ObjectID'];
    fullName: Scalars['String'];
    email: Scalars['String'];
    mobilePhone: MobilePhone;
    type: UserType;
    activationStage: UserActivationStage;
    registeredAt: Scalars['DateTime'];
    deviceSettings: Array<DeviceSetting>;
    profilePicture?: Maybe<UploadedFile>;
    testAccount?: Maybe<Scalars['Boolean']>;
    activities: Array<Activity>;
    lastActive?: Maybe<Scalars['DateTime']>;
};

export type Vehicle = {
    number: Scalars['String'];
    beExported: Scalars['Boolean'];
    intendedDeregistrationDate: Scalars['DateTime'];
    make: Scalars['String'];
    model: Scalars['String'];
    primaryColour: Scalars['String'];
    manufacturingYear: Scalars['Int'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    maximumPowerOutput: Scalars['String'];
    openMarketValue: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transferCount: Scalars['Int'];
    actualARFPaid: Scalars['Float'];
    parfEligibility: Scalars['Boolean'];
    parfEligibilityDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount?: Maybe<Scalars['Int']>;
    coeExpiryDate: Scalars['DateTime'];
    coeCategory: Scalars['String'];
    coePeriodYear: Scalars['Int'];
    qpPaid?: Maybe<Scalars['Float']>;
    coeRebateAmount?: Maybe<Scalars['Float']>;
    mileage: Scalars['Int'];
    secondaryColour?: Maybe<Scalars['String']>;
    opcCashRebateEligibility?: Maybe<Scalars['String']>;
    opcCashRebateEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    opcCashRebateAmount?: Maybe<Scalars['Float']>;
    pqpPaid?: Maybe<Scalars['Float']>;
    ltaMessage?: Maybe<Scalars['String']>;
    activities?: Maybe<Array<Maybe<Activity>>>;
    totalRebateAmount?: Maybe<Scalars['Float']>;
    importMethod: Scalars['String'];
    setOfKeys?: Maybe<Scalars['Int']>;
    ownerHandbookCollected?: Maybe<Scalars['Boolean']>;
};

export type VehicleDiagramComment = {
    __typename?: 'VehicleDiagramComment';
    id: Scalars['ObjectID'];
    index: Scalars['Int'];
    comment: Scalars['String'];
    position: VehicleDiagramCommentPosition;
};

export type VehicleDiagramCommentPosition = {
    __typename?: 'VehicleDiagramCommentPosition';
    top: Scalars['String'];
    left: Scalars['String'];
};

export type VehicleFields = {
    number: Scalars['String'];
    beExported: Scalars['Boolean'];
    intendedDeregistrationDate: Scalars['DateTime'];
    make: Scalars['String'];
    model: Scalars['String'];
    primaryColour: Scalars['String'];
    manufacturingYear: Scalars['Int'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    maximumPowerOutput: Scalars['String'];
    openMarketValue: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transferCount: Scalars['Int'];
    actualARFPaid: Scalars['Float'];
    parfEligibility: Scalars['Boolean'];
    parfEligibilityDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount?: Maybe<Scalars['Int']>;
    coeExpiryDate: Scalars['DateTime'];
    coeCategory: Scalars['String'];
    coePeriodYear: Scalars['Int'];
    qpPaid?: Maybe<Scalars['Float']>;
    coeRebateAmount?: Maybe<Scalars['Float']>;
    mileage: Scalars['Int'];
    purchaseAgreementNumber: Scalars['String'];
    saleAgreementNumber: Scalars['String'];
    secondaryColour?: Maybe<Scalars['String']>;
    opcCashRebateEligibility?: Maybe<Scalars['String']>;
    opcCashRebateEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    opcCashRebateAmount?: Maybe<Scalars['Float']>;
    pqpPaid?: Maybe<Scalars['Float']>;
    ltaMessage?: Maybe<Scalars['String']>;
    isOneMotoringData: Scalars['Boolean'];
    totalRebateAmount?: Maybe<Scalars['Float']>;
    importMethod: Scalars['String'];
    setOfKeys?: Maybe<Scalars['Int']>;
    ownerHandbookCollected?: Maybe<Scalars['Boolean']>;
};

export type VehicleForApprovalRequestFields = {
    purchaseAgreementNumber: Scalars['String'];
};

export type VehicleDiagramCommentFields = {
    comment: Scalars['String'];
    position: VehicleDiagramCommentPositionFields;
};

export type VehicleDiagramCommentPositionFields = {
    top: Scalars['Float'];
    left: Scalars['Float'];
};

export type BiddingEntryDataFragment = {
    __typename?: 'BiddingEntry';
    id: string;
    amount: number;
    placedOn: string | Date;
    dealerId: string;
    dealer:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

export type FullBiddingDataFragment = {
    __typename?: 'Bidding';
    id: string;
    sessionName: string;
    notes?: Maybe<string>;
    startPeriod: string | Date;
    endPeriod: string | Date;
    status: BiddingStatus;
    biddingSaleTransactions: Array<{
        __typename?: 'BiddingSaleTransaction';
        remainDealerBiddingEntryCount: number;
        saleTransaction: { __typename?: 'SaleTransaction' } & PreviewSaleTransactionDataFragment;
        awardedBiddingEntry?: Maybe<{ __typename?: 'BiddingEntry' } & BiddingEntryDataFragment>;
        biddingEntries: Array<{ __typename?: 'BiddingEntry' } & BiddingEntryDataFragment>;
    }>;
};

export type PreviewBiddingDataFragment = {
    __typename?: 'Bidding';
    id: string;
    sessionName: string;
    notes?: Maybe<string>;
    startPeriod: string | Date;
    endPeriod: string | Date;
    status: BiddingStatus;
};

type DeviceSettingData_BiometricDeviceSetting_Fragment = {
    __typename?: 'BiometricDeviceSetting';
    type: DeviceSettingsType;
    deviceFingerprint: string;
    enabledOn: string | Date;
};

type DeviceSettingData_PushDeviceSetting_Fragment = {
    __typename?: 'PushDeviceSetting';
    type: DeviceSettingsType;
    deviceFingerprint: string;
    enabledOn: string | Date;
};

export type DeviceSettingDataFragment =
    | DeviceSettingData_BiometricDeviceSetting_Fragment
    | DeviceSettingData_PushDeviceSetting_Fragment;

type FullNotificationData_AwardedNoticeNotification_Fragment = {
    __typename?: 'AwardedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    saleTransactions: Array<{ __typename?: 'SaleTransaction'; id: string; identifier: string }>;
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_HandoverDateChangeNoticeNotification_Fragment = {
    __typename?: 'HandoverDateChangeNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PendingAcceptanceNoticeNotification_Fragment = {
    __typename?: 'PendingAcceptanceNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PendingRequoteNoticeNotification_Fragment = {
    __typename?: 'PendingRequoteNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PendingValuationNoticeNotification_Fragment = {
    __typename?: 'PendingValuationNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PurchaseTransactionAmendedNoticeNotification_Fragment = {
    __typename?: 'PurchaseTransactionAmendedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PurchaseTransactionApprovalNoticeNotification_Fragment = {
    __typename?: 'PurchaseTransactionApprovalNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PurchaseTransactionApprovedNoticeNotification_Fragment = {
    __typename?: 'PurchaseTransactionApprovedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PurchaseTransactionRejectedNoticeNotification_Fragment = {
    __typename?: 'PurchaseTransactionRejectedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_PurchaseTransactionValuationAcceptedNoticeNotification_Fragment = {
    __typename?: 'PurchaseTransactionValuationAcceptedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    purchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_SalesTransactionApprovalNoticeNotification_Fragment = {
    __typename?: 'SalesTransactionApprovalNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    salesTransaction: { __typename?: 'SaleTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_SalesTransactionApprovedNoticeNotification_Fragment = {
    __typename?: 'SalesTransactionApprovedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    salesTransaction: { __typename?: 'SaleTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_SalesTransactionRejectedNoticeNotification_Fragment = {
    __typename?: 'SalesTransactionRejectedNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    salesTransaction: { __typename?: 'SaleTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_SignUpApprovalNotification_Fragment = {
    __typename?: 'SignUpApprovalNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_SignUpRejectNotification_Fragment = {
    __typename?: 'SignUpRejectNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_UnawardNoticeNotification_Fragment = {
    __typename?: 'UnawardNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    salesTransaction: { __typename?: 'SaleTransaction'; id: string; identifier: string };
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

type FullNotificationData_UpcomingBiddingNoticeNotification_Fragment = {
    __typename?: 'UpcomingBiddingNoticeNotification';
    id: string;
    type: NotificationType;
    createdAt: string | Date;
    channels: Array<NotificationChannel>;
    saleTransactions: Array<{ __typename?: 'SaleTransaction'; id: string; identifier: string }>;
    user:
        | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment);
};

export type FullNotificationDataFragment =
    | FullNotificationData_AwardedNoticeNotification_Fragment
    | FullNotificationData_HandoverDateChangeNoticeNotification_Fragment
    | FullNotificationData_PendingAcceptanceNoticeNotification_Fragment
    | FullNotificationData_PendingRequoteNoticeNotification_Fragment
    | FullNotificationData_PendingValuationNoticeNotification_Fragment
    | FullNotificationData_PurchaseTransactionAmendedNoticeNotification_Fragment
    | FullNotificationData_PurchaseTransactionApprovalNoticeNotification_Fragment
    | FullNotificationData_PurchaseTransactionApprovedNoticeNotification_Fragment
    | FullNotificationData_PurchaseTransactionRejectedNoticeNotification_Fragment
    | FullNotificationData_PurchaseTransactionValuationAcceptedNoticeNotification_Fragment
    | FullNotificationData_SalesTransactionApprovalNoticeNotification_Fragment
    | FullNotificationData_SalesTransactionApprovedNoticeNotification_Fragment
    | FullNotificationData_SalesTransactionRejectedNoticeNotification_Fragment
    | FullNotificationData_SignUpApprovalNotification_Fragment
    | FullNotificationData_SignUpRejectNotification_Fragment
    | FullNotificationData_UnawardNoticeNotification_Fragment
    | FullNotificationData_UpcomingBiddingNoticeNotification_Fragment;

export type FullNotificationPageDataFragment = {
    __typename?: 'NotificationPage';
    count: number;
    items: Array<
        | ({ __typename?: 'AwardedNoticeNotification' } & FullNotificationData_AwardedNoticeNotification_Fragment)
        | ({
              __typename?: 'HandoverDateChangeNoticeNotification';
          } & FullNotificationData_HandoverDateChangeNoticeNotification_Fragment)
        | ({
              __typename?: 'PendingAcceptanceNoticeNotification';
          } & FullNotificationData_PendingAcceptanceNoticeNotification_Fragment)
        | ({
              __typename?: 'PendingRequoteNoticeNotification';
          } & FullNotificationData_PendingRequoteNoticeNotification_Fragment)
        | ({
              __typename?: 'PendingValuationNoticeNotification';
          } & FullNotificationData_PendingValuationNoticeNotification_Fragment)
        | ({
              __typename?: 'PurchaseTransactionAmendedNoticeNotification';
          } & FullNotificationData_PurchaseTransactionAmendedNoticeNotification_Fragment)
        | ({
              __typename?: 'PurchaseTransactionApprovalNoticeNotification';
          } & FullNotificationData_PurchaseTransactionApprovalNoticeNotification_Fragment)
        | ({
              __typename?: 'PurchaseTransactionApprovedNoticeNotification';
          } & FullNotificationData_PurchaseTransactionApprovedNoticeNotification_Fragment)
        | ({
              __typename?: 'PurchaseTransactionRejectedNoticeNotification';
          } & FullNotificationData_PurchaseTransactionRejectedNoticeNotification_Fragment)
        | ({
              __typename?: 'PurchaseTransactionValuationAcceptedNoticeNotification';
          } & FullNotificationData_PurchaseTransactionValuationAcceptedNoticeNotification_Fragment)
        | ({
              __typename?: 'SalesTransactionApprovalNoticeNotification';
          } & FullNotificationData_SalesTransactionApprovalNoticeNotification_Fragment)
        | ({
              __typename?: 'SalesTransactionApprovedNoticeNotification';
          } & FullNotificationData_SalesTransactionApprovedNoticeNotification_Fragment)
        | ({
              __typename?: 'SalesTransactionRejectedNoticeNotification';
          } & FullNotificationData_SalesTransactionRejectedNoticeNotification_Fragment)
        | ({ __typename?: 'SignUpApprovalNotification' } & FullNotificationData_SignUpApprovalNotification_Fragment)
        | ({ __typename?: 'SignUpRejectNotification' } & FullNotificationData_SignUpRejectNotification_Fragment)
        | ({ __typename?: 'UnawardNoticeNotification' } & FullNotificationData_UnawardNoticeNotification_Fragment)
        | ({
              __typename?: 'UpcomingBiddingNoticeNotification';
          } & FullNotificationData_UpcomingBiddingNoticeNotification_Fragment)
    >;
};

export type FullPurchaseTransactionDataFragment = {
    __typename?: 'PurchaseTransaction';
    id: string;
    stage: PurchaseTransactionStage;
    internalRemarks?: Maybe<string>;
    identifier: string;
    adminRemark?: Maybe<string>;
    cancelReason?: Maybe<string>;
    frontPagePhotoSourceId?: Maybe<string>;
    saleConsultantId?: Maybe<string>;
    createdAt: string | Date;
    updatedAt: string | Date;
    closedAt?: Maybe<string | Date>;
    archived: boolean;
    frontPagePhoto?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    saleConsultant?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    createdBy:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
    updatedBy?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    closedBy?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    customer: {
        __typename?: 'Customer';
        fullName: string;
        email: string;
        ownerIdType: string;
        ownerId: string;
        ownerName?: Maybe<string>;
        modelInterested?: Maybe<string>;
        mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
    };
    vehicle: {
        __typename?: 'PurchaseTransactionVehicle';
        number: string;
        beExported: boolean;
        intendedDeregistrationDate: string | Date;
        make: string;
        model: string;
        primaryColour: string;
        manufacturingYear: number;
        engineNumber: string;
        chassisNumber: string;
        maximumPowerOutput: string;
        openMarketValue: number;
        originalRegistrationDate: string | Date;
        firstRegistrationDate: string | Date;
        transferCount: number;
        actualARFPaid: number;
        parfEligibility: boolean;
        parfEligibilityDate?: Maybe<string | Date>;
        parfRebateAmount?: Maybe<number>;
        coeExpiryDate: string | Date;
        coeCategory: string;
        coePeriodYear: number;
        qpPaid?: Maybe<number>;
        coeRebateAmount?: Maybe<number>;
        mileage: number;
        purchaseAgreementNumber: string;
        saleAgreementNumber: string;
        secondaryColour?: Maybe<string>;
        opcCashRebateEligibility?: Maybe<string>;
        opcCashRebateEligibilityExpiryDate?: Maybe<string | Date>;
        opcCashRebateAmount?: Maybe<number>;
        pqpPaid?: Maybe<number>;
        ltaMessage?: Maybe<string>;
        isOneMotoringData: boolean;
        totalRebateAmount?: Maybe<number>;
        importMethod: string;
        setOfKeys?: Maybe<number>;
        ownerHandbookCollected?: Maybe<boolean>;
    };
    valuation?: Maybe<{
        __typename?: 'Valuation';
        generalCondition?: Maybe<string>;
        valuatedValuations: Array<{
            __typename?: 'ValuatedValuation';
            value: number;
            valuatedOn?: Maybe<string | Date>;
            valuatedBy?: Maybe<
                | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
                | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
                | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
                | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
                | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
            >;
        }>;
        latestValuatedValuation?: Maybe<{
            __typename?: 'ValuatedValuation';
            value: number;
            valuatedBy?: Maybe<
                | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
                | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
                | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
                | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
                | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
            >;
        }>;
        dealerInformations: Array<{ __typename?: 'DealerInformation'; name: string; price?: Maybe<number> }>;
        photos: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
        vehicleDiagramComments: Array<{
            __typename?: 'VehicleDiagramComment';
            id: string;
            index: number;
            comment: string;
            position: { __typename?: 'VehicleDiagramCommentPosition'; top: string; left: string };
        }>;
    }>;
    handover?: Maybe<{
        __typename?: 'Handover';
        targetHandoverDateTime?: Maybe<string | Date>;
        handoverLocation?: Maybe<string>;
        actualHandoverDate?: Maybe<string | Date>;
        remarks?: Maybe<string>;
        deliveryPeriod: number;
        targetSetOfKeys?: Maybe<number>;
        targetHandbook?: Maybe<boolean>;
        deliveryPeriodIndentOrder?: Maybe<string>;
        personResponsible:
            | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
        attachments: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
        vpaAttachment?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
    activities: Array<
        | ({ __typename?: 'BiddingActivity' } & PurchaseTransactionActivityData_BiddingActivity_Fragment)
        | ({ __typename?: 'CreationActivity' } & PurchaseTransactionActivityData_CreationActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateActivity';
          } & PurchaseTransactionActivityData_PurchaseStageUpdateActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'PurchaseUpdateActivity' } & PurchaseTransactionActivityData_PurchaseUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateActivity';
          } & PurchaseTransactionActivityData_SaleStageUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'SaleUpdateActivity' } & PurchaseTransactionActivityData_SaleUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateActivity';
          } & PurchaseTransactionActivityData_UserStageUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_UserStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'UserUpdateActivity' } & PurchaseTransactionActivityData_UserUpdateActivity_Fragment)
        | ({ __typename?: 'ValuationActivity' } & PurchaseTransactionActivityData_ValuationActivity_Fragment)
    >;
    lastActivity?: Maybe<
        | ({ __typename?: 'BiddingActivity' } & PurchaseTransactionActivityData_BiddingActivity_Fragment)
        | ({ __typename?: 'CreationActivity' } & PurchaseTransactionActivityData_CreationActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateActivity';
          } & PurchaseTransactionActivityData_PurchaseStageUpdateActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'PurchaseUpdateActivity' } & PurchaseTransactionActivityData_PurchaseUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateActivity';
          } & PurchaseTransactionActivityData_SaleStageUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'SaleUpdateActivity' } & PurchaseTransactionActivityData_SaleUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateActivity';
          } & PurchaseTransactionActivityData_UserStageUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateBySystemActivity';
          } & PurchaseTransactionActivityData_UserStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'UserUpdateActivity' } & PurchaseTransactionActivityData_UserUpdateActivity_Fragment)
        | ({ __typename?: 'ValuationActivity' } & PurchaseTransactionActivityData_ValuationActivity_Fragment)
    >;
    duplicateTransactions: Array<{
        __typename?: 'PurchaseTransaction';
        id: string;
        identifier: string;
        saleConsultantId?: Maybe<string>;
    }>;
};

type PurchaseTransactionActivityData_BiddingActivity_Fragment = {
    __typename?: 'BiddingActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_CreationActivity_Fragment = {
    __typename?: 'CreationActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type PurchaseTransactionActivityData_PurchaseStageUpdateActivity_Fragment = {
    __typename?: 'PurchaseStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type PurchaseTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment = {
    __typename?: 'PurchaseStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_PurchaseUpdateActivity_Fragment = {
    __typename?: 'PurchaseUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type PurchaseTransactionActivityData_SaleStageUpdateActivity_Fragment = {
    __typename?: 'SaleStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment = {
    __typename?: 'SaleStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_SaleUpdateActivity_Fragment = {
    __typename?: 'SaleUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_UserStageUpdateActivity_Fragment = {
    __typename?: 'UserStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_UserStageUpdateBySystemActivity_Fragment = {
    __typename?: 'UserStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_UserUpdateActivity_Fragment = {
    __typename?: 'UserUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type PurchaseTransactionActivityData_ValuationActivity_Fragment = {
    __typename?: 'ValuationActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

export type PurchaseTransactionActivityDataFragment =
    | PurchaseTransactionActivityData_BiddingActivity_Fragment
    | PurchaseTransactionActivityData_CreationActivity_Fragment
    | PurchaseTransactionActivityData_PurchaseStageUpdateActivity_Fragment
    | PurchaseTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment
    | PurchaseTransactionActivityData_PurchaseUpdateActivity_Fragment
    | PurchaseTransactionActivityData_SaleStageUpdateActivity_Fragment
    | PurchaseTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment
    | PurchaseTransactionActivityData_SaleUpdateActivity_Fragment
    | PurchaseTransactionActivityData_UserStageUpdateActivity_Fragment
    | PurchaseTransactionActivityData_UserStageUpdateBySystemActivity_Fragment
    | PurchaseTransactionActivityData_UserUpdateActivity_Fragment
    | PurchaseTransactionActivityData_ValuationActivity_Fragment;

export type ValuationPhotosOnlyDataFragment = {
    __typename?: 'PurchaseTransaction';
    id: string;
    valuation?: Maybe<{
        __typename?: 'Valuation';
        photos: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
};

export type HandoverAttachmentsOnlyDataFragment = {
    __typename?: 'PurchaseTransaction';
    id: string;
    handover?: Maybe<{
        __typename?: 'Handover';
        attachments: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
};

export type VpaAttachmentOnlyDataFragment = {
    __typename?: 'PurchaseTransaction';
    id: string;
    handover?: Maybe<{
        __typename?: 'Handover';
        vpaAttachment?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
};

export type PreviewPurchaseTransactionDataFragment = {
    __typename?: 'PurchaseTransaction';
    id: string;
    stage: PurchaseTransactionStage;
    createdAt: string | Date;
    closedAt?: Maybe<string | Date>;
    identifier: string;
    internalRemarks?: Maybe<string>;
    saleConsultantId?: Maybe<string>;
    updatedAt: string | Date;
    archived: boolean;
    saleConsultant?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    vehicle: {
        __typename?: 'PurchaseTransactionVehicle';
        number: string;
        make: string;
        model: string;
        mileage: number;
        originalRegistrationDate: string | Date;
        firstRegistrationDate: string | Date;
        opcCashRebateAmount?: Maybe<number>;
        opcCashRebateEligibility?: Maybe<string>;
        opcCashRebateEligibilityExpiryDate?: Maybe<string | Date>;
        chassisNumber: string;
    };
    valuation?: Maybe<{
        __typename?: 'Valuation';
        latestValuatedValuation?: Maybe<{
            __typename?: 'ValuatedValuation';
            value: number;
            valuatedBy?: Maybe<
                | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
                | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
                | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
                | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
                | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
            >;
        }>;
        dealerInformations: Array<{ __typename?: 'DealerInformation'; name: string; price?: Maybe<number> }>;
    }>;
    handover?: Maybe<{
        __typename?: 'Handover';
        deliveryPeriod: number;
        assignPersonResponsibleAt?: Maybe<string | Date>;
        personResponsible:
            | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
    }>;
    updatedBy?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
};

export type FullSaleTransactionDataFragment = {
    __typename?: 'SaleTransaction';
    id: string;
    identifier: string;
    stage: SaleTransactionStage;
    internalRemarks?: Maybe<string>;
    adminRemark?: Maybe<string>;
    createdAt: string | Date;
    updatedAt: string | Date;
    frontPagePhotoSourceId?: Maybe<string>;
    archived: boolean;
    cloned: boolean;
    originalSalesTransactionId?: Maybe<string>;
    createdBy:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
    updatedBy?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & PreviewPurchaseTransactionDataFragment>;
    photos: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    frontPagePhoto?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    vehicle: {
        __typename?: 'SalesTransactionVehicle';
        number: string;
        beExported: boolean;
        intendedDeregistrationDate: string | Date;
        make: string;
        model: string;
        primaryColour: string;
        manufacturingYear: number;
        engineNumber: string;
        chassisNumber: string;
        maximumPowerOutput: string;
        openMarketValue: number;
        originalRegistrationDate: string | Date;
        firstRegistrationDate: string | Date;
        transferCount: number;
        actualARFPaid: number;
        parfEligibility: boolean;
        parfEligibilityDate?: Maybe<string | Date>;
        parfRebateAmount?: Maybe<number>;
        coeExpiryDate: string | Date;
        coeCategory: string;
        coePeriodYear: number;
        qpPaid?: Maybe<number>;
        coeRebateAmount?: Maybe<number>;
        mileage: number;
        secondaryColour?: Maybe<string>;
        opcCashRebateEligibility?: Maybe<string>;
        opcCashRebateEligibilityExpiryDate?: Maybe<string | Date>;
        opcCashRebateAmount?: Maybe<number>;
        pqpPaid?: Maybe<number>;
        ltaMessage?: Maybe<string>;
        importMethod: string;
        totalRebateAmount?: Maybe<number>;
        setOfKeys?: Maybe<number>;
        ownerHandbookCollected?: Maybe<boolean>;
        generalCondition?: Maybe<string>;
        dealerInformations: Array<{ __typename?: 'DealerInformation'; name: string; price?: Maybe<number> }>;
    };
    latestBiddingSession?: Maybe<{ __typename?: 'Bidding' } & PreviewBiddingDataFragment>;
    latestBiddingSessionEntry?: Maybe<{
        __typename?: 'BiddingSaleTransaction';
        saleTransactionId: string;
        remainDealerBiddingEntryCount: number;
        isInTop: boolean;
        lastUserBid?: Maybe<{ __typename?: 'BiddingEntry'; amount: number }>;
        biddingEntries: Array<{ __typename?: 'BiddingEntry' } & BiddingEntryDataFragment>;
        topBiddingEntries: Array<{ __typename?: 'BiddingEntry' } & BiddingEntryDataFragment>;
    }>;
    awardedBiddingEntry?: Maybe<{
        __typename?: 'BiddingEntry';
        id: string;
        amount: number;
        placedOn: string | Date;
        dealer:
            | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
    }>;
    handover?: Maybe<{
        __typename?: 'SalesTransactionHandover';
        targetHandoverDateTime: string | Date;
        handoverLocation?: Maybe<string>;
        actualHandoverDate?: Maybe<string | Date>;
        vehicleSalesAgreementNo?: Maybe<string>;
        remarks?: Maybe<string>;
        manualSalesDealer?: Maybe<string>;
        manualSalesPrice?: Maybe<number>;
        personResponsible?: Maybe<
            | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
        >;
        attachments: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
        vsaAttachment?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
    activities: Array<
        | ({ __typename?: 'BiddingActivity' } & SalesTransactionActivityData_BiddingActivity_Fragment)
        | ({ __typename?: 'CreationActivity' } & SalesTransactionActivityData_CreationActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateActivity';
          } & SalesTransactionActivityData_PurchaseStageUpdateActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'PurchaseUpdateActivity' } & SalesTransactionActivityData_PurchaseUpdateActivity_Fragment)
        | ({ __typename?: 'SaleStageUpdateActivity' } & SalesTransactionActivityData_SaleStageUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'SaleUpdateActivity' } & SalesTransactionActivityData_SaleUpdateActivity_Fragment)
        | ({ __typename?: 'UserStageUpdateActivity' } & SalesTransactionActivityData_UserStageUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_UserStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'UserUpdateActivity' } & SalesTransactionActivityData_UserUpdateActivity_Fragment)
        | ({ __typename?: 'ValuationActivity' } & SalesTransactionActivityData_ValuationActivity_Fragment)
    >;
    lastActivity?: Maybe<
        | ({ __typename?: 'BiddingActivity' } & SalesTransactionActivityData_BiddingActivity_Fragment)
        | ({ __typename?: 'CreationActivity' } & SalesTransactionActivityData_CreationActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateActivity';
          } & SalesTransactionActivityData_PurchaseStageUpdateActivity_Fragment)
        | ({
              __typename?: 'PurchaseStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'PurchaseUpdateActivity' } & SalesTransactionActivityData_PurchaseUpdateActivity_Fragment)
        | ({ __typename?: 'SaleStageUpdateActivity' } & SalesTransactionActivityData_SaleStageUpdateActivity_Fragment)
        | ({
              __typename?: 'SaleStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'SaleUpdateActivity' } & SalesTransactionActivityData_SaleUpdateActivity_Fragment)
        | ({ __typename?: 'UserStageUpdateActivity' } & SalesTransactionActivityData_UserStageUpdateActivity_Fragment)
        | ({
              __typename?: 'UserStageUpdateBySystemActivity';
          } & SalesTransactionActivityData_UserStageUpdateBySystemActivity_Fragment)
        | ({ __typename?: 'UserUpdateActivity' } & SalesTransactionActivityData_UserUpdateActivity_Fragment)
        | ({ __typename?: 'ValuationActivity' } & SalesTransactionActivityData_ValuationActivity_Fragment)
    >;
    originalSalesTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & PreviewSaleTransactionDataFragment>;
};

export type PreviewSaleTransactionDataFragment = {
    __typename?: 'SaleTransaction';
    id: string;
    identifier: string;
    stage: SaleTransactionStage;
    createdAt: string | Date;
    updatedAt: string | Date;
    archived: boolean;
    cloned: boolean;
    frontPagePhoto?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    handover?: Maybe<{ __typename?: 'SalesTransactionHandover'; targetHandoverDateTime: string | Date }>;
    vehicle: {
        __typename?: 'SalesTransactionVehicle';
        number: string;
        make: string;
        model: string;
        mileage: number;
        originalRegistrationDate: string | Date;
        firstRegistrationDate: string | Date;
        opcCashRebateEligibility?: Maybe<string>;
        opcCashRebateEligibilityExpiryDate?: Maybe<string | Date>;
        opcCashRebateAmount?: Maybe<number>;
    };
    awardedBiddingEntry?: Maybe<{
        __typename?: 'BiddingEntry';
        id: string;
        amount: number;
        placedOn: string | Date;
        dealer:
            | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
    }>;
    updatedBy?: Maybe<
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment)
    >;
    latestBiddingSession?: Maybe<{ __typename?: 'Bidding' } & PreviewBiddingDataFragment>;
    latestBiddingSessionEntry?: Maybe<{
        __typename?: 'BiddingSaleTransaction';
        lastUserBid?: Maybe<{ __typename?: 'BiddingEntry'; amount: number }>;
    }>;
};

export type SalesTransactionHandoverAttachmentsOnlyDataFragment = {
    __typename?: 'SaleTransaction';
    id: string;
    identifier: string;
    handover?: Maybe<{
        __typename?: 'SalesTransactionHandover';
        attachments: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
};

export type VsaAttachmentOnlyDataFragment = {
    __typename?: 'SaleTransaction';
    id: string;
    identifier: string;
    handover?: Maybe<{
        __typename?: 'SalesTransactionHandover';
        vsaAttachment?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    }>;
};

export type SaleTransactionPhotosOnlyDataFragment = {
    __typename?: 'SaleTransaction';
    id: string;
    identifier: string;
    photos: Array<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

type SalesTransactionActivityData_BiddingActivity_Fragment = {
    __typename?: 'BiddingActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type SalesTransactionActivityData_CreationActivity_Fragment = {
    __typename?: 'CreationActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type SalesTransactionActivityData_PurchaseStageUpdateActivity_Fragment = {
    __typename?: 'PurchaseStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment = {
    __typename?: 'PurchaseStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_PurchaseUpdateActivity_Fragment = {
    __typename?: 'PurchaseUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_SaleStageUpdateActivity_Fragment = {
    __typename?: 'SaleStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type SalesTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment = {
    __typename?: 'SaleStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_SaleUpdateActivity_Fragment = {
    __typename?: 'SaleUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
    author:
        | ({ __typename?: 'AdminUser' } & NameOnlyUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & NameOnlyUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & NameOnlyUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & NameOnlyUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & NameOnlyUserData_ValuationTeamUser_Fragment);
};

type SalesTransactionActivityData_UserStageUpdateActivity_Fragment = {
    __typename?: 'UserStageUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_UserStageUpdateBySystemActivity_Fragment = {
    __typename?: 'UserStageUpdateBySystemActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_UserUpdateActivity_Fragment = {
    __typename?: 'UserUpdateActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

type SalesTransactionActivityData_ValuationActivity_Fragment = {
    __typename?: 'ValuationActivity';
    id: string;
    date: string | Date;
    type: ActivityType;
};

export type SalesTransactionActivityDataFragment =
    | SalesTransactionActivityData_BiddingActivity_Fragment
    | SalesTransactionActivityData_CreationActivity_Fragment
    | SalesTransactionActivityData_PurchaseStageUpdateActivity_Fragment
    | SalesTransactionActivityData_PurchaseStageUpdateBySystemActivity_Fragment
    | SalesTransactionActivityData_PurchaseUpdateActivity_Fragment
    | SalesTransactionActivityData_SaleStageUpdateActivity_Fragment
    | SalesTransactionActivityData_SaleStageUpdateBySystemActivity_Fragment
    | SalesTransactionActivityData_SaleUpdateActivity_Fragment
    | SalesTransactionActivityData_UserStageUpdateActivity_Fragment
    | SalesTransactionActivityData_UserStageUpdateBySystemActivity_Fragment
    | SalesTransactionActivityData_UserUpdateActivity_Fragment
    | SalesTransactionActivityData_ValuationActivity_Fragment;

export type NameOnlyFileDataFragment = { __typename?: 'UploadedFile'; id: string; filename: string; signedUrl: string };

type FullUserData_AdminUser_Fragment = {
    __typename?: 'AdminUser';
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    type: UserType;
    registeredAt: string | Date;
    testAccount?: Maybe<boolean>;
    lastActive?: Maybe<string | Date>;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type FullUserData_ApproverUser_Fragment = {
    __typename?: 'ApproverUser';
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    type: UserType;
    registeredAt: string | Date;
    testAccount?: Maybe<boolean>;
    lastActive?: Maybe<string | Date>;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type FullUserData_DealerUser_Fragment = {
    __typename?: 'DealerUser';
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    type: UserType;
    registeredAt: string | Date;
    testAccount?: Maybe<boolean>;
    lastActive?: Maybe<string | Date>;
    business: { __typename?: 'DealerBusiness'; registrationNumber: string; address: string; name: string };
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type FullUserData_SaleConsultantUser_Fragment = {
    __typename?: 'SaleConsultantUser';
    brand: string;
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    type: UserType;
    registeredAt: string | Date;
    testAccount?: Maybe<boolean>;
    lastActive?: Maybe<string | Date>;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type FullUserData_ValuationTeamUser_Fragment = {
    __typename?: 'ValuationTeamUser';
    id: string;
    email: string;
    fullName: string;
    activationStage: UserActivationStage;
    type: UserType;
    registeredAt: string | Date;
    testAccount?: Maybe<boolean>;
    lastActive?: Maybe<string | Date>;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

export type FullUserDataFragment =
    | FullUserData_AdminUser_Fragment
    | FullUserData_ApproverUser_Fragment
    | FullUserData_DealerUser_Fragment
    | FullUserData_SaleConsultantUser_Fragment
    | FullUserData_ValuationTeamUser_Fragment;

type PreviewUserData_AdminUser_Fragment = {
    __typename?: 'AdminUser';
    id: string;
    email: string;
    fullName: string;
    type: UserType;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type PreviewUserData_ApproverUser_Fragment = {
    __typename?: 'ApproverUser';
    id: string;
    email: string;
    fullName: string;
    type: UserType;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type PreviewUserData_DealerUser_Fragment = {
    __typename?: 'DealerUser';
    id: string;
    email: string;
    fullName: string;
    type: UserType;
    business: { __typename?: 'DealerBusiness'; registrationNumber: string; address: string; name: string };
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type PreviewUserData_SaleConsultantUser_Fragment = {
    __typename?: 'SaleConsultantUser';
    brand: string;
    id: string;
    email: string;
    fullName: string;
    type: UserType;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

type PreviewUserData_ValuationTeamUser_Fragment = {
    __typename?: 'ValuationTeamUser';
    id: string;
    email: string;
    fullName: string;
    type: UserType;
    mobilePhone: { __typename?: 'MobilePhone'; internationalCode: string; number: string };
};

export type PreviewUserDataFragment =
    | PreviewUserData_AdminUser_Fragment
    | PreviewUserData_ApproverUser_Fragment
    | PreviewUserData_DealerUser_Fragment
    | PreviewUserData_SaleConsultantUser_Fragment
    | PreviewUserData_ValuationTeamUser_Fragment;

type NameOnlyUserData_AdminUser_Fragment = { __typename?: 'AdminUser'; id: string; fullName: string; type: UserType };

type NameOnlyUserData_ApproverUser_Fragment = {
    __typename?: 'ApproverUser';
    id: string;
    fullName: string;
    type: UserType;
};

type NameOnlyUserData_DealerUser_Fragment = { __typename?: 'DealerUser'; id: string; fullName: string; type: UserType };

type NameOnlyUserData_SaleConsultantUser_Fragment = {
    __typename?: 'SaleConsultantUser';
    id: string;
    fullName: string;
    type: UserType;
};

type NameOnlyUserData_ValuationTeamUser_Fragment = {
    __typename?: 'ValuationTeamUser';
    id: string;
    fullName: string;
    type: UserType;
};

export type NameOnlyUserDataFragment =
    | NameOnlyUserData_AdminUser_Fragment
    | NameOnlyUserData_ApproverUser_Fragment
    | NameOnlyUserData_DealerUser_Fragment
    | NameOnlyUserData_SaleConsultantUser_Fragment
    | NameOnlyUserData_ValuationTeamUser_Fragment;

type ProfilePictureOnlyData_AdminUser_Fragment = {
    __typename?: 'AdminUser';
    id: string;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

type ProfilePictureOnlyData_ApproverUser_Fragment = {
    __typename?: 'ApproverUser';
    id: string;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

type ProfilePictureOnlyData_DealerUser_Fragment = {
    __typename?: 'DealerUser';
    id: string;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

type ProfilePictureOnlyData_SaleConsultantUser_Fragment = {
    __typename?: 'SaleConsultantUser';
    id: string;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

type ProfilePictureOnlyData_ValuationTeamUser_Fragment = {
    __typename?: 'ValuationTeamUser';
    id: string;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile' } & NameOnlyFileDataFragment>;
};

export type ProfilePictureOnlyDataFragment =
    | ProfilePictureOnlyData_AdminUser_Fragment
    | ProfilePictureOnlyData_ApproverUser_Fragment
    | ProfilePictureOnlyData_DealerUser_Fragment
    | ProfilePictureOnlyData_SaleConsultantUser_Fragment
    | ProfilePictureOnlyData_ValuationTeamUser_Fragment;

export type ExportExcelForActivityLogsQueryVariables = Exact<{
    filter: ActivityLogFilterField;
}>;

export type ExportExcelForActivityLogsQuery = { __typename?: 'Query'; signedUrl: string };

export type GetPendingApprovalsQueryVariables = Exact<{
    page: Page;
    search: Scalars['String'];
}>;

export type GetPendingApprovalsQuery = {
    __typename?: 'Query';
    users: {
        __typename?: 'UserPage';
        count: number;
        items: Array<
            | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment)
        >;
    };
};

export type ApproveAccountMutationVariables = Exact<{
    userId: Scalars['ObjectID'];
    activityOption?: Maybe<UserUpdateActivityInput>;
}>;

export type ApproveAccountMutation = {
    __typename?: 'Mutation';
    approveAccount?: Maybe<
        | { __typename?: 'AdminUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ApproverUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'DealerUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'SaleConsultantUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ValuationTeamUser'; id: string; activationStage: UserActivationStage }
    >;
};

export type EnableAccountMutationVariables = Exact<{
    userId: Scalars['ObjectID'];
}>;

export type EnableAccountMutation = {
    __typename?: 'Mutation';
    enableAccount?: Maybe<
        | { __typename?: 'AdminUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ApproverUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'DealerUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'SaleConsultantUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ValuationTeamUser'; id: string; activationStage: UserActivationStage }
    >;
};

export type DisableAccountMutationVariables = Exact<{
    userId: Scalars['ObjectID'];
}>;

export type DisableAccountMutation = {
    __typename?: 'Mutation';
    disableAccount?: Maybe<
        | { __typename?: 'AdminUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ApproverUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'DealerUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'SaleConsultantUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ValuationTeamUser'; id: string; activationStage: UserActivationStage }
    >;
};

export type RejectAccountMutationVariables = Exact<{
    userId: Scalars['ObjectID'];
    activityOption?: Maybe<UserUpdateActivityInput>;
}>;

export type RejectAccountMutation = {
    __typename?: 'Mutation';
    rejectAccount?: Maybe<
        | { __typename?: 'AdminUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ApproverUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'DealerUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'SaleConsultantUser'; id: string; activationStage: UserActivationStage }
        | { __typename?: 'ValuationTeamUser'; id: string; activationStage: UserActivationStage }
    >;
};

export type AuthenticateMutationVariables = Exact<{
    mobilePhone: MobilePhoneInput;
    password: Scalars['String'];
    sendOtpByEmail?: Maybe<Scalars['Boolean']>;
}>;

export type AuthenticateMutation = {
    __typename?: 'Mutation';
    authenticate: { __typename?: 'AuthenticateResult'; token: string; email?: Maybe<string> };
};

export type ConfirmAuthenticationMutationVariables = Exact<{
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
    code: Scalars['String'];
    deviceFingerprint?: Maybe<Scalars['String']>;
}>;

export type ConfirmAuthenticationMutation = {
    __typename?: 'Mutation';
    confirmAuthentication: {
        __typename?: 'UserSession';
        token: string;
        signInToken?: Maybe<string>;
        user:
            | ({
                  __typename?: 'AdminUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_AdminUser_Fragment)
            | ({
                  __typename?: 'ApproverUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_ApproverUser_Fragment)
            | ({
                  __typename?: 'DealerUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_DealerUser_Fragment)
            | ({
                  __typename?: 'SaleConsultantUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_SaleConsultantUser_Fragment)
            | ({
                  __typename?: 'ValuationTeamUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_ValuationTeamUser_Fragment);
    };
};

export type GetSingleSignInTokenMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
}>;

export type GetSingleSignInTokenMutation = { __typename?: 'Mutation'; getSingleSignInToken?: Maybe<string> };

export type ConsumeSingleSignInTokenMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
    token: Scalars['String'];
}>;

export type ConsumeSingleSignInTokenMutation = {
    __typename?: 'Mutation';
    authenticateWithToken: {
        __typename?: 'UserSession';
        token: string;
        signInToken?: Maybe<string>;
        user:
            | ({
                  __typename?: 'AdminUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_AdminUser_Fragment)
            | ({
                  __typename?: 'ApproverUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_ApproverUser_Fragment)
            | ({
                  __typename?: 'DealerUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_DealerUser_Fragment)
            | ({
                  __typename?: 'SaleConsultantUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_SaleConsultantUser_Fragment)
            | ({
                  __typename?: 'ValuationTeamUser';
                  deviceSettings: Array<
                      | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                      | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
                  >;
              } & PreviewUserData_ValuationTeamUser_Fragment);
    };
};

export type RefreshSessionMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshSessionMutation = { __typename?: 'Mutation'; response: string };

export type ListBiddingsQueryVariables = Exact<{
    page: Page;
    filter: BiddingFilterField;
}>;

export type ListBiddingsQuery = {
    __typename?: 'Query';
    biddings: {
        __typename?: 'BiddingPage';
        count: number;
        items: Array<{ __typename?: 'Bidding' } & PreviewBiddingDataFragment>;
    };
};

export type GetBiddingQueryVariables = Exact<{
    biddingId: Scalars['ObjectID'];
}>;

export type GetBiddingQuery = {
    __typename?: 'Query';
    bidding?: Maybe<{ __typename?: 'Bidding' } & FullBiddingDataFragment>;
};

export type CreateBiddingMutationVariables = Exact<{
    fields: NewBiddingFields;
}>;

export type CreateBiddingMutation = { __typename?: 'Mutation'; createBidding: { __typename?: 'Bidding'; id: string } };

export type UpdateBiddingMutationVariables = Exact<{
    biddingId: Scalars['ObjectID'];
    fields: UpdateBiddingFields;
}>;

export type UpdateBiddingMutation = {
    __typename?: 'Mutation';
    bidding?: Maybe<{ __typename?: 'Bidding' } & FullBiddingDataFragment>;
};

export type CancelBiddingMutationVariables = Exact<{
    biddingId: Scalars['ObjectID'];
}>;

export type CancelBiddingMutation = {
    __typename?: 'Mutation';
    bidding?: Maybe<{ __typename?: 'Bidding' } & FullBiddingDataFragment>;
};

export type AddBiddingEntryMutationVariables = Exact<{
    biddingId: Scalars['ObjectID'];
    saleTransactionId: Scalars['ObjectID'];
    amount: Scalars['Int'];
}>;

export type AddBiddingEntryMutation = {
    __typename?: 'Mutation';
    bidding?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateAwardedBiddingEntryMutationVariables = Exact<{
    biddingId: Scalars['ObjectID'];
    saleTransactionId: Scalars['ObjectID'];
    biddingEntryId: Scalars['ObjectID'];
}>;

export type UpdateAwardedBiddingEntryMutation = {
    __typename?: 'Mutation';
    bidding?: Maybe<{ __typename?: 'Bidding' } & FullBiddingDataFragment>;
};

export type GenerateBiddingSalesTransactionsFileQueryVariables = Exact<{
    biddingId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
}>;

export type GenerateBiddingSalesTransactionsFileQuery = { __typename?: 'Query'; signedUrl?: Maybe<string> };

export type ToggleBiometricMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
    state: Scalars['Boolean'];
}>;

export type ToggleBiometricMutation = {
    __typename?: 'Mutation';
    isEnabledFingerprint:
        | {
              __typename?: 'AdminUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ApproverUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'DealerUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'SaleConsultantUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ValuationTeamUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          };
};

export type EnablePushMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
    registrationToken: Scalars['String'];
}>;

export type EnablePushMutation = {
    __typename?: 'Mutation';
    isEnabledPushNotification:
        | {
              __typename?: 'AdminUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ApproverUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'DealerUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'SaleConsultantUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ValuationTeamUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          };
};

export type DisablePushMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
}>;

export type DisablePushMutation = {
    __typename?: 'Mutation';
    isEnabledPushNotification:
        | {
              __typename?: 'AdminUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ApproverUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'DealerUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'SaleConsultantUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ValuationTeamUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          };
};

export type UpdateRegistrationTokenMutationVariables = Exact<{
    deviceFingerprint: Scalars['String'];
    registrationToken: Scalars['String'];
}>;

export type UpdateRegistrationTokenMutation = {
    __typename?: 'Mutation';
    isEnabledPushNotification:
        | {
              __typename?: 'AdminUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ApproverUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'DealerUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'SaleConsultantUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          }
        | {
              __typename?: 'ValuationTeamUser';
              id: string;
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          };
};

export type GetNotificationsQueryVariables = Exact<{
    page: Page;
    filter?: Maybe<NotificationFilterField>;
}>;

export type GetNotificationsQuery = {
    __typename?: 'Query';
    NotificationPage: { __typename?: 'NotificationPage' } & FullNotificationPageDataFragment;
};

export type GetPurchaseTransactionsQueryVariables = Exact<{
    page: Page;
    filter?: Maybe<PurchaseTransactionFilterField>;
    sort?: Maybe<SortPurchaseTransactionField>;
}>;

export type GetPurchaseTransactionsQuery = {
    __typename?: 'Query';
    purchaseTransactions: {
        __typename?: 'PurchaseTransactionPage';
        count: number;
        items: Array<{ __typename?: 'PurchaseTransaction' } & PreviewPurchaseTransactionDataFragment>;
    };
};

export type GetPurchaseTransactionQueryVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type GetPurchaseTransactionQuery = {
    __typename?: 'Query';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToInitialValuationMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateTransactionToInitialValuationMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToPendingAcceptanceMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateTransactionToPendingAcceptanceMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToFinalValuationMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateTransactionToFinalValuationMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToPendingApprovalMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateTransactionToPendingApprovalMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToHandoverMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateTransactionToHandoverMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToCompleteMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    setOfKeys: Scalars['Int'];
    ownerHandbookCollected: Scalars['Boolean'];
    handoverRemarks: Scalars['String'];
}>;

export type UpdateTransactionToCompleteMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdateTransactionToCancelMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    cancelReason: Scalars['String'];
}>;

export type UpdateTransactionToCancelMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type CreatePurchaseTransactionMutationVariables = Exact<{
    fields: NewPurchaseTransactionFields;
}>;

export type CreatePurchaseTransactionMutation = {
    __typename?: 'Mutation';
    createPurchaseTransaction: { __typename?: 'PurchaseTransaction'; id: string };
};

export type UpdatePurchaseTransactionMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    fields: NewPurchaseTransactionFields;
}>;

export type UpdatePurchaseTransactionMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type CheckDuplicateTransactionQueryVariables = Exact<{
    chassisNumber: Scalars['String'];
}>;

export type CheckDuplicateTransactionQuery = { __typename?: 'Query'; isDuplicated: boolean };

export type UpdatePurchaseTransactionFrontPagePhotoMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    frontPagePhotoId?: Maybe<Scalars['ObjectID']>;
}>;

export type UpdatePurchaseTransactionFrontPagePhotoMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction'; id: string }>;
};

export type UploadValuationPhotoMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isFrontPagePhoto: Scalars['Boolean'];
}>;

export type UploadValuationPhotoMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & ValuationPhotosOnlyDataFragment>;
};

export type UploadHandoverAttachmentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
}>;

export type UploadHandoverAttachmentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & HandoverAttachmentsOnlyDataFragment>;
};

export type UploadVpaAttachmentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
}>;

export type UploadVpaAttachmentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & VpaAttachmentOnlyDataFragment>;
};

export type RemoveValuationPhotoMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveValuationPhotoMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & ValuationPhotosOnlyDataFragment>;
};

export type RemoveHandoverAttachmentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveHandoverAttachmentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & HandoverAttachmentsOnlyDataFragment>;
};

export type RemoveVpaAttachmentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveVpaAttachmentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & VpaAttachmentOnlyDataFragment>;
};

export type AddVehicleDiagramCommentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    fields: VehicleDiagramCommentFields;
}>;

export type AddVehicleDiagramCommentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type RemoveVehicleDiagramCommentMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    vehicleDiagramCommentID: Scalars['ObjectID'];
}>;

export type RemoveVehicleDiagramCommentMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type GeneratePurchaseTransactionsFileQueryVariables = Exact<{
    downloadFileType: DownloadFileType;
    filter?: Maybe<PurchaseTransactionFilterField>;
    sort?: Maybe<SortPurchaseTransactionField>;
}>;

export type GeneratePurchaseTransactionsFileQuery = { __typename?: 'Query'; signedUrl?: Maybe<string> };

export type GeneratePurchaseTransactionDetailFileQueryVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
}>;

export type GeneratePurchaseTransactionDetailFileQuery = { __typename?: 'Query'; signedUrl?: Maybe<string> };

export type UpdatePurchaseTransactionForApprovalRequestMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    fields: PurchaseTransactionForApprovalRequestFields;
}>;

export type UpdatePurchaseTransactionForApprovalRequestMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & FullPurchaseTransactionDataFragment>;
};

export type UpdatePurchaseTransactionForAcceptanceMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    fields: PurchaseTransactionForAcceptanceFields;
}>;

export type UpdatePurchaseTransactionForAcceptanceMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & PreviewPurchaseTransactionDataFragment>;
};

export type UpdatePurchaseTransactionForInitValuationMutationVariables = Exact<{
    purchaseTransactionId: Scalars['ObjectID'];
    fields: InitValuationFields;
}>;

export type UpdatePurchaseTransactionForInitValuationMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction' } & PreviewPurchaseTransactionDataFragment>;
};

export type TriggerPendingAcceptanceNotificationMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type TriggerPendingAcceptanceNotificationMutation = {
    __typename?: 'Mutation';
    purchaseTransaction?: Maybe<{ __typename?: 'PurchaseTransaction'; id: string }>;
};

export type RequestResetPasswordMutationVariables = Exact<{
    mobilePhone: MobilePhoneInput;
    sendOtpByEmail?: Maybe<Scalars['Boolean']>;
}>;

export type RequestResetPasswordMutation = {
    __typename?: 'Mutation';
    requestResetPassword: { __typename?: 'RequestPasswordResult'; token: string; email?: Maybe<string> };
};

export type ConfirmResetPasswordMutationVariables = Exact<{
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
    code: Scalars['String'];
}>;

export type ConfirmResetPasswordMutation = { __typename?: 'Mutation'; confirmResetPassword: string };

export type ConsumeResetPasswordMutationVariables = Exact<{
    token: Scalars['String'];
    password: Scalars['String'];
}>;

export type ConsumeResetPasswordMutation = {
    __typename?: 'Mutation';
    consumeResetPassword:
        | { __typename?: 'AdminUser'; id: string }
        | { __typename?: 'ApproverUser'; id: string }
        | { __typename?: 'DealerUser'; id: string }
        | { __typename?: 'SaleConsultantUser'; id: string }
        | { __typename?: 'ValuationTeamUser'; id: string };
};

export type GetSaleTransactionsQueryVariables = Exact<{
    page: Page;
    filter?: Maybe<SaleTransactionFilterField>;
    sort?: Maybe<SortSaleTransactionField>;
}>;

export type GetSaleTransactionsQuery = {
    __typename?: 'Query';
    saleTransactions: {
        __typename?: 'SaleTransactionPage';
        count: number;
        items: Array<{ __typename?: 'SaleTransaction' } & PreviewSaleTransactionDataFragment>;
    };
};

export type GetSaleTransactionQueryVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type GetSaleTransactionQuery = {
    __typename?: 'Query';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type CreateSaleTransactionMutationVariables = Exact<{
    fields: NewSaleTransactionFields;
}>;

export type CreateSaleTransactionMutation = {
    __typename?: 'Mutation';
    createSaleTransaction: { __typename?: 'SaleTransaction'; id: string };
};

export type UpdateSaleTransactionMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    fields: NewSaleTransactionFields;
}>;

export type UpdateSaleTransactionMutation = {
    __typename?: 'Mutation';
    saleTransaction: { __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment;
};

export type UpdateSaleTransactionToForHandoverMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateSaleTransactionToForHandoverMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateSaleTransactionToCompletedMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateSaleTransactionToCompletedMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateSaleTransactionToPendingApprovalMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    handoverLocation?: Maybe<Scalars['String']>;
}>;

export type UpdateSaleTransactionToPendingApprovalMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateSaleTransactionToPendingMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    stage: SaleTransactionStage;
}>;

export type UpdateSaleTransactionToPendingMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateSaleTransactionToClosedMutationVariables = Exact<{
    transactionId: Scalars['ObjectID'];
}>;

export type UpdateSaleTransactionToClosedMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & FullSaleTransactionDataFragment>;
};

export type UpdateSalesTransactionFrontPagePhotoMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    frontPagePhotoId?: Maybe<Scalars['ObjectID']>;
}>;

export type UpdateSalesTransactionFrontPagePhotoMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction'; id: string }>;
};

export type UploadSalesTransactionPhotoMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isFrontPagePhoto: Scalars['Boolean'];
}>;

export type UploadSalesTransactionPhotoMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & SaleTransactionPhotosOnlyDataFragment>;
};

export type UploadSalesTransactionHandoverAttachmentMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
}>;

export type UploadSalesTransactionHandoverAttachmentMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & SalesTransactionHandoverAttachmentsOnlyDataFragment>;
};

export type UploadVsaAttachmentMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
}>;

export type UploadVsaAttachmentMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & VsaAttachmentOnlyDataFragment>;
};

export type RemoveSalesTransactionHandoverAttachmentMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveSalesTransactionHandoverAttachmentMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & SalesTransactionHandoverAttachmentsOnlyDataFragment>;
};

export type RemoveVsaAttachmentMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveVsaAttachmentMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & VsaAttachmentOnlyDataFragment>;
};

export type RemoveSalesTransactionPhotoMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    uploadedFileId: Scalars['ObjectID'];
}>;

export type RemoveSalesTransactionPhotoMutation = {
    __typename?: 'Mutation';
    saleTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & SaleTransactionPhotosOnlyDataFragment>;
};

export type GenerateSalesTransactionsFileQueryVariables = Exact<{
    downloadFileType: DownloadFileType;
    filter?: Maybe<SaleTransactionFilterField>;
    sort?: Maybe<SortSaleTransactionField>;
}>;

export type GenerateSalesTransactionsFileQuery = { __typename?: 'Query'; signedUrl?: Maybe<string> };

export type GenerateSalesTransactionDetailFileQueryVariables = Exact<{
    transactionId: Scalars['ObjectID'];
    downloadFileType: DownloadFileType;
}>;

export type GenerateSalesTransactionDetailFileQuery = { __typename?: 'Query'; signedUrl?: Maybe<string> };

export type UpdateSalesTransactionForAwardingMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
    fields: SalesTransactionForAwardingFields;
}>;

export type UpdateSalesTransactionForAwardingMutation = {
    __typename?: 'Mutation';
    salesTransaction?: Maybe<{ __typename?: 'SaleTransaction' } & PreviewSaleTransactionDataFragment>;
};

export type CloneSaleTransactionCreateNewMutationVariables = Exact<{
    salesTransactionId: Scalars['ObjectID'];
}>;

export type CloneSaleTransactionCreateNewMutation = {
    __typename?: 'Mutation';
    cloneSaleTransactionCreateNew: { __typename?: 'SaleTransaction' } & PreviewSaleTransactionDataFragment;
};

export type SignUpMutationVariables = Exact<{
    fields: AccountRegistrationFields;
}>;

export type SignUpMutation = { __typename?: 'Mutation'; registerNewAccount: string };

export type ConfirmSignUpMutationVariables = Exact<{
    mobilePhone: MobilePhoneInput;
    token: Scalars['String'];
    code: Scalars['String'];
}>;

export type ConfirmSignUpMutation = {
    __typename?: 'Mutation';
    confirmNewAccountRegistration:
        | { __typename?: 'AdminUser'; id: string }
        | { __typename?: 'ApproverUser'; id: string }
        | { __typename?: 'DealerUser'; id: string }
        | { __typename?: 'SaleConsultantUser'; id: string }
        | { __typename?: 'ValuationTeamUser'; id: string };
};

export type GetSelfQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelfQuery = {
    __typename?: 'Query';
    getSelf:
        | ({
              __typename?: 'AdminUser';
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          } & FullUserData_AdminUser_Fragment)
        | ({
              __typename?: 'ApproverUser';
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          } & FullUserData_ApproverUser_Fragment)
        | ({
              __typename?: 'DealerUser';
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          } & FullUserData_DealerUser_Fragment)
        | ({
              __typename?: 'SaleConsultantUser';
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          } & FullUserData_SaleConsultantUser_Fragment)
        | ({
              __typename?: 'ValuationTeamUser';
              deviceSettings: Array<
                  | ({ __typename?: 'BiometricDeviceSetting' } & DeviceSettingData_BiometricDeviceSetting_Fragment)
                  | ({ __typename?: 'PushDeviceSetting' } & DeviceSettingData_PushDeviceSetting_Fragment)
              >;
          } & FullUserData_ValuationTeamUser_Fragment);
};

export type UpdateUserMutationVariables = Exact<{
    id: Scalars['ObjectID'];
    fields: AccountUpdateFields;
    activityOption?: Maybe<UserUpdateActivityInput>;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateAccount?: Maybe<
        | ({ __typename?: 'AdminUser' } & FullUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & FullUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & FullUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & FullUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & FullUserData_ValuationTeamUser_Fragment)
    >;
};

export type GetUserQueryVariables = Exact<{
    id: Scalars['ObjectID'];
}>;

export type GetUserQuery = {
    __typename?: 'Query';
    user?: Maybe<
        | ({ __typename?: 'AdminUser' } & FullUserData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & FullUserData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & FullUserData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & FullUserData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & FullUserData_ValuationTeamUser_Fragment)
    >;
};

export type GetUserListByTypeQueryVariables = Exact<{
    type?: Maybe<UserType>;
}>;

export type GetUserListByTypeQuery = {
    __typename?: 'Query';
    users: {
        __typename?: 'UserPage';
        items: Array<
            | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment)
        >;
    };
};

export type GetAwardedDealersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwardedDealersQuery = {
    __typename?: 'Query';
    users: {
        __typename?: 'UserPage';
        items: Array<
            | ({ __typename?: 'AdminUser' } & PreviewUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & PreviewUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & PreviewUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & PreviewUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & PreviewUserData_ValuationTeamUser_Fragment)
        >;
    };
};

export type ListUsersQueryVariables = Exact<{
    page: Page;
    search?: Maybe<Scalars['String']>;
    activationStage?: Maybe<UserActivationStage>;
    type?: Maybe<UserType>;
}>;

export type ListUsersQuery = {
    __typename?: 'Query';
    users: {
        __typename?: 'UserPage';
        count: number;
        items: Array<
            | ({ __typename?: 'AdminUser' } & FullUserData_AdminUser_Fragment)
            | ({ __typename?: 'ApproverUser' } & FullUserData_ApproverUser_Fragment)
            | ({ __typename?: 'DealerUser' } & FullUserData_DealerUser_Fragment)
            | ({ __typename?: 'SaleConsultantUser' } & FullUserData_SaleConsultantUser_Fragment)
            | ({ __typename?: 'ValuationTeamUser' } & FullUserData_ValuationTeamUser_Fragment)
        >;
    };
};

export type UploadProfilePictureMutationVariables = Exact<{
    userId: Scalars['ObjectID'];
    file: Scalars['Upload'];
}>;

export type UploadProfilePictureMutation = {
    __typename?: 'Mutation';
    user?: Maybe<
        | ({ __typename?: 'AdminUser' } & ProfilePictureOnlyData_AdminUser_Fragment)
        | ({ __typename?: 'ApproverUser' } & ProfilePictureOnlyData_ApproverUser_Fragment)
        | ({ __typename?: 'DealerUser' } & ProfilePictureOnlyData_DealerUser_Fragment)
        | ({ __typename?: 'SaleConsultantUser' } & ProfilePictureOnlyData_SaleConsultantUser_Fragment)
        | ({ __typename?: 'ValuationTeamUser' } & ProfilePictureOnlyData_ValuationTeamUser_Fragment)
    >;
};

export type CheckDuplicateMobileNumberQueryVariables = Exact<{
    userId: Scalars['ObjectID'];
    mobilePhone: MobilePhoneInput;
}>;

export type CheckDuplicateMobileNumberQuery = { __typename?: 'Query'; isDuplicated: boolean };

export const NameOnlyFileDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const NameOnlyUserDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const PreviewBiddingDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const PreviewSaleTransactionDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const BiddingEntryDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullBiddingDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biddingSaleTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'awardedBiddingEntry' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const DeviceSettingDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const PreviewUserDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullNotificationDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullNotificationData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'channels' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewUserData' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingValuationNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingRequoteNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingAcceptanceNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'HandoverDateChangeNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionAmendedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpcomingBiddingNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AwardedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionValuationAcceptedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UnawardNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullNotificationPageDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullNotificationPageData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullNotificationData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullNotificationData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'channels' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewUserData' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingValuationNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingRequoteNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingAcceptanceNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'HandoverDateChangeNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionAmendedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpcomingBiddingNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AwardedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionValuationAcceptedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UnawardNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const PurchaseTransactionActivityDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullPurchaseTransactionDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const ValuationPhotosOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ValuationPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const HandoverAttachmentsOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'HandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const VpaAttachmentOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VpaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const PreviewPurchaseTransactionDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const SalesTransactionActivityDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullSaleTransactionDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const SalesTransactionHandoverAttachmentsOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionHandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const VsaAttachmentOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VsaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const SaleTransactionPhotosOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SaleTransactionPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const FullUserDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'testAccount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastActive' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const ProfilePictureOnlyDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProfilePictureOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const ExportExcelForActivityLogsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'exportExcelForActivityLogs' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityLogFilterField' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'exportExcelForActivityLogs' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useExportExcelForActivityLogsQuery__
 *
 * To run a query within a React component, call `useExportExcelForActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportExcelForActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportExcelForActivityLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportExcelForActivityLogsQuery(
    baseOptions: Apollo.QueryHookOptions<ExportExcelForActivityLogsQuery, ExportExcelForActivityLogsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<ExportExcelForActivityLogsQuery, ExportExcelForActivityLogsQueryVariables>(
        ExportExcelForActivityLogsDocument,
        options
    );
}
export function useExportExcelForActivityLogsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExportExcelForActivityLogsQuery, ExportExcelForActivityLogsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<ExportExcelForActivityLogsQuery, ExportExcelForActivityLogsQueryVariables>(
        ExportExcelForActivityLogsDocument,
        options
    );
}
export type ExportExcelForActivityLogsQueryHookResult = ReturnType<typeof useExportExcelForActivityLogsQuery>;
export type ExportExcelForActivityLogsLazyQueryHookResult = ReturnType<typeof useExportExcelForActivityLogsLazyQuery>;
export type ExportExcelForActivityLogsQueryResult = Apollo.QueryResult<
    ExportExcelForActivityLogsQuery,
    ExportExcelForActivityLogsQueryVariables
>;
export const GetPendingApprovalsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPendingApprovals' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'users' },
                        name: { kind: 'Name', value: 'listUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activationStage' },
                                value: { kind: 'EnumValue', value: 'New' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'search' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetPendingApprovalsQuery__
 *
 * To run a query within a React component, call `useGetPendingApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingApprovalsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPendingApprovalsQuery(
    baseOptions: Apollo.QueryHookOptions<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>(
        GetPendingApprovalsDocument,
        options
    );
}
export function useGetPendingApprovalsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>(
        GetPendingApprovalsDocument,
        options
    );
}
export type GetPendingApprovalsQueryHookResult = ReturnType<typeof useGetPendingApprovalsQuery>;
export type GetPendingApprovalsLazyQueryHookResult = ReturnType<typeof useGetPendingApprovalsLazyQuery>;
export type GetPendingApprovalsQueryResult = Apollo.QueryResult<
    GetPendingApprovalsQuery,
    GetPendingApprovalsQueryVariables
>;
export const ApproveAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'approveAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateActivityInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approveAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activityOption' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ApproveAccountMutationFn = Apollo.MutationFunction<ApproveAccountMutation, ApproveAccountMutationVariables>;

/**
 * __useApproveAccountMutation__
 *
 * To run a mutation, you first call `useApproveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAccountMutation, { data, loading, error }] = useApproveAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      activityOption: // value for 'activityOption'
 *   },
 * });
 */
export function useApproveAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ApproveAccountMutation, ApproveAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ApproveAccountMutation, ApproveAccountMutationVariables>(ApproveAccountDocument, options);
}
export type ApproveAccountMutationHookResult = ReturnType<typeof useApproveAccountMutation>;
export type ApproveAccountMutationResult = Apollo.MutationResult<ApproveAccountMutation>;
export type ApproveAccountMutationOptions = Apollo.BaseMutationOptions<
    ApproveAccountMutation,
    ApproveAccountMutationVariables
>;
export const EnableAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'enableAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enableAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type EnableAccountMutationFn = Apollo.MutationFunction<EnableAccountMutation, EnableAccountMutationVariables>;

/**
 * __useEnableAccountMutation__
 *
 * To run a mutation, you first call `useEnableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAccountMutation, { data, loading, error }] = useEnableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnableAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<EnableAccountMutation, EnableAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<EnableAccountMutation, EnableAccountMutationVariables>(EnableAccountDocument, options);
}
export type EnableAccountMutationHookResult = ReturnType<typeof useEnableAccountMutation>;
export type EnableAccountMutationResult = Apollo.MutationResult<EnableAccountMutation>;
export type EnableAccountMutationOptions = Apollo.BaseMutationOptions<
    EnableAccountMutation,
    EnableAccountMutationVariables
>;
export const DisableAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'disableAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disableAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type DisableAccountMutationFn = Apollo.MutationFunction<DisableAccountMutation, DisableAccountMutationVariables>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisableAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<DisableAccountMutation, DisableAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<DisableAccountMutation, DisableAccountMutationVariables>(DisableAccountDocument, options);
}
export type DisableAccountMutationHookResult = ReturnType<typeof useDisableAccountMutation>;
export type DisableAccountMutationResult = Apollo.MutationResult<DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<
    DisableAccountMutation,
    DisableAccountMutationVariables
>;
export const RejectAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'rejectAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateActivityInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activityOption' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RejectAccountMutationFn = Apollo.MutationFunction<RejectAccountMutation, RejectAccountMutationVariables>;

/**
 * __useRejectAccountMutation__
 *
 * To run a mutation, you first call `useRejectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAccountMutation, { data, loading, error }] = useRejectAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      activityOption: // value for 'activityOption'
 *   },
 * });
 */
export function useRejectAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectAccountMutation, RejectAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RejectAccountMutation, RejectAccountMutationVariables>(RejectAccountDocument, options);
}
export type RejectAccountMutationHookResult = ReturnType<typeof useRejectAccountMutation>;
export type RejectAccountMutationResult = Apollo.MutationResult<RejectAccountMutation>;
export type RejectAccountMutationOptions = Apollo.BaseMutationOptions<
    RejectAccountMutation,
    RejectAccountMutationVariables
>;
export const AuthenticateDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'authenticate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendOtpByEmail' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authenticate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'password' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sendOtpByEmail' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendOtpByEmail' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      mobilePhone: // value for 'mobilePhone'
 *      password: // value for 'password'
 *      sendOtpByEmail: // value for 'sendOtpByEmail'
 *   },
 * });
 */
export function useAuthenticateMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
>;
export const ConfirmAuthenticationDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'confirmAuthentication' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmAuthentication' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'code' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signInToken' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewUserData' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'deviceSettings' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'DeviceSettingData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ConfirmAuthenticationMutationFn = Apollo.MutationFunction<
    ConfirmAuthenticationMutation,
    ConfirmAuthenticationMutationVariables
>;

/**
 * __useConfirmAuthenticationMutation__
 *
 * To run a mutation, you first call `useConfirmAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAuthenticationMutation, { data, loading, error }] = useConfirmAuthenticationMutation({
 *   variables: {
 *      mobilePhone: // value for 'mobilePhone'
 *      token: // value for 'token'
 *      code: // value for 'code'
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *   },
 * });
 */
export function useConfirmAuthenticationMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmAuthenticationMutation, ConfirmAuthenticationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ConfirmAuthenticationMutation, ConfirmAuthenticationMutationVariables>(
        ConfirmAuthenticationDocument,
        options
    );
}
export type ConfirmAuthenticationMutationHookResult = ReturnType<typeof useConfirmAuthenticationMutation>;
export type ConfirmAuthenticationMutationResult = Apollo.MutationResult<ConfirmAuthenticationMutation>;
export type ConfirmAuthenticationMutationOptions = Apollo.BaseMutationOptions<
    ConfirmAuthenticationMutation,
    ConfirmAuthenticationMutationVariables
>;
export const GetSingleSignInTokenDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'getSingleSignInToken' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getSingleSignInToken' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type GetSingleSignInTokenMutationFn = Apollo.MutationFunction<
    GetSingleSignInTokenMutation,
    GetSingleSignInTokenMutationVariables
>;

/**
 * __useGetSingleSignInTokenMutation__
 *
 * To run a mutation, you first call `useGetSingleSignInTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSingleSignInTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSingleSignInTokenMutation, { data, loading, error }] = useGetSingleSignInTokenMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *   },
 * });
 */
export function useGetSingleSignInTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<GetSingleSignInTokenMutation, GetSingleSignInTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<GetSingleSignInTokenMutation, GetSingleSignInTokenMutationVariables>(
        GetSingleSignInTokenDocument,
        options
    );
}
export type GetSingleSignInTokenMutationHookResult = ReturnType<typeof useGetSingleSignInTokenMutation>;
export type GetSingleSignInTokenMutationResult = Apollo.MutationResult<GetSingleSignInTokenMutation>;
export type GetSingleSignInTokenMutationOptions = Apollo.BaseMutationOptions<
    GetSingleSignInTokenMutation,
    GetSingleSignInTokenMutationVariables
>;
export const ConsumeSingleSignInTokenDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'consumeSingleSignInToken' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authenticateWithToken' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signInToken' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewUserData' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'deviceSettings' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'DeviceSettingData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ConsumeSingleSignInTokenMutationFn = Apollo.MutationFunction<
    ConsumeSingleSignInTokenMutation,
    ConsumeSingleSignInTokenMutationVariables
>;

/**
 * __useConsumeSingleSignInTokenMutation__
 *
 * To run a mutation, you first call `useConsumeSingleSignInTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeSingleSignInTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeSingleSignInTokenMutation, { data, loading, error }] = useConsumeSingleSignInTokenMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConsumeSingleSignInTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ConsumeSingleSignInTokenMutation,
        ConsumeSingleSignInTokenMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ConsumeSingleSignInTokenMutation, ConsumeSingleSignInTokenMutationVariables>(
        ConsumeSingleSignInTokenDocument,
        options
    );
}
export type ConsumeSingleSignInTokenMutationHookResult = ReturnType<typeof useConsumeSingleSignInTokenMutation>;
export type ConsumeSingleSignInTokenMutationResult = Apollo.MutationResult<ConsumeSingleSignInTokenMutation>;
export type ConsumeSingleSignInTokenMutationOptions = Apollo.BaseMutationOptions<
    ConsumeSingleSignInTokenMutation,
    ConsumeSingleSignInTokenMutationVariables
>;
export const RefreshSessionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'refreshSession' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'response' },
                        name: { kind: 'Name', value: 'refreshToken' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RefreshSessionMutationFn = Apollo.MutationFunction<RefreshSessionMutation, RefreshSessionMutationVariables>;

/**
 * __useRefreshSessionMutation__
 *
 * To run a mutation, you first call `useRefreshSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshSessionMutation, { data, loading, error }] = useRefreshSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshSessionMutation(
    baseOptions?: Apollo.MutationHookOptions<RefreshSessionMutation, RefreshSessionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RefreshSessionMutation, RefreshSessionMutationVariables>(RefreshSessionDocument, options);
}
export type RefreshSessionMutationHookResult = ReturnType<typeof useRefreshSessionMutation>;
export type RefreshSessionMutationResult = Apollo.MutationResult<RefreshSessionMutation>;
export type RefreshSessionMutationOptions = Apollo.BaseMutationOptions<
    RefreshSessionMutation,
    RefreshSessionMutationVariables
>;
export const ListBiddingsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listBiddings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingFilterField' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'biddings' },
                        name: { kind: 'Name', value: 'listBiddings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewBiddingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useListBiddingsQuery__
 *
 * To run a query within a React component, call `useListBiddingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBiddingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBiddingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListBiddingsQuery(
    baseOptions: Apollo.QueryHookOptions<ListBiddingsQuery, ListBiddingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<ListBiddingsQuery, ListBiddingsQueryVariables>(ListBiddingsDocument, options);
}
export function useListBiddingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListBiddingsQuery, ListBiddingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<ListBiddingsQuery, ListBiddingsQueryVariables>(ListBiddingsDocument, options);
}
export type ListBiddingsQueryHookResult = ReturnType<typeof useListBiddingsQuery>;
export type ListBiddingsLazyQueryHookResult = ReturnType<typeof useListBiddingsLazyQuery>;
export type ListBiddingsQueryResult = Apollo.QueryResult<ListBiddingsQuery, ListBiddingsQueryVariables>;
export const GetBiddingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBidding' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidding' },
                        name: { kind: 'Name', value: 'getBidding' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullBiddingData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biddingSaleTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'awardedBiddingEntry' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetBiddingQuery__
 *
 * To run a query within a React component, call `useGetBiddingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBiddingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBiddingQuery({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *   },
 * });
 */
export function useGetBiddingQuery(baseOptions: Apollo.QueryHookOptions<GetBiddingQuery, GetBiddingQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetBiddingQuery, GetBiddingQueryVariables>(GetBiddingDocument, options);
}
export function useGetBiddingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBiddingQuery, GetBiddingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetBiddingQuery, GetBiddingQueryVariables>(GetBiddingDocument, options);
}
export type GetBiddingQueryHookResult = ReturnType<typeof useGetBiddingQuery>;
export type GetBiddingLazyQueryHookResult = ReturnType<typeof useGetBiddingLazyQuery>;
export type GetBiddingQueryResult = Apollo.QueryResult<GetBiddingQuery, GetBiddingQueryVariables>;
export const CreateBiddingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createBidding' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewBiddingFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createBidding' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CreateBiddingMutationFn = Apollo.MutationFunction<CreateBiddingMutation, CreateBiddingMutationVariables>;

/**
 * __useCreateBiddingMutation__
 *
 * To run a mutation, you first call `useCreateBiddingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBiddingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBiddingMutation, { data, loading, error }] = useCreateBiddingMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateBiddingMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBiddingMutation, CreateBiddingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CreateBiddingMutation, CreateBiddingMutationVariables>(CreateBiddingDocument, options);
}
export type CreateBiddingMutationHookResult = ReturnType<typeof useCreateBiddingMutation>;
export type CreateBiddingMutationResult = Apollo.MutationResult<CreateBiddingMutation>;
export type CreateBiddingMutationOptions = Apollo.BaseMutationOptions<
    CreateBiddingMutation,
    CreateBiddingMutationVariables
>;
export const UpdateBiddingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateBidding' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateBiddingFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidding' },
                        name: { kind: 'Name', value: 'updateBidding' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullBiddingData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biddingSaleTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'awardedBiddingEntry' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateBiddingMutationFn = Apollo.MutationFunction<UpdateBiddingMutation, UpdateBiddingMutationVariables>;

/**
 * __useUpdateBiddingMutation__
 *
 * To run a mutation, you first call `useUpdateBiddingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBiddingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBiddingMutation, { data, loading, error }] = useUpdateBiddingMutation({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateBiddingMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateBiddingMutation, UpdateBiddingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateBiddingMutation, UpdateBiddingMutationVariables>(UpdateBiddingDocument, options);
}
export type UpdateBiddingMutationHookResult = ReturnType<typeof useUpdateBiddingMutation>;
export type UpdateBiddingMutationResult = Apollo.MutationResult<UpdateBiddingMutation>;
export type UpdateBiddingMutationOptions = Apollo.BaseMutationOptions<
    UpdateBiddingMutation,
    UpdateBiddingMutationVariables
>;
export const CancelBiddingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cancelBidding' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidding' },
                        name: { kind: 'Name', value: 'cancelBidding' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullBiddingData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biddingSaleTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'awardedBiddingEntry' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CancelBiddingMutationFn = Apollo.MutationFunction<CancelBiddingMutation, CancelBiddingMutationVariables>;

/**
 * __useCancelBiddingMutation__
 *
 * To run a mutation, you first call `useCancelBiddingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBiddingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBiddingMutation, { data, loading, error }] = useCancelBiddingMutation({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *   },
 * });
 */
export function useCancelBiddingMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelBiddingMutation, CancelBiddingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CancelBiddingMutation, CancelBiddingMutationVariables>(CancelBiddingDocument, options);
}
export type CancelBiddingMutationHookResult = ReturnType<typeof useCancelBiddingMutation>;
export type CancelBiddingMutationResult = Apollo.MutationResult<CancelBiddingMutation>;
export type CancelBiddingMutationOptions = Apollo.BaseMutationOptions<
    CancelBiddingMutation,
    CancelBiddingMutationVariables
>;
export const AddBiddingEntryDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addBiddingEntry' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'saleTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidding' },
                        name: { kind: 'Name', value: 'addBiddingEntry' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'saleTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'saleTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'amount' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type AddBiddingEntryMutationFn = Apollo.MutationFunction<
    AddBiddingEntryMutation,
    AddBiddingEntryMutationVariables
>;

/**
 * __useAddBiddingEntryMutation__
 *
 * To run a mutation, you first call `useAddBiddingEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBiddingEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBiddingEntryMutation, { data, loading, error }] = useAddBiddingEntryMutation({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *      saleTransactionId: // value for 'saleTransactionId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAddBiddingEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<AddBiddingEntryMutation, AddBiddingEntryMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<AddBiddingEntryMutation, AddBiddingEntryMutationVariables>(
        AddBiddingEntryDocument,
        options
    );
}
export type AddBiddingEntryMutationHookResult = ReturnType<typeof useAddBiddingEntryMutation>;
export type AddBiddingEntryMutationResult = Apollo.MutationResult<AddBiddingEntryMutation>;
export type AddBiddingEntryMutationOptions = Apollo.BaseMutationOptions<
    AddBiddingEntryMutation,
    AddBiddingEntryMutationVariables
>;
export const UpdateAwardedBiddingEntryDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAwardedBiddingEntry' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'saleTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingEntryId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'bidding' },
                        name: { kind: 'Name', value: 'updateAwardedBiddingEntry' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'saleTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'saleTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingEntryId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingEntryId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullBiddingData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'biddingSaleTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'awardedBiddingEntry' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateAwardedBiddingEntryMutationFn = Apollo.MutationFunction<
    UpdateAwardedBiddingEntryMutation,
    UpdateAwardedBiddingEntryMutationVariables
>;

/**
 * __useUpdateAwardedBiddingEntryMutation__
 *
 * To run a mutation, you first call `useUpdateAwardedBiddingEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwardedBiddingEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwardedBiddingEntryMutation, { data, loading, error }] = useUpdateAwardedBiddingEntryMutation({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *      saleTransactionId: // value for 'saleTransactionId'
 *      biddingEntryId: // value for 'biddingEntryId'
 *   },
 * });
 */
export function useUpdateAwardedBiddingEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAwardedBiddingEntryMutation,
        UpdateAwardedBiddingEntryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateAwardedBiddingEntryMutation, UpdateAwardedBiddingEntryMutationVariables>(
        UpdateAwardedBiddingEntryDocument,
        options
    );
}
export type UpdateAwardedBiddingEntryMutationHookResult = ReturnType<typeof useUpdateAwardedBiddingEntryMutation>;
export type UpdateAwardedBiddingEntryMutationResult = Apollo.MutationResult<UpdateAwardedBiddingEntryMutation>;
export type UpdateAwardedBiddingEntryMutationOptions = Apollo.BaseMutationOptions<
    UpdateAwardedBiddingEntryMutation,
    UpdateAwardedBiddingEntryMutationVariables
>;
export const GenerateBiddingSalesTransactionsFileDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'generateBiddingSalesTransactionsFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DownloadFileType' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'generateBiddingSalesTransactionsFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biddingId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'biddingId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'downloadFileType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGenerateBiddingSalesTransactionsFileQuery__
 *
 * To run a query within a React component, call `useGenerateBiddingSalesTransactionsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateBiddingSalesTransactionsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateBiddingSalesTransactionsFileQuery({
 *   variables: {
 *      biddingId: // value for 'biddingId'
 *      downloadFileType: // value for 'downloadFileType'
 *   },
 * });
 */
export function useGenerateBiddingSalesTransactionsFileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GenerateBiddingSalesTransactionsFileQuery,
        GenerateBiddingSalesTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<
        GenerateBiddingSalesTransactionsFileQuery,
        GenerateBiddingSalesTransactionsFileQueryVariables
    >(GenerateBiddingSalesTransactionsFileDocument, options);
}
export function useGenerateBiddingSalesTransactionsFileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GenerateBiddingSalesTransactionsFileQuery,
        GenerateBiddingSalesTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<
        GenerateBiddingSalesTransactionsFileQuery,
        GenerateBiddingSalesTransactionsFileQueryVariables
    >(GenerateBiddingSalesTransactionsFileDocument, options);
}
export type GenerateBiddingSalesTransactionsFileQueryHookResult = ReturnType<
    typeof useGenerateBiddingSalesTransactionsFileQuery
>;
export type GenerateBiddingSalesTransactionsFileLazyQueryHookResult = ReturnType<
    typeof useGenerateBiddingSalesTransactionsFileLazyQuery
>;
export type GenerateBiddingSalesTransactionsFileQueryResult = Apollo.QueryResult<
    GenerateBiddingSalesTransactionsFileQuery,
    GenerateBiddingSalesTransactionsFileQueryVariables
>;
export const ToggleBiometricDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'toggleBiometric' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isEnabledFingerprint' },
                        name: { kind: 'Name', value: 'toggleBiometric' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'state' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deviceSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceSettingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ToggleBiometricMutationFn = Apollo.MutationFunction<
    ToggleBiometricMutation,
    ToggleBiometricMutationVariables
>;

/**
 * __useToggleBiometricMutation__
 *
 * To run a mutation, you first call `useToggleBiometricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBiometricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBiometricMutation, { data, loading, error }] = useToggleBiometricMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useToggleBiometricMutation(
    baseOptions?: Apollo.MutationHookOptions<ToggleBiometricMutation, ToggleBiometricMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ToggleBiometricMutation, ToggleBiometricMutationVariables>(
        ToggleBiometricDocument,
        options
    );
}
export type ToggleBiometricMutationHookResult = ReturnType<typeof useToggleBiometricMutation>;
export type ToggleBiometricMutationResult = Apollo.MutationResult<ToggleBiometricMutation>;
export type ToggleBiometricMutationOptions = Apollo.BaseMutationOptions<
    ToggleBiometricMutation,
    ToggleBiometricMutationVariables
>;
export const EnablePushDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'enablePush' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationToken' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isEnabledPushNotification' },
                        name: { kind: 'Name', value: 'enablePush' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'registrationToken' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationToken' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deviceSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceSettingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type EnablePushMutationFn = Apollo.MutationFunction<EnablePushMutation, EnablePushMutationVariables>;

/**
 * __useEnablePushMutation__
 *
 * To run a mutation, you first call `useEnablePushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePushMutation, { data, loading, error }] = useEnablePushMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *      registrationToken: // value for 'registrationToken'
 *   },
 * });
 */
export function useEnablePushMutation(
    baseOptions?: Apollo.MutationHookOptions<EnablePushMutation, EnablePushMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<EnablePushMutation, EnablePushMutationVariables>(EnablePushDocument, options);
}
export type EnablePushMutationHookResult = ReturnType<typeof useEnablePushMutation>;
export type EnablePushMutationResult = Apollo.MutationResult<EnablePushMutation>;
export type EnablePushMutationOptions = Apollo.BaseMutationOptions<EnablePushMutation, EnablePushMutationVariables>;
export const DisablePushDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'disablePush' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isEnabledPushNotification' },
                        name: { kind: 'Name', value: 'disablePush' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deviceSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceSettingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type DisablePushMutationFn = Apollo.MutationFunction<DisablePushMutation, DisablePushMutationVariables>;

/**
 * __useDisablePushMutation__
 *
 * To run a mutation, you first call `useDisablePushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePushMutation, { data, loading, error }] = useDisablePushMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *   },
 * });
 */
export function useDisablePushMutation(
    baseOptions?: Apollo.MutationHookOptions<DisablePushMutation, DisablePushMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<DisablePushMutation, DisablePushMutationVariables>(DisablePushDocument, options);
}
export type DisablePushMutationHookResult = ReturnType<typeof useDisablePushMutation>;
export type DisablePushMutationResult = Apollo.MutationResult<DisablePushMutation>;
export type DisablePushMutationOptions = Apollo.BaseMutationOptions<DisablePushMutation, DisablePushMutationVariables>;
export const UpdateRegistrationTokenDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateRegistrationToken' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationToken' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isEnabledPushNotification' },
                        name: { kind: 'Name', value: 'updateRegistrationToken' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'deviceFingerprint' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceFingerprint' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'registrationToken' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationToken' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deviceSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceSettingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateRegistrationTokenMutationFn = Apollo.MutationFunction<
    UpdateRegistrationTokenMutation,
    UpdateRegistrationTokenMutationVariables
>;

/**
 * __useUpdateRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useUpdateRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistrationTokenMutation, { data, loading, error }] = useUpdateRegistrationTokenMutation({
 *   variables: {
 *      deviceFingerprint: // value for 'deviceFingerprint'
 *      registrationToken: // value for 'registrationToken'
 *   },
 * });
 */
export function useUpdateRegistrationTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateRegistrationTokenMutation, UpdateRegistrationTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateRegistrationTokenMutation, UpdateRegistrationTokenMutationVariables>(
        UpdateRegistrationTokenDocument,
        options
    );
}
export type UpdateRegistrationTokenMutationHookResult = ReturnType<typeof useUpdateRegistrationTokenMutation>;
export type UpdateRegistrationTokenMutationResult = Apollo.MutationResult<UpdateRegistrationTokenMutation>;
export type UpdateRegistrationTokenMutationOptions = Apollo.BaseMutationOptions<
    UpdateRegistrationTokenMutation,
    UpdateRegistrationTokenMutationVariables
>;
export const GetNotificationsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getNotifications' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationFilterField' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'NotificationPage' },
                        name: { kind: 'Name', value: 'listNotifications' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullNotificationPageData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullNotificationData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'channels' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewUserData' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingValuationNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingRequoteNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PendingAcceptanceNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'HandoverDateChangeNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionAmendedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpcomingBiddingNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'AwardedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'saleTransactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionValuationAcceptedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'purchaseTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovalNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionApprovedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SalesTransactionRejectedNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UnawardNoticeNotification' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salesTransaction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullNotificationPageData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullNotificationData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetNotificationsQuery(
    baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
        GetNotificationsDocument,
        options
    );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetPurchaseTransactionsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPurchaseTransactions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransactionFilterField' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortPurchaseTransactionField' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransactions' },
                        name: { kind: 'Name', value: 'listPurchaseTransactions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sort' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetPurchaseTransactionsQuery__
 *
 * To run a query within a React component, call `useGetPurchaseTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPurchaseTransactionsQuery(
    baseOptions: Apollo.QueryHookOptions<GetPurchaseTransactionsQuery, GetPurchaseTransactionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetPurchaseTransactionsQuery, GetPurchaseTransactionsQueryVariables>(
        GetPurchaseTransactionsDocument,
        options
    );
}
export function useGetPurchaseTransactionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseTransactionsQuery, GetPurchaseTransactionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetPurchaseTransactionsQuery, GetPurchaseTransactionsQueryVariables>(
        GetPurchaseTransactionsDocument,
        options
    );
}
export type GetPurchaseTransactionsQueryHookResult = ReturnType<typeof useGetPurchaseTransactionsQuery>;
export type GetPurchaseTransactionsLazyQueryHookResult = ReturnType<typeof useGetPurchaseTransactionsLazyQuery>;
export type GetPurchaseTransactionsQueryResult = Apollo.QueryResult<
    GetPurchaseTransactionsQuery,
    GetPurchaseTransactionsQueryVariables
>;
export const GetPurchaseTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPurchaseTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'getPurchaseTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetPurchaseTransactionQuery__
 *
 * To run a query within a React component, call `useGetPurchaseTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetPurchaseTransactionQuery(
    baseOptions: Apollo.QueryHookOptions<GetPurchaseTransactionQuery, GetPurchaseTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetPurchaseTransactionQuery, GetPurchaseTransactionQueryVariables>(
        GetPurchaseTransactionDocument,
        options
    );
}
export function useGetPurchaseTransactionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseTransactionQuery, GetPurchaseTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetPurchaseTransactionQuery, GetPurchaseTransactionQueryVariables>(
        GetPurchaseTransactionDocument,
        options
    );
}
export type GetPurchaseTransactionQueryHookResult = ReturnType<typeof useGetPurchaseTransactionQuery>;
export type GetPurchaseTransactionLazyQueryHookResult = ReturnType<typeof useGetPurchaseTransactionLazyQuery>;
export type GetPurchaseTransactionQueryResult = Apollo.QueryResult<
    GetPurchaseTransactionQuery,
    GetPurchaseTransactionQueryVariables
>;
export const UpdateTransactionToInitialValuationDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToInitialValuation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToInitialValuation' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToInitialValuationMutationFn = Apollo.MutationFunction<
    UpdateTransactionToInitialValuationMutation,
    UpdateTransactionToInitialValuationMutationVariables
>;

/**
 * __useUpdateTransactionToInitialValuationMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToInitialValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToInitialValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToInitialValuationMutation, { data, loading, error }] = useUpdateTransactionToInitialValuationMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateTransactionToInitialValuationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToInitialValuationMutation,
        UpdateTransactionToInitialValuationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateTransactionToInitialValuationMutation,
        UpdateTransactionToInitialValuationMutationVariables
    >(UpdateTransactionToInitialValuationDocument, options);
}
export type UpdateTransactionToInitialValuationMutationHookResult = ReturnType<
    typeof useUpdateTransactionToInitialValuationMutation
>;
export type UpdateTransactionToInitialValuationMutationResult =
    Apollo.MutationResult<UpdateTransactionToInitialValuationMutation>;
export type UpdateTransactionToInitialValuationMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToInitialValuationMutation,
    UpdateTransactionToInitialValuationMutationVariables
>;
export const UpdateTransactionToPendingAcceptanceDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToPendingAcceptance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToPendingAcceptance' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToPendingAcceptanceMutationFn = Apollo.MutationFunction<
    UpdateTransactionToPendingAcceptanceMutation,
    UpdateTransactionToPendingAcceptanceMutationVariables
>;

/**
 * __useUpdateTransactionToPendingAcceptanceMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToPendingAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToPendingAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToPendingAcceptanceMutation, { data, loading, error }] = useUpdateTransactionToPendingAcceptanceMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateTransactionToPendingAcceptanceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToPendingAcceptanceMutation,
        UpdateTransactionToPendingAcceptanceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateTransactionToPendingAcceptanceMutation,
        UpdateTransactionToPendingAcceptanceMutationVariables
    >(UpdateTransactionToPendingAcceptanceDocument, options);
}
export type UpdateTransactionToPendingAcceptanceMutationHookResult = ReturnType<
    typeof useUpdateTransactionToPendingAcceptanceMutation
>;
export type UpdateTransactionToPendingAcceptanceMutationResult =
    Apollo.MutationResult<UpdateTransactionToPendingAcceptanceMutation>;
export type UpdateTransactionToPendingAcceptanceMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToPendingAcceptanceMutation,
    UpdateTransactionToPendingAcceptanceMutationVariables
>;
export const UpdateTransactionToFinalValuationDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToFinalValuation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToFinalValuation' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToFinalValuationMutationFn = Apollo.MutationFunction<
    UpdateTransactionToFinalValuationMutation,
    UpdateTransactionToFinalValuationMutationVariables
>;

/**
 * __useUpdateTransactionToFinalValuationMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToFinalValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToFinalValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToFinalValuationMutation, { data, loading, error }] = useUpdateTransactionToFinalValuationMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateTransactionToFinalValuationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToFinalValuationMutation,
        UpdateTransactionToFinalValuationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateTransactionToFinalValuationMutation,
        UpdateTransactionToFinalValuationMutationVariables
    >(UpdateTransactionToFinalValuationDocument, options);
}
export type UpdateTransactionToFinalValuationMutationHookResult = ReturnType<
    typeof useUpdateTransactionToFinalValuationMutation
>;
export type UpdateTransactionToFinalValuationMutationResult =
    Apollo.MutationResult<UpdateTransactionToFinalValuationMutation>;
export type UpdateTransactionToFinalValuationMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToFinalValuationMutation,
    UpdateTransactionToFinalValuationMutationVariables
>;
export const UpdateTransactionToPendingApprovalDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToPendingApproval' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToPendingApproval' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToPendingApprovalMutationFn = Apollo.MutationFunction<
    UpdateTransactionToPendingApprovalMutation,
    UpdateTransactionToPendingApprovalMutationVariables
>;

/**
 * __useUpdateTransactionToPendingApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToPendingApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToPendingApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToPendingApprovalMutation, { data, loading, error }] = useUpdateTransactionToPendingApprovalMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateTransactionToPendingApprovalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToPendingApprovalMutation,
        UpdateTransactionToPendingApprovalMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateTransactionToPendingApprovalMutation,
        UpdateTransactionToPendingApprovalMutationVariables
    >(UpdateTransactionToPendingApprovalDocument, options);
}
export type UpdateTransactionToPendingApprovalMutationHookResult = ReturnType<
    typeof useUpdateTransactionToPendingApprovalMutation
>;
export type UpdateTransactionToPendingApprovalMutationResult =
    Apollo.MutationResult<UpdateTransactionToPendingApprovalMutation>;
export type UpdateTransactionToPendingApprovalMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToPendingApprovalMutation,
    UpdateTransactionToPendingApprovalMutationVariables
>;
export const UpdateTransactionToHandoverDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToHandover' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToHandover' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToHandoverMutationFn = Apollo.MutationFunction<
    UpdateTransactionToHandoverMutation,
    UpdateTransactionToHandoverMutationVariables
>;

/**
 * __useUpdateTransactionToHandoverMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToHandoverMutation, { data, loading, error }] = useUpdateTransactionToHandoverMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateTransactionToHandoverMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToHandoverMutation,
        UpdateTransactionToHandoverMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateTransactionToHandoverMutation, UpdateTransactionToHandoverMutationVariables>(
        UpdateTransactionToHandoverDocument,
        options
    );
}
export type UpdateTransactionToHandoverMutationHookResult = ReturnType<typeof useUpdateTransactionToHandoverMutation>;
export type UpdateTransactionToHandoverMutationResult = Apollo.MutationResult<UpdateTransactionToHandoverMutation>;
export type UpdateTransactionToHandoverMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToHandoverMutation,
    UpdateTransactionToHandoverMutationVariables
>;
export const UpdateTransactionToCompleteDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToComplete' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'setOfKeys' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'handoverRemarks' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToComplete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'setOfKeys' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'setOfKeys' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ownerHandbookCollected' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'handoverRemarks' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'handoverRemarks' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToCompleteMutationFn = Apollo.MutationFunction<
    UpdateTransactionToCompleteMutation,
    UpdateTransactionToCompleteMutationVariables
>;

/**
 * __useUpdateTransactionToCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToCompleteMutation, { data, loading, error }] = useUpdateTransactionToCompleteMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      setOfKeys: // value for 'setOfKeys'
 *      ownerHandbookCollected: // value for 'ownerHandbookCollected'
 *      handoverRemarks: // value for 'handoverRemarks'
 *   },
 * });
 */
export function useUpdateTransactionToCompleteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToCompleteMutation,
        UpdateTransactionToCompleteMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateTransactionToCompleteMutation, UpdateTransactionToCompleteMutationVariables>(
        UpdateTransactionToCompleteDocument,
        options
    );
}
export type UpdateTransactionToCompleteMutationHookResult = ReturnType<typeof useUpdateTransactionToCompleteMutation>;
export type UpdateTransactionToCompleteMutationResult = Apollo.MutationResult<UpdateTransactionToCompleteMutation>;
export type UpdateTransactionToCompleteMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToCompleteMutation,
    UpdateTransactionToCompleteMutationVariables
>;
export const UpdateTransactionToCancelDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTransactionToCancel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'cancelReason' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updateTransactionToCancel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cancelReason' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'cancelReason' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateTransactionToCancelMutationFn = Apollo.MutationFunction<
    UpdateTransactionToCancelMutation,
    UpdateTransactionToCancelMutationVariables
>;

/**
 * __useUpdateTransactionToCancelMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionToCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionToCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionToCancelMutation, { data, loading, error }] = useUpdateTransactionToCancelMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      cancelReason: // value for 'cancelReason'
 *   },
 * });
 */
export function useUpdateTransactionToCancelMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTransactionToCancelMutation,
        UpdateTransactionToCancelMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateTransactionToCancelMutation, UpdateTransactionToCancelMutationVariables>(
        UpdateTransactionToCancelDocument,
        options
    );
}
export type UpdateTransactionToCancelMutationHookResult = ReturnType<typeof useUpdateTransactionToCancelMutation>;
export type UpdateTransactionToCancelMutationResult = Apollo.MutationResult<UpdateTransactionToCancelMutation>;
export type UpdateTransactionToCancelMutationOptions = Apollo.BaseMutationOptions<
    UpdateTransactionToCancelMutation,
    UpdateTransactionToCancelMutationVariables
>;
export const CreatePurchaseTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createPurchaseTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewPurchaseTransactionFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createPurchaseTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CreatePurchaseTransactionMutationFn = Apollo.MutationFunction<
    CreatePurchaseTransactionMutation,
    CreatePurchaseTransactionMutationVariables
>;

/**
 * __useCreatePurchaseTransactionMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseTransactionMutation, { data, loading, error }] = useCreatePurchaseTransactionMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreatePurchaseTransactionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePurchaseTransactionMutation,
        CreatePurchaseTransactionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CreatePurchaseTransactionMutation, CreatePurchaseTransactionMutationVariables>(
        CreatePurchaseTransactionDocument,
        options
    );
}
export type CreatePurchaseTransactionMutationHookResult = ReturnType<typeof useCreatePurchaseTransactionMutation>;
export type CreatePurchaseTransactionMutationResult = Apollo.MutationResult<CreatePurchaseTransactionMutation>;
export type CreatePurchaseTransactionMutationOptions = Apollo.BaseMutationOptions<
    CreatePurchaseTransactionMutation,
    CreatePurchaseTransactionMutationVariables
>;
export const UpdatePurchaseTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePurchaseTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewPurchaseTransactionFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updatePurchaseTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdatePurchaseTransactionMutationFn = Apollo.MutationFunction<
    UpdatePurchaseTransactionMutation,
    UpdatePurchaseTransactionMutationVariables
>;

/**
 * __useUpdatePurchaseTransactionMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseTransactionMutation, { data, loading, error }] = useUpdatePurchaseTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdatePurchaseTransactionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseTransactionMutation,
        UpdatePurchaseTransactionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdatePurchaseTransactionMutation, UpdatePurchaseTransactionMutationVariables>(
        UpdatePurchaseTransactionDocument,
        options
    );
}
export type UpdatePurchaseTransactionMutationHookResult = ReturnType<typeof useUpdatePurchaseTransactionMutation>;
export type UpdatePurchaseTransactionMutationResult = Apollo.MutationResult<UpdatePurchaseTransactionMutation>;
export type UpdatePurchaseTransactionMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseTransactionMutation,
    UpdatePurchaseTransactionMutationVariables
>;
export const CheckDuplicateTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'checkDuplicateTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'chassisNumber' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isDuplicated' },
                        name: { kind: 'Name', value: 'checkDuplicateTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'chassisNumber' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'chassisNumber' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCheckDuplicateTransactionQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateTransactionQuery({
 *   variables: {
 *      chassisNumber: // value for 'chassisNumber'
 *   },
 * });
 */
export function useCheckDuplicateTransactionQuery(
    baseOptions: Apollo.QueryHookOptions<CheckDuplicateTransactionQuery, CheckDuplicateTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<CheckDuplicateTransactionQuery, CheckDuplicateTransactionQueryVariables>(
        CheckDuplicateTransactionDocument,
        options
    );
}
export function useCheckDuplicateTransactionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckDuplicateTransactionQuery, CheckDuplicateTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<CheckDuplicateTransactionQuery, CheckDuplicateTransactionQueryVariables>(
        CheckDuplicateTransactionDocument,
        options
    );
}
export type CheckDuplicateTransactionQueryHookResult = ReturnType<typeof useCheckDuplicateTransactionQuery>;
export type CheckDuplicateTransactionLazyQueryHookResult = ReturnType<typeof useCheckDuplicateTransactionLazyQuery>;
export type CheckDuplicateTransactionQueryResult = Apollo.QueryResult<
    CheckDuplicateTransactionQuery,
    CheckDuplicateTransactionQueryVariables
>;
export const UpdatePurchaseTransactionFrontPagePhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePurchaseTransactionFrontPagePhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'frontPagePhotoId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updatePurchaseTransactionFrontPagePhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'frontPagePhotoId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'frontPagePhotoId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdatePurchaseTransactionFrontPagePhotoMutationFn = Apollo.MutationFunction<
    UpdatePurchaseTransactionFrontPagePhotoMutation,
    UpdatePurchaseTransactionFrontPagePhotoMutationVariables
>;

/**
 * __useUpdatePurchaseTransactionFrontPagePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseTransactionFrontPagePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseTransactionFrontPagePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseTransactionFrontPagePhotoMutation, { data, loading, error }] = useUpdatePurchaseTransactionFrontPagePhotoMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      frontPagePhotoId: // value for 'frontPagePhotoId'
 *   },
 * });
 */
export function useUpdatePurchaseTransactionFrontPagePhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseTransactionFrontPagePhotoMutation,
        UpdatePurchaseTransactionFrontPagePhotoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdatePurchaseTransactionFrontPagePhotoMutation,
        UpdatePurchaseTransactionFrontPagePhotoMutationVariables
    >(UpdatePurchaseTransactionFrontPagePhotoDocument, options);
}
export type UpdatePurchaseTransactionFrontPagePhotoMutationHookResult = ReturnType<
    typeof useUpdatePurchaseTransactionFrontPagePhotoMutation
>;
export type UpdatePurchaseTransactionFrontPagePhotoMutationResult =
    Apollo.MutationResult<UpdatePurchaseTransactionFrontPagePhotoMutation>;
export type UpdatePurchaseTransactionFrontPagePhotoMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseTransactionFrontPagePhotoMutation,
    UpdatePurchaseTransactionFrontPagePhotoMutationVariables
>;
export const UploadValuationPhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadValuationPhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'isFrontPagePhoto' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'uploadValuationPhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'isFrontPagePhoto' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'isFrontPagePhoto' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ValuationPhotosOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ValuationPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadValuationPhotoMutationFn = Apollo.MutationFunction<
    UploadValuationPhotoMutation,
    UploadValuationPhotoMutationVariables
>;

/**
 * __useUploadValuationPhotoMutation__
 *
 * To run a mutation, you first call `useUploadValuationPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadValuationPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadValuationPhotoMutation, { data, loading, error }] = useUploadValuationPhotoMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      file: // value for 'file'
 *      isFrontPagePhoto: // value for 'isFrontPagePhoto'
 *   },
 * });
 */
export function useUploadValuationPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadValuationPhotoMutation, UploadValuationPhotoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadValuationPhotoMutation, UploadValuationPhotoMutationVariables>(
        UploadValuationPhotoDocument,
        options
    );
}
export type UploadValuationPhotoMutationHookResult = ReturnType<typeof useUploadValuationPhotoMutation>;
export type UploadValuationPhotoMutationResult = Apollo.MutationResult<UploadValuationPhotoMutation>;
export type UploadValuationPhotoMutationOptions = Apollo.BaseMutationOptions<
    UploadValuationPhotoMutation,
    UploadValuationPhotoMutationVariables
>;
export const UploadHandoverAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadHandoverAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'uploadHandoverAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'HandoverAttachmentsOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'HandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadHandoverAttachmentMutationFn = Apollo.MutationFunction<
    UploadHandoverAttachmentMutation,
    UploadHandoverAttachmentMutationVariables
>;

/**
 * __useUploadHandoverAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadHandoverAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadHandoverAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadHandoverAttachmentMutation, { data, loading, error }] = useUploadHandoverAttachmentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadHandoverAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadHandoverAttachmentMutation,
        UploadHandoverAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadHandoverAttachmentMutation, UploadHandoverAttachmentMutationVariables>(
        UploadHandoverAttachmentDocument,
        options
    );
}
export type UploadHandoverAttachmentMutationHookResult = ReturnType<typeof useUploadHandoverAttachmentMutation>;
export type UploadHandoverAttachmentMutationResult = Apollo.MutationResult<UploadHandoverAttachmentMutation>;
export type UploadHandoverAttachmentMutationOptions = Apollo.BaseMutationOptions<
    UploadHandoverAttachmentMutation,
    UploadHandoverAttachmentMutationVariables
>;
export const UploadVpaAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadVpaAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'uploadVpaAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VpaAttachmentOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VpaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadVpaAttachmentMutationFn = Apollo.MutationFunction<
    UploadVpaAttachmentMutation,
    UploadVpaAttachmentMutationVariables
>;

/**
 * __useUploadVpaAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadVpaAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVpaAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVpaAttachmentMutation, { data, loading, error }] = useUploadVpaAttachmentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadVpaAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadVpaAttachmentMutation, UploadVpaAttachmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadVpaAttachmentMutation, UploadVpaAttachmentMutationVariables>(
        UploadVpaAttachmentDocument,
        options
    );
}
export type UploadVpaAttachmentMutationHookResult = ReturnType<typeof useUploadVpaAttachmentMutation>;
export type UploadVpaAttachmentMutationResult = Apollo.MutationResult<UploadVpaAttachmentMutation>;
export type UploadVpaAttachmentMutationOptions = Apollo.BaseMutationOptions<
    UploadVpaAttachmentMutation,
    UploadVpaAttachmentMutationVariables
>;
export const RemoveValuationPhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeValuationPhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'removeValuationPhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ValuationPhotosOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ValuationPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveValuationPhotoMutationFn = Apollo.MutationFunction<
    RemoveValuationPhotoMutation,
    RemoveValuationPhotoMutationVariables
>;

/**
 * __useRemoveValuationPhotoMutation__
 *
 * To run a mutation, you first call `useRemoveValuationPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveValuationPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeValuationPhotoMutation, { data, loading, error }] = useRemoveValuationPhotoMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveValuationPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveValuationPhotoMutation, RemoveValuationPhotoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveValuationPhotoMutation, RemoveValuationPhotoMutationVariables>(
        RemoveValuationPhotoDocument,
        options
    );
}
export type RemoveValuationPhotoMutationHookResult = ReturnType<typeof useRemoveValuationPhotoMutation>;
export type RemoveValuationPhotoMutationResult = Apollo.MutationResult<RemoveValuationPhotoMutation>;
export type RemoveValuationPhotoMutationOptions = Apollo.BaseMutationOptions<
    RemoveValuationPhotoMutation,
    RemoveValuationPhotoMutationVariables
>;
export const RemoveHandoverAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeHandoverAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'removeHandoverAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'HandoverAttachmentsOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'HandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveHandoverAttachmentMutationFn = Apollo.MutationFunction<
    RemoveHandoverAttachmentMutation,
    RemoveHandoverAttachmentMutationVariables
>;

/**
 * __useRemoveHandoverAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveHandoverAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandoverAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandoverAttachmentMutation, { data, loading, error }] = useRemoveHandoverAttachmentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveHandoverAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveHandoverAttachmentMutation,
        RemoveHandoverAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveHandoverAttachmentMutation, RemoveHandoverAttachmentMutationVariables>(
        RemoveHandoverAttachmentDocument,
        options
    );
}
export type RemoveHandoverAttachmentMutationHookResult = ReturnType<typeof useRemoveHandoverAttachmentMutation>;
export type RemoveHandoverAttachmentMutationResult = Apollo.MutationResult<RemoveHandoverAttachmentMutation>;
export type RemoveHandoverAttachmentMutationOptions = Apollo.BaseMutationOptions<
    RemoveHandoverAttachmentMutation,
    RemoveHandoverAttachmentMutationVariables
>;
export const RemoveVpaAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeVpaAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'removeVpaAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VpaAttachmentOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VpaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveVpaAttachmentMutationFn = Apollo.MutationFunction<
    RemoveVpaAttachmentMutation,
    RemoveVpaAttachmentMutationVariables
>;

/**
 * __useRemoveVpaAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveVpaAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVpaAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVpaAttachmentMutation, { data, loading, error }] = useRemoveVpaAttachmentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveVpaAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveVpaAttachmentMutation, RemoveVpaAttachmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveVpaAttachmentMutation, RemoveVpaAttachmentMutationVariables>(
        RemoveVpaAttachmentDocument,
        options
    );
}
export type RemoveVpaAttachmentMutationHookResult = ReturnType<typeof useRemoveVpaAttachmentMutation>;
export type RemoveVpaAttachmentMutationResult = Apollo.MutationResult<RemoveVpaAttachmentMutation>;
export type RemoveVpaAttachmentMutationOptions = Apollo.BaseMutationOptions<
    RemoveVpaAttachmentMutation,
    RemoveVpaAttachmentMutationVariables
>;
export const AddVehicleDiagramCommentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addVehicleDiagramComment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'vehicleDiagramCommentFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'addVehicleDiagramComment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type AddVehicleDiagramCommentMutationFn = Apollo.MutationFunction<
    AddVehicleDiagramCommentMutation,
    AddVehicleDiagramCommentMutationVariables
>;

/**
 * __useAddVehicleDiagramCommentMutation__
 *
 * To run a mutation, you first call `useAddVehicleDiagramCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleDiagramCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleDiagramCommentMutation, { data, loading, error }] = useAddVehicleDiagramCommentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAddVehicleDiagramCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddVehicleDiagramCommentMutation,
        AddVehicleDiagramCommentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<AddVehicleDiagramCommentMutation, AddVehicleDiagramCommentMutationVariables>(
        AddVehicleDiagramCommentDocument,
        options
    );
}
export type AddVehicleDiagramCommentMutationHookResult = ReturnType<typeof useAddVehicleDiagramCommentMutation>;
export type AddVehicleDiagramCommentMutationResult = Apollo.MutationResult<AddVehicleDiagramCommentMutation>;
export type AddVehicleDiagramCommentMutationOptions = Apollo.BaseMutationOptions<
    AddVehicleDiagramCommentMutation,
    AddVehicleDiagramCommentMutationVariables
>;
export const RemoveVehicleDiagramCommentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeVehicleDiagramComment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'vehicleDiagramCommentID' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'removeVehicleDiagramComment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'vehicleDiagramCommentID' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'vehicleDiagramCommentID' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveVehicleDiagramCommentMutationFn = Apollo.MutationFunction<
    RemoveVehicleDiagramCommentMutation,
    RemoveVehicleDiagramCommentMutationVariables
>;

/**
 * __useRemoveVehicleDiagramCommentMutation__
 *
 * To run a mutation, you first call `useRemoveVehicleDiagramCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVehicleDiagramCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVehicleDiagramCommentMutation, { data, loading, error }] = useRemoveVehicleDiagramCommentMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      vehicleDiagramCommentID: // value for 'vehicleDiagramCommentID'
 *   },
 * });
 */
export function useRemoveVehicleDiagramCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveVehicleDiagramCommentMutation,
        RemoveVehicleDiagramCommentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveVehicleDiagramCommentMutation, RemoveVehicleDiagramCommentMutationVariables>(
        RemoveVehicleDiagramCommentDocument,
        options
    );
}
export type RemoveVehicleDiagramCommentMutationHookResult = ReturnType<typeof useRemoveVehicleDiagramCommentMutation>;
export type RemoveVehicleDiagramCommentMutationResult = Apollo.MutationResult<RemoveVehicleDiagramCommentMutation>;
export type RemoveVehicleDiagramCommentMutationOptions = Apollo.BaseMutationOptions<
    RemoveVehicleDiagramCommentMutation,
    RemoveVehicleDiagramCommentMutationVariables
>;
export const GeneratePurchaseTransactionsFileDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'generatePurchaseTransactionsFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DownloadFileType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransactionFilterField' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortPurchaseTransactionField' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'generatePurchaseTransactionsFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'downloadFileType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sort' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGeneratePurchaseTransactionsFileQuery__
 *
 * To run a query within a React component, call `useGeneratePurchaseTransactionsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePurchaseTransactionsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePurchaseTransactionsFileQuery({
 *   variables: {
 *      downloadFileType: // value for 'downloadFileType'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGeneratePurchaseTransactionsFileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GeneratePurchaseTransactionsFileQuery,
        GeneratePurchaseTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GeneratePurchaseTransactionsFileQuery, GeneratePurchaseTransactionsFileQueryVariables>(
        GeneratePurchaseTransactionsFileDocument,
        options
    );
}
export function useGeneratePurchaseTransactionsFileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GeneratePurchaseTransactionsFileQuery,
        GeneratePurchaseTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GeneratePurchaseTransactionsFileQuery, GeneratePurchaseTransactionsFileQueryVariables>(
        GeneratePurchaseTransactionsFileDocument,
        options
    );
}
export type GeneratePurchaseTransactionsFileQueryHookResult = ReturnType<
    typeof useGeneratePurchaseTransactionsFileQuery
>;
export type GeneratePurchaseTransactionsFileLazyQueryHookResult = ReturnType<
    typeof useGeneratePurchaseTransactionsFileLazyQuery
>;
export type GeneratePurchaseTransactionsFileQueryResult = Apollo.QueryResult<
    GeneratePurchaseTransactionsFileQuery,
    GeneratePurchaseTransactionsFileQueryVariables
>;
export const GeneratePurchaseTransactionDetailFileDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'generatePurchaseTransactionDetailFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DownloadFileType' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'generatePurchaseTransactionDetailFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'downloadFileType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGeneratePurchaseTransactionDetailFileQuery__
 *
 * To run a query within a React component, call `useGeneratePurchaseTransactionDetailFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePurchaseTransactionDetailFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePurchaseTransactionDetailFileQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      downloadFileType: // value for 'downloadFileType'
 *   },
 * });
 */
export function useGeneratePurchaseTransactionDetailFileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GeneratePurchaseTransactionDetailFileQuery,
        GeneratePurchaseTransactionDetailFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<
        GeneratePurchaseTransactionDetailFileQuery,
        GeneratePurchaseTransactionDetailFileQueryVariables
    >(GeneratePurchaseTransactionDetailFileDocument, options);
}
export function useGeneratePurchaseTransactionDetailFileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GeneratePurchaseTransactionDetailFileQuery,
        GeneratePurchaseTransactionDetailFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<
        GeneratePurchaseTransactionDetailFileQuery,
        GeneratePurchaseTransactionDetailFileQueryVariables
    >(GeneratePurchaseTransactionDetailFileDocument, options);
}
export type GeneratePurchaseTransactionDetailFileQueryHookResult = ReturnType<
    typeof useGeneratePurchaseTransactionDetailFileQuery
>;
export type GeneratePurchaseTransactionDetailFileLazyQueryHookResult = ReturnType<
    typeof useGeneratePurchaseTransactionDetailFileLazyQuery
>;
export type GeneratePurchaseTransactionDetailFileQueryResult = Apollo.QueryResult<
    GeneratePurchaseTransactionDetailFileQuery,
    GeneratePurchaseTransactionDetailFileQueryVariables
>;
export const UpdatePurchaseTransactionForApprovalRequestDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePurchaseTransactionForApprovalRequest' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionForApprovalRequestFields' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updatePurchaseTransactionForApprovalRequest' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerIdType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'modelInterested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mobilePhone' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purchaseAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleAgreementNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isOneMotoringData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuatedValuations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'valuatedOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicleDiagramComments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetSetOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandbook' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriodIndentOrder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vpaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateTransactions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PurchaseTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValuationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseStageUpdateActivity' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdatePurchaseTransactionForApprovalRequestMutationFn = Apollo.MutationFunction<
    UpdatePurchaseTransactionForApprovalRequestMutation,
    UpdatePurchaseTransactionForApprovalRequestMutationVariables
>;

/**
 * __useUpdatePurchaseTransactionForApprovalRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseTransactionForApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseTransactionForApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseTransactionForApprovalRequestMutation, { data, loading, error }] = useUpdatePurchaseTransactionForApprovalRequestMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdatePurchaseTransactionForApprovalRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseTransactionForApprovalRequestMutation,
        UpdatePurchaseTransactionForApprovalRequestMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdatePurchaseTransactionForApprovalRequestMutation,
        UpdatePurchaseTransactionForApprovalRequestMutationVariables
    >(UpdatePurchaseTransactionForApprovalRequestDocument, options);
}
export type UpdatePurchaseTransactionForApprovalRequestMutationHookResult = ReturnType<
    typeof useUpdatePurchaseTransactionForApprovalRequestMutation
>;
export type UpdatePurchaseTransactionForApprovalRequestMutationResult =
    Apollo.MutationResult<UpdatePurchaseTransactionForApprovalRequestMutation>;
export type UpdatePurchaseTransactionForApprovalRequestMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseTransactionForApprovalRequestMutation,
    UpdatePurchaseTransactionForApprovalRequestMutationVariables
>;
export const UpdatePurchaseTransactionForAcceptanceDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePurchaseTransactionForAcceptance' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PurchaseTransactionForAcceptanceFields' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updatePurchaseTransactionForAcceptance' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdatePurchaseTransactionForAcceptanceMutationFn = Apollo.MutationFunction<
    UpdatePurchaseTransactionForAcceptanceMutation,
    UpdatePurchaseTransactionForAcceptanceMutationVariables
>;

/**
 * __useUpdatePurchaseTransactionForAcceptanceMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseTransactionForAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseTransactionForAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseTransactionForAcceptanceMutation, { data, loading, error }] = useUpdatePurchaseTransactionForAcceptanceMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdatePurchaseTransactionForAcceptanceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseTransactionForAcceptanceMutation,
        UpdatePurchaseTransactionForAcceptanceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdatePurchaseTransactionForAcceptanceMutation,
        UpdatePurchaseTransactionForAcceptanceMutationVariables
    >(UpdatePurchaseTransactionForAcceptanceDocument, options);
}
export type UpdatePurchaseTransactionForAcceptanceMutationHookResult = ReturnType<
    typeof useUpdatePurchaseTransactionForAcceptanceMutation
>;
export type UpdatePurchaseTransactionForAcceptanceMutationResult =
    Apollo.MutationResult<UpdatePurchaseTransactionForAcceptanceMutation>;
export type UpdatePurchaseTransactionForAcceptanceMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseTransactionForAcceptanceMutation,
    UpdatePurchaseTransactionForAcceptanceMutationVariables
>;
export const UpdatePurchaseTransactionForInitValuationDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePurchaseTransactionForInitValuation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitValuationFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'updatePurchaseTransactionForInitValuation' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'purchaseTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'purchaseTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdatePurchaseTransactionForInitValuationMutationFn = Apollo.MutationFunction<
    UpdatePurchaseTransactionForInitValuationMutation,
    UpdatePurchaseTransactionForInitValuationMutationVariables
>;

/**
 * __useUpdatePurchaseTransactionForInitValuationMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseTransactionForInitValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseTransactionForInitValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseTransactionForInitValuationMutation, { data, loading, error }] = useUpdatePurchaseTransactionForInitValuationMutation({
 *   variables: {
 *      purchaseTransactionId: // value for 'purchaseTransactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdatePurchaseTransactionForInitValuationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePurchaseTransactionForInitValuationMutation,
        UpdatePurchaseTransactionForInitValuationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdatePurchaseTransactionForInitValuationMutation,
        UpdatePurchaseTransactionForInitValuationMutationVariables
    >(UpdatePurchaseTransactionForInitValuationDocument, options);
}
export type UpdatePurchaseTransactionForInitValuationMutationHookResult = ReturnType<
    typeof useUpdatePurchaseTransactionForInitValuationMutation
>;
export type UpdatePurchaseTransactionForInitValuationMutationResult =
    Apollo.MutationResult<UpdatePurchaseTransactionForInitValuationMutation>;
export type UpdatePurchaseTransactionForInitValuationMutationOptions = Apollo.BaseMutationOptions<
    UpdatePurchaseTransactionForInitValuationMutation,
    UpdatePurchaseTransactionForInitValuationMutationVariables
>;
export const TriggerPendingAcceptanceNotificationDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'triggerPendingAcceptanceNotification' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'purchaseTransaction' },
                        name: { kind: 'Name', value: 'triggerPendingAcceptanceNotification' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type TriggerPendingAcceptanceNotificationMutationFn = Apollo.MutationFunction<
    TriggerPendingAcceptanceNotificationMutation,
    TriggerPendingAcceptanceNotificationMutationVariables
>;

/**
 * __useTriggerPendingAcceptanceNotificationMutation__
 *
 * To run a mutation, you first call `useTriggerPendingAcceptanceNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPendingAcceptanceNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPendingAcceptanceNotificationMutation, { data, loading, error }] = useTriggerPendingAcceptanceNotificationMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useTriggerPendingAcceptanceNotificationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TriggerPendingAcceptanceNotificationMutation,
        TriggerPendingAcceptanceNotificationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        TriggerPendingAcceptanceNotificationMutation,
        TriggerPendingAcceptanceNotificationMutationVariables
    >(TriggerPendingAcceptanceNotificationDocument, options);
}
export type TriggerPendingAcceptanceNotificationMutationHookResult = ReturnType<
    typeof useTriggerPendingAcceptanceNotificationMutation
>;
export type TriggerPendingAcceptanceNotificationMutationResult =
    Apollo.MutationResult<TriggerPendingAcceptanceNotificationMutation>;
export type TriggerPendingAcceptanceNotificationMutationOptions = Apollo.BaseMutationOptions<
    TriggerPendingAcceptanceNotificationMutation,
    TriggerPendingAcceptanceNotificationMutationVariables
>;
export const RequestResetPasswordDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'requestResetPassword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendOtpByEmail' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestResetPassword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sendOtpByEmail' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendOtpByEmail' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      mobilePhone: // value for 'mobilePhone'
 *      sendOtpByEmail: // value for 'sendOtpByEmail'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(
        RequestResetPasswordDocument,
        options
    );
}
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
>;
export const ConfirmResetPasswordDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'confirmResetPassword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmResetPassword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'code' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ConfirmResetPasswordMutationFn = Apollo.MutationFunction<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
>;

/**
 * __useConfirmResetPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmResetPasswordMutation, { data, loading, error }] = useConfirmResetPasswordMutation({
 *   variables: {
 *      mobilePhone: // value for 'mobilePhone'
 *      token: // value for 'token'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>(
        ConfirmResetPasswordDocument,
        options
    );
}
export type ConfirmResetPasswordMutationHookResult = ReturnType<typeof useConfirmResetPasswordMutation>;
export type ConfirmResetPasswordMutationResult = Apollo.MutationResult<ConfirmResetPasswordMutation>;
export type ConfirmResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
>;
export const ConsumeResetPasswordDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'consumeResetPassword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'consumeResetPassword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'password' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ConsumeResetPasswordMutationFn = Apollo.MutationFunction<
    ConsumeResetPasswordMutation,
    ConsumeResetPasswordMutationVariables
>;

/**
 * __useConsumeResetPasswordMutation__
 *
 * To run a mutation, you first call `useConsumeResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeResetPasswordMutation, { data, loading, error }] = useConsumeResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConsumeResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ConsumeResetPasswordMutation, ConsumeResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ConsumeResetPasswordMutation, ConsumeResetPasswordMutationVariables>(
        ConsumeResetPasswordDocument,
        options
    );
}
export type ConsumeResetPasswordMutationHookResult = ReturnType<typeof useConsumeResetPasswordMutation>;
export type ConsumeResetPasswordMutationResult = Apollo.MutationResult<ConsumeResetPasswordMutation>;
export type ConsumeResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ConsumeResetPasswordMutation,
    ConsumeResetPasswordMutationVariables
>;
export const GetSaleTransactionsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSaleTransactions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransactionFilterField' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortSaleTransactionField' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransactions' },
                        name: { kind: 'Name', value: 'listSaleTransactions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sort' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetSaleTransactionsQuery__
 *
 * To run a query within a React component, call `useGetSaleTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetSaleTransactionsQuery(
    baseOptions: Apollo.QueryHookOptions<GetSaleTransactionsQuery, GetSaleTransactionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetSaleTransactionsQuery, GetSaleTransactionsQueryVariables>(
        GetSaleTransactionsDocument,
        options
    );
}
export function useGetSaleTransactionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSaleTransactionsQuery, GetSaleTransactionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetSaleTransactionsQuery, GetSaleTransactionsQueryVariables>(
        GetSaleTransactionsDocument,
        options
    );
}
export type GetSaleTransactionsQueryHookResult = ReturnType<typeof useGetSaleTransactionsQuery>;
export type GetSaleTransactionsLazyQueryHookResult = ReturnType<typeof useGetSaleTransactionsLazyQuery>;
export type GetSaleTransactionsQueryResult = Apollo.QueryResult<
    GetSaleTransactionsQuery,
    GetSaleTransactionsQueryVariables
>;
export const GetSaleTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSaleTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'getSaleTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetSaleTransactionQuery__
 *
 * To run a query within a React component, call `useGetSaleTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetSaleTransactionQuery(
    baseOptions: Apollo.QueryHookOptions<GetSaleTransactionQuery, GetSaleTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetSaleTransactionQuery, GetSaleTransactionQueryVariables>(
        GetSaleTransactionDocument,
        options
    );
}
export function useGetSaleTransactionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSaleTransactionQuery, GetSaleTransactionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetSaleTransactionQuery, GetSaleTransactionQueryVariables>(
        GetSaleTransactionDocument,
        options
    );
}
export type GetSaleTransactionQueryHookResult = ReturnType<typeof useGetSaleTransactionQuery>;
export type GetSaleTransactionLazyQueryHookResult = ReturnType<typeof useGetSaleTransactionLazyQuery>;
export type GetSaleTransactionQueryResult = Apollo.QueryResult<
    GetSaleTransactionQuery,
    GetSaleTransactionQueryVariables
>;
export const CreateSaleTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createSaleTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewSaleTransactionFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createSaleTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CreateSaleTransactionMutationFn = Apollo.MutationFunction<
    CreateSaleTransactionMutation,
    CreateSaleTransactionMutationVariables
>;

/**
 * __useCreateSaleTransactionMutation__
 *
 * To run a mutation, you first call `useCreateSaleTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleTransactionMutation, { data, loading, error }] = useCreateSaleTransactionMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateSaleTransactionMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateSaleTransactionMutation, CreateSaleTransactionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CreateSaleTransactionMutation, CreateSaleTransactionMutationVariables>(
        CreateSaleTransactionDocument,
        options
    );
}
export type CreateSaleTransactionMutationHookResult = ReturnType<typeof useCreateSaleTransactionMutation>;
export type CreateSaleTransactionMutationResult = Apollo.MutationResult<CreateSaleTransactionMutation>;
export type CreateSaleTransactionMutationOptions = Apollo.BaseMutationOptions<
    CreateSaleTransactionMutation,
    CreateSaleTransactionMutationVariables
>;
export const UpdateSaleTransactionDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransaction' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewSaleTransactionFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionMutation,
    UpdateSaleTransactionMutationVariables
>;

/**
 * __useUpdateSaleTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionMutation, { data, loading, error }] = useUpdateSaleTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateSaleTransactionMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateSaleTransactionMutation, UpdateSaleTransactionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateSaleTransactionMutation, UpdateSaleTransactionMutationVariables>(
        UpdateSaleTransactionDocument,
        options
    );
}
export type UpdateSaleTransactionMutationHookResult = ReturnType<typeof useUpdateSaleTransactionMutation>;
export type UpdateSaleTransactionMutationResult = Apollo.MutationResult<UpdateSaleTransactionMutation>;
export type UpdateSaleTransactionMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionMutation,
    UpdateSaleTransactionMutationVariables
>;
export const UpdateSaleTransactionToForHandoverDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransactionToForHandover' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransactionToForHandover' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionToForHandoverMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionToForHandoverMutation,
    UpdateSaleTransactionToForHandoverMutationVariables
>;

/**
 * __useUpdateSaleTransactionToForHandoverMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionToForHandoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionToForHandoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionToForHandoverMutation, { data, loading, error }] = useUpdateSaleTransactionToForHandoverMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateSaleTransactionToForHandoverMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSaleTransactionToForHandoverMutation,
        UpdateSaleTransactionToForHandoverMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateSaleTransactionToForHandoverMutation,
        UpdateSaleTransactionToForHandoverMutationVariables
    >(UpdateSaleTransactionToForHandoverDocument, options);
}
export type UpdateSaleTransactionToForHandoverMutationHookResult = ReturnType<
    typeof useUpdateSaleTransactionToForHandoverMutation
>;
export type UpdateSaleTransactionToForHandoverMutationResult =
    Apollo.MutationResult<UpdateSaleTransactionToForHandoverMutation>;
export type UpdateSaleTransactionToForHandoverMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionToForHandoverMutation,
    UpdateSaleTransactionToForHandoverMutationVariables
>;
export const UpdateSaleTransactionToCompletedDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransactionToCompleted' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransactionToCompleted' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionToCompletedMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionToCompletedMutation,
    UpdateSaleTransactionToCompletedMutationVariables
>;

/**
 * __useUpdateSaleTransactionToCompletedMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionToCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionToCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionToCompletedMutation, { data, loading, error }] = useUpdateSaleTransactionToCompletedMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateSaleTransactionToCompletedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSaleTransactionToCompletedMutation,
        UpdateSaleTransactionToCompletedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateSaleTransactionToCompletedMutation,
        UpdateSaleTransactionToCompletedMutationVariables
    >(UpdateSaleTransactionToCompletedDocument, options);
}
export type UpdateSaleTransactionToCompletedMutationHookResult = ReturnType<
    typeof useUpdateSaleTransactionToCompletedMutation
>;
export type UpdateSaleTransactionToCompletedMutationResult =
    Apollo.MutationResult<UpdateSaleTransactionToCompletedMutation>;
export type UpdateSaleTransactionToCompletedMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionToCompletedMutation,
    UpdateSaleTransactionToCompletedMutationVariables
>;
export const UpdateSaleTransactionToPendingApprovalDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransactionToPendingApproval' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'handoverLocation' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransactionToPendingApproval' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'handoverLocation' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'handoverLocation' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionToPendingApprovalMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionToPendingApprovalMutation,
    UpdateSaleTransactionToPendingApprovalMutationVariables
>;

/**
 * __useUpdateSaleTransactionToPendingApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionToPendingApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionToPendingApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionToPendingApprovalMutation, { data, loading, error }] = useUpdateSaleTransactionToPendingApprovalMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      handoverLocation: // value for 'handoverLocation'
 *   },
 * });
 */
export function useUpdateSaleTransactionToPendingApprovalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSaleTransactionToPendingApprovalMutation,
        UpdateSaleTransactionToPendingApprovalMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateSaleTransactionToPendingApprovalMutation,
        UpdateSaleTransactionToPendingApprovalMutationVariables
    >(UpdateSaleTransactionToPendingApprovalDocument, options);
}
export type UpdateSaleTransactionToPendingApprovalMutationHookResult = ReturnType<
    typeof useUpdateSaleTransactionToPendingApprovalMutation
>;
export type UpdateSaleTransactionToPendingApprovalMutationResult =
    Apollo.MutationResult<UpdateSaleTransactionToPendingApprovalMutation>;
export type UpdateSaleTransactionToPendingApprovalMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionToPendingApprovalMutation,
    UpdateSaleTransactionToPendingApprovalMutationVariables
>;
export const UpdateSaleTransactionToPendingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransactionToPending' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'stage' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransactionStage' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransactionToPending' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'stage' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'stage' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionToPendingMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionToPendingMutation,
    UpdateSaleTransactionToPendingMutationVariables
>;

/**
 * __useUpdateSaleTransactionToPendingMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionToPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionToPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionToPendingMutation, { data, loading, error }] = useUpdateSaleTransactionToPendingMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useUpdateSaleTransactionToPendingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSaleTransactionToPendingMutation,
        UpdateSaleTransactionToPendingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateSaleTransactionToPendingMutation, UpdateSaleTransactionToPendingMutationVariables>(
        UpdateSaleTransactionToPendingDocument,
        options
    );
}
export type UpdateSaleTransactionToPendingMutationHookResult = ReturnType<
    typeof useUpdateSaleTransactionToPendingMutation
>;
export type UpdateSaleTransactionToPendingMutationResult =
    Apollo.MutationResult<UpdateSaleTransactionToPendingMutation>;
export type UpdateSaleTransactionToPendingMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionToPendingMutation,
    UpdateSaleTransactionToPendingMutationVariables
>;
export const UpdateSaleTransactionToClosedDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSaleTransactionToClosed' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSaleTransactionToClosed' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BiddingEntryData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'dealerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'saleConsultantId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleConsultant' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latestValuatedValuation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'valuatedBy' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'NameOnlyUserData' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPeriod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assignPersonResponsibleAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'internalRemarks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'adminRemark' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PreviewPurchaseTransactionData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'frontPagePhotoSourceId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'beExported' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'intendedDeregistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'engineNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'chassisNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maximumPowerOutput' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'openMarketValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'transferCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualARFPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfEligibilityDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'parfRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeCategory' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coePeriodYear' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'qpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'coeRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pqpPaid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ltaMessage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'importMethod' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'totalRebateAmount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'setOfKeys' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ownerHandbookCollected' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'generalCondition' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealerInformations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'saleTransactionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remainDealerBiddingEntryCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isInTop' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topBiddingEntries' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'BiddingEntryData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handoverLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actualHandoverDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'vehicleSalesAgreementNo' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'remarks' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personResponsible' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesDealer' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manualSalesPrice' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionActivityData' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'originalSalesTransactionId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSalesTransaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionActivityData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Activity' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreationActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BiddingActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleStageUpdateActivity' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSaleTransactionToClosedMutationFn = Apollo.MutationFunction<
    UpdateSaleTransactionToClosedMutation,
    UpdateSaleTransactionToClosedMutationVariables
>;

/**
 * __useUpdateSaleTransactionToClosedMutation__
 *
 * To run a mutation, you first call `useUpdateSaleTransactionToClosedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleTransactionToClosedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleTransactionToClosedMutation, { data, loading, error }] = useUpdateSaleTransactionToClosedMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUpdateSaleTransactionToClosedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSaleTransactionToClosedMutation,
        UpdateSaleTransactionToClosedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateSaleTransactionToClosedMutation, UpdateSaleTransactionToClosedMutationVariables>(
        UpdateSaleTransactionToClosedDocument,
        options
    );
}
export type UpdateSaleTransactionToClosedMutationHookResult = ReturnType<
    typeof useUpdateSaleTransactionToClosedMutation
>;
export type UpdateSaleTransactionToClosedMutationResult = Apollo.MutationResult<UpdateSaleTransactionToClosedMutation>;
export type UpdateSaleTransactionToClosedMutationOptions = Apollo.BaseMutationOptions<
    UpdateSaleTransactionToClosedMutation,
    UpdateSaleTransactionToClosedMutationVariables
>;
export const UpdateSalesTransactionFrontPagePhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSalesTransactionFrontPagePhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'frontPagePhotoId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'updateSalesTransactionFrontPagePhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'frontPagePhotoId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'frontPagePhotoId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSalesTransactionFrontPagePhotoMutationFn = Apollo.MutationFunction<
    UpdateSalesTransactionFrontPagePhotoMutation,
    UpdateSalesTransactionFrontPagePhotoMutationVariables
>;

/**
 * __useUpdateSalesTransactionFrontPagePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTransactionFrontPagePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTransactionFrontPagePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTransactionFrontPagePhotoMutation, { data, loading, error }] = useUpdateSalesTransactionFrontPagePhotoMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      frontPagePhotoId: // value for 'frontPagePhotoId'
 *   },
 * });
 */
export function useUpdateSalesTransactionFrontPagePhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSalesTransactionFrontPagePhotoMutation,
        UpdateSalesTransactionFrontPagePhotoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateSalesTransactionFrontPagePhotoMutation,
        UpdateSalesTransactionFrontPagePhotoMutationVariables
    >(UpdateSalesTransactionFrontPagePhotoDocument, options);
}
export type UpdateSalesTransactionFrontPagePhotoMutationHookResult = ReturnType<
    typeof useUpdateSalesTransactionFrontPagePhotoMutation
>;
export type UpdateSalesTransactionFrontPagePhotoMutationResult =
    Apollo.MutationResult<UpdateSalesTransactionFrontPagePhotoMutation>;
export type UpdateSalesTransactionFrontPagePhotoMutationOptions = Apollo.BaseMutationOptions<
    UpdateSalesTransactionFrontPagePhotoMutation,
    UpdateSalesTransactionFrontPagePhotoMutationVariables
>;
export const UploadSalesTransactionPhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadSalesTransactionPhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'isFrontPagePhoto' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'uploadSalesTransactionPhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'isFrontPagePhoto' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'isFrontPagePhoto' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SaleTransactionPhotosOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SaleTransactionPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadSalesTransactionPhotoMutationFn = Apollo.MutationFunction<
    UploadSalesTransactionPhotoMutation,
    UploadSalesTransactionPhotoMutationVariables
>;

/**
 * __useUploadSalesTransactionPhotoMutation__
 *
 * To run a mutation, you first call `useUploadSalesTransactionPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSalesTransactionPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSalesTransactionPhotoMutation, { data, loading, error }] = useUploadSalesTransactionPhotoMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      file: // value for 'file'
 *      isFrontPagePhoto: // value for 'isFrontPagePhoto'
 *   },
 * });
 */
export function useUploadSalesTransactionPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadSalesTransactionPhotoMutation,
        UploadSalesTransactionPhotoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadSalesTransactionPhotoMutation, UploadSalesTransactionPhotoMutationVariables>(
        UploadSalesTransactionPhotoDocument,
        options
    );
}
export type UploadSalesTransactionPhotoMutationHookResult = ReturnType<typeof useUploadSalesTransactionPhotoMutation>;
export type UploadSalesTransactionPhotoMutationResult = Apollo.MutationResult<UploadSalesTransactionPhotoMutation>;
export type UploadSalesTransactionPhotoMutationOptions = Apollo.BaseMutationOptions<
    UploadSalesTransactionPhotoMutation,
    UploadSalesTransactionPhotoMutationVariables
>;
export const UploadSalesTransactionHandoverAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadSalesTransactionHandoverAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'uploadSalesTransactionHandoverAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionHandoverAttachmentsOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionHandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadSalesTransactionHandoverAttachmentMutationFn = Apollo.MutationFunction<
    UploadSalesTransactionHandoverAttachmentMutation,
    UploadSalesTransactionHandoverAttachmentMutationVariables
>;

/**
 * __useUploadSalesTransactionHandoverAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadSalesTransactionHandoverAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSalesTransactionHandoverAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSalesTransactionHandoverAttachmentMutation, { data, loading, error }] = useUploadSalesTransactionHandoverAttachmentMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadSalesTransactionHandoverAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadSalesTransactionHandoverAttachmentMutation,
        UploadSalesTransactionHandoverAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UploadSalesTransactionHandoverAttachmentMutation,
        UploadSalesTransactionHandoverAttachmentMutationVariables
    >(UploadSalesTransactionHandoverAttachmentDocument, options);
}
export type UploadSalesTransactionHandoverAttachmentMutationHookResult = ReturnType<
    typeof useUploadSalesTransactionHandoverAttachmentMutation
>;
export type UploadSalesTransactionHandoverAttachmentMutationResult =
    Apollo.MutationResult<UploadSalesTransactionHandoverAttachmentMutation>;
export type UploadSalesTransactionHandoverAttachmentMutationOptions = Apollo.BaseMutationOptions<
    UploadSalesTransactionHandoverAttachmentMutation,
    UploadSalesTransactionHandoverAttachmentMutationVariables
>;
export const UploadVsaAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadVsaAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'uploadVsaAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VsaAttachmentOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VsaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadVsaAttachmentMutationFn = Apollo.MutationFunction<
    UploadVsaAttachmentMutation,
    UploadVsaAttachmentMutationVariables
>;

/**
 * __useUploadVsaAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadVsaAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVsaAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVsaAttachmentMutation, { data, loading, error }] = useUploadVsaAttachmentMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadVsaAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadVsaAttachmentMutation, UploadVsaAttachmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadVsaAttachmentMutation, UploadVsaAttachmentMutationVariables>(
        UploadVsaAttachmentDocument,
        options
    );
}
export type UploadVsaAttachmentMutationHookResult = ReturnType<typeof useUploadVsaAttachmentMutation>;
export type UploadVsaAttachmentMutationResult = Apollo.MutationResult<UploadVsaAttachmentMutation>;
export type UploadVsaAttachmentMutationOptions = Apollo.BaseMutationOptions<
    UploadVsaAttachmentMutation,
    UploadVsaAttachmentMutationVariables
>;
export const RemoveSalesTransactionHandoverAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeSalesTransactionHandoverAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'removeSalesTransactionHandoverAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SalesTransactionHandoverAttachmentsOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SalesTransactionHandoverAttachmentsOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveSalesTransactionHandoverAttachmentMutationFn = Apollo.MutationFunction<
    RemoveSalesTransactionHandoverAttachmentMutation,
    RemoveSalesTransactionHandoverAttachmentMutationVariables
>;

/**
 * __useRemoveSalesTransactionHandoverAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveSalesTransactionHandoverAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesTransactionHandoverAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesTransactionHandoverAttachmentMutation, { data, loading, error }] = useRemoveSalesTransactionHandoverAttachmentMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveSalesTransactionHandoverAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveSalesTransactionHandoverAttachmentMutation,
        RemoveSalesTransactionHandoverAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        RemoveSalesTransactionHandoverAttachmentMutation,
        RemoveSalesTransactionHandoverAttachmentMutationVariables
    >(RemoveSalesTransactionHandoverAttachmentDocument, options);
}
export type RemoveSalesTransactionHandoverAttachmentMutationHookResult = ReturnType<
    typeof useRemoveSalesTransactionHandoverAttachmentMutation
>;
export type RemoveSalesTransactionHandoverAttachmentMutationResult =
    Apollo.MutationResult<RemoveSalesTransactionHandoverAttachmentMutation>;
export type RemoveSalesTransactionHandoverAttachmentMutationOptions = Apollo.BaseMutationOptions<
    RemoveSalesTransactionHandoverAttachmentMutation,
    RemoveSalesTransactionHandoverAttachmentMutationVariables
>;
export const RemoveVsaAttachmentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeVsaAttachment' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'removeVsaAttachment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'VsaAttachmentOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'VsaAttachmentOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vsaAttachment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyFileData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveVsaAttachmentMutationFn = Apollo.MutationFunction<
    RemoveVsaAttachmentMutation,
    RemoveVsaAttachmentMutationVariables
>;

/**
 * __useRemoveVsaAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveVsaAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVsaAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVsaAttachmentMutation, { data, loading, error }] = useRemoveVsaAttachmentMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveVsaAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveVsaAttachmentMutation, RemoveVsaAttachmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveVsaAttachmentMutation, RemoveVsaAttachmentMutationVariables>(
        RemoveVsaAttachmentDocument,
        options
    );
}
export type RemoveVsaAttachmentMutationHookResult = ReturnType<typeof useRemoveVsaAttachmentMutation>;
export type RemoveVsaAttachmentMutationResult = Apollo.MutationResult<RemoveVsaAttachmentMutation>;
export type RemoveVsaAttachmentMutationOptions = Apollo.BaseMutationOptions<
    RemoveVsaAttachmentMutation,
    RemoveVsaAttachmentMutationVariables
>;
export const RemoveSalesTransactionPhotoDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeSalesTransactionPhoto' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'saleTransaction' },
                        name: { kind: 'Name', value: 'removeSalesTransactionPhoto' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadedFileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadedFileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SaleTransactionPhotosOnlyData' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SaleTransactionPhotosOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type RemoveSalesTransactionPhotoMutationFn = Apollo.MutationFunction<
    RemoveSalesTransactionPhotoMutation,
    RemoveSalesTransactionPhotoMutationVariables
>;

/**
 * __useRemoveSalesTransactionPhotoMutation__
 *
 * To run a mutation, you first call `useRemoveSalesTransactionPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesTransactionPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesTransactionPhotoMutation, { data, loading, error }] = useRemoveSalesTransactionPhotoMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      uploadedFileId: // value for 'uploadedFileId'
 *   },
 * });
 */
export function useRemoveSalesTransactionPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveSalesTransactionPhotoMutation,
        RemoveSalesTransactionPhotoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RemoveSalesTransactionPhotoMutation, RemoveSalesTransactionPhotoMutationVariables>(
        RemoveSalesTransactionPhotoDocument,
        options
    );
}
export type RemoveSalesTransactionPhotoMutationHookResult = ReturnType<typeof useRemoveSalesTransactionPhotoMutation>;
export type RemoveSalesTransactionPhotoMutationResult = Apollo.MutationResult<RemoveSalesTransactionPhotoMutation>;
export type RemoveSalesTransactionPhotoMutationOptions = Apollo.BaseMutationOptions<
    RemoveSalesTransactionPhotoMutation,
    RemoveSalesTransactionPhotoMutationVariables
>;
export const GenerateSalesTransactionsFileDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'generateSalesTransactionsFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DownloadFileType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransactionFilterField' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortSaleTransactionField' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'generateSalesTransactionsFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'downloadFileType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sort' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGenerateSalesTransactionsFileQuery__
 *
 * To run a query within a React component, call `useGenerateSalesTransactionsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSalesTransactionsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSalesTransactionsFileQuery({
 *   variables: {
 *      downloadFileType: // value for 'downloadFileType'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGenerateSalesTransactionsFileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GenerateSalesTransactionsFileQuery,
        GenerateSalesTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GenerateSalesTransactionsFileQuery, GenerateSalesTransactionsFileQueryVariables>(
        GenerateSalesTransactionsFileDocument,
        options
    );
}
export function useGenerateSalesTransactionsFileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GenerateSalesTransactionsFileQuery,
        GenerateSalesTransactionsFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GenerateSalesTransactionsFileQuery, GenerateSalesTransactionsFileQueryVariables>(
        GenerateSalesTransactionsFileDocument,
        options
    );
}
export type GenerateSalesTransactionsFileQueryHookResult = ReturnType<typeof useGenerateSalesTransactionsFileQuery>;
export type GenerateSalesTransactionsFileLazyQueryHookResult = ReturnType<
    typeof useGenerateSalesTransactionsFileLazyQuery
>;
export type GenerateSalesTransactionsFileQueryResult = Apollo.QueryResult<
    GenerateSalesTransactionsFileQuery,
    GenerateSalesTransactionsFileQueryVariables
>;
export const GenerateSalesTransactionDetailFileDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'generateSalesTransactionDetailFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'DownloadFileType' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'signedUrl' },
                        name: { kind: 'Name', value: 'generateSalesTransactionDetailFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'transactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'downloadFileType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'downloadFileType' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGenerateSalesTransactionDetailFileQuery__
 *
 * To run a query within a React component, call `useGenerateSalesTransactionDetailFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSalesTransactionDetailFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSalesTransactionDetailFileQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      downloadFileType: // value for 'downloadFileType'
 *   },
 * });
 */
export function useGenerateSalesTransactionDetailFileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GenerateSalesTransactionDetailFileQuery,
        GenerateSalesTransactionDetailFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GenerateSalesTransactionDetailFileQuery, GenerateSalesTransactionDetailFileQueryVariables>(
        GenerateSalesTransactionDetailFileDocument,
        options
    );
}
export function useGenerateSalesTransactionDetailFileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GenerateSalesTransactionDetailFileQuery,
        GenerateSalesTransactionDetailFileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<
        GenerateSalesTransactionDetailFileQuery,
        GenerateSalesTransactionDetailFileQueryVariables
    >(GenerateSalesTransactionDetailFileDocument, options);
}
export type GenerateSalesTransactionDetailFileQueryHookResult = ReturnType<
    typeof useGenerateSalesTransactionDetailFileQuery
>;
export type GenerateSalesTransactionDetailFileLazyQueryHookResult = ReturnType<
    typeof useGenerateSalesTransactionDetailFileLazyQuery
>;
export type GenerateSalesTransactionDetailFileQueryResult = Apollo.QueryResult<
    GenerateSalesTransactionDetailFileQuery,
    GenerateSalesTransactionDetailFileQueryVariables
>;
export const UpdateSalesTransactionForAwardingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSalesTransactionForAwarding' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesTransactionForAwardingFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'salesTransaction' },
                        name: { kind: 'Name', value: 'updateSalesTransactionForAwarding' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateSalesTransactionForAwardingMutationFn = Apollo.MutationFunction<
    UpdateSalesTransactionForAwardingMutation,
    UpdateSalesTransactionForAwardingMutationVariables
>;

/**
 * __useUpdateSalesTransactionForAwardingMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTransactionForAwardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTransactionForAwardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTransactionForAwardingMutation, { data, loading, error }] = useUpdateSalesTransactionForAwardingMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateSalesTransactionForAwardingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSalesTransactionForAwardingMutation,
        UpdateSalesTransactionForAwardingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<
        UpdateSalesTransactionForAwardingMutation,
        UpdateSalesTransactionForAwardingMutationVariables
    >(UpdateSalesTransactionForAwardingDocument, options);
}
export type UpdateSalesTransactionForAwardingMutationHookResult = ReturnType<
    typeof useUpdateSalesTransactionForAwardingMutation
>;
export type UpdateSalesTransactionForAwardingMutationResult =
    Apollo.MutationResult<UpdateSalesTransactionForAwardingMutation>;
export type UpdateSalesTransactionForAwardingMutationOptions = Apollo.BaseMutationOptions<
    UpdateSalesTransactionForAwardingMutation,
    UpdateSalesTransactionForAwardingMutationVariables
>;
export const CloneSaleTransactionCreateNewDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cloneSaleTransactionCreateNew' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cloneSaleTransactionCreateNew' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'salesTransactionId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'salesTransactionId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewSaleTransactionData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewBiddingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bidding' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'sessionName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endPeriod' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewSaleTransactionData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleTransaction' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPagePhoto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handover' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'targetHandoverDateTime' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originalRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstRegistrationDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibility' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateEligibilityExpiryDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'opcCashRebateAmount' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'awardedBiddingEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placedOn' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dealer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'NameOnlyUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyUserData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PreviewBiddingData' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestBiddingSessionEntry' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUserBid' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cloned' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CloneSaleTransactionCreateNewMutationFn = Apollo.MutationFunction<
    CloneSaleTransactionCreateNewMutation,
    CloneSaleTransactionCreateNewMutationVariables
>;

/**
 * __useCloneSaleTransactionCreateNewMutation__
 *
 * To run a mutation, you first call `useCloneSaleTransactionCreateNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSaleTransactionCreateNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSaleTransactionCreateNewMutation, { data, loading, error }] = useCloneSaleTransactionCreateNewMutation({
 *   variables: {
 *      salesTransactionId: // value for 'salesTransactionId'
 *   },
 * });
 */
export function useCloneSaleTransactionCreateNewMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CloneSaleTransactionCreateNewMutation,
        CloneSaleTransactionCreateNewMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CloneSaleTransactionCreateNewMutation, CloneSaleTransactionCreateNewMutationVariables>(
        CloneSaleTransactionCreateNewDocument,
        options
    );
}
export type CloneSaleTransactionCreateNewMutationHookResult = ReturnType<
    typeof useCloneSaleTransactionCreateNewMutation
>;
export type CloneSaleTransactionCreateNewMutationResult = Apollo.MutationResult<CloneSaleTransactionCreateNewMutation>;
export type CloneSaleTransactionCreateNewMutationOptions = Apollo.BaseMutationOptions<
    CloneSaleTransactionCreateNewMutation,
    CloneSaleTransactionCreateNewMutationVariables
>;
export const SignUpDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'signUp' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRegistrationFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerNewAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const ConfirmSignUpDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'confirmSignUp' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmNewAccountRegistration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'code' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type ConfirmSignUpMutationFn = Apollo.MutationFunction<ConfirmSignUpMutation, ConfirmSignUpMutationVariables>;

/**
 * __useConfirmSignUpMutation__
 *
 * To run a mutation, you first call `useConfirmSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSignUpMutation, { data, loading, error }] = useConfirmSignUpMutation({
 *   variables: {
 *      mobilePhone: // value for 'mobilePhone'
 *      token: // value for 'token'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmSignUpMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmSignUpMutation, ConfirmSignUpMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<ConfirmSignUpMutation, ConfirmSignUpMutationVariables>(ConfirmSignUpDocument, options);
}
export type ConfirmSignUpMutationHookResult = ReturnType<typeof useConfirmSignUpMutation>;
export type ConfirmSignUpMutationResult = Apollo.MutationResult<ConfirmSignUpMutation>;
export type ConfirmSignUpMutationOptions = Apollo.BaseMutationOptions<
    ConfirmSignUpMutation,
    ConfirmSignUpMutationVariables
>;
export const GetSelfDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSelf' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getSelf' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullUserData' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deviceSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DeviceSettingData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DeviceSettingData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceSetting' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deviceFingerprint' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabledOn' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'testAccount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastActive' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
}
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
}
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export const UpdateUserDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountUpdateFields' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateActivityInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activityOption' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityOption' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'testAccount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastActive' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *      activityOption: // value for 'activityOption'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'user' },
                        name: { kind: 'Name', value: 'getUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullUserData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'testAccount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastActive' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserListByTypeDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getUserListByType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserType' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'users' },
                        name: { kind: 'Name', value: 'listUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activationStage' },
                                value: { kind: 'EnumValue', value: 'Active' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: { kind: 'IntValue', value: '99999' },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: { kind: 'IntValue', value: '0' },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetUserListByTypeQuery__
 *
 * To run a query within a React component, call `useGetUserListByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserListByTypeQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUserListByTypeQuery, GetUserListByTypeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetUserListByTypeQuery, GetUserListByTypeQueryVariables>(GetUserListByTypeDocument, options);
}
export function useGetUserListByTypeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserListByTypeQuery, GetUserListByTypeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetUserListByTypeQuery, GetUserListByTypeQueryVariables>(
        GetUserListByTypeDocument,
        options
    );
}
export type GetUserListByTypeQueryHookResult = ReturnType<typeof useGetUserListByTypeQuery>;
export type GetUserListByTypeLazyQueryHookResult = ReturnType<typeof useGetUserListByTypeLazyQuery>;
export type GetUserListByTypeQueryResult = Apollo.QueryResult<GetUserListByTypeQuery, GetUserListByTypeQueryVariables>;
export const GetAwardedDealersDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAwardedDealers' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'users' },
                        name: { kind: 'Name', value: 'listUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activationStage' },
                                value: { kind: 'EnumValue', value: 'Active' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'limit' },
                                            value: { kind: 'IntValue', value: '99999' },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'offset' },
                                            value: { kind: 'IntValue', value: '0' },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'onlyAwardedDealer' },
                                value: { kind: 'BooleanValue', value: true },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PreviewUserData' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PreviewUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useGetAwardedDealersQuery__
 *
 * To run a query within a React component, call `useGetAwardedDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardedDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardedDealersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAwardedDealersQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAwardedDealersQuery, GetAwardedDealersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<GetAwardedDealersQuery, GetAwardedDealersQueryVariables>(GetAwardedDealersDocument, options);
}
export function useGetAwardedDealersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAwardedDealersQuery, GetAwardedDealersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<GetAwardedDealersQuery, GetAwardedDealersQueryVariables>(
        GetAwardedDealersDocument,
        options
    );
}
export type GetAwardedDealersQueryHookResult = ReturnType<typeof useGetAwardedDealersQuery>;
export type GetAwardedDealersLazyQueryHookResult = ReturnType<typeof useGetAwardedDealersLazyQuery>;
export type GetAwardedDealersQueryResult = Apollo.QueryResult<GetAwardedDealersQuery, GetAwardedDealersQueryVariables>;
export const ListUsersDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listUsers' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Page' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'activationStage' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivationStage' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserType' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'users' },
                        name: { kind: 'Name', value: 'listUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'page' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'search' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'activationStage' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'activationStage' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullUserData' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FullUserData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'activationStage' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'testAccount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastActive' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            ],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SaleConsultantUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'brand' } }],
                        },
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealerUser' } },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'business' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'registrationNumber' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      activationStage: // value for 'activationStage'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const UploadProfilePictureDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadProfilePicture' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'user' },
                        name: { kind: 'Name', value: 'uploadProfilePicture' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfilePictureOnlyData' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NameOnlyFileData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadedFile' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProfilePictureOnlyData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameOnlyFileData' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadProfilePictureMutationFn = Apollo.MutationFunction<
    UploadProfilePictureMutation,
    UploadProfilePictureMutationVariables
>;

/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePictureMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(
        UploadProfilePictureDocument,
        options
    );
}
export type UploadProfilePictureMutationHookResult = ReturnType<typeof useUploadProfilePictureMutation>;
export type UploadProfilePictureMutationResult = Apollo.MutationResult<UploadProfilePictureMutation>;
export type UploadProfilePictureMutationOptions = Apollo.BaseMutationOptions<
    UploadProfilePictureMutation,
    UploadProfilePictureMutationVariables
>;
export const CheckDuplicateMobileNumberDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'checkDuplicateMobileNumber' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MobilePhoneInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'isDuplicated' },
                        name: { kind: 'Name', value: 'checkDuplicateMobileNumber' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mobilePhone' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mobilePhone' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCheckDuplicateMobileNumberQuery__
 *
 * To run a query within a React component, call `useCheckDuplicateMobileNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDuplicateMobileNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDuplicateMobileNumberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      mobilePhone: // value for 'mobilePhone'
 *   },
 * });
 */
export function useCheckDuplicateMobileNumberQuery(
    baseOptions: Apollo.QueryHookOptions<CheckDuplicateMobileNumberQuery, CheckDuplicateMobileNumberQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<CheckDuplicateMobileNumberQuery, CheckDuplicateMobileNumberQueryVariables>(
        CheckDuplicateMobileNumberDocument,
        options
    );
}
export function useCheckDuplicateMobileNumberLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckDuplicateMobileNumberQuery, CheckDuplicateMobileNumberQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<CheckDuplicateMobileNumberQuery, CheckDuplicateMobileNumberQueryVariables>(
        CheckDuplicateMobileNumberDocument,
        options
    );
}
export type CheckDuplicateMobileNumberQueryHookResult = ReturnType<typeof useCheckDuplicateMobileNumberQuery>;
export type CheckDuplicateMobileNumberLazyQueryHookResult = ReturnType<typeof useCheckDuplicateMobileNumberLazyQuery>;
export type CheckDuplicateMobileNumberQueryResult = Apollo.QueryResult<
    CheckDuplicateMobileNumberQuery,
    CheckDuplicateMobileNumberQueryVariables
>;
export type ActivityKeySpecifier = ('id' | 'date' | 'type' | ActivityKeySpecifier)[];
export type ActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminUserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | AdminUserKeySpecifier
)[];
export type AdminUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApproverUserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | ApproverUserKeySpecifier
)[];
export type ApproverUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AuthenticateResultKeySpecifier = ('token' | 'email' | AuthenticateResultKeySpecifier)[];
export type AuthenticateResultFieldPolicy = {
    token?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AwardedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'saleTransactions'
    | 'user'
    | 'createdAt'
    | 'channels'
    | AwardedNoticeNotificationKeySpecifier
)[];
export type AwardedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    saleTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiddingKeySpecifier = (
    | 'id'
    | 'sessionName'
    | 'notes'
    | 'startPeriod'
    | 'endPeriod'
    | 'status'
    | 'biddingSaleTransactions'
    | 'dealerIds'
    | BiddingKeySpecifier
)[];
export type BiddingFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    sessionName?: FieldPolicy<any> | FieldReadFunction<any>;
    notes?: FieldPolicy<any> | FieldReadFunction<any>;
    startPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
    endPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
    status?: FieldPolicy<any> | FieldReadFunction<any>;
    biddingSaleTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    dealerIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiddingActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'biddingValue'
    | 'stage'
    | 'diff'
    | BiddingActivityKeySpecifier
)[];
export type BiddingActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    biddingValue?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
    diff?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiddingEntryKeySpecifier = (
    | 'id'
    | 'amount'
    | 'placedOn'
    | 'dealerId'
    | 'dealer'
    | BiddingEntryKeySpecifier
)[];
export type BiddingEntryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    amount?: FieldPolicy<any> | FieldReadFunction<any>;
    placedOn?: FieldPolicy<any> | FieldReadFunction<any>;
    dealerId?: FieldPolicy<any> | FieldReadFunction<any>;
    dealer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiddingPageKeySpecifier = ('items' | 'count' | BiddingPageKeySpecifier)[];
export type BiddingPageFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiddingSaleTransactionKeySpecifier = (
    | 'saleTransactionId'
    | 'saleTransaction'
    | 'remainDealerBiddingEntryCount'
    | 'biddingEntries'
    | 'topBiddingEntries'
    | 'awardedBiddingEntry'
    | 'lastUserBid'
    | 'isInTop'
    | 'closed'
    | 'closedAt'
    | BiddingSaleTransactionKeySpecifier
)[];
export type BiddingSaleTransactionFieldPolicy = {
    saleTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
    saleTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    remainDealerBiddingEntryCount?: FieldPolicy<any> | FieldReadFunction<any>;
    biddingEntries?: FieldPolicy<any> | FieldReadFunction<any>;
    topBiddingEntries?: FieldPolicy<any> | FieldReadFunction<any>;
    awardedBiddingEntry?: FieldPolicy<any> | FieldReadFunction<any>;
    lastUserBid?: FieldPolicy<any> | FieldReadFunction<any>;
    isInTop?: FieldPolicy<any> | FieldReadFunction<any>;
    closed?: FieldPolicy<any> | FieldReadFunction<any>;
    closedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BiometricDeviceSettingKeySpecifier = (
    | 'type'
    | 'deviceFingerprint'
    | 'enabledOn'
    | BiometricDeviceSettingKeySpecifier
)[];
export type BiometricDeviceSettingFieldPolicy = {
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceFingerprint?: FieldPolicy<any> | FieldReadFunction<any>;
    enabledOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreationActivityKeySpecifier = ('id' | 'date' | 'type' | 'author' | CreationActivityKeySpecifier)[];
export type CreationActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CustomerKeySpecifier = (
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'ownerIdType'
    | 'ownerId'
    | 'ownerName'
    | 'modelInterested'
    | CustomerKeySpecifier
)[];
export type CustomerFieldPolicy = {
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerIdType?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerId?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerName?: FieldPolicy<any> | FieldReadFunction<any>;
    modelInterested?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealerBusinessKeySpecifier = ('name' | 'address' | 'registrationNumber' | DealerBusinessKeySpecifier)[];
export type DealerBusinessFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    address?: FieldPolicy<any> | FieldReadFunction<any>;
    registrationNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealerInformationKeySpecifier = ('name' | 'price' | DealerInformationKeySpecifier)[];
export type DealerInformationFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DealerUserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'business'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | DealerUserKeySpecifier
)[];
export type DealerUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    business?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeviceSettingKeySpecifier = ('type' | 'deviceFingerprint' | 'enabledOn' | DeviceSettingKeySpecifier)[];
export type DeviceSettingFieldPolicy = {
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceFingerprint?: FieldPolicy<any> | FieldReadFunction<any>;
    enabledOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HandoverKeySpecifier = (
    | 'personResponsible'
    | 'targetHandoverDateTime'
    | 'handoverLocation'
    | 'actualHandoverDate'
    | 'remarks'
    | 'attachments'
    | 'vpaAttachment'
    | 'deliveryPeriod'
    | 'assignPersonResponsibleAt'
    | 'targetSetOfKeys'
    | 'targetHandbook'
    | 'deliveryPeriodIndentOrder'
    | HandoverKeySpecifier
)[];
export type HandoverFieldPolicy = {
    personResponsible?: FieldPolicy<any> | FieldReadFunction<any>;
    targetHandoverDateTime?: FieldPolicy<any> | FieldReadFunction<any>;
    handoverLocation?: FieldPolicy<any> | FieldReadFunction<any>;
    actualHandoverDate?: FieldPolicy<any> | FieldReadFunction<any>;
    remarks?: FieldPolicy<any> | FieldReadFunction<any>;
    attachments?: FieldPolicy<any> | FieldReadFunction<any>;
    vpaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    deliveryPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
    assignPersonResponsibleAt?: FieldPolicy<any> | FieldReadFunction<any>;
    targetSetOfKeys?: FieldPolicy<any> | FieldReadFunction<any>;
    targetHandbook?: FieldPolicy<any> | FieldReadFunction<any>;
    deliveryPeriodIndentOrder?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HandoverDateChangeNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | HandoverDateChangeNoticeNotificationKeySpecifier
)[];
export type HandoverDateChangeNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MobilePhoneKeySpecifier = ('internationalCode' | 'number' | MobilePhoneKeySpecifier)[];
export type MobilePhoneFieldPolicy = {
    internationalCode?: FieldPolicy<any> | FieldReadFunction<any>;
    number?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
    | 'registerNewAccount'
    | 'confirmNewAccountRegistration'
    | 'authenticate'
    | 'authenticateWithToken'
    | 'refreshToken'
    | 'getSingleSignInToken'
    | 'confirmAuthentication'
    | 'updateAccount'
    | 'uploadProfilePicture'
    | 'enableAccount'
    | 'disableAccount'
    | 'approveAccount'
    | 'rejectAccount'
    | 'requestResetPassword'
    | 'confirmResetPassword'
    | 'consumeResetPassword'
    | 'createPurchaseTransaction'
    | 'createSaleTransaction'
    | 'cloneSaleTransactionCreateNew'
    | 'updateTransactionToInitialValuation'
    | 'updateTransactionToPendingAcceptance'
    | 'updateTransactionToFinalValuation'
    | 'updateTransactionToPendingApproval'
    | 'updateTransactionToHandover'
    | 'updateTransactionToComplete'
    | 'updateTransactionToCancel'
    | 'updatePurchaseTransaction'
    | 'createBidding'
    | 'updateBidding'
    | 'cancelBidding'
    | 'addBiddingEntry'
    | 'updateAwardedBiddingEntry'
    | 'updateSaleTransaction'
    | 'updateSaleTransactionToForHandover'
    | 'updateSaleTransactionToCompleted'
    | 'updateSaleTransactionToClosed'
    | 'updateSaleTransactionToPending'
    | 'updateSaleTransactionToPendingApproval'
    | 'toggleBiometric'
    | 'enablePush'
    | 'updateRegistrationToken'
    | 'disablePush'
    | 'addVehicleDiagramComment'
    | 'removeVehicleDiagramComment'
    | 'updatePurchaseTransactionFrontPagePhoto'
    | 'uploadValuationPhoto'
    | 'uploadHandoverAttachment'
    | 'uploadVpaAttachment'
    | 'removeValuationPhoto'
    | 'removeHandoverAttachment'
    | 'removeVpaAttachment'
    | 'uploadSalesTransactionHandoverAttachment'
    | 'uploadVsaAttachment'
    | 'updateSalesTransactionFrontPagePhoto'
    | 'uploadSalesTransactionPhoto'
    | 'removeSalesTransactionPhoto'
    | 'removeSalesTransactionHandoverAttachment'
    | 'removeVsaAttachment'
    | 'updatePurchaseTransactionForApprovalRequest'
    | 'updatePurchaseTransactionForAcceptance'
    | 'updateSalesTransactionForAwarding'
    | 'updatePurchaseTransactionForInitValuation'
    | 'triggerPendingAcceptanceNotification'
    | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
    registerNewAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    confirmNewAccountRegistration?: FieldPolicy<any> | FieldReadFunction<any>;
    authenticate?: FieldPolicy<any> | FieldReadFunction<any>;
    authenticateWithToken?: FieldPolicy<any> | FieldReadFunction<any>;
    refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
    getSingleSignInToken?: FieldPolicy<any> | FieldReadFunction<any>;
    confirmAuthentication?: FieldPolicy<any> | FieldReadFunction<any>;
    updateAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadProfilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    enableAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    disableAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    approveAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    rejectAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    requestResetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
    confirmResetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
    consumeResetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
    createPurchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    createSaleTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    cloneSaleTransactionCreateNew?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToInitialValuation?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToPendingAcceptance?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToFinalValuation?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToPendingApproval?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToHandover?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToComplete?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTransactionToCancel?: FieldPolicy<any> | FieldReadFunction<any>;
    updatePurchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    createBidding?: FieldPolicy<any> | FieldReadFunction<any>;
    updateBidding?: FieldPolicy<any> | FieldReadFunction<any>;
    cancelBidding?: FieldPolicy<any> | FieldReadFunction<any>;
    addBiddingEntry?: FieldPolicy<any> | FieldReadFunction<any>;
    updateAwardedBiddingEntry?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransactionToForHandover?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransactionToCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransactionToClosed?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransactionToPending?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSaleTransactionToPendingApproval?: FieldPolicy<any> | FieldReadFunction<any>;
    toggleBiometric?: FieldPolicy<any> | FieldReadFunction<any>;
    enablePush?: FieldPolicy<any> | FieldReadFunction<any>;
    updateRegistrationToken?: FieldPolicy<any> | FieldReadFunction<any>;
    disablePush?: FieldPolicy<any> | FieldReadFunction<any>;
    addVehicleDiagramComment?: FieldPolicy<any> | FieldReadFunction<any>;
    removeVehicleDiagramComment?: FieldPolicy<any> | FieldReadFunction<any>;
    updatePurchaseTransactionFrontPagePhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadValuationPhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadHandoverAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadVpaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    removeValuationPhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    removeHandoverAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    removeVpaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadSalesTransactionHandoverAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadVsaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSalesTransactionFrontPagePhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadSalesTransactionPhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    removeSalesTransactionPhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    removeSalesTransactionHandoverAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    removeVsaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    updatePurchaseTransactionForApprovalRequest?: FieldPolicy<any> | FieldReadFunction<any>;
    updatePurchaseTransactionForAcceptance?: FieldPolicy<any> | FieldReadFunction<any>;
    updateSalesTransactionForAwarding?: FieldPolicy<any> | FieldReadFunction<any>;
    updatePurchaseTransactionForInitValuation?: FieldPolicy<any> | FieldReadFunction<any>;
    triggerPendingAcceptanceNotification?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationKeySpecifier = ('id' | 'type' | 'user' | 'createdAt' | 'channels' | NotificationKeySpecifier)[];
export type NotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationPageKeySpecifier = ('items' | 'count' | NotificationPageKeySpecifier)[];
export type NotificationPageFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingAcceptanceNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PendingAcceptanceNoticeNotificationKeySpecifier
)[];
export type PendingAcceptanceNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingRequoteNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PendingRequoteNoticeNotificationKeySpecifier
)[];
export type PendingRequoteNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingValuationNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PendingValuationNoticeNotificationKeySpecifier
)[];
export type PendingValuationNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseStageUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | PurchaseStageUpdateActivityKeySpecifier
)[];
export type PurchaseStageUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseStageUpdateBySystemActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | PurchaseStageUpdateBySystemActivityKeySpecifier
)[];
export type PurchaseStageUpdateBySystemActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionKeySpecifier = (
    | 'id'
    | 'identifier'
    | 'stage'
    | 'saleConsultantId'
    | 'saleConsultant'
    | 'internalRemarks'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'closedAt'
    | 'closedBy'
    | 'customer'
    | 'vehicle'
    | 'valuation'
    | 'handover'
    | 'frontPagePhoto'
    | 'frontPagePhotoSourceId'
    | 'salesTransactionId'
    | 'activities'
    | 'lastActivity'
    | 'duplicateTransactions'
    | 'adminRemark'
    | 'cancelReason'
    | 'archived'
    | PurchaseTransactionKeySpecifier
)[];
export type PurchaseTransactionFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    identifier?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
    saleConsultantId?: FieldPolicy<any> | FieldReadFunction<any>;
    saleConsultant?: FieldPolicy<any> | FieldReadFunction<any>;
    internalRemarks?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
    closedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    closedBy?: FieldPolicy<any> | FieldReadFunction<any>;
    customer?: FieldPolicy<any> | FieldReadFunction<any>;
    vehicle?: FieldPolicy<any> | FieldReadFunction<any>;
    valuation?: FieldPolicy<any> | FieldReadFunction<any>;
    handover?: FieldPolicy<any> | FieldReadFunction<any>;
    frontPagePhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    frontPagePhotoSourceId?: FieldPolicy<any> | FieldReadFunction<any>;
    salesTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActivity?: FieldPolicy<any> | FieldReadFunction<any>;
    duplicateTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    adminRemark?: FieldPolicy<any> | FieldReadFunction<any>;
    cancelReason?: FieldPolicy<any> | FieldReadFunction<any>;
    archived?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionAmendedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PurchaseTransactionAmendedNoticeNotificationKeySpecifier
)[];
export type PurchaseTransactionAmendedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionApprovalNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PurchaseTransactionApprovalNoticeNotificationKeySpecifier
)[];
export type PurchaseTransactionApprovalNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionApprovedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PurchaseTransactionApprovedNoticeNotificationKeySpecifier
)[];
export type PurchaseTransactionApprovedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionPageKeySpecifier = ('items' | 'count' | PurchaseTransactionPageKeySpecifier)[];
export type PurchaseTransactionPageFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionRejectedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PurchaseTransactionRejectedNoticeNotificationKeySpecifier
)[];
export type PurchaseTransactionRejectedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionValuationAcceptedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'purchaseTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | PurchaseTransactionValuationAcceptedNoticeNotificationKeySpecifier
)[];
export type PurchaseTransactionValuationAcceptedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseTransactionVehicleKeySpecifier = (
    | 'number'
    | 'beExported'
    | 'intendedDeregistrationDate'
    | 'make'
    | 'model'
    | 'primaryColour'
    | 'manufacturingYear'
    | 'engineNumber'
    | 'chassisNumber'
    | 'maximumPowerOutput'
    | 'openMarketValue'
    | 'originalRegistrationDate'
    | 'firstRegistrationDate'
    | 'transferCount'
    | 'actualARFPaid'
    | 'parfEligibility'
    | 'parfEligibilityDate'
    | 'parfRebateAmount'
    | 'coeExpiryDate'
    | 'coeCategory'
    | 'coePeriodYear'
    | 'qpPaid'
    | 'coeRebateAmount'
    | 'mileage'
    | 'purchaseAgreementNumber'
    | 'saleAgreementNumber'
    | 'secondaryColour'
    | 'opcCashRebateEligibility'
    | 'opcCashRebateEligibilityExpiryDate'
    | 'opcCashRebateAmount'
    | 'pqpPaid'
    | 'ltaMessage'
    | 'activities'
    | 'isOneMotoringData'
    | 'totalRebateAmount'
    | 'importMethod'
    | 'setOfKeys'
    | 'ownerHandbookCollected'
    | PurchaseTransactionVehicleKeySpecifier
)[];
export type PurchaseTransactionVehicleFieldPolicy = {
    number?: FieldPolicy<any> | FieldReadFunction<any>;
    beExported?: FieldPolicy<any> | FieldReadFunction<any>;
    intendedDeregistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    make?: FieldPolicy<any> | FieldReadFunction<any>;
    model?: FieldPolicy<any> | FieldReadFunction<any>;
    primaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    manufacturingYear?: FieldPolicy<any> | FieldReadFunction<any>;
    engineNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    chassisNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    maximumPowerOutput?: FieldPolicy<any> | FieldReadFunction<any>;
    openMarketValue?: FieldPolicy<any> | FieldReadFunction<any>;
    originalRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    firstRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    transferCount?: FieldPolicy<any> | FieldReadFunction<any>;
    actualARFPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibilityDate?: FieldPolicy<any> | FieldReadFunction<any>;
    parfRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    coeExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    coeCategory?: FieldPolicy<any> | FieldReadFunction<any>;
    coePeriodYear?: FieldPolicy<any> | FieldReadFunction<any>;
    qpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    coeRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    mileage?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseAgreementNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    saleAgreementNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    secondaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibilityExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    pqpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    ltaMessage?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    isOneMotoringData?: FieldPolicy<any> | FieldReadFunction<any>;
    totalRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    importMethod?: FieldPolicy<any> | FieldReadFunction<any>;
    setOfKeys?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerHandbookCollected?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'diff'
    | PurchaseUpdateActivityKeySpecifier
)[];
export type PurchaseUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    diff?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PushDeviceSettingKeySpecifier = (
    | 'type'
    | 'deviceFingerprint'
    | 'enabledOn'
    | PushDeviceSettingKeySpecifier
)[];
export type PushDeviceSettingFieldPolicy = {
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceFingerprint?: FieldPolicy<any> | FieldReadFunction<any>;
    enabledOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
    | 'getUser'
    | 'getSelf'
    | 'listUsers'
    | 'listPurchaseTransactions'
    | 'getPurchaseTransaction'
    | 'listSaleTransactions'
    | 'getSaleTransaction'
    | 'listBiddings'
    | 'getBidding'
    | 'listNotifications'
    | 'checkDuplicateTransaction'
    | 'generatePurchaseTransactionsFile'
    | 'generateSalesTransactionsFile'
    | 'generatePurchaseTransactionDetailFile'
    | 'generateSalesTransactionDetailFile'
    | 'generateBiddingSalesTransactionsFile'
    | 'exportExcelForActivityLogs'
    | 'checkDuplicateMobileNumber'
    | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
    getUser?: FieldPolicy<any> | FieldReadFunction<any>;
    getSelf?: FieldPolicy<any> | FieldReadFunction<any>;
    listUsers?: FieldPolicy<any> | FieldReadFunction<any>;
    listPurchaseTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    getPurchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    listSaleTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    getSaleTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    listBiddings?: FieldPolicy<any> | FieldReadFunction<any>;
    getBidding?: FieldPolicy<any> | FieldReadFunction<any>;
    listNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
    checkDuplicateTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    generatePurchaseTransactionsFile?: FieldPolicy<any> | FieldReadFunction<any>;
    generateSalesTransactionsFile?: FieldPolicy<any> | FieldReadFunction<any>;
    generatePurchaseTransactionDetailFile?: FieldPolicy<any> | FieldReadFunction<any>;
    generateSalesTransactionDetailFile?: FieldPolicy<any> | FieldReadFunction<any>;
    generateBiddingSalesTransactionsFile?: FieldPolicy<any> | FieldReadFunction<any>;
    exportExcelForActivityLogs?: FieldPolicy<any> | FieldReadFunction<any>;
    checkDuplicateMobileNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestPasswordResultKeySpecifier = ('token' | 'email' | RequestPasswordResultKeySpecifier)[];
export type RequestPasswordResultFieldPolicy = {
    token?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleConsultantUserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'brand'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | SaleConsultantUserKeySpecifier
)[];
export type SaleConsultantUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    brand?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleStageUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'stage'
    | SaleStageUpdateActivityKeySpecifier
)[];
export type SaleStageUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleStageUpdateBySystemActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'stage'
    | SaleStageUpdateBySystemActivityKeySpecifier
)[];
export type SaleStageUpdateBySystemActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleTransactionKeySpecifier = (
    | 'id'
    | 'identifier'
    | 'stage'
    | 'purchaseTransactionId'
    | 'purchaseTransaction'
    | 'internalRemarks'
    | 'vehicle'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'biddingSessions'
    | 'awardedBiddingEntry'
    | 'latestBiddingSession'
    | 'latestBiddingSessionEntry'
    | 'handover'
    | 'frontPagePhoto'
    | 'frontPagePhotoSourceId'
    | 'photos'
    | 'activities'
    | 'lastActivity'
    | 'adminRemark'
    | 'archived'
    | 'cloned'
    | 'originalSalesTransactionId'
    | 'originalSalesTransaction'
    | SaleTransactionKeySpecifier
)[];
export type SaleTransactionFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    identifier?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
    purchaseTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    internalRemarks?: FieldPolicy<any> | FieldReadFunction<any>;
    vehicle?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
    biddingSessions?: FieldPolicy<any> | FieldReadFunction<any>;
    awardedBiddingEntry?: FieldPolicy<any> | FieldReadFunction<any>;
    latestBiddingSession?: FieldPolicy<any> | FieldReadFunction<any>;
    latestBiddingSessionEntry?: FieldPolicy<any> | FieldReadFunction<any>;
    handover?: FieldPolicy<any> | FieldReadFunction<any>;
    frontPagePhoto?: FieldPolicy<any> | FieldReadFunction<any>;
    frontPagePhotoSourceId?: FieldPolicy<any> | FieldReadFunction<any>;
    photos?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActivity?: FieldPolicy<any> | FieldReadFunction<any>;
    adminRemark?: FieldPolicy<any> | FieldReadFunction<any>;
    archived?: FieldPolicy<any> | FieldReadFunction<any>;
    cloned?: FieldPolicy<any> | FieldReadFunction<any>;
    originalSalesTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
    originalSalesTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleTransactionPageKeySpecifier = ('items' | 'count' | SaleTransactionPageKeySpecifier)[];
export type SaleTransactionPageFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaleUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'diff'
    | SaleUpdateActivityKeySpecifier
)[];
export type SaleUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    diff?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesTransactionApprovalNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'salesTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | SalesTransactionApprovalNoticeNotificationKeySpecifier
)[];
export type SalesTransactionApprovalNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    salesTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesTransactionApprovedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'salesTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | SalesTransactionApprovedNoticeNotificationKeySpecifier
)[];
export type SalesTransactionApprovedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    salesTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesTransactionHandoverKeySpecifier = (
    | 'personResponsible'
    | 'targetHandoverDateTime'
    | 'handoverLocation'
    | 'actualHandoverDate'
    | 'vehicleSalesAgreementNo'
    | 'remarks'
    | 'attachments'
    | 'vsaAttachment'
    | 'manualSalesDealer'
    | 'manualSalesPrice'
    | SalesTransactionHandoverKeySpecifier
)[];
export type SalesTransactionHandoverFieldPolicy = {
    personResponsible?: FieldPolicy<any> | FieldReadFunction<any>;
    targetHandoverDateTime?: FieldPolicy<any> | FieldReadFunction<any>;
    handoverLocation?: FieldPolicy<any> | FieldReadFunction<any>;
    actualHandoverDate?: FieldPolicy<any> | FieldReadFunction<any>;
    vehicleSalesAgreementNo?: FieldPolicy<any> | FieldReadFunction<any>;
    remarks?: FieldPolicy<any> | FieldReadFunction<any>;
    attachments?: FieldPolicy<any> | FieldReadFunction<any>;
    vsaAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
    manualSalesDealer?: FieldPolicy<any> | FieldReadFunction<any>;
    manualSalesPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesTransactionRejectedNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'salesTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | SalesTransactionRejectedNoticeNotificationKeySpecifier
)[];
export type SalesTransactionRejectedNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    salesTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesTransactionVehicleKeySpecifier = (
    | 'number'
    | 'beExported'
    | 'intendedDeregistrationDate'
    | 'make'
    | 'model'
    | 'primaryColour'
    | 'manufacturingYear'
    | 'engineNumber'
    | 'chassisNumber'
    | 'maximumPowerOutput'
    | 'openMarketValue'
    | 'originalRegistrationDate'
    | 'firstRegistrationDate'
    | 'transferCount'
    | 'actualARFPaid'
    | 'parfEligibility'
    | 'parfEligibilityDate'
    | 'parfRebateAmount'
    | 'coeExpiryDate'
    | 'coeCategory'
    | 'coePeriodYear'
    | 'qpPaid'
    | 'coeRebateAmount'
    | 'mileage'
    | 'secondaryColour'
    | 'opcCashRebateEligibility'
    | 'opcCashRebateEligibilityExpiryDate'
    | 'opcCashRebateAmount'
    | 'pqpPaid'
    | 'ltaMessage'
    | 'activities'
    | 'totalRebateAmount'
    | 'importMethod'
    | 'setOfKeys'
    | 'ownerHandbookCollected'
    | 'generalCondition'
    | 'dealerInformations'
    | SalesTransactionVehicleKeySpecifier
)[];
export type SalesTransactionVehicleFieldPolicy = {
    number?: FieldPolicy<any> | FieldReadFunction<any>;
    beExported?: FieldPolicy<any> | FieldReadFunction<any>;
    intendedDeregistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    make?: FieldPolicy<any> | FieldReadFunction<any>;
    model?: FieldPolicy<any> | FieldReadFunction<any>;
    primaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    manufacturingYear?: FieldPolicy<any> | FieldReadFunction<any>;
    engineNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    chassisNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    maximumPowerOutput?: FieldPolicy<any> | FieldReadFunction<any>;
    openMarketValue?: FieldPolicy<any> | FieldReadFunction<any>;
    originalRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    firstRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    transferCount?: FieldPolicy<any> | FieldReadFunction<any>;
    actualARFPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibilityDate?: FieldPolicy<any> | FieldReadFunction<any>;
    parfRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    coeExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    coeCategory?: FieldPolicy<any> | FieldReadFunction<any>;
    coePeriodYear?: FieldPolicy<any> | FieldReadFunction<any>;
    qpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    coeRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    mileage?: FieldPolicy<any> | FieldReadFunction<any>;
    secondaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibilityExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    pqpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    ltaMessage?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    totalRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    importMethod?: FieldPolicy<any> | FieldReadFunction<any>;
    setOfKeys?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerHandbookCollected?: FieldPolicy<any> | FieldReadFunction<any>;
    generalCondition?: FieldPolicy<any> | FieldReadFunction<any>;
    dealerInformations?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignUpApprovalNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'user'
    | 'createdAt'
    | 'channels'
    | SignUpApprovalNotificationKeySpecifier
)[];
export type SignUpApprovalNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignUpRejectNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'user'
    | 'createdAt'
    | 'channels'
    | SignUpRejectNotificationKeySpecifier
)[];
export type SignUpRejectNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnawardNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'salesTransaction'
    | 'user'
    | 'createdAt'
    | 'channels'
    | UnawardNoticeNotificationKeySpecifier
)[];
export type UnawardNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    salesTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpcomingBiddingNoticeNotificationKeySpecifier = (
    | 'id'
    | 'type'
    | 'saleTransactions'
    | 'user'
    | 'createdAt'
    | 'channels'
    | UpcomingBiddingNoticeNotificationKeySpecifier
)[];
export type UpcomingBiddingNoticeNotificationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    saleTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    channels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UploadedFileKeySpecifier = (
    | 'id'
    | 'filename'
    | 'etag'
    | 'size'
    | 'objectName'
    | 'uploadedAt'
    | 'signedUrl'
    | UploadedFileKeySpecifier
)[];
export type UploadedFileFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    filename?: FieldPolicy<any> | FieldReadFunction<any>;
    etag?: FieldPolicy<any> | FieldReadFunction<any>;
    size?: FieldPolicy<any> | FieldReadFunction<any>;
    objectName?: FieldPolicy<any> | FieldReadFunction<any>;
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    signedUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | UserKeySpecifier
)[];
export type UserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserPageKeySpecifier = ('items' | 'count' | UserPageKeySpecifier)[];
export type UserPageFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserSessionKeySpecifier = ('token' | 'signInToken' | 'user' | UserSessionKeySpecifier)[];
export type UserSessionFieldPolicy = {
    token?: FieldPolicy<any> | FieldReadFunction<any>;
    signInToken?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserStageUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'stage'
    | 'author'
    | UserStageUpdateActivityKeySpecifier
)[];
export type UserStageUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserStageUpdateBySystemActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'stage'
    | UserStageUpdateBySystemActivityKeySpecifier
)[];
export type UserStageUpdateBySystemActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    stage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserUpdateActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'diff'
    | UserUpdateActivityKeySpecifier
)[];
export type UserUpdateActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    diff?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValuatedValuationKeySpecifier = ('value' | 'valuatedBy' | 'valuatedOn' | ValuatedValuationKeySpecifier)[];
export type ValuatedValuationFieldPolicy = {
    value?: FieldPolicy<any> | FieldReadFunction<any>;
    valuatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
    valuatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValuationKeySpecifier = (
    | 'valuatedValuations'
    | 'latestValuatedValuation'
    | 'generalCondition'
    | 'vehicleDiagramComments'
    | 'photos'
    | 'dealerInformations'
    | ValuationKeySpecifier
)[];
export type ValuationFieldPolicy = {
    valuatedValuations?: FieldPolicy<any> | FieldReadFunction<any>;
    latestValuatedValuation?: FieldPolicy<any> | FieldReadFunction<any>;
    generalCondition?: FieldPolicy<any> | FieldReadFunction<any>;
    vehicleDiagramComments?: FieldPolicy<any> | FieldReadFunction<any>;
    photos?: FieldPolicy<any> | FieldReadFunction<any>;
    dealerInformations?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValuationActivityKeySpecifier = (
    | 'id'
    | 'date'
    | 'type'
    | 'author'
    | 'valuationValue'
    | ValuationActivityKeySpecifier
)[];
export type ValuationActivityFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    date?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    valuationValue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValuationTeamUserKeySpecifier = (
    | 'id'
    | 'fullName'
    | 'email'
    | 'mobilePhone'
    | 'type'
    | 'activationStage'
    | 'registeredAt'
    | 'deviceSettings'
    | 'profilePicture'
    | 'testAccount'
    | 'activities'
    | 'lastActive'
    | ValuationTeamUserKeySpecifier
)[];
export type ValuationTeamUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    fullName?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
    type?: FieldPolicy<any> | FieldReadFunction<any>;
    activationStage?: FieldPolicy<any> | FieldReadFunction<any>;
    registeredAt?: FieldPolicy<any> | FieldReadFunction<any>;
    deviceSettings?: FieldPolicy<any> | FieldReadFunction<any>;
    profilePicture?: FieldPolicy<any> | FieldReadFunction<any>;
    testAccount?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    lastActive?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleKeySpecifier = (
    | 'number'
    | 'beExported'
    | 'intendedDeregistrationDate'
    | 'make'
    | 'model'
    | 'primaryColour'
    | 'manufacturingYear'
    | 'engineNumber'
    | 'chassisNumber'
    | 'maximumPowerOutput'
    | 'openMarketValue'
    | 'originalRegistrationDate'
    | 'firstRegistrationDate'
    | 'transferCount'
    | 'actualARFPaid'
    | 'parfEligibility'
    | 'parfEligibilityDate'
    | 'parfRebateAmount'
    | 'coeExpiryDate'
    | 'coeCategory'
    | 'coePeriodYear'
    | 'qpPaid'
    | 'coeRebateAmount'
    | 'mileage'
    | 'secondaryColour'
    | 'opcCashRebateEligibility'
    | 'opcCashRebateEligibilityExpiryDate'
    | 'opcCashRebateAmount'
    | 'pqpPaid'
    | 'ltaMessage'
    | 'activities'
    | 'totalRebateAmount'
    | 'importMethod'
    | 'setOfKeys'
    | 'ownerHandbookCollected'
    | VehicleKeySpecifier
)[];
export type VehicleFieldPolicy = {
    number?: FieldPolicy<any> | FieldReadFunction<any>;
    beExported?: FieldPolicy<any> | FieldReadFunction<any>;
    intendedDeregistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    make?: FieldPolicy<any> | FieldReadFunction<any>;
    model?: FieldPolicy<any> | FieldReadFunction<any>;
    primaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    manufacturingYear?: FieldPolicy<any> | FieldReadFunction<any>;
    engineNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    chassisNumber?: FieldPolicy<any> | FieldReadFunction<any>;
    maximumPowerOutput?: FieldPolicy<any> | FieldReadFunction<any>;
    openMarketValue?: FieldPolicy<any> | FieldReadFunction<any>;
    originalRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    firstRegistrationDate?: FieldPolicy<any> | FieldReadFunction<any>;
    transferCount?: FieldPolicy<any> | FieldReadFunction<any>;
    actualARFPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    parfEligibilityDate?: FieldPolicy<any> | FieldReadFunction<any>;
    parfRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    coeExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    coeCategory?: FieldPolicy<any> | FieldReadFunction<any>;
    coePeriodYear?: FieldPolicy<any> | FieldReadFunction<any>;
    qpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    coeRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    mileage?: FieldPolicy<any> | FieldReadFunction<any>;
    secondaryColour?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateEligibilityExpiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
    opcCashRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    pqpPaid?: FieldPolicy<any> | FieldReadFunction<any>;
    ltaMessage?: FieldPolicy<any> | FieldReadFunction<any>;
    activities?: FieldPolicy<any> | FieldReadFunction<any>;
    totalRebateAmount?: FieldPolicy<any> | FieldReadFunction<any>;
    importMethod?: FieldPolicy<any> | FieldReadFunction<any>;
    setOfKeys?: FieldPolicy<any> | FieldReadFunction<any>;
    ownerHandbookCollected?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleDiagramCommentKeySpecifier = (
    | 'id'
    | 'index'
    | 'comment'
    | 'position'
    | VehicleDiagramCommentKeySpecifier
)[];
export type VehicleDiagramCommentFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    index?: FieldPolicy<any> | FieldReadFunction<any>;
    comment?: FieldPolicy<any> | FieldReadFunction<any>;
    position?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleDiagramCommentPositionKeySpecifier = ('top' | 'left' | VehicleDiagramCommentPositionKeySpecifier)[];
export type VehicleDiagramCommentPositionFieldPolicy = {
    top?: FieldPolicy<any> | FieldReadFunction<any>;
    left?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TypedTypePolicies = TypePolicies & {
    Activity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ActivityKeySpecifier | (() => undefined | ActivityKeySpecifier);
        fields?: ActivityFieldPolicy;
    };
    AdminUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AdminUserKeySpecifier | (() => undefined | AdminUserKeySpecifier);
        fields?: AdminUserFieldPolicy;
    };
    ApproverUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ApproverUserKeySpecifier | (() => undefined | ApproverUserKeySpecifier);
        fields?: ApproverUserFieldPolicy;
    };
    AuthenticateResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuthenticateResultKeySpecifier | (() => undefined | AuthenticateResultKeySpecifier);
        fields?: AuthenticateResultFieldPolicy;
    };
    AwardedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AwardedNoticeNotificationKeySpecifier
            | (() => undefined | AwardedNoticeNotificationKeySpecifier);
        fields?: AwardedNoticeNotificationFieldPolicy;
    };
    Bidding?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiddingKeySpecifier | (() => undefined | BiddingKeySpecifier);
        fields?: BiddingFieldPolicy;
    };
    BiddingActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiddingActivityKeySpecifier | (() => undefined | BiddingActivityKeySpecifier);
        fields?: BiddingActivityFieldPolicy;
    };
    BiddingEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiddingEntryKeySpecifier | (() => undefined | BiddingEntryKeySpecifier);
        fields?: BiddingEntryFieldPolicy;
    };
    BiddingPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiddingPageKeySpecifier | (() => undefined | BiddingPageKeySpecifier);
        fields?: BiddingPageFieldPolicy;
    };
    BiddingSaleTransaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiddingSaleTransactionKeySpecifier | (() => undefined | BiddingSaleTransactionKeySpecifier);
        fields?: BiddingSaleTransactionFieldPolicy;
    };
    BiometricDeviceSetting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BiometricDeviceSettingKeySpecifier | (() => undefined | BiometricDeviceSettingKeySpecifier);
        fields?: BiometricDeviceSettingFieldPolicy;
    };
    CreationActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CreationActivityKeySpecifier | (() => undefined | CreationActivityKeySpecifier);
        fields?: CreationActivityFieldPolicy;
    };
    Customer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CustomerKeySpecifier | (() => undefined | CustomerKeySpecifier);
        fields?: CustomerFieldPolicy;
    };
    DealerBusiness?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DealerBusinessKeySpecifier | (() => undefined | DealerBusinessKeySpecifier);
        fields?: DealerBusinessFieldPolicy;
    };
    DealerInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DealerInformationKeySpecifier | (() => undefined | DealerInformationKeySpecifier);
        fields?: DealerInformationFieldPolicy;
    };
    DealerUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DealerUserKeySpecifier | (() => undefined | DealerUserKeySpecifier);
        fields?: DealerUserFieldPolicy;
    };
    DeviceSetting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DeviceSettingKeySpecifier | (() => undefined | DeviceSettingKeySpecifier);
        fields?: DeviceSettingFieldPolicy;
    };
    Handover?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HandoverKeySpecifier | (() => undefined | HandoverKeySpecifier);
        fields?: HandoverFieldPolicy;
    };
    HandoverDateChangeNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HandoverDateChangeNoticeNotificationKeySpecifier
            | (() => undefined | HandoverDateChangeNoticeNotificationKeySpecifier);
        fields?: HandoverDateChangeNoticeNotificationFieldPolicy;
    };
    MobilePhone?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MobilePhoneKeySpecifier | (() => undefined | MobilePhoneKeySpecifier);
        fields?: MobilePhoneFieldPolicy;
    };
    Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
        fields?: MutationFieldPolicy;
    };
    Notification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotificationKeySpecifier | (() => undefined | NotificationKeySpecifier);
        fields?: NotificationFieldPolicy;
    };
    NotificationPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotificationPageKeySpecifier | (() => undefined | NotificationPageKeySpecifier);
        fields?: NotificationPageFieldPolicy;
    };
    PendingAcceptanceNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PendingAcceptanceNoticeNotificationKeySpecifier
            | (() => undefined | PendingAcceptanceNoticeNotificationKeySpecifier);
        fields?: PendingAcceptanceNoticeNotificationFieldPolicy;
    };
    PendingRequoteNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PendingRequoteNoticeNotificationKeySpecifier
            | (() => undefined | PendingRequoteNoticeNotificationKeySpecifier);
        fields?: PendingRequoteNoticeNotificationFieldPolicy;
    };
    PendingValuationNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PendingValuationNoticeNotificationKeySpecifier
            | (() => undefined | PendingValuationNoticeNotificationKeySpecifier);
        fields?: PendingValuationNoticeNotificationFieldPolicy;
    };
    PurchaseStageUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseStageUpdateActivityKeySpecifier
            | (() => undefined | PurchaseStageUpdateActivityKeySpecifier);
        fields?: PurchaseStageUpdateActivityFieldPolicy;
    };
    PurchaseStageUpdateBySystemActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseStageUpdateBySystemActivityKeySpecifier
            | (() => undefined | PurchaseStageUpdateBySystemActivityKeySpecifier);
        fields?: PurchaseStageUpdateBySystemActivityFieldPolicy;
    };
    PurchaseTransaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PurchaseTransactionKeySpecifier | (() => undefined | PurchaseTransactionKeySpecifier);
        fields?: PurchaseTransactionFieldPolicy;
    };
    PurchaseTransactionAmendedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionAmendedNoticeNotificationKeySpecifier
            | (() => undefined | PurchaseTransactionAmendedNoticeNotificationKeySpecifier);
        fields?: PurchaseTransactionAmendedNoticeNotificationFieldPolicy;
    };
    PurchaseTransactionApprovalNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionApprovalNoticeNotificationKeySpecifier
            | (() => undefined | PurchaseTransactionApprovalNoticeNotificationKeySpecifier);
        fields?: PurchaseTransactionApprovalNoticeNotificationFieldPolicy;
    };
    PurchaseTransactionApprovedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionApprovedNoticeNotificationKeySpecifier
            | (() => undefined | PurchaseTransactionApprovedNoticeNotificationKeySpecifier);
        fields?: PurchaseTransactionApprovedNoticeNotificationFieldPolicy;
    };
    PurchaseTransactionPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionPageKeySpecifier
            | (() => undefined | PurchaseTransactionPageKeySpecifier);
        fields?: PurchaseTransactionPageFieldPolicy;
    };
    PurchaseTransactionRejectedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionRejectedNoticeNotificationKeySpecifier
            | (() => undefined | PurchaseTransactionRejectedNoticeNotificationKeySpecifier);
        fields?: PurchaseTransactionRejectedNoticeNotificationFieldPolicy;
    };
    PurchaseTransactionValuationAcceptedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionValuationAcceptedNoticeNotificationKeySpecifier
            | (() => undefined | PurchaseTransactionValuationAcceptedNoticeNotificationKeySpecifier);
        fields?: PurchaseTransactionValuationAcceptedNoticeNotificationFieldPolicy;
    };
    PurchaseTransactionVehicle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PurchaseTransactionVehicleKeySpecifier
            | (() => undefined | PurchaseTransactionVehicleKeySpecifier);
        fields?: PurchaseTransactionVehicleFieldPolicy;
    };
    PurchaseUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PurchaseUpdateActivityKeySpecifier | (() => undefined | PurchaseUpdateActivityKeySpecifier);
        fields?: PurchaseUpdateActivityFieldPolicy;
    };
    PushDeviceSetting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PushDeviceSettingKeySpecifier | (() => undefined | PushDeviceSettingKeySpecifier);
        fields?: PushDeviceSettingFieldPolicy;
    };
    Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
        fields?: QueryFieldPolicy;
    };
    RequestPasswordResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RequestPasswordResultKeySpecifier | (() => undefined | RequestPasswordResultKeySpecifier);
        fields?: RequestPasswordResultFieldPolicy;
    };
    SaleConsultantUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SaleConsultantUserKeySpecifier | (() => undefined | SaleConsultantUserKeySpecifier);
        fields?: SaleConsultantUserFieldPolicy;
    };
    SaleStageUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SaleStageUpdateActivityKeySpecifier
            | (() => undefined | SaleStageUpdateActivityKeySpecifier);
        fields?: SaleStageUpdateActivityFieldPolicy;
    };
    SaleStageUpdateBySystemActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SaleStageUpdateBySystemActivityKeySpecifier
            | (() => undefined | SaleStageUpdateBySystemActivityKeySpecifier);
        fields?: SaleStageUpdateBySystemActivityFieldPolicy;
    };
    SaleTransaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SaleTransactionKeySpecifier | (() => undefined | SaleTransactionKeySpecifier);
        fields?: SaleTransactionFieldPolicy;
    };
    SaleTransactionPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SaleTransactionPageKeySpecifier | (() => undefined | SaleTransactionPageKeySpecifier);
        fields?: SaleTransactionPageFieldPolicy;
    };
    SaleUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SaleUpdateActivityKeySpecifier | (() => undefined | SaleUpdateActivityKeySpecifier);
        fields?: SaleUpdateActivityFieldPolicy;
    };
    SalesTransactionApprovalNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SalesTransactionApprovalNoticeNotificationKeySpecifier
            | (() => undefined | SalesTransactionApprovalNoticeNotificationKeySpecifier);
        fields?: SalesTransactionApprovalNoticeNotificationFieldPolicy;
    };
    SalesTransactionApprovedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SalesTransactionApprovedNoticeNotificationKeySpecifier
            | (() => undefined | SalesTransactionApprovedNoticeNotificationKeySpecifier);
        fields?: SalesTransactionApprovedNoticeNotificationFieldPolicy;
    };
    SalesTransactionHandover?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SalesTransactionHandoverKeySpecifier
            | (() => undefined | SalesTransactionHandoverKeySpecifier);
        fields?: SalesTransactionHandoverFieldPolicy;
    };
    SalesTransactionRejectedNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SalesTransactionRejectedNoticeNotificationKeySpecifier
            | (() => undefined | SalesTransactionRejectedNoticeNotificationKeySpecifier);
        fields?: SalesTransactionRejectedNoticeNotificationFieldPolicy;
    };
    SalesTransactionVehicle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SalesTransactionVehicleKeySpecifier
            | (() => undefined | SalesTransactionVehicleKeySpecifier);
        fields?: SalesTransactionVehicleFieldPolicy;
    };
    SignUpApprovalNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SignUpApprovalNotificationKeySpecifier
            | (() => undefined | SignUpApprovalNotificationKeySpecifier);
        fields?: SignUpApprovalNotificationFieldPolicy;
    };
    SignUpRejectNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SignUpRejectNotificationKeySpecifier
            | (() => undefined | SignUpRejectNotificationKeySpecifier);
        fields?: SignUpRejectNotificationFieldPolicy;
    };
    UnawardNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UnawardNoticeNotificationKeySpecifier
            | (() => undefined | UnawardNoticeNotificationKeySpecifier);
        fields?: UnawardNoticeNotificationFieldPolicy;
    };
    UpcomingBiddingNoticeNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UpcomingBiddingNoticeNotificationKeySpecifier
            | (() => undefined | UpcomingBiddingNoticeNotificationKeySpecifier);
        fields?: UpcomingBiddingNoticeNotificationFieldPolicy;
    };
    UploadedFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UploadedFileKeySpecifier | (() => undefined | UploadedFileKeySpecifier);
        fields?: UploadedFileFieldPolicy;
    };
    User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
        fields?: UserFieldPolicy;
    };
    UserPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserPageKeySpecifier | (() => undefined | UserPageKeySpecifier);
        fields?: UserPageFieldPolicy;
    };
    UserSession?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserSessionKeySpecifier | (() => undefined | UserSessionKeySpecifier);
        fields?: UserSessionFieldPolicy;
    };
    UserStageUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UserStageUpdateActivityKeySpecifier
            | (() => undefined | UserStageUpdateActivityKeySpecifier);
        fields?: UserStageUpdateActivityFieldPolicy;
    };
    UserStageUpdateBySystemActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UserStageUpdateBySystemActivityKeySpecifier
            | (() => undefined | UserStageUpdateBySystemActivityKeySpecifier);
        fields?: UserStageUpdateBySystemActivityFieldPolicy;
    };
    UserUpdateActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserUpdateActivityKeySpecifier | (() => undefined | UserUpdateActivityKeySpecifier);
        fields?: UserUpdateActivityFieldPolicy;
    };
    ValuatedValuation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ValuatedValuationKeySpecifier | (() => undefined | ValuatedValuationKeySpecifier);
        fields?: ValuatedValuationFieldPolicy;
    };
    Valuation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ValuationKeySpecifier | (() => undefined | ValuationKeySpecifier);
        fields?: ValuationFieldPolicy;
    };
    ValuationActivity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ValuationActivityKeySpecifier | (() => undefined | ValuationActivityKeySpecifier);
        fields?: ValuationActivityFieldPolicy;
    };
    ValuationTeamUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ValuationTeamUserKeySpecifier | (() => undefined | ValuationTeamUserKeySpecifier);
        fields?: ValuationTeamUserFieldPolicy;
    };
    Vehicle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VehicleKeySpecifier | (() => undefined | VehicleKeySpecifier);
        fields?: VehicleFieldPolicy;
    };
    VehicleDiagramComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VehicleDiagramCommentKeySpecifier | (() => undefined | VehicleDiagramCommentKeySpecifier);
        fields?: VehicleDiagramCommentFieldPolicy;
    };
    VehicleDiagramCommentPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VehicleDiagramCommentPositionKeySpecifier
            | (() => undefined | VehicleDiagramCommentPositionKeySpecifier);
        fields?: VehicleDiagramCommentPositionFieldPolicy;
    };
};
