import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';

export type DealerInformationItemProps = {
    name: string;
    index: number;
};

const DealerInformationItem = ({ name, index }: DealerInformationItemProps) => {
    const { t } = useTranslation('purchaseTransactionsPage');

    return (
        <>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.dealer', { index: index + 1 })}
                    name={`${name}.name`}
                    placeholder={t('purchaseTransactionsPage:valuation.dealer', { index: index + 1 })}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.price')}
                    name={`${name}.price`}
                    placeholder={t('purchaseTransactionsPage:valuation.price')}
                    type="number"
                />
            </Grid>
        </>
    );
};

export default DealerInformationItem;
