import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage } from '../api';

export const defaultAllOption = 'all';

const useSalesTransactionStateFilterOptoins = (allValue = defaultAllOption) => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useMemo(
        () => [
            { value: allValue, label: t('saleTransactionsPage:filter.all') },
            { value: SaleTransactionStage.New, label: t('saleTransactionsPage:filter.new') },
            { value: SaleTransactionStage.UpcomingBidding, label: t('saleTransactionsPage:filter.upcomingBidding') },
            { value: SaleTransactionStage.BiddingOpen, label: t('saleTransactionsPage:filter.biddingOpen') },
            { value: SaleTransactionStage.ForAwarding, label: t('saleTransactionsPage:filter.forAwarding') },
            { value: SaleTransactionStage.ForHandover, label: t('saleTransactionsPage:filter.forHandover') },
            { value: SaleTransactionStage.Completed, label: t('saleTransactionsPage:filter.completed') },
            { value: SaleTransactionStage.PendingApproval, label: t('saleTransactionsPage:filter.pendingApproval') },
            { value: SaleTransactionStage.Pending, label: t('saleTransactionsPage:filter.pending') },
            { value: SaleTransactionStage.Closed, label: t('saleTransactionsPage:filter.closed') },
        ],
        [allValue, t]
    );
};

export default useSalesTransactionStateFilterOptoins;
