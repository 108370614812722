import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../../Session';
import { SaleTransactionStage, useGetSaleTransactionsQuery, UserType } from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import TabPanel from '../../../components/TabPanel';
import useDidUpdateEffect from '../../../utilities/useDidUpdateEffect';
import MenuTabs from './AllSessionsMenuTabs';
import SalesTransactionsTab from './SalesTransactionsTab';

const a11yProps = (index: string) => ({
    id: `bidding-sessions-tab-${index}`,
    'aria-controls': `bidding-sessions-tabpanel-${index}`,
});

const pageSize = 9;

const AllBiddingSessions = () => {
    const [value, setValue] = useState('upcomingBiddingSessions');
    const location = useLocation();
    const { refreshBiddingList } = (location.state || {}) as LocationHistory;
    const currentUser = useCurrentUser();

    const [upcomingTransactionPage, setUpcomingTransactionPage] = useState(0);

    const allUpcomingVariables = useMemo(
        () => ({
            page: { limit: pageSize, offset: upcomingTransactionPage * pageSize },
            filter: {
                stages: [SaleTransactionStage.UpcomingBidding],
                onlySelf: currentUser.type === UserType.Dealer,
            },
        }),
        [upcomingTransactionPage, currentUser.type]
    );

    const { data: allUpcomingBiddingData, refetch: refetchAllUpcoming } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: allUpcomingVariables,
        skip: value !== 'upcomingBiddingSessions',
    });

    const upcomingTransactionCount = allUpcomingBiddingData?.saleTransactions?.count || 0;
    const upcomingTransactions = allUpcomingBiddingData?.saleTransactions?.items || [];

    const [currentTransactionPage, setCurrentTransactionPage] = useState(0);

    const allCurrentVariables = useMemo(
        () => ({
            page: { limit: pageSize, offset: currentTransactionPage * pageSize },
            filter: {
                stages: [SaleTransactionStage.BiddingOpen],
                onlySelf: currentUser.type === UserType.Dealer,
            },
        }),
        [currentTransactionPage, currentUser.type]
    );
    const { data: allCurrnetBiddingData, refetch: refetchAllCurrent } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: allCurrentVariables,
        skip: value !== 'currentBiddingSessions',
    });

    useDidUpdateEffect(() => {
        if (refreshBiddingList) {
            if (value === 'upcomingBiddingSessions') {
                refetchAllUpcoming(allUpcomingVariables);
            } else {
                refetchAllCurrent(allCurrentVariables);
            }
        }
    }, [value, refreshBiddingList, refetchAllUpcoming, refetchAllCurrent]);

    const currentTransactionCount = allCurrnetBiddingData?.saleTransactions?.count || 0;
    const currentTransactions = allCurrnetBiddingData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="upcomingBiddingSessions" value={value}>
                    <SalesTransactionsTab
                        count={upcomingTransactionCount}
                        page={upcomingTransactionPage}
                        pageSize={pageSize}
                        saleTransactions={upcomingTransactions}
                        setActivePage={(newPage: number) => setUpcomingTransactionPage(newPage)}
                    />
                </TabPanel>
                <TabPanel index="currentBiddingSessions" value={value}>
                    <SalesTransactionsTab
                        count={currentTransactionCount}
                        page={currentTransactionPage}
                        pageSize={pageSize}
                        saleTransactions={currentTransactions}
                        setActivePage={(newPage: number) => setCurrentTransactionPage(newPage)}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AllBiddingSessions;
