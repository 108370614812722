import React, { useCallback } from 'react';
import { DownloadBiddingSalesTransactionsFileProps } from '..';
import { FullBiddingDataFragment, DownloadFileType, BiddingStatus } from '../../../../api';
import { useLoading } from '../../../../components/LoadingProvider';
import ViewBiddingSessionDetailBody from './ViewBiddingSessionDetailBody';
import ViewBiddingSessionDetailHeader from './ViewBiddingSessionDetailHeader';

export type ViewBiddingSessionDetailProps = {
    bidding: FullBiddingDataFragment;
    goToEdition: () => void;
    cancelBidding: () => Promise<void>;
    downloadBiddingSalesTransactionsFile: (props: DownloadBiddingSalesTransactionsFileProps) => void;
};

const ViewBiddingSessionDetail = ({
    bidding,
    cancelBidding,
    goToEdition,
    downloadBiddingSalesTransactionsFile,
}: ViewBiddingSessionDetailProps) => {
    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadBiddingSalesTransactionsFile({
                    downloadFileType: type,
                    biddingId: bidding.id,
                });
            };

            attach(execute());
        },
        [attach, bidding, downloadBiddingSalesTransactionsFile]
    );

    return (
        <>
            <ViewBiddingSessionDetailHeader
                cancelBidding={cancelBidding}
                displayOptions={{
                    disabledCancelBtn: [BiddingStatus.Past, BiddingStatus.Cancelled].includes(bidding.status),
                }}
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                goToEdition={goToEdition}
                title={bidding.sessionName}
            />
            <ViewBiddingSessionDetailBody bidding={bidding} />
        </>
    );
};

export default ViewBiddingSessionDetail;
