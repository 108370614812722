import { Box, Grid, Typography } from '@material-ui/core';
import cs from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OtpConfigProps } from '../../../routes/LoginRoute';
import useCommonStyles from '../../../useCommonStyles';

export type OTPPageBodyProps = {
    otpConfig: OtpConfigProps;
};

const OTPPageBody = ({ otpConfig }: OTPPageBodyProps) => {
    const { t } = useTranslation(['otpPage']);
    const commonStyles = useCommonStyles();

    return (
        <Grid xs={12} item>
            <Typography
                align="center"
                className={cs(commonStyles.fontWeightBold, commonStyles.secondaryFont)}
                color="secondary"
                variant="h5"
            >
                {t('otpPage:title')}
            </Typography>
            <Box mb={3} mt={1.5}>
                <Typography align="center" className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>
                    <Trans
                        i18nKey={otpConfig.sendByEmail ? 'otpPage:bodyEmail' : 'otpPage:body'}
                        values={{ email: otpConfig.email }}
                    >
                        a <span className={commonStyles.secondary}>b</span> c
                    </Trans>
                </Typography>
            </Box>
        </Grid>
    );
};

export default OTPPageBody;
