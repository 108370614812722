import { Dialog, Slide, Transitions, Link, Box, Typography, Button } from '@material-ui/core';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import MobileAppBar from '../../../components/MobileAppBar';
import BooleanCheckbox from '../../../components/fields/BooleanCheckbox';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import usePurchaseTransactionStateFilterOptoins from '../../../utilities/usePurchaseTransactionStateFilterOptoins';
import { defaultTransactionFilter, TransactionFilter } from '../PurchaseTransactionsList';
import useFilterDialogStyles from './useFilterDialogStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (value: TransactionFilter) => void;
    filter: TransactionFilter;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterDialog = ({ open, setOpen, setValues, filter }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterDialogStyles();
    const currentUser = useCurrentUser();

    const statuses = usePurchaseTransactionStateFilterOptoins();

    const onSubmit = useCallback(
        (values: TransactionFilter) => {
            setValues(values);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open} fullScreen>
            <Formik initialValues={filter} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                    <>
                        <MobileAppBar>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setOpen(false)}
                                underline="none"
                            >
                                {t('common:cancel')}
                            </Link>
                            <Box display="flex" justifyContent="center" width="100%">
                                <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                                    {t('common:filter')}
                                </Typography>
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() =>
                                    Object.entries(defaultTransactionFilter).forEach(([key, value]) => {
                                        setFieldValue(key, value);
                                    })
                                }
                                underline="none"
                            >
                                {t('common:reset')}
                            </Link>
                        </MobileAppBar>
                        <Form>
                            <Box className={styles.content} display="flex" flexDirection="column">
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('purchaseTransactionsPage:state')}
                                        name="stage"
                                        options={statuses}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:filter.fromCreated')}
                                        maxDate={values?.toCreated || undefined}
                                        name="fromCreated"
                                        placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:filter.toCreated')}
                                        minDate={values?.fromCreated || undefined}
                                        name="toCreated"
                                        placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                {currentUser.type === UserType.Admin && (
                                    <>
                                        <Box mt={3} width="100%">
                                            <OutlinedDatePickerField
                                                color="secondary"
                                                label={t('purchaseTransactionsPage:filter.fromHandover')}
                                                maxDate={values?.toHandover || undefined}
                                                name="fromHandover"
                                                placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                            />
                                        </Box>
                                        <Box mt={3} width="100%">
                                            <OutlinedDatePickerField
                                                color="secondary"
                                                label={t('purchaseTransactionsPage:filter.toHandover')}
                                                minDate={values?.fromHandover || undefined}
                                                name="toHandover"
                                                placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                            />
                                        </Box>
                                    </>
                                )}

                                <Box mt={3} width="100%">
                                    <BooleanCheckbox
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:filter.showArchived')}
                                        name="showArchived"
                                        placeholder={t('purchaseTransactionsPage:filter.showArchived')}
                                    />
                                </Box>
                            </Box>
                            <Box className={styles.appltFilters}>
                                <Box alignSelf="center" margin="auto" width="100%">
                                    <Button
                                        className={styles.applyButton}
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:filter.applyFilters')}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterDialog;
