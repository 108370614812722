import { Grid, Typography, Box } from '@material-ui/core';
import cs from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, SalesTransactionActivityDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import useCommonStyles from '../../../../useCommonStyles';

export type TransactionTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const TransactionTab = ({ saleTransaction }: TransactionTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const { lastActivity } = saleTransaction;

    const getAuthorName = useCallback((activity: SalesTransactionActivityDataFragment) => {
        switch (activity.__typename) {
            case 'CreationActivity':
            case 'BiddingActivity':
            case 'SaleUpdateActivity':
            case 'SaleStageUpdateActivity':
                return activity.author.fullName;
            default:
                return '';
        }
    }, []);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.transactionDetail')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.transactionNo')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.identifier}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.transactionStatus')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.stage}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.createdOn')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:formats.longDateTime', { date: new Date(saleTransaction.createdAt) })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.createdBy')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.createdBy.fullName}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.lastUpdatedOn')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:formats.longDateTime', { date: new Date(saleTransaction.updatedAt) })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:transactionDetails.closedOn')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}> - </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {saleTransaction.originalSalesTransactionId
                                        ? t('saleTransactionsPage:transactionDetails.originalSalesTransaction')
                                        : t('saleTransactionsPage:transactionDetails.purchaseTransactionNo')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.originalSalesTransactionId
                                        ? saleTransaction.originalSalesTransaction.identifier
                                        : saleTransaction.purchaseTransaction?.identifier}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box>
                                <Typography
                                    className={cs(commonStyles.secondary2, {
                                        [commonStyles.redText]: saleTransaction.internalRemarks,
                                    })}
                                >
                                    {t('saleTransactionsPage:transactionDetails.internalRemarks')}
                                </Typography>
                                <Typography className={cs({ [commonStyles.redText]: saleTransaction.internalRemarks })}>
                                    {saleTransaction.internalRemarks}
                                </Typography>
                            </Box>
                        </Grid>
                        {saleTransaction.adminRemark && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={cs(commonStyles.secondary2)}>
                                        {t('saleTransactionsPage:transactionDetails.adminRemark')}
                                    </Typography>
                                    <Typography>{saleTransaction.adminRemark}</Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Accordion>
            </Grid>

            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.activityLog')} defaultExpanded>
                    {lastActivity && (
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary2}>
                                        {t('saleTransactionsPage:activity.action')}
                                    </Typography>
                                    <Typography className={commonStyles.autoMarginLeft}>{lastActivity.type}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary2}>
                                        {t('saleTransactionsPage:activity.updatedBy')}
                                    </Typography>
                                    <Typography className={commonStyles.autoMarginLeft}>
                                        {getAuthorName(lastActivity)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary2}>
                                        {t('saleTransactionsPage:activity.updatedOn')}
                                    </Typography>
                                    <Typography className={commonStyles.autoMarginLeft}>
                                        {t('common:formats.longDateTime', {
                                            date: new Date(lastActivity.date),
                                        })}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
