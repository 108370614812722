import {
    Accordion as MaterialAccordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'classnames';
import { ReactElement } from 'react';
import useCommonStyles from '../useCommonStyles';

export type AccordionProps = {
    title: string;
    defaultExpanded?: boolean;
    children: ReactElement | null;
    noBorder?: boolean;
    detailClassNames?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiButtonBase-root.Mui-expanded': {
                minHeight: `${theme.spacing(6)}px !important`,
                height: `${theme.spacing(6)}px !important`,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0,
            },
        },
        noPadding: {
            '& .MuiAccordionSummary-root': {
                padding: 0,
                minHeight: `${theme.spacing(3)}px !important`,
                height: `${theme.spacing(3)}px !important`,

                '&.MuiButtonBase-root.Mui-expanded': {
                    minHeight: `${theme.spacing(3)}px !important`,
                    height: `${theme.spacing(3)}px !important`,
                },
            },

            '& .MuiAccordionDetails-root': {
                padding: 0,
            },

            '& .MuiCollapse-root > .MuiCollapse-wrapper': {
                paddingTop: theme.spacing(1),
            },
        },
        title: {
            fontWeight: 'bold',
        },
    })
);

const Accordion = ({ title, defaultExpanded, children, detailClassNames, noBorder = false }: AccordionProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    return (
        <MaterialAccordion
            className={cs(styles.root, noBorder && styles.noPadding)}
            classes={{
                root: cs(
                    detailClassNames,
                    { [commonStyles.removeBoxShadow]: noBorder },
                    { [commonStyles.cardBoxShadow]: !noBorder }
                ),
            }}
            defaultExpanded={defaultExpanded}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography className={cs(!noBorder && styles.title)} color="secondary">
                    {title}
                </Typography>
            </AccordionSummary>
            {!noBorder && <Divider />}
            <AccordionDetails className={detailClassNames}>{children}</AccordionDetails>
        </MaterialAccordion>
    );
};

export default Accordion;
