import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../api';

const getNotificationType = (type: NotificationType) => {
    const { t } = useTranslation(['notifications']);

    return useMemo(() => {
        switch (type) {
            case NotificationType.SignUpApproval:
                return t('notifications:types.signUpApproval');

            case NotificationType.SignUpReject:
                return t('notifications:types.signUpReject');

            case NotificationType.PendingValuationNotice:
                return t('notifications:types.pendingValuation');

            case NotificationType.PendingRequoteNotice:
                return t('notifications:types.pendingRequote');

            case NotificationType.PendingAcceptanceNotice:
                return t('notifications:types.pendingAcceptance');

            case NotificationType.HandoverDateChangeNotice:
                return t('notifications:types.handoverDateChange');

            case NotificationType.PurchaseTransactionAmendedNotice:
                return t('notifications:types.purchaseTransactionAmendedNotice');

            case NotificationType.UpcomingBiddingNotice:
                return t('notifications:types.upcomingBidding');

            case NotificationType.AwardedNotice:
                return t('notifications:types.awardedNotice');

            case NotificationType.PurchaseTransactionValuationAcceptedNotice:
                return t('notifications:types.valuationAccepted');

            case NotificationType.PurchaseTransactionApprovalNotice:
                return t('notifications:types.pendingApproval');

            case NotificationType.SalesTransactionApprovalNotice:
                return t('notifications:types.saleTransactionPendingApproval');

            case NotificationType.PurchaseTransactionApprovedNotice:
            case NotificationType.SalesTransactionApprovedNotice:
                return t('notifications:types.transactionApproved');

            case NotificationType.PurchaseTransactionRejectedNotice:
                return t('notifications:types.transactionRejected');

            case NotificationType.SalesTransactionRejectedNotice:
                return t('notifications:types.saleTransactionRejected');

            case NotificationType.UnawardNotice:
                return t('notifications:types.unawardNotice');

            default:
                return '';
        }
    }, [type, t]);
};

export default getNotificationType;
