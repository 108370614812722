import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';

const SaleTransactionsFooter = () => {
    const currentUser = useCurrentUser();
    const history = useHistory();
    const canCreate = currentUser.type === UserType.Admin;

    if (!canCreate) {
        return null;
    }

    return (
        <Box mt={7}>
            <Box bottom="1rem" position="fixed" right="2rem">
                <Fab color="secondary" onClick={() => history.push('/salesTransactions/create')} size="large">
                    <AddIcon />
                </Fab>
            </Box>
        </Box>
    );
};

export default SaleTransactionsFooter;
