import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useBasicStyles from '../../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../../useCommonStyles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            gap: '20px',
        },
    })
);

const CreateBiddingSessionHeader = () => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { isSubmitting } = useFormikContext();

    const styles = useStyles();

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link color="secondary" component="a" onClick={history.goBack} underline="none">
                            {t('common:cancel')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                            {t('biddingSessionsPage:createTitleMobile')}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('biddingSessionsPage:createTitle')}</Typography>
            <Box className={styles.buttonContainer} ml="auto">
                <Button color="secondary" onClick={history.goBack} size="large" variant="outlined">
                    {t('common:cancel')}
                </Button>
                <Button color="secondary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                    {t('common:save')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateBiddingSessionHeader;
