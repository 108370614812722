import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../../Session';
import { useGetPurchaseTransactionsQuery } from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import { DownloadListFn } from '../../PurchaseTransactions/components';
import PurchaseHandoverTab from './PurchaseHandoverTab';
import { PurchaseHandoverListContext, PurchaseTransactionFilter } from './VtTransactions';

const pageSize = 9;

type ScTransactionsProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

const defaultPurchaseTransactionFiler: PurchaseTransactionFilter = {
    stage: 'all',
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    showArchived: false,
};

const ScTransactions = ({ downloadPurchaseTransactionsFile }: ScTransactionsProps) => {
    const currentUser = useCurrentUser();
    const { resetPurchaseTransactionsFilter = true } = (useLocation().state || {}) as LocationHistory;

    const defaultPurchaseTransactionFilterLS = useMemo(
        () =>
            !resetPurchaseTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`ScTransactionsPurchaseTransactionsListLS-${currentUser.id}`)
                  ) as PurchaseTransactionFilter) || defaultPurchaseTransactionFiler
                : defaultPurchaseTransactionFiler,
        [currentUser.id, resetPurchaseTransactionsFilter]
    );

    useEffect(() => {
        if (resetPurchaseTransactionsFilter) {
            localStorage.removeItem(`ScTransactionsPurchaseTransactionsListLS-${currentUser.id}`);
        }
    }, [resetPurchaseTransactionsFilter, currentUser.id]);

    const [purchaseTransactionFilter, setPurchaseTransactoinFilter] = useState<PurchaseTransactionFilter>(
        defaultPurchaseTransactionFilterLS
    );

    const setPurchaseTransactionFilterAndPersist = useCallback(
        (data: PurchaseTransactionFilter) => {
            setPurchaseTransactoinFilter(data);
            localStorage.setItem(`ScTransactionsPurchaseTransactionsListLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setPurchaseTransactoinFilter, currentUser.id]
    );

    const queryPurchaseTransactionFilter = useMemo(
        () => ({
            stages: purchaseTransactionFilter.stage === 'all' ? null : [purchaseTransactionFilter.stage],
            fromCreated: purchaseTransactionFilter.fromCreated,
            toCreated: purchaseTransactionFilter.toCreated,
            fromHandover: purchaseTransactionFilter.fromHandover,
            toHandover: purchaseTransactionFilter.toHandover,
            showArchived: purchaseTransactionFilter.showArchived,
        }),
        [purchaseTransactionFilter]
    );

    const [{ purchaseHandoverPage, purchaseHandoverSearch }, setPurchaseHandoverListContext] =
        useState<PurchaseHandoverListContext>({
            purchaseHandoverSearch: '',
            purchaseHandoverPage: 0,
        });

    const variables = useMemo(
        () => ({
            page: { limit: pageSize, offset: purchaseHandoverPage * pageSize },
            filter: {
                search: purchaseHandoverSearch,
                ...queryPurchaseTransactionFilter,
            },
        }),
        [purchaseHandoverPage, purchaseHandoverSearch, queryPurchaseTransactionFilter]
    );

    const { data: purchaseHandoverData, refetch } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables,
    });

    const refetchFn = useCallback(() => refetch(variables), [refetch, variables]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [purchaseHandoverPage]);

    const setPurchaseHandoverActivePage = useCallback(
        (newPage: number) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverPage: newPage }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverSearch: searchValue }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverCount = purchaseHandoverData?.purchaseTransactions?.count || 0;
    const purchaseHandoverTransactions = purchaseHandoverData?.purchaseTransactions?.items || [];

    return (
        <PurchaseHandoverTab
            count={purchaseHandoverCount}
            downloadPurchaseTransactionsFile={downloadPurchaseTransactionsFile}
            filter={purchaseTransactionFilter}
            page={purchaseHandoverPage}
            pageSize={pageSize}
            purchaseTransactions={purchaseHandoverTransactions}
            refreshFn={refetchFn}
            search={purchaseHandoverSearch}
            searchOnChange={purchaseHandoverSearchOnChange}
            setActivePage={setPurchaseHandoverActivePage}
            setFilterValue={setPurchaseTransactionFilterAndPersist}
        />
    );
};

export default ScTransactions;
