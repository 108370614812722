import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import VehicleDetailsForm from '../../CreatePurchaseTransaction/components/VehicleDetailsForm';
import { PurchaseTransactionFormValues } from '.';

type VehicleTabProps = {
    stage: PurchaseTransactionStage;
};

const VehicleTab = ({ stage }: VehicleTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const { values } = useFormikContext<PurchaseTransactionFormValues>();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')} defaultExpanded>
                    <VehicleDetailsForm dataManually={!values.vehicle.isOneMotoringData} stage={stage} onEdition />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
