import { Grid, Link, Typography } from '@material-ui/core';
import cs from 'classnames';
import React from 'react';
import { OtpConfigProps } from '../../../routes/LoginRoute';
import useCommonStyles from '../../../useCommonStyles';
import useDidUpdateEffect from '../../../utilities/useDidUpdateEffect';

export type OTPPageFooterProps = {
    resendCode: (sendByEmail?: boolean) => void;
    otpConfig?: OtpConfigProps;
};

const OTPPageFooter = ({ resendCode, otpConfig }: OTPPageFooterProps) => {
    const commonStyles = useCommonStyles();

    const linkElement = (
        <Link color="secondary" component="a" onClick={() => resendCode(otpConfig.sendByEmail)}>
            Resend OTP
        </Link>
    );
    const otpTypeElement = (
        <Link
            color="secondary"
            component="a"
            onClick={() => {
                otpConfig.toggle();
            }}
            underline="none"
        >
            click here
        </Link>
    );

    const otpType = !otpConfig.sendByEmail ? 'email' : 'mobile';
    // resend otp code once user changes the opt method (mobile to email...)
    useDidUpdateEffect(() => {
        resendCode(otpConfig.sendByEmail);
    }, [otpConfig.sendByEmail]);

    return (
        <>
            <Grid xs={12} item>
                <Typography align="center" className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>
                    Didn’t get an OTP Code? {linkElement}
                </Typography>
            </Grid>

            <Grid xs={12} item>
                <Typography align="center" className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>
                    To receive OTP via {otpType}, {otpTypeElement}
                </Typography>
            </Grid>
        </>
    );
};

export default OTPPageFooter;
