import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';

export type VehicleDiagramHeaderProps = {
    goToView: () => void;
};

const VehicleDiagramHeader = ({ goToView }: VehicleDiagramHeaderProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link color="secondary" component="a" onClick={goToView} underline="none">
                            {t('common:portalBackButton')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                            {t('purchaseTransactionsPage:vehicleDiagram.title')}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('purchaseTransactionsPage:vehicleDiagram.title')}</Typography>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    <Button color="secondary" onClick={goToView} size="large" variant="outlined">
                        {t('common:portalBackButton')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default VehicleDiagramHeader;
