import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

export type DealerInformationItemProps = {
    name: string;
    index: number;
};

const DealerInformationItem = ({ name, index }: DealerInformationItemProps) => {
    const { t } = useTranslation('saleTransactionsPage');

    return (
        <>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:vehicle.dealer', { index: index + 1 })}
                    name={`${name}.name`}
                    placeholder={t('saleTransactionsPage:vehicle.dealer', { index: index + 1 })}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:vehicle.price')}
                    name={`${name}.price`}
                    placeholder={t('saleTransactionsPage:vehicle.price')}
                    type="number"
                />
            </Grid>
        </>
    );
};

export default DealerInformationItem;
