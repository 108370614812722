import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import basicTheme from '../../layouts/BasicLayout/basicTheme';
import CreateBiddingSession, { FormValues } from '../../pages/BiddingSessions/CreateBiddingSession';

const CreateBiddingSessionsRoute = () => {
    const currentUser = useCurrentUser();
    const history = useHistory();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (currentUser.type !== UserType.Admin) {
        return <Redirect to="/403" />;
    }

    const initialValues: FormValues = {
        endPeriod: null,
        startPeriod: null,
        saleTransactions: [],
        dealers: [],
        sessionName: '',
        notes: '',
    };

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <CreateBiddingSession initialValues={initialValues} />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout onBack={history.goBack}>
            <CreateBiddingSession initialValues={initialValues} />
        </BasicLayout>
    );
};

export default CreateBiddingSessionsRoute;
