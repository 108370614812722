import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const defaultOtherLocationOption = '__OTHERS__';

const useHandoverLocationOptions = (otherValue = defaultOtherLocationOption) => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        const labels = [
            t('common:handoverLocation.preOwnedAtLengKee'),
            t('common:handoverLocation.porscheAtLengKee'),
            t('common:handoverLocation.porscheAtTanjongPenjuru'),
            t('common:handoverLocation.mcLarenAtLengKee'),
            t('common:handoverLocation.rollsRoyceAtLengKee'),
            t('common:handoverLocation.miniAtLengKee'),
            t('common:handoverLocation.mazdaAtLengKee'),
            t('common:handoverLocation.mazdaAtUbu'),
            t('common:handoverLocation.mgAtLengKee'),
        ];

        return [
            ...labels.map(label => ({ value: label, label })),
            { value: otherValue, label: t('common:handoverLocation.others') },
        ];
    }, [t, otherValue]);
};

export default useHandoverLocationOptions;
