import { Avatar, Box, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { FullUserDataFragment, UserType } from '../../../api';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';
import ProfileContainer from './ProfileContainer';

export type ProfileViewBodyProps = {
    user: FullUserDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            overflowX: 'hidden',
        },
        main: {
            [theme.breakpoints.down('sm')]: {
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: theme.spacing(30),
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '0 0 50% 50%/0 0 100% 100%',
                    transform: 'scaleX(1.8)',
                },
            },
        },
    })
);

const ProfileViewBody = ({ user }: ProfileViewBodyProps) => {
    const { t } = useTranslation(['profilePage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyle();
    const styles = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={styles.root}>
            <Box className={styles.main}>
                <Box ml={isSmall ? 3 : 0} mr={isSmall ? 3 : 0} position="relative">
                    <ProfileContainer>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <Box mt={isSmall ? 0 : -7}>
                                    <Box bottom={0} display="flex" justifyContent={isSmall ? 'center' : 'flex-start'}>
                                        <Avatar
                                            className={basicStyles.profilePhoto}
                                            src={user.profilePicture?.signedUrl}
                                        />
                                        <Box ml={isSmall ? 0 : 2} mt={isSmall ? 7 : 6}>
                                            <Typography
                                                className={cs(commonStyles.bigFontSize, commonStyles.fontWeightBold)}
                                                color="secondary"
                                            >
                                                {user.fullName}
                                            </Typography>
                                            {!isSmall && (
                                                <Typography
                                                    className={cs(commonStyles.smallFontSize, commonStyles.secondary2)}
                                                >
                                                    {user.type}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid lg={5} md={8} sm={12} xs={12} item>
                                <Box mt={isSmall ? 2 : 5}>
                                    <Grid spacing={2} container>
                                        {user.type === UserType.Admin && (
                                            <Grid xs={12} item>
                                                <Grid spacing={2} container>
                                                    <Grid xs={6} item>
                                                        <Typography
                                                            className={
                                                                isSmall
                                                                    ? cs(
                                                                          commonStyles.secondary2,
                                                                          commonStyles.smallFontSize
                                                                      )
                                                                    : cs(commonStyles.secondary2)
                                                            }
                                                        >
                                                            {t('profilePage:fields.databaseIdPlaceholder')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={6} item>
                                                        <Typography
                                                            className={cs(
                                                                commonStyles.rightTextAlign,
                                                                commonStyles.ellipsisTextOverflow,
                                                                commonStyles.smallFontSize
                                                            )}
                                                        >
                                                            {user.id}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid xs={12} item>
                                            <Grid spacing={2} container>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={
                                                            isSmall
                                                                ? cs(
                                                                      commonStyles.secondary2,
                                                                      commonStyles.smallFontSize
                                                                  )
                                                                : cs(commonStyles.secondary2)
                                                        }
                                                    >
                                                        {t('profilePage:fields.fullNamePlaceholder')}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.rightTextAlign,
                                                            commonStyles.ellipsisTextOverflow,
                                                            commonStyles.smallFontSize
                                                        )}
                                                    >
                                                        {user.fullName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid spacing={2} container>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={
                                                            isSmall
                                                                ? cs(
                                                                      commonStyles.secondary2,
                                                                      commonStyles.smallFontSize
                                                                  )
                                                                : cs(commonStyles.secondary2)
                                                        }
                                                    >
                                                        {t('profilePage:fields.emailPlaceholder')}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.rightTextAlign,
                                                            commonStyles.ellipsisTextOverflow,
                                                            commonStyles.smallFontSize
                                                        )}
                                                    >
                                                        {user.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid spacing={2} container>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={
                                                            isSmall
                                                                ? cs(
                                                                      commonStyles.secondary2,
                                                                      commonStyles.smallFontSize
                                                                  )
                                                                : cs(commonStyles.secondary2)
                                                        }
                                                    >
                                                        {t('profilePage:fields.mobilePlaceholder')}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.rightTextAlign,
                                                            commonStyles.ellipsisTextOverflow,
                                                            commonStyles.smallFontSize
                                                        )}
                                                    >
                                                        {user.mobilePhone.number}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid spacing={2} container>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={
                                                            isSmall
                                                                ? cs(
                                                                      commonStyles.secondary2,
                                                                      commonStyles.smallFontSize
                                                                  )
                                                                : cs(commonStyles.secondary2)
                                                        }
                                                    >
                                                        {t('profilePage:fields.userTypePlaceholder')}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.rightTextAlign,
                                                            commonStyles.ellipsisTextOverflow,
                                                            commonStyles.smallFontSize
                                                        )}
                                                    >
                                                        {user.type}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {user.__typename === 'SaleConsultantUser' && (
                                            <Grid xs={12} item>
                                                <Grid spacing={2} container>
                                                    <Grid xs={6} item>
                                                        <Typography
                                                            className={
                                                                isSmall
                                                                    ? cs(
                                                                          commonStyles.secondary2,
                                                                          commonStyles.smallFontSize
                                                                      )
                                                                    : cs(commonStyles.secondary2)
                                                            }
                                                        >
                                                            {t('profilePage:fields.brandPlaceholder')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={6} item>
                                                        <Typography
                                                            className={cs(
                                                                commonStyles.rightTextAlign,
                                                                commonStyles.ellipsisTextOverflow,
                                                                commonStyles.smallFontSize
                                                            )}
                                                        >
                                                            {user.brand}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {user.__typename === 'DealerUser' && (
                                            <>
                                                <Grid xs={12} item>
                                                    <Grid spacing={2} container>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={
                                                                    isSmall
                                                                        ? cs(
                                                                              commonStyles.secondary2,
                                                                              commonStyles.smallFontSize
                                                                          )
                                                                        : cs(commonStyles.secondary2)
                                                                }
                                                            >
                                                                {t('profilePage:fields.dealerBusinessNamePlaceholder')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={cs(
                                                                    commonStyles.rightTextAlign,
                                                                    commonStyles.ellipsisTextOverflow,
                                                                    commonStyles.smallFontSize
                                                                )}
                                                            >
                                                                {user.business.name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <Grid spacing={2} container>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={
                                                                    isSmall
                                                                        ? cs(
                                                                              commonStyles.secondary2,
                                                                              commonStyles.smallFontSize
                                                                          )
                                                                        : cs(commonStyles.secondary2)
                                                                }
                                                            >
                                                                {t('profilePage:fields.dealerRocPlaceholder')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={cs(
                                                                    commonStyles.rightTextAlign,
                                                                    commonStyles.ellipsisTextOverflow,
                                                                    commonStyles.smallFontSize
                                                                )}
                                                            >
                                                                {user.business.registrationNumber}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <Grid spacing={2} container>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={
                                                                    isSmall
                                                                        ? cs(
                                                                              commonStyles.secondary2,
                                                                              commonStyles.smallFontSize
                                                                          )
                                                                        : cs(commonStyles.secondary2)
                                                                }
                                                            >
                                                                {t(
                                                                    // eslint-disable-next-line max-len
                                                                    'profilePage:fields.dealerBusinessAddressPlaceholder'
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Typography
                                                                className={cs(
                                                                    commonStyles.rightTextAlign,
                                                                    commonStyles.ellipsisTextOverflow,
                                                                    commonStyles.smallFontSize
                                                                )}
                                                            >
                                                                {user.business.address}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid xs={12} item>
                                            <Box mb={1} mt={1}>
                                                <Divider />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid spacing={2} container>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={
                                                            isSmall
                                                                ? cs(
                                                                      commonStyles.secondary2,
                                                                      commonStyles.smallFontSize
                                                                  )
                                                                : cs(commonStyles.secondary2)
                                                        }
                                                    >
                                                        {t('profilePage:registeredOn')}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.rightTextAlign,
                                                            commonStyles.ellipsisTextOverflow,
                                                            commonStyles.smallFontSize
                                                        )}
                                                    >
                                                        {t('common:formats.longDateTime', {
                                                            date: new Date(user.registeredAt),
                                                        })}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </ProfileContainer>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileViewBody;
