import { Grid, Typography, Box, Link } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../../Session';
import {
    FullPurchaseTransactionDataFragment,
    PurchaseTransactionActivityDataFragment,
    UserType,
    PurchaseTransactionStage,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import useCommonStyles from '../../../../useCommonStyles';
import useViewTransactionStyles from './useViewTransactionStyles';

export type TransactionTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const TransactionTab = ({ purchaseTransaction }: TransactionTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const currentUser = useCurrentUser();
    const viewTransactionStyles = useViewTransactionStyles();
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const { lastActivity } = purchaseTransaction;

    const getAuthorName = useCallback((activity: PurchaseTransactionActivityDataFragment) => {
        switch (activity.__typename) {
            case 'CreationActivity':
            case 'ValuationActivity':
            case 'PurchaseStageUpdateActivity':
            case 'PurchaseUpdateActivity':
                return activity.author.fullName;
            default:
                return '';
        }
    }, []);

    const onClickDuplicateTransaction = useCallback(
        (id: string) => {
            history.push(`/purchaseTransaction/${id}`);
        },
        [history]
    );

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.transactionDetail')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.identifier}
                                label={t('purchaseTransactionsPage:transactionDetails.transactionNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.saleConsultant
                                        ? purchaseTransaction.saleConsultant.fullName
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:transactionDetails.assignedSalesConsultant')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.longDateTime', {
                                    date: new Date(purchaseTransaction.createdAt),
                                })}
                                label={t('purchaseTransactionsPage:transactionDetails.createdOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.createdBy.fullName}
                                label={t('purchaseTransactionsPage:transactionDetails.createdBy')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.longDateTime', {
                                    date: new Date(purchaseTransaction.updatedAt),
                                })}
                                label={t('purchaseTransactionsPage:transactionDetails.lastUpdatedOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.closedAt
                                        ? t('common:formats.longDateTime', {
                                              date: new Date(purchaseTransaction.closedAt),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:transactionDetails.closedOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Box>
                                <Typography
                                    className={cs(viewTransactionStyles.label, {
                                        [commonStyles.redText]: purchaseTransaction.internalRemarks,
                                    })}
                                >
                                    {t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                                </Typography>
                                <Typography
                                    className={cs({ [commonStyles.redText]: purchaseTransaction.internalRemarks })}
                                >
                                    {purchaseTransaction.internalRemarks || t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        {purchaseTransaction.adminRemark && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:transactionDetails.adminRemark')}
                                    </Typography>
                                    <Typography>{purchaseTransaction.adminRemark}</Typography>
                                </Box>
                            </Grid>
                        )}
                        {purchaseTransaction.stage === PurchaseTransactionStage.Cancelled && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:transactionDetails.cancelReason')}
                                    </Typography>
                                    <Typography>
                                        {purchaseTransaction.cancelReason || t('common:blankValue')}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        {purchaseTransaction.duplicateTransactions.length > 0 && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.duplicateTransactionLabel}>
                                        {t('purchaseTransactionsPage:transactionDetails.duplicateTransaction')}
                                    </Typography>
                                    <Typography className={commonStyles.autoMarginLeft}>
                                        <Box display="flex" flexDirection="column">
                                            {purchaseTransaction.duplicateTransactions.map(transaction => {
                                                const noAccess =
                                                    currentUser.type === UserType.SaleConsultant &&
                                                    transaction.saleConsultantId !== currentUser.id;

                                                if (noAccess) {
                                                    return (
                                                        <Box alignItems="center" display="flex">
                                                            <Typography className={viewTransactionStyles.content}>
                                                                {transaction.identifier}
                                                            </Typography>
                                                            <ChevronRightIcon fontSize="small" />
                                                        </Box>
                                                    );
                                                }

                                                return (
                                                    <Link
                                                        className={cs(
                                                            commonStyles.displayFlex,
                                                            commonStyles.centerAlignItems
                                                        )}
                                                        color="secondary"
                                                        onClick={() => onClickDuplicateTransaction(transaction.id)}
                                                        underline="none"
                                                    >
                                                        {transaction.identifier}
                                                        <ChevronRightIcon fontSize="small" />
                                                    </Link>
                                                );
                                            })}
                                        </Box>
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Accordion>
            </Grid>

            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.activityLog')} defaultExpanded>
                    {lastActivity && (
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:activity.action')}
                                    </Typography>
                                    <Typography className={viewTransactionStyles.content}>
                                        {lastActivity.type}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:activity.updatedBy')}
                                    </Typography>
                                    <Typography className={viewTransactionStyles.content}>
                                        {getAuthorName(lastActivity)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={viewTransactionStyles.label}>
                                        {t('purchaseTransactionsPage:activity.updatedOn')}
                                    </Typography>
                                    <Typography className={viewTransactionStyles.content}>
                                        {t('common:formats.longDateTime', {
                                            date: new Date(lastActivity.date),
                                        })}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
