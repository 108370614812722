import { Box, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    FullPurchaseTransactionDataFragment,
    PreviewPurchaseTransactionDataFragment,
    useCheckDuplicateTransactionQuery,
} from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import useDeliveryPeriod from '../../../../utilities/useDeliveryPeriod';
import usePurchaseTransactionStatus from '../../../../utilities/usePurchaseTransactionStatus';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';

export type HistoricalReferenceCardProps = {
    historicalReferenceTransaction: PreviewPurchaseTransactionDataFragment;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderRadius: theme.spacing(1),
        },
        content: {
            padding: '0 !important',
        },
        header: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            color: '#717073',
            '& .MuiGrid-item': {
                opacity: '0.8',
            },
        },
        vehicleInfo: {
            maxWidth: `calc(100% - ${theme.spacing(13)}px)`,
            '& > p': {
                marginBottom: theme.spacing(0.5),
            },
        },
        stage: {
            margin: 0,
        },
        label: {
            marginRight: theme.spacing(1),
        },
        duplicated: {
            backgroundColor: '#B23F51',
        },
    })
);

const HistoricalReferenceCard = ({
    purchaseTransaction,
    historicalReferenceTransaction,
}: HistoricalReferenceCardProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage', 'historicalReferenceFilterOptions']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const vehicleLogo = useVehicleLogo(historicalReferenceTransaction.vehicle.make);
    const transactionStatus = usePurchaseTransactionStatus(historicalReferenceTransaction.stage);

    const { data } = useCheckDuplicateTransactionQuery({
        fetchPolicy: 'network-only',
        variables: { chassisNumber: historicalReferenceTransaction.vehicle.chassisNumber },
    });

    const isDuplicated =
        purchaseTransaction.vehicle.chassisNumber.toLowerCase() ===
            historicalReferenceTransaction.vehicle.chassisNumber.toLowerCase() &&
        (data?.isDuplicated || false);

    const deliveryPeriod = useDeliveryPeriod(historicalReferenceTransaction.handover.deliveryPeriod);

    return (
        <Card className={styles.card} elevation={0}>
            <CardContent className={styles.content}>
                <Box className={styles.header}>
                    <Box display="flex">
                        <Box className={styles.vehicleInfo}>
                            <Typography>
                                {historicalReferenceTransaction.vehicle.make}{' '}
                                {historicalReferenceTransaction.vehicle.model}
                            </Typography>
                            <Typography className={cs(commonStyles.transactionStatus, styles.stage)}>
                                {transactionStatus}
                            </Typography>
                            <Typography>{historicalReferenceTransaction.vehicle.number}</Typography>
                        </Box>
                        <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                    </Box>
                </Box>
                <Box className={styles.body} display="flex">
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Typography className={commonStyles.primary2}>
                                {t('common:formats.longDate', {
                                    date: new Date(historicalReferenceTransaction.vehicle.originalRegistrationDate),
                                })}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {historicalReferenceTransaction.vehicle.mileage.toLocaleString()}
                                &nbsp;
                                {t('common:shortKilometer')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:handover.deliveryPeriod')}
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {deliveryPeriod}
                                </Typography>
                            </Box>
                        </Grid>
                        {historicalReferenceTransaction.valuation?.latestValuatedValuation?.valuatedBy &&
                            historicalReferenceTransaction.handover?.personResponsible?.id !==
                                historicalReferenceTransaction.valuation?.latestValuatedValuation?.valuatedBy?.id && (
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={commonStyles.secondary3}>
                                            {t('purchaseTransactionsPage:valuation.valuatedBy')}
                                        </Typography>
                                        <Typography
                                            className={cs(
                                                commonStyles.primary2,
                                                commonStyles.autoMarginLeft,
                                                commonStyles.rightTextAlign
                                            )}
                                        >
                                            {historicalReferenceTransaction.valuation?.latestValuatedValuation
                                                ?.valuatedBy
                                                ? historicalReferenceTransaction.valuation?.latestValuatedValuation
                                                      ?.valuatedBy.fullName
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:handover.personResponsible')}:
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {historicalReferenceTransaction.handover?.personResponsible?.fullName}
                                </Typography>
                            </Box>
                        </Grid>
                        {historicalReferenceTransaction?.handover?.assignPersonResponsibleAt && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary3}>
                                        {t('purchaseTransactionsPage:handover.purchaserAssignedOn')}:
                                    </Typography>
                                    <Typography
                                        className={cs(
                                            commonStyles.primary2,
                                            commonStyles.autoMarginLeft,
                                            commonStyles.rightTextAlign
                                        )}
                                    >
                                        {t('common:formats.longDateTime', {
                                            date: new Date(
                                                historicalReferenceTransaction.handover.assignPersonResponsibleAt
                                            ),
                                        })}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:transactionDetails.assignedSC')}
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {historicalReferenceTransaction.saleConsultant
                                        ? historicalReferenceTransaction.saleConsultant?.fullName
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:label.createdOn')}
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {t('common:formats.longDateTime', {
                                        date: new Date(historicalReferenceTransaction.createdAt),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:label.updatedOn')}
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {t('common:formats.longDateTime', {
                                        date: new Date(historicalReferenceTransaction.updatedAt),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary3}>
                                    {t('purchaseTransactionsPage:valuation.latestValuation')}
                                </Typography>
                                <Typography
                                    className={cs(
                                        commonStyles.primary2,
                                        commonStyles.autoMarginLeft,
                                        commonStyles.rightTextAlign
                                    )}
                                >
                                    {historicalReferenceTransaction.valuation?.latestValuatedValuation?.value
                                        ? `${t(
                                              'common:currency'
                                              // eslint-disable-next-line max-len
                                          )}${historicalReferenceTransaction.valuation.latestValuatedValuation.value.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {isDuplicated && (
                    <Box className={styles.duplicated} display="flex" justifyContent="center" p={1}>
                        <Typography className={commonStyles.secondary3}>
                            {t('purchaseTransactionsPage:duplicated')}
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default HistoricalReferenceCard;
