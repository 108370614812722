import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React from 'react';
import { CreatePurchaseTransactionFormValues } from '..';
import { CheckDuplicateVehicle } from './DuplicateTransactionProvider';
import ManualInformationAccordion from './ManualInformationAccordion';
import VehicleDetailsAccordion from './VehicleDetailsAccordion';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
    })
);

export type CreatePurchaseTransactionBodyProps = {
    showForm: boolean;
    setShowForm: (value: boolean) => void;
    checkDuplicateVehicle: CheckDuplicateVehicle;
};

const CreatePurchaseTransactionBody = ({
    showForm,
    setShowForm,
    checkDuplicateVehicle,
}: CreatePurchaseTransactionBodyProps) => {
    const styles = useStyles();
    const { values } = useFormikContext<CreatePurchaseTransactionFormValues>();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={styles.root} mb={isSmall ? 10 : 0}>
            <Grid spacing={2} container>
                <Grid lg={6} md={6} sm={12} style={{ order: values.vehicle.isOneMotoringData ? 1 : 0 }} xs={12} item>
                    <VehicleDetailsAccordion
                        checkDuplicateVehicle={checkDuplicateVehicle}
                        setShowForm={setShowForm}
                        showForm={showForm}
                    />
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12} item>
                    <ManualInformationAccordion />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreatePurchaseTransactionBody;
