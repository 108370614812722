import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import { useFlutter } from '../../../components/FlutterProvider';

const PurchaseTransactionsFooter = () => {
    const currentUser = useCurrentUser();
    const history = useHistory();
    const {
        capabilities: { hasOneMonitoring },
    } = useFlutter();

    const canCreate =
        currentUser.type === UserType.Admin ||
        (currentUser.type === UserType.SaleConsultant && hasOneMonitoring) ||
        currentUser.type === UserType.Approver;

    if (!canCreate) {
        return null;
    }

    return (
        <Box mt={7}>
            <Box bottom="1rem" position="fixed" right="2rem">
                <Fab color="secondary" onClick={() => history.push('/purchaseTransaction/create')} size="large">
                    <AddIcon />
                </Fab>
            </Box>
        </Box>
    );
};

export default PurchaseTransactionsFooter;
