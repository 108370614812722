import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import TransactionForm from './TransactionForm';

export type TransactionTabProps = {
    stage: PurchaseTransactionStage;
};

const TransactionTab = ({ stage }: TransactionTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.transactionDetail')} defaultExpanded>
                    <TransactionForm stage={stage} />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
