import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery } from '../../../api';
import ContentWithLabel from '../../../components/ContentWithLabel';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import { BiddingEntryDataItem } from '../EditSaleTransactions';

export type BiddingEntryItemProps = {
    biddingEntry: BiddingEntryDataItem;
    name: string;
    index: number;
};

const BiddingEntryItem = ({ biddingEntry, name, index }: BiddingEntryItemProps) => {
    const { t } = useTranslation('saleTransactionsPage');
    const { data: userQuery } = useGetUserQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            id: biddingEntry.dealerId,
        },
    });

    const getDealerBusinessName = useMemo(() => {
        switch (userQuery?.user?.__typename) {
            case 'DealerUser':
                return userQuery?.user?.business?.name;
        }

        return '';
    }, [userQuery?.user]);

    return (
        <Grid spacing={1} container>
            <Grid xs={12} item>
                <h4>{t('saleTransactionsPage:sessionDetails.topBids.index', { index })}</h4>
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={biddingEntry.id}
                    label={t('saleTransactionsPage:sessionDetails.topBids.entryId')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={getDealerBusinessName}
                    label={t('saleTransactionsPage:sessionDetails.topBids.dealer')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={t('common:formats:longDateTime', { date: new Date(biddingEntry.placedOn) })}
                    label={t('saleTransactionsPage:sessionDetails.topBids.date')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:sessionDetails.topBids.amount')}
                    name={`${name}.amount`}
                    placeholder={t('saleTransactionsPage:sessionDetails.topBids.amount')}
                    type="number"
                />
            </Grid>
        </Grid>
    );
};

export default BiddingEntryItem;
