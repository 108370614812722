import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    FullSaleTransactionDataFragment,
    useGetUserListByTypeQuery,
    UserType,
    useUpdateSalesTransactionForAwardingMutation,
} from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

export type ErrorSelectAwardeeDialogProps = {
    show?: boolean;
    setHideDialog: () => void;
    setOkDialog: () => void;
    title: string;
    cancelBtn?: string;
    okBtn?: string;
    saleTransaction: FullSaleTransactionDataFragment;
    isManualSales?: boolean;
};

type ErrorSelectAwardeeDialogFormValues = {
    handover: {
        targetHandoverDate?: Date;
        targetHandoverTime?: Date;
        vehicleSalesAgreementNo: string;
        personResponsible: string;
        manualSalesDealer?: string;
        manualSalesPrice?: number;
    };
};

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            width: '100%',
        },
    })
);

const ErrorSelectAwardeeDialog = ({
    show,
    setHideDialog,
    setOkDialog,
    title,
    cancelBtn,
    okBtn,
    saleTransaction,
    isManualSales = false,
}: ErrorSelectAwardeeDialogProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const currentUser = useCurrentUser();
    const [updateSalesTransactionForAwardingMutation] = useUpdateSalesTransactionForAwardingMutation();

    const { data: valuationTeamUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });

    const valuationTeamUserOptions = useMemo(
        () =>
            (valuationTeamUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [valuationTeamUserData]
    );
    const { data: adminUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Admin,
        },
    });

    const adminUserOptions = useMemo(
        () =>
            (adminUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [adminUserData]
    );

    const { data: approverUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Approver,
        },
    });

    const approverUserOptions = useMemo(
        () =>
            (approverUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [approverUserData]
    );
    const personResponsibleOptions = adminUserOptions.concat(approverUserOptions).concat(valuationTeamUserOptions);
    // const personResponsibleOptions = valuationTeamUserOptions;

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.requiredString('handover.personResponsible'),
                validators.requiredDate('handover.targetHandoverDate'),
                validators.requiredString('handover.vehicleSalesAgreementNo'),
                validators.only(
                    () => isManualSales,
                    validators.compose(
                        validators.requiredString('handover.manualSalesDealer'),
                        validators.requiredNumber('handover.manualSalesPrice')
                    )
                )
            ),
        [isManualSales]
    );
    const validate = useValidator(formValidator);

    const initialValues = useMemo(
        () => ({
            handover: {
                vehicleSalesAgreementNo: saleTransaction.handover.vehicleSalesAgreementNo,
                personResponsible: saleTransaction.handover.personResponsible?.id,
                targetHandoverDate: saleTransaction.handover.targetHandoverDateTime,
                manualSalesDealer: saleTransaction.handover.manualSalesDealer,
                manualSalesPrice: saleTransaction.handover.manualSalesPrice,
            },
        }),
        [
            saleTransaction.handover.vehicleSalesAgreementNo,
            saleTransaction.handover.personResponsible?.id,
            saleTransaction.handover.targetHandoverDateTime,
            saleTransaction.handover.manualSalesDealer,
            saleTransaction.handover.manualSalesPrice,
        ]
    );

    const onSubmit = useHandleError(
        async (values: ErrorSelectAwardeeDialogFormValues) => {
            await updateSalesTransactionForAwardingMutation({
                variables: {
                    salesTransactionId: saleTransaction.id,
                    fields: {
                        handover: {
                            vehicleSalesAgreementNo: values.handover.vehicleSalesAgreementNo,
                            targetHandoverDateTime: values.handover.targetHandoverDate,
                            personResponsible: values.handover.personResponsible,
                            manualSalesDealer: values.handover.manualSalesDealer,
                            manualSalesPrice: values.handover.manualSalesPrice,
                        },
                    },
                },
            });

            setOkDialog();
        },
        [setOkDialog]
    );

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="sm"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.leftTextAlign)}>
                            {title}
                        </Typography>
                    </Box>
                    <Box mb={1} mt={1}>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                            {({ handleSubmit }) => (
                                <Form>
                                    <Grid spacing={2} container>
                                        <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                            <OutlinedSelectField
                                                color="secondary"
                                                disabled={
                                                    currentUser.type !== UserType.Admin &&
                                                    currentUser.type !== UserType.Approver &&
                                                    currentUser.type !== UserType.ValuationTeam
                                                }
                                                inputProps={{
                                                    classes: {
                                                        icon: commonStyles.fillSecondary,
                                                    },
                                                }}
                                                label={t('saleTransactionsPage:handover.personResponsible')}
                                                name="handover.personResponsible"
                                                options={personResponsibleOptions}
                                                placeholder={t('saleTransactionsPage:handover.personResponsible')}
                                            />
                                        </Grid>
                                        <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                            <OutlinedDatePickerField
                                                color="secondary"
                                                label={t('saleTransactionsPage:handover.targetHandoverDate')}
                                                name="handover.targetHandoverDate"
                                                placeholder={t('saleTransactionsPage:handover.targetHandoverDate')}
                                            />
                                        </Grid>
                                        <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                            <OutlinedInputField
                                                color="secondary"
                                                label={t('saleTransactionsPage:handover.vsaNo')}
                                                name="handover.vehicleSalesAgreementNo"
                                                placeholder={t('saleTransactionsPage:handover.vsaNo')}
                                            />
                                        </Grid>
                                        {isManualSales && (
                                            <>
                                                <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                    <OutlinedInputField
                                                        color="secondary"
                                                        label={t('saleTransactionsPage:handover.manualSalesDealer')}
                                                        name="handover.manualSalesDealer"
                                                        placeholder={t(
                                                            'saleTransactionsPage:handover.manualSalesDealer'
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                    <OutlinedInputField
                                                        color="secondary"
                                                        label={t('saleTransactionsPage:handover.manualSalesPrice')}
                                                        name="handover.manualSalesPrice"
                                                        placeholder={t(
                                                            'saleTransactionsPage:handover.manualSalesPrice'
                                                        )}
                                                        type="number"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>

                                    <Box display="flex" mt={3}>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box mr={1}>
                                                <Button
                                                    className={styles.button}
                                                    onClick={setHideDialog}
                                                    size="large"
                                                    variant="outlined"
                                                >
                                                    {cancelBtn || t('common:cancel')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box>
                                                <Button
                                                    className={styles.button}
                                                    color="secondary"
                                                    onClick={() => handleSubmit()}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    {okBtn || t('common:ok')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorSelectAwardeeDialog;
