import { Button, Grid, FormControl } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullBiddingDataFragment } from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        chooseVehicleListingBtn: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        itemTitle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '140px',
            marginLeft: '10px',
            color: '#717073',
        },
        gridMargin: {
            marginBottom: '24px',
            '&:first-child': {
                marginTop: '24px',
            },
        },
    })
);

export type EditSessionFormProps = {
    bidding: FullBiddingDataFragment;
};

const EditSessionForm = ({ bidding }: EditSessionFormProps) => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);

    const styles = useStyles();

    return (
        <Grid spacing={2} container>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.bidSessionName')}
                </Grid>
                <Grid xs={10} item>
                    <OutlinedInputField
                        color="secondary"
                        defaultValue={bidding.sessionName}
                        name="sessionName"
                        placeholder={t('biddingSessionsPage:session.bidSessionName')}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.bidNotes')}
                </Grid>
                <Grid xs={10} item>
                    <OutlinedInputField
                        color="secondary"
                        defaultValue={bidding.notes}
                        name="notes"
                        placeholder={t('biddingSessionsPage:session.enterDescription')}
                        multiline
                    />
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.vehicleListings')}
                </Grid>
                <Grid xs={10} item>
                    <FormControl fullWidth>
                        <Button
                            className={styles.chooseVehicleListingBtn}
                            color="secondary"
                            variant="outlined"
                            disabled
                        >
                            {t('biddingSessionsPage:button:chooseVehicleListings')}
                            <ChevronRightIcon color="secondary" />
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.startPeriod')}
                </Grid>
                <Grid xs={3} item>
                    <OutlinedDatePickerField
                        color="secondary"
                        defaultValue={bidding.startPeriod}
                        name="startPeriod"
                        placeholder={t('purchaseTransactionsPage:session.chooseStartDate')}
                        disabled
                    />
                </Grid>
            </Grid>

            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.endPeriod')}
                </Grid>
                <Grid xs={3} item>
                    <OutlinedDatePickerField
                        color="secondary"
                        defaultValue={bidding.endPeriod}
                        name="endPeriod"
                        placeholder={t('purchaseTransactionsPage:session.chooseEndDate')}
                        disabled
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditSessionForm;
