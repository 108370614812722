import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FieldArray, Form, Formik } from 'formik';
import { getOr } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DealerInformation,
    FullPurchaseTransactionDataFragment,
    PreviewPurchaseTransactionDataFragment,
    useUpdatePurchaseTransactionForAcceptanceMutation,
} from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import { useHandleError } from '../../../../utilities/handleErrors';
import useGeneralConditionOptions from '../../../../utilities/useGeneralConditionOptions';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import DealerInformationItem from '../EditPurchaseTransaction/DealerInformationItem';

export type ErrorLatestValuationDialogProps = {
    show?: boolean;
    setHideDialog: () => void;
    setOkDialog: (updatedPurchaseTransaction: PreviewPurchaseTransactionDataFragment) => void;
    title: string;
    cancelBtn?: string;
    okBtn?: string;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

type LatestValuationFormValues = {
    latestValuation: number;
    dealerInformations: DealerInformation[];
    generalCondition: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            width: '100%',
        },
    })
);

const ErrorLatestValuationDialog = ({
    show,
    setHideDialog,
    setOkDialog,
    title,
    cancelBtn,
    okBtn,
    purchaseTransaction,
}: ErrorLatestValuationDialogProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const [updatePurchaseTransactionForAcceptance] = useUpdatePurchaseTransactionForAcceptanceMutation();
    const generalConditionOptions = useGeneralConditionOptions();
    const onSubmit = useHandleError(
        async (variables: LatestValuationFormValues) => {
            const {
                data: { purchaseTransaction: updatedPurchaseTransaction },
            } = await updatePurchaseTransactionForAcceptance({
                variables: {
                    purchaseTransactionId: purchaseTransaction.id,
                    fields: {
                        valuation: variables,
                    },
                },
            });

            setOkDialog(updatedPurchaseTransaction);
        },
        [updatePurchaseTransactionForAcceptance, setOkDialog]
    );
    const initialValues = useMemo(
        () => ({
            latestValuation: purchaseTransaction.valuation.latestValuatedValuation?.value,
            dealerInformations: purchaseTransaction.valuation.dealerInformations || [],
            generalCondition: purchaseTransaction.valuation.generalCondition,
        }),
        [
            purchaseTransaction.valuation.latestValuatedValuation?.value,
            purchaseTransaction.valuation.dealerInformations,
            purchaseTransaction.valuation.generalCondition,
        ]
    );

    const formValidator = validators.compose(
        validators.requiredNumber('latestValuation'),
        validators.requiredStringOptionValues(
            'generalCondition',
            generalConditionOptions.map(option => option.value)
        ),
        validators.forEach(
            'dealerInformations',
            validators.compose(validators.requiredString('name'), validators.requiredNumber('price'))
        )
    );
    const validate = useValidator(formValidator);

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="sm"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={styles.dialogContent}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary}>{title}</Typography>
                    </Box>
                    <Box mb={1} mt={1}>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                            {({ handleSubmit }) => (
                                <Form>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedInputField
                                            color="secondary"
                                            label={t('purchaseTransactionsPage:valuation.latestValuation')}
                                            name="latestValuation"
                                            placeholder={t('purchaseTransactionsPage:valuation.latestValuation')}
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <FieldArray
                                            name="dealerInformations"
                                            render={() =>
                                                getOr([], 'valuation.dealerInformations', purchaseTransaction).map(
                                                    (value: DealerInformation, index: number) => (
                                                        <DealerInformationItem
                                                            key={`dealerInformation-${index.toString()}`}
                                                            index={index}
                                                            name={`dealerInformations[${index}]`}
                                                        />
                                                    )
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedSelectField
                                            color="secondary"
                                            inputProps={{
                                                classes: {
                                                    icon: commonStyles.fillSecondary,
                                                },
                                            }}
                                            label={t('purchaseTransactionsPage:valuation.generalCondition')}
                                            name="generalCondition"
                                            options={generalConditionOptions}
                                            placeholder={t('purchaseTransactionsPage:valuation.generalCondition')}
                                        />
                                    </Grid>
                                    <Box display="flex" mt={3}>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box mr={1}>
                                                <Button
                                                    className={styles.button}
                                                    onClick={setHideDialog}
                                                    size="large"
                                                    variant="outlined"
                                                >
                                                    {cancelBtn || t('common:cancel')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box>
                                                <Button
                                                    className={styles.button}
                                                    color="secondary"
                                                    onClick={() => handleSubmit()}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    {okBtn || t('common:ok')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorLatestValuationDialog;
