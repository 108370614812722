import { Grid, Typography, Box } from '@material-ui/core';
import cs from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import FileList from '../../../../components/attachments/FileList';
import useCommonStyles from '../../../../useCommonStyles';

export type VehicleTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const VehicleTab = ({ saleTransaction }: VehicleTabProps) => {
    const currentUser = useCurrentUser();
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();

    const isRegistrationDateDifferent = useMemo(
        () =>
            !dayjs(saleTransaction.vehicle.originalRegistrationDate).isSame(
                saleTransaction.vehicle.firstRegistrationDate,
                'day'
            ),
        [saleTransaction.vehicle.originalRegistrationDate, saleTransaction.vehicle.firstRegistrationDate]
    );

    const isRegistrationDateGreaterOrEqual2023 = useMemo(() => {
        // Only applicable to Admin’s, Valuator’s and Approver’s view.
        if (![UserType.Admin, UserType.Approver, UserType.ValuationTeam].includes(currentUser.type)) {
            return false;
        }

        return (
            dayjs(saleTransaction.vehicle.originalRegistrationDate).year() >= 2023 ||
            dayjs(saleTransaction.vehicle.firstRegistrationDate).year() >= 2023
        );
    }, [
        currentUser.type,
        saleTransaction.vehicle.originalRegistrationDate,
        saleTransaction.vehicle.firstRegistrationDate,
    ]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.vehicleDetails')} defaultExpanded>
                    <Grid spacing={1} container>
                        {currentUser.type !== UserType.Dealer && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={cs(commonStyles.secondary2)}>
                                        {t('saleTransactionsPage:vehicle.vehicleNo')}
                                    </Typography>
                                    <Typography className={cs(commonStyles.autoMarginLeft)}>
                                        {saleTransaction.vehicle.number}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.vehicleToBeExported')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.beExported ? t('common:yes') : t('common:no')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.intendedDeregistrationDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:formats.shortDate', {
                                        date: new Date(saleTransaction.vehicle.intendedDeregistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.vehicleMake')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.make}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.vehicleModel')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.model}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.primaryColour')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.primaryColour}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.secondaryColour')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.secondaryColour}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.manufacturingYear')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.manufacturingYear}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.engineNo')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.engineNumber}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.chassisNo')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.chassisNumber}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.maxPowerOutput')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.maximumPowerOutput}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.openMarketValue')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:currency') + saleTransaction.vehicle.openMarketValue.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography
                                    className={cs(commonStyles.secondary2, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('saleTransactionsPage:vehicle.originalRegistrationDate')}
                                </Typography>
                                <Typography
                                    className={cs(commonStyles.autoMarginLeft, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('common:formats.shortDate', {
                                        date: new Date(saleTransaction.vehicle.originalRegistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography
                                    className={cs(commonStyles.secondary2, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('saleTransactionsPage:vehicle.firstRegistrationDate')}
                                </Typography>
                                <Typography
                                    className={cs(commonStyles.autoMarginLeft, {
                                        [commonStyles.redText]: isRegistrationDateDifferent,
                                    })}
                                >
                                    {t('common:formats.shortDate', {
                                        date: new Date(saleTransaction.vehicle.firstRegistrationDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.transferCount')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.transferCount}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedTop]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(commonStyles.secondary2, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('saleTransactionsPage:vehicle.actualArfPaid')}
                                </Typography>
                                <Typography
                                    className={cs(commonStyles.autoMarginLeft, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('common:currency') + saleTransaction.vehicle.actualARFPaid.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        {(saleTransaction.vehicle.opcCashRebateEligibility ||
                            saleTransaction.vehicle.opcCashRebateEligibilityExpiryDate ||
                            saleTransaction.vehicle.opcCashRebateAmount) && (
                            <>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.secondary2, commonStyles.redText)}>
                                            {t('saleTransactionsPage:vehicle.opcCashRebateEligibility')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft, commonStyles.redText)}>
                                            {saleTransaction.vehicle.opcCashRebateEligibility}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.secondary2, commonStyles.redText)}>
                                            {t('saleTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft, commonStyles.redText)}>
                                            {saleTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                ? t('common:formats.shortDate', {
                                                      date: new Date(
                                                          saleTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                      ),
                                                  })
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    className={cs({
                                        [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                        [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                    xs={12}
                                    item
                                >
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.secondary2, commonStyles.redText)}>
                                            {t('saleTransactionsPage:vehicle.opcCashRebateAmount')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft, commonStyles.redText)}>
                                            {saleTransaction.vehicle.opcCashRebateAmount
                                                ? `${t(
                                                      'common:currency'
                                                  )}${saleTransaction.vehicle.opcCashRebateAmount.toLocaleString()}`
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        <Grid
                            className={cs({
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.parfEligibility')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.parfEligibility ? t('common:yes') : t('common:no')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.parfEligibilityDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.vehicle.parfEligibilityDate),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            className={cs({
                                [commonStyles.borderedBottom]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedLeft]: isRegistrationDateGreaterOrEqual2023,
                                [commonStyles.borderedRight]: isRegistrationDateGreaterOrEqual2023,
                            })}
                            xs={12}
                            item
                        >
                            <Box display="flex">
                                <Typography
                                    className={cs(commonStyles.secondary2, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {t('saleTransactionsPage:vehicle.parfRebateValue')}
                                </Typography>
                                <Typography
                                    className={cs(commonStyles.autoMarginLeft, {
                                        [commonStyles.redText]: isRegistrationDateGreaterOrEqual2023,
                                    })}
                                >
                                    {saleTransaction.vehicle.parfRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${saleTransaction.vehicle.parfRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.coeExpiryDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:formats.shortDate', {
                                        date: new Date(saleTransaction.vehicle.coeExpiryDate),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.coeCategory')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.coeCategory}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.coePeriod')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.coePeriodYear}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.qpPaid')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.qpPaid
                                        ? `${t('common:currency')}${saleTransaction.vehicle.qpPaid.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.pqpPaid')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.pqpPaid
                                        ? `${t('common:currency')}${saleTransaction.vehicle.pqpPaid.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.coeRebateAmount')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.coeRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${saleTransaction.vehicle.coeRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.totalRebateAmount')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.totalRebateAmount
                                        ? `${t(
                                              'common:currency'
                                          )}${saleTransaction.vehicle.totalRebateAmount.toLocaleString()}`
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.ltaMessage')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.ltaMessage}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.mileage')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.mileage.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.importMethod')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.importMethod}
                                </Typography>
                            </Box>
                        </Grid>
                        {currentUser.type !== UserType.Dealer &&
                            (saleTransaction.vehicle.dealerInformations || []).map((dealer, index) => (
                                <>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={cs(commonStyles.secondary2)}>
                                                {t('saleTransactionsPage:vehicle.dealer', { index: index + 1 })}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {dealer.name ? dealer.name : t('common:blankValue')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={cs(commonStyles.secondary2)}>
                                                {t('saleTransactionsPage:vehicle.price')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {dealer.price
                                                    ? `${t('common:currency')}${dealer.price.toLocaleString()}`
                                                    : t('common:blankValue')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            ))}
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.setOfKeys')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.setOfKeys
                                        ? saleTransaction.vehicle.setOfKeys
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:vehicle.ownerHandbookCollected')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.vehicle.ownerHandbookCollected ? t('common:yes') : t('common:no')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={commonStyles.secondary2}>
                                    {t('saleTransactionsPage:handover.targetHandoverDate')}
                                </Typography>
                                <Typography className={commonStyles.autoMarginLeft}>
                                    {saleTransaction.handover.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        {[UserType.Admin, UserType.Approver, UserType.ValuationTeam].includes(currentUser.type) && (
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={cs(commonStyles.secondary2)}>
                                        {t('saleTransactionsPage:vehicle.generalCondition')}
                                    </Typography>
                                    <Typography className={cs(commonStyles.autoMarginLeft, commonStyles.redText)}>
                                        {saleTransaction.vehicle.generalCondition
                                            ? saleTransaction.vehicle.generalCondition
                                            : t('common:blankValue')}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        {[UserType.Dealer, UserType.ValuationTeam, UserType.Approver, UserType.Admin].includes(
                            currentUser.type
                        ) && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={commonStyles.secondary2}>
                                        {t('saleTransactionsPage:transactionDetails.photos')}
                                    </Typography>
                                    {saleTransaction.photos.length > 0 ? (
                                        <FileList files={saleTransaction.photos} />
                                    ) : (
                                        <Typography>{t('common:noPhotos')}</Typography>
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
