import React, { useState, useCallback, useMemo } from 'react';
import { PurchaseTransactionFilter } from '../..';
import { PreviewPurchaseTransactionDataFragment, DownloadFileType, PurchaseTransactionStage } from '../../../../api';
import ListPagination from '../../../../components/ListPagination';
import { useLoading } from '../../../../components/LoadingProvider';
import {
    PurchaseTransactionsHeader,
    PurchaseTransactionsBody,
    DownloadListFn,
} from '../../../PurchaseTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type PurchaseTransactionsTabProps = {
    search: string;
    purchaseTransactions: PreviewPurchaseTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPurchaseTransactionsFile: DownloadListFn;
    filter: PurchaseTransactionFilter;
    setFilterValue: (value: PurchaseTransactionFilter) => void;
    refreshFn?: () => Promise<unknown>;
};

const PurchaseTransactionsTab = ({
    search,
    purchaseTransactions,
    page,
    pageSize,
    count,
    filter,
    searchOnChange,
    setActivePage,
    downloadPurchaseTransactionsFile,
    setFilterValue,
    refreshFn,
}: PurchaseTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
    const { attach } = useLoading();

    const queryFilter = useMemo(
        () => ({
            stages: filter.stage === 'all' ? null : [filter.stage],
            fromCreated: filter.fromCreated,
            toCreated: filter.toCreated,
            fromHandover: filter.fromHandover,
            toHandover: filter.toHandover,
            showArchived: filter.showArchived,
        }),
        [filter]
    );

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        ...queryFilter,
                    },
                    sort: {
                        stagePriority: PurchaseTransactionStage.PendingApproval,
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadPurchaseTransactionsFile, queryFilter, search]
    );

    const setValues = useCallback(
        (value: PurchaseTransactionFilter) => {
            setFilterValue(value);
        },
        [setFilterValue]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <PurchaseTransactionsHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                refreshFn={refreshFn}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <PurchaseTransactionsBody purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default PurchaseTransactionsTab;
