import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import { VehicleForm } from '../../Forms';

const VehicleTab = () => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.vehicleDetails')} defaultExpanded>
                    <VehicleForm />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
