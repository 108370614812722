import { validators } from '@amille/simple-validators';
import { PropertyPath } from 'lodash';
import { get, isBoolean } from 'lodash/fp';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const validEmail = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && !emailRegex.test(value)) {
            return context.defaultMessages.invalidEmail;
        }

        return null;
    });

const passwordMatch = (field: PropertyPath, sourceField: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && value !== get(sourceField, values)) {
            return context.defaultMessages.passwordMismatch;
        }

        return null;
    });

const phoneRegex = /^[89][0-9]{7}$/;

const validPhone = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && !phoneRegex.test(value)) {
            return context.defaultMessages.invalidPhone;
        }

        return null;
    });

const integerRegex = /^[1-9]\d*$/;

const validInteger = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (!value || !integerRegex.test(value.toString())) {
            return context.defaultMessages.invalidValue;
        }

        return null;
    });

const validAttachments = (field: PropertyPath, errorMessage?: string) =>
    validators.custom(field, (value, values, errors, context) => {
        if (!value || value.length === 0) {
            return errorMessage || context.defaultMessages.invalidValue;
        }

        return null;
    });

const requiredBoolean = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (!isBoolean(value)) {
            return context.defaultMessages.requiredValue;
        }

        return null;
    });

const requiredStringOptionValues = (field: PropertyPath, optionValues: string[]) =>
    validators.custom(field, (value, values, errors, context) => {
        if (!optionValues.includes(value)) {
            return context.defaultMessages.requiredValue;
        }

        return null;
    });

export default {
    ...validators,
    validEmail,
    validPhone,
    passwordMatch,
    validInteger,
    validAttachments,
    requiredBoolean,
    requiredStringOptionValues,
};
