import { Grid, FormControl, Select, SelectProps, MenuItem, Chip } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

export interface OutlinedSelectProps extends SelectProps {
    value: string[];
    options: Array<{ value: string; label: string }>;
    handleDeleteFilter: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            backgroundColor: '#EDEDED',
            '& span': {
                color: theme.palette.secondary.main,
                [theme.breakpoints.down('sm')]: {
                    padding: '12px',
                    whiteSpace: 'pre-wrap',
                },
            },
            '& svg': {
                color: theme.palette.secondary.main,
                width: '15px',
                height: '15px',
            },
            [theme.breakpoints.down('sm')]: {
                height: '42px',
            },
        },
    })
);

const OutlinedFilterField = ({ value, options, placeholder, handleDeleteFilter, ...props }: OutlinedSelectProps) => {
    const styles = useStyles();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        // spread props
                        {...props}
                        renderValue={value => placeholder}
                        value={value}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value.toString()} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} item>
                <Grid spacing={1} container>
                    {value.map(filterValue => {
                        const option = options.find(element => element.value === filterValue);

                        return (
                            <Grid key={filterValue} item>
                                <Chip
                                    className={styles.chip}
                                    deleteIcon={<CloseIcon />}
                                    label={option.label}
                                    onDelete={value.length > 1 ? () => handleDeleteFilter(filterValue) : undefined}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default OutlinedFilterField;
