import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import PortalLayout from '../layouts/PortalLayout';
import { LoginPage, LoginPageValidation, LoginPageValidationProps } from '../pages/Login';
import { useRuntimeConfig } from '../runtimeConfig';

type PendingData = LoginPageValidationProps['validation'] & Pick<OtpConfigProps, 'email'>;

export type OtpConfigProps = {
    sendByEmail: boolean;
    toggle: () => void;
    email?: string;
    setEmail?: (email: string) => void;
};

type LocationState = { mobilePhone?: string };

const LoginRoute = () => {
    const [pendingData, setPendingData] = useState<PendingData | null>(null);
    const [sendOtpByEmail, setSendOtpByEmail] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');
    const { countryCode } = useRuntimeConfig();

    const { mobilePhone: initialMobilePhone = '' } = useLocation<LocationState>().state || {};

    const initialValues = useMemo(
        (): PendingData['values'] => ({
            mobilePhone: { internationalCode: countryCode, number: initialMobilePhone },
            password: '',
        }),
        [initialMobilePhone, countryCode]
    );

    const onBack = useMemo(() => {
        if (pendingData?.token) {
            return () =>
                setPendingData({
                    values: { ...pendingData.values, password: '' },
                    token: null,
                });
        }

        return undefined;
    }, [pendingData, setPendingData]);

    const toggleOtpType = useCallback(() => setSendOtpByEmail(prev => !prev), [setSendOtpByEmail]);
    const otpConfig: OtpConfigProps = {
        sendByEmail: sendOtpByEmail,
        toggle: toggleOtpType,
        email: otpEmail,
    };
    const setPendingDataWrapperFn = useCallback(
        input => {
            setPendingData(input);
            setOtpEmail(input.email);
        },
        [setPendingData, setOtpEmail]
    );

    return (
        <PortalLayout onBack={onBack}>
            {pendingData?.token ? (
                <LoginPageValidation otpConfig={otpConfig} validation={pendingData} />
            ) : (
                <LoginPage initialValues={pendingData?.values || initialValues} next={setPendingDataWrapperFn} />
            )}
        </PortalLayout>
    );
};

export default LoginRoute;
