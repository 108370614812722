import { Grid, Box, Typography, Button, FormControl, Divider, Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { merge } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import { useFlutter } from '../../../../components/FlutterProvider';
import { useLoading } from '../../../../components/LoadingProvider';
import useCommonStyles from '../../../../useCommonStyles';
import { CreatePurchaseTransactionFormValues } from '../index';
import { CheckDuplicateVehicle } from './DuplicateTransactionProvider';
import VehicleDetailsForm from './VehicleDetailsForm';

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            width: '40%',
        },
    })
);

export type VehicleDetailsAccordionProps = {
    showForm: boolean;
    setShowForm: (value: boolean) => void;
    checkDuplicateVehicle: CheckDuplicateVehicle;
};

const VehicleDetailsAccordion = ({ showForm, setShowForm, checkDuplicateVehicle }: VehicleDetailsAccordionProps) => {
    const { requestOneMonitoring } = useFlutter();
    const { values, setValues } = useFormikContext<CreatePurchaseTransactionFormValues>();
    const { attach } = useLoading();
    const currentUser = useCurrentUser();

    const proceedWithOneMonitoring = useCallback(() => {
        const execute = async () => {
            const data = await requestOneMonitoring();

            if (data.vehicle.number) {
                const nextValues = merge(values, data);

                checkDuplicateVehicle(nextValues.vehicle).then(isDuplicated => {
                    if (!isDuplicated) {
                        setValues(nextValues);
                        setShowForm(true);
                    }
                });
            }
        };

        attach(execute());
    }, [attach, requestOneMonitoring, values, checkDuplicateVehicle, setValues, setShowForm]);

    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return (
        <Accordion title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')} defaultExpanded>
            <Grid spacing={2} container>
                {showForm ? (
                    <Grid xs={12} item>
                        <VehicleDetailsForm dataManually={!values.vehicle.isOneMotoringData} onCreation />
                    </Grid>
                ) : (
                    <>
                        <Grid xs={12} item>
                            <Typography className={commonStyles.secondary2}>
                                {t('purchaseTransactionsPage:parfOrCoeRebateForRegisteredVehicle')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <FormControl fullWidth>
                                <Button color="secondary" onClick={proceedWithOneMonitoring} variant="contained">
                                    {t('purchaseTransactionsPage:button.retrieveData')}
                                </Button>
                            </FormControl>
                        </Grid>
                        {[UserType.Approver, UserType.Admin, UserType.ValuationTeam].includes(currentUser.type) && (
                            <>
                                <Grid xs={12} item>
                                    <Box alignItems="center" display="flex">
                                        <Divider className={styles.divider} />
                                        <Box ml="auto" mr="auto">
                                            <Typography className={commonStyles.secondary2}>
                                                {t('common:or')}
                                            </Typography>
                                        </Box>
                                        <Divider className={styles.divider} />
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box textAlign="center">
                                        <Link
                                            color="secondary"
                                            component="a"
                                            onClick={() => setShowForm(true)}
                                            underline="none"
                                        >
                                            {t('purchaseTransactionsPage:button.enterDataManually')}
                                        </Link>
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Accordion>
    );
};

export default VehicleDetailsAccordion;
