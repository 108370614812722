import { Container, Box, Grid } from '@material-ui/core';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { SignUpForm, SignUpFooter, SignUpTitle, SignUpNotice, SignUpHeader, SignUpFormProps } from './components';

export type SignUpPageProps = SignUpFormProps;

const SignUpPage = ({ next, initialValues }: SignUpPageProps) => (
    <Container maxWidth="sm">
        <Box pb={10} pt={10}>
            <Grid spacing={2} container>
                <SignUpHeader />
                <SignUpNotice />
                <SignUpTitle />
                <SignUpForm initialValues={initialValues} next={next} />
                <SignUpFooter />
            </Grid>
        </Box>
    </Container>
);

export default withErrorBoundary(SignUpPage);
