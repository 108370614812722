import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
    input: {
        borderRadius: '5px',
        fontSize: '1.5rem',
        width: '3.5rem',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        padding: '0.75rem',
        '-moz-appearance': 'none',

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: '0',
        },

        '&:focus': {
            outline: 'none',
        },
    },
}));

export type OTPInputProps = {
    index: number;
    autoFocus?: boolean;
};

const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
};

const otpRegex = /^[0-9]{4}$/;

const OTPInput = ({ index, autoFocus }: OTPInputProps) => {
    const styles = useStyles();
    const [{ onChange: onFieldChange, ...field }] = useField({ name: `code[${index}]` });
    const { setFieldValue } = useFormikContext();

    const onChange = useCallback(
        event => {
            const newValue = event.target.value;
            if (newValue.length <= 1) {
                // process the change as expected
                onFieldChange(event);

                const nextIndex = event.target.value && index <= 3 ? index + 1 : index - 1;
                document.querySelector<HTMLElement>(`input[name="code[${nextIndex}]"]`)?.focus();
            }
            // check paste value is valid
            else if (newValue && otpRegex.test(newValue)) {
                const otpValues: string[] = newValue.split('');

                setFieldValue('code', otpValues);
                document.querySelector<HTMLElement>(`input[name="code[3]"]`)?.focus();
            }
        },
        [index, onFieldChange, setFieldValue]
    );

    return (
        <Box ml={1} mr={1}>
            <input
                autoComplete="off"
                className={styles.input}
                maxLength={1}
                type="number"
                {...field}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={onFocus}
                pattern="[0-9]"
            />
        </Box>
    );
};

export default OTPInput;
