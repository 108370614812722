import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';

const SignUpNotice = () => {
    const { t } = useTranslation(['signUpPage']);
    const protalStyles = usePortalStyles();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid xs={12} item>
            <Typography align="center" className={cs(protalStyles.welcomeTitle)}>
                {t(isSmall ? 'signUpPage:welcomeMessageMobile' : 'signUpPage:welcomeMessage')}
            </Typography>
        </Grid>
    );
};

export default SignUpNotice;
