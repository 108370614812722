import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { BiddingStatus, PreviewBiddingDataFragment } from '../../../api';
import BiddingDetailsTab from './ViewBiddingSessions/components/BiddingDetailsTab';
import { DesktopMenuTabs, MobileMenuTabs } from '.';

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
    })
);

export type BiddingSessionsBodyProps = {
    biddings: PreviewBiddingDataFragment[];
    setStatus: (biddingStatus: BiddingStatus | null) => void;
};

const BiddingSessionsBody = ({ biddings, setStatus }: BiddingSessionsBodyProps) => {
    const styles = useStyles();
    const [value, setValue] = useState('all');

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        switch (value) {
            case 'upcoming':
                setStatus(BiddingStatus.Upcoming);
                break;

            case 'current':
                setStatus(BiddingStatus.Current);
                break;

            case 'past':
                setStatus(BiddingStatus.Past);
                break;

            case 'cancelled':
                setStatus(BiddingStatus.Cancelled);
                break;

            case 'all':
            default:
                setStatus(null);
                break;
        }
    }, [setStatus, value]);

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        {!isSmall && <DesktopMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
                        <BiddingDetailsTab biddings={biddings} />
                    </Grid>
                </Grid>
            </Box>
            {isSmall && <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
        </>
    );
};

export default BiddingSessionsBody;
