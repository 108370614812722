import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../../Session';
import {
    PurchaseTransactionStage,
    SaleTransactionStage,
    useGetPurchaseTransactionsQuery,
    useGetSaleTransactionsQuery,
} from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import TabPanel from '../../../components/TabPanel';
import { DownloadListFn } from '../../PurchaseTransactions/components';
import { PurchaseOrSalesTransactionTabType } from '../../SaleTransactions';
import { DownloadSalesTransactionsFileProvider } from '../../SaleTransactions/components';
import PurchaseHandoverTab from './PurchaseHandoverTab';
import SalesHandoverTab from './SalesHandoverTab';
import TransactionMenuTabs from './TransactionMenuTabs';

const a11yProps = (index: string) => ({
    id: `transaction-tab-${index}`,
    'aria-controls': `transactions-tabpanel-${index}`,
});

export type PurchaseHandoverListContext = {
    purchaseHandoverSearch: string;
    purchaseHandoverPage: number;
};

type SalesHandoverListContext = {
    salesHandoverSearch: string;
    salesHandoverPage: number;
};

const pageSize = 9;

type VtTransactionsProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

export type PurchaseTransactionFilter = {
    fromCreated: Date | null;
    toCreated: Date | null;
    fromHandover: Date | null;
    toHandover: Date | null;
    stage: 'all' | PurchaseTransactionStage;
    showArchived: boolean;
};

export const defaultPurchaseTransactionFilter: PurchaseTransactionFilter = {
    stage: 'all',
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    showArchived: false,
};

export type SalesTransactionFilter = {
    fromCreated: Date | null;
    toCreated: Date | null;
    fromHandover: Date | null;
    toHandover: Date | null;
    awardedDealer: string;
    stage: 'all' | SaleTransactionStage;
    showArchived: boolean;
};

export const defaultSalesTransactionFilter: SalesTransactionFilter = {
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    awardedDealer: 'all',
    stage: 'all',
    showArchived: false,
};

const VtTransactions = ({ downloadPurchaseTransactionsFile }: VtTransactionsProps) => {
    const currentUser = useCurrentUser();

    const {
        resetPurchaseTransactionsFilter = true,
        resetSalesTransactionsFilter = true,
        myTransactionsTab = 'purchaseTransactions',
    } = (useLocation().state || {}) as LocationHistory;
    const [value, setValue] = useState<PurchaseOrSalesTransactionTabType>(myTransactionsTab);

    const defaultPurchaseTransactionFilterLS = useMemo(
        () =>
            !resetPurchaseTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`VtTransactionsPurchaseTransactionsListLS-${currentUser.id}`)
                  ) as PurchaseTransactionFilter) || defaultPurchaseTransactionFilter
                : defaultPurchaseTransactionFilter,
        [resetPurchaseTransactionsFilter, currentUser.id]
    );

    useEffect(() => {
        if (resetPurchaseTransactionsFilter) {
            localStorage.removeItem(`VtTransactionsPurchaseTransactionsListLS-${currentUser.id}`);
        }
    }, [resetPurchaseTransactionsFilter, currentUser.id]);

    const [purchaseTransactionFilter, setPurchaseTransactoinFilter] = useState<PurchaseTransactionFilter>(
        defaultPurchaseTransactionFilterLS
    );

    const setPurchaseTransactionFilterAndPersist = useCallback(
        (data: PurchaseTransactionFilter) => {
            setPurchaseTransactoinFilter(data);
            localStorage.setItem(`VtTransactionsPurchaseTransactionsListLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setPurchaseTransactoinFilter, currentUser.id]
    );

    // Purchase handover data
    const [{ purchaseHandoverPage, purchaseHandoverSearch }, setPurchaseHandoverListContext] =
        useState<PurchaseHandoverListContext>({
            purchaseHandoverSearch: '',
            purchaseHandoverPage: 0,
        });

    const queryPurchaseTransactionFilter = useMemo(
        () => ({
            stages: purchaseTransactionFilter.stage === 'all' ? null : [purchaseTransactionFilter.stage],
            fromCreated: purchaseTransactionFilter.fromCreated,
            toCreated: purchaseTransactionFilter.toCreated,
            fromHandover: purchaseTransactionFilter.fromHandover,
            toHandover: purchaseTransactionFilter.toHandover,
            showArchived: purchaseTransactionFilter.showArchived,
        }),
        [purchaseTransactionFilter]
    );

    const variablesPt = useMemo(
        () => ({
            page: { limit: pageSize, offset: purchaseHandoverPage * pageSize },
            filter: {
                search: purchaseHandoverSearch,
                onlySelf: true,
                ...queryPurchaseTransactionFilter,
            },
        }),
        [purchaseHandoverPage, purchaseHandoverSearch, queryPurchaseTransactionFilter]
    );

    const { data: purchaseHandoverData, refetch: refetchPt } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesPt,
    });

    const refetchPtFn = useCallback(() => refetchPt(variablesPt), [variablesPt, refetchPt]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [purchaseHandoverPage]);

    const setPurchaseHandoverActivePage = useCallback(
        (newPage: number) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverPage: newPage }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverSearch: searchValue }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverCount = purchaseHandoverData?.purchaseTransactions?.count || 0;
    const purchaseHandoverTransactions = purchaseHandoverData?.purchaseTransactions?.items || [];

    // Sales handover data
    const [{ salesHandoverPage, salesHandoverSearch }, setSalesHandoverListContext] =
        useState<SalesHandoverListContext>({
            salesHandoverSearch: '',
            salesHandoverPage: 0,
        });

    const defaultSalesTransactionFilterLS = useMemo(
        () =>
            !resetSalesTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`VtTransactionsSaleTransactionsListLS-${currentUser.id}`)
                  ) as SalesTransactionFilter) || defaultSalesTransactionFilter
                : defaultSalesTransactionFilter,
        [resetSalesTransactionsFilter, currentUser.id]
    );

    useEffect(() => {
        if (resetSalesTransactionsFilter) {
            localStorage.removeItem(`VtTransactionsSaleTransactionsListLS-${currentUser.id}`);
        }
    }, [resetSalesTransactionsFilter, currentUser.id]);

    const [salesTransactionFilter, setSalesTransactionFilter] = useState<SalesTransactionFilter>(
        defaultSalesTransactionFilterLS
    );

    const setSaleTransactionFilterAndPersist = useCallback(
        (data: SalesTransactionFilter) => {
            setSalesTransactionFilter(data);
            localStorage.setItem(`VtTransactionsSaleTransactionsListLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setSalesTransactionFilter, currentUser.id]
    );

    const querySalesTransactionFilter = useMemo(
        () => ({
            fromCreated: salesTransactionFilter.fromCreated,
            toCreated: salesTransactionFilter.toCreated,
            fromHandover: salesTransactionFilter.fromHandover,
            toHandover: salesTransactionFilter.toHandover,
            awardedDealer: salesTransactionFilter.awardedDealer === 'all' ? null : salesTransactionFilter.awardedDealer,
            stages: salesTransactionFilter.stage === 'all' ? null : [salesTransactionFilter.stage],
            showArchived: salesTransactionFilter.showArchived,
        }),
        [salesTransactionFilter]
    );

    const variablesSt = useMemo(
        () => ({
            page: { limit: pageSize, offset: salesHandoverPage * pageSize },
            filter: {
                search: salesHandoverSearch,
                onlySelf: true,
                ...querySalesTransactionFilter,
            },
        }),
        [salesHandoverPage, salesHandoverSearch, querySalesTransactionFilter]
    );

    const { data: salesHandoverData, refetch: refetchSt } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesSt,
    });

    const refetchStFn = useCallback(() => refetchSt(variablesSt), [variablesSt, refetchSt]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [salesHandoverPage]);

    const setSalesHandoverActivePage = useCallback(
        (newPage: number) => {
            setSalesHandoverListContext(state => ({ ...state, salesHandoverPage: newPage }));
        },
        [setSalesHandoverListContext]
    );

    const salesHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setSalesHandoverListContext(state => ({ ...state, salesHandoverSearch: searchValue }));
        },
        [setSalesHandoverListContext]
    );

    const salesHandoverCount = salesHandoverData?.saleTransactions?.count || 0;
    const salesHandoverTransactions = salesHandoverData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <TransactionMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="purchaseTransactions" value={value}>
                    <PurchaseHandoverTab
                        count={purchaseHandoverCount}
                        currentTab={value}
                        downloadPurchaseTransactionsFile={downloadPurchaseTransactionsFile}
                        filter={purchaseTransactionFilter}
                        page={purchaseHandoverPage}
                        pageSize={pageSize}
                        purchaseTransactions={purchaseHandoverTransactions}
                        refreshFn={refetchPtFn}
                        search={purchaseHandoverSearch}
                        searchOnChange={purchaseHandoverSearchOnChange}
                        setActivePage={setPurchaseHandoverActivePage}
                        setFilterValue={setPurchaseTransactionFilterAndPersist}
                    />
                </TabPanel>
                <TabPanel index="salesTransactions" value={value}>
                    <DownloadSalesTransactionsFileProvider>
                        {downloadFile => (
                            <SalesHandoverTab
                                count={salesHandoverCount}
                                currentTab={value}
                                downloadSalesTransactionsFile={downloadFile}
                                filter={salesTransactionFilter}
                                page={salesHandoverPage}
                                pageSize={pageSize}
                                refreshFn={refetchStFn}
                                saleTransactions={salesHandoverTransactions}
                                search={salesHandoverSearch}
                                searchOnChange={salesHandoverSearchOnChange}
                                setActivePage={setSalesHandoverActivePage}
                                setFilterValue={setSaleTransactionFilterAndPersist}
                            />
                        )}
                    </DownloadSalesTransactionsFileProvider>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default VtTransactions;
