import { Grid, Link, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../../useCommonStyles';

const LoginFooter = () => {
    useTranslation(['loginPage']);
    const commonStyles = useCommonStyles();
    const history = useHistory();

    const linkElement = (
        <Link
            className={commonStyles.fontWeightBold}
            color="secondary"
            component="a"
            onClick={() => history.push('/signUp')}
        >
            b
        </Link>
    );

    return (
        <Grid xs={12} item>
            <Typography align="center" className={commonStyles.primary2}>
                <Trans i18nKey="loginPage:noAccountQuestion">a {linkElement} c</Trans>
            </Typography>
        </Grid>
    );
};

export default LoginFooter;
