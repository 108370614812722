import { Grid } from '@material-ui/core';
import { take } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { maxBiddingEditableRecord } from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import useCommonStyles from '../../../../useCommonStyles';
import ViewBiddingEntry from './ViewBiddingEntry';

export type TopBidsProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const TopBids = ({ saleTransaction }: TopBidsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const user = useCurrentUser();
    const commonStyles = useCommonStyles();

    if (!saleTransaction.latestBiddingSessionEntry) {
        // no bidding session
        return null;
    }

    const entries = saleTransaction.latestBiddingSessionEntry.biddingEntries;

    if (!entries.length) {
        // no biddings
        return null;
    }

    if (user.type !== UserType.Admin && user.type !== UserType.Approver) {
        // user cannot see biddings
        return null;
    }

    const sortedEntries = take(
        maxBiddingEditableRecord,
        [...entries].sort((a, b) => b.amount - a.amount)
    );

    return (
        <Grid xs={12} item>
            <Accordion
                detailClassNames={commonStyles.flexWrap}
                title={t('saleTransactionsPage:sessionDetails.topBids.title')}
                defaultExpanded
            >
                <>
                    {sortedEntries.map((entry, index) => (
                        <ViewBiddingEntry key={entry.id} entry={entry} index={index} />
                    ))}
                </>
            </Accordion>
        </Grid>
    );
};

export default TopBids;
