import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import { CreatePurchaseTransactionFormValues } from '../../pages/PurchaseTransactions/CreatePurchaseTransaction';
import FlutterAction from '../FlutterAction';

export type OneMonitoringData = {
    customer: Partial<CreatePurchaseTransactionFormValues['customer']>;
    vehicle: Partial<CreatePurchaseTransactionFormValues['vehicle']>;
};

type Response = {
    data: { key: string; value: string }[];
};

const parseCurrency = (value: string) => parseFloat(value.replace('$', '').replace(/,/g, '').trim());

const action = new FlutterAction<void, Response, OneMonitoringData>('oneMonitoring', async ({ data }) => {
    const computedData: OneMonitoringData = { customer: {}, vehicle: { isOneMotoringData: true } };

    data.forEach(({ key, value }) => {
        switch (key) {
            case 'Owner ID Type':
                computedData.customer.ownerIdType = value;
                break;

            case 'Owner ID':
                computedData.customer.ownerId = value;
                break;

            case 'Vehicle No.':
                computedData.vehicle.number = value;
                break;

            case 'Vehicle to be Exported':
                computedData.vehicle.beExported = value.toLowerCase().includes('yes');
                break;

            case 'Intended Deregistration Date':
                computedData.vehicle.intendedDeregistrationDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'Vehicle Make':
                computedData.vehicle.make = value;
                break;

            case 'Vehicle Model':
                computedData.vehicle.model = value;
                break;

            case 'Primary Colour':
                computedData.vehicle.primaryColour = value;
                break;

            case 'Manufacturing Year':
                computedData.vehicle.manufacturingYear = parseInt(value, 10);
                break;

            case 'Engine No.':
                computedData.vehicle.engineNumber = value;
                break;

            case 'Chassis No.':
                computedData.vehicle.chassisNumber = value;
                break;

            case 'Maximum Power Output':
                computedData.vehicle.maximumPowerOutput = sanitizeHtml(value).replace(/[\t\n]/g, '');
                break;

            case 'Open Market Value':
                computedData.vehicle.openMarketValue = parseCurrency(value);
                break;

            case 'Original Registeration Date':
                computedData.vehicle.originalRegistrationDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'First Registeration Date':
                computedData.vehicle.firstRegistrationDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'Transfer Count':
                computedData.vehicle.transferCount = parseInt(value, 10);
                break;

            case 'Actual ARF Paid':
                computedData.vehicle.actualARFPaid = parseCurrency(value);
                break;

            case 'PARF Eligibility':
                computedData.vehicle.parfEligibility = value.toLowerCase().includes('yes');
                break;

            case 'PARF Eligibility Expiry Date':
                computedData.vehicle.parfEligibilityDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'PARF Rebate Amount':
                computedData.vehicle.parfRebateAmount = parseCurrency(value);
                break;

            case 'COE Expiry Date':
                computedData.vehicle.coeExpiryDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'COE Category':
                computedData.vehicle.coeCategory = value;
                break;

            case 'QP Paid':
                computedData.vehicle.qpPaid = parseCurrency(value);
                break;

            case 'COE Rebate Amount':
                computedData.vehicle.coeRebateAmount = parseCurrency(value);
                break;

            case 'Secondary Colour':
                computedData.vehicle.secondaryColour = value;
                break;

            case 'OPC Cash Rebate Eligibility':
                computedData.vehicle.opcCashRebateEligibility = value;
                break;

            case 'OPC Cash Rebate Eligibility Expiry Date':
                computedData.vehicle.opcCashRebateEligibilityExpiryDate = dayjs(value).isValid()
                    ? dayjs(value).toDate()
                    : null;
                break;

            case 'OPC Cash Rebate Amount':
                computedData.vehicle.opcCashRebateAmount = parseCurrency(value);
                break;

            case 'PQP Paid':
                computedData.vehicle.pqpPaid = parseCurrency(value);
                break;

            case 'LTA Message':
                computedData.vehicle.ltaMessage = value;
                break;

            case 'Original Registration Date':
                computedData.vehicle.originalRegistrationDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'First Registration Date':
                computedData.vehicle.firstRegistrationDate = dayjs(value).isValid() ? dayjs(value).toDate() : null;
                break;

            case 'COE Period(Years)':
                computedData.vehicle.coePeriodYear = parseInt(value, 10);
                break;

            case 'Total Rebate Amount':
                computedData.vehicle.totalRebateAmount = parseCurrency(value);
                break;

            default:
                break;
        }
    });

    return computedData;
});

export default action;
