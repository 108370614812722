import { Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MobileAppBar from '../../../components/MobileAppBar';
import useCommonStyles from '../../../useCommonStyles';

const BiddingSessionsHeader = ({ refreshFn }: { refreshFn: () => Promise<unknown> }) => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <Link color="secondary" component="a" onClick={history.goBack} underline="none">
                        {t('common:cancel')}
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={2} width="100%">
                    <Typography className={commonStyles.fontWeightBold} color="secondary">
                        {t('biddingSessionsPage:details.title')}
                    </Typography>
                </Box>
                <Box ml="auto" zIndex={1}>
                    <Link color="secondary" component="a" onClick={refreshFn} underline="none">
                        {t('common:refresh')}
                    </Link>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('biddingSessionsPage:details.title')}</Typography>
            <Box className={commonStyles.buttonGroup} ml="auto">
                <Button color="secondary" onClick={refreshFn} variant="contained">
                    <RefreshIcon fontSize="medium" /> {t('common:refresh')}
                </Button>
                <Button color="secondary" onClick={() => history.push('/biddingSession/create')} variant="contained">
                    <AddIcon fontSize="medium" /> {t('common:create')}
                </Button>
            </Box>
        </Box>
    );
};

export default BiddingSessionsHeader;
