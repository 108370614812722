import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../Session';
import { useGetSaleTransactionsQuery, SaleTransactionStage, DownloadFileType } from '../../api';
import { useLoading } from '../../components/LoadingProvider';
import { LocationHistory } from '../../components/LocationHistory';
import TabPanel from '../../components/TabPanel';
import { DownloadSaleListFn } from '../SaleTransactions/components';
import { MenuTabs, SalesTransactionsTab } from './components';

const a11yProps = (index: string) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

type ForAwardingTransactionListContext = {
    forAwardingTransactionSearch: string;
    forAwardingTransactionPage: number;
};

type CurrentBiddingListContext = {
    currentBiddingSearch: string;
    currentBiddingPage: number;
};

const pageSize = 9;

export type ApproverBiddingSessionsProps = {
    downloadSalesTransactionsFile: DownloadSaleListFn;
};

export type BiddingFilter = {
    fromBidOpeningOn: Date | null;
    toBidOpeningOn: Date | null;
    fromBidClosingOn: Date | null;
    toBidClosingOn: Date | null;
};

export const defaultBiddingFilter: BiddingFilter = {
    fromBidOpeningOn: null,
    toBidOpeningOn: null,
    fromBidClosingOn: null,
    toBidClosingOn: null,
};

export type BiddingApprovalTabType = 'forAwarding' | 'currentBidding';

const ApproverBiddingSessions = ({ downloadSalesTransactionsFile }: ApproverBiddingSessionsProps) => {
    const { biddingApprovalTab = 'forAwarding' } = (useLocation().state || {}) as LocationHistory;

    const [value, setValue] = useState<BiddingApprovalTabType>(biddingApprovalTab);
    const { attach } = useLoading();
    const currentUser = useCurrentUser();

    useEffect(
        () => () => {
            // cleanup current filter
            localStorage.removeItem(`ApproverBiddingSessionsLS-${currentUser.id}`);
            localStorage.removeItem(`CurrentBiddingSessionsLS-${currentUser.id}`);
        },
        [currentUser.id]
    );

    const defaultBiddingFilterLS =
        (JSON.parse(localStorage.getItem(`ApproverBiddingSessionsLS-${currentUser.id}`)) as BiddingFilter) ||
        defaultBiddingFilter;
    const [forAwardingBiddingFilter, setForAwardingBiddingFilter] = useState<BiddingFilter>(defaultBiddingFilterLS);
    const setForAwardingBiddingFilterAndPersist = useCallback(
        (data: BiddingFilter) => {
            setForAwardingBiddingFilter(data);
            localStorage.setItem(`ApproverBiddingSessionsLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setForAwardingBiddingFilter, currentUser.id]
    );

    const [{ forAwardingTransactionPage, forAwardingTransactionSearch }, setForAwardingTransactionListContext] =
        useState<ForAwardingTransactionListContext>({
            forAwardingTransactionSearch: '',
            forAwardingTransactionPage: 0,
        });

    const variablesAwarding = useMemo(
        () => ({
            page: { limit: pageSize, offset: forAwardingTransactionPage * pageSize },
            filter: {
                search: forAwardingTransactionSearch,
                searchFields: ['vehicle.number', 'vehicle.make', 'vehicle.model'],
                stages: [SaleTransactionStage.ForAwarding],
                ...forAwardingBiddingFilter,
            },
        }),
        [forAwardingTransactionPage, forAwardingTransactionSearch, forAwardingBiddingFilter]
    );

    const { data: forAwardingTransactionsData, refetch: refetchAwarding } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesAwarding,
        skip: value !== 'forAwarding',
    });

    const refetchAwardingFn = useCallback(
        () => refetchAwarding(variablesAwarding),
        [refetchAwarding, variablesAwarding]
    );

    const setForAwardingTransactionActivePage = useCallback(
        (newPage: number) => {
            setForAwardingTransactionListContext(state => ({ ...state, forAwardingTransactionPage: newPage }));
        },
        [setForAwardingTransactionListContext]
    );

    const forAwardingTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setForAwardingTransactionListContext(state => ({ ...state, forAwardingTransactionSearch: searchValue }));
        },
        [setForAwardingTransactionListContext]
    );

    const forAwardingTransactionCount = forAwardingTransactionsData?.saleTransactions?.count || 0;
    const forAwardingTransactions = forAwardingTransactionsData?.saleTransactions?.items || [];

    const downloadForAwardingTransactionsFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search: forAwardingTransactionSearch,
                        searchFields: ['vehicle.number', 'vehicle.make', 'vehicle.model'],
                        stages: [SaleTransactionStage.ForAwarding],
                        ...forAwardingBiddingFilter,
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadSalesTransactionsFile, forAwardingTransactionSearch, forAwardingBiddingFilter]
    );

    const defaultCurrentBiddingFilterLS =
        (JSON.parse(localStorage.getItem(`CurrentBiddingSessionsLS-${currentUser.id}`)) as BiddingFilter) ||
        defaultBiddingFilter;
    const [currentBiddingFilter, setCurrentBiddingFilter] = useState<BiddingFilter>(defaultCurrentBiddingFilterLS);
    const setCurrentBiddingFilterAndPersist = useCallback(
        (data: BiddingFilter) => {
            setCurrentBiddingFilter(data);
            localStorage.setItem(`CurrentBiddingSessionsLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setCurrentBiddingFilter, currentUser.id]
    );

    const [{ currentBiddingPage, currentBiddingSearch }, setCurrentBiddingListContext] =
        useState<CurrentBiddingListContext>({
            currentBiddingSearch: '',
            currentBiddingPage: 0,
        });

    const variablesCurrentBidding = useMemo(
        () => ({
            page: { limit: pageSize, offset: currentBiddingPage * pageSize },
            filter: {
                search: currentBiddingSearch,
                searchFields: ['vehicle.number', 'vehicle.make', 'vehicle.model'],
                stages: [SaleTransactionStage.BiddingOpen],
                ...currentBiddingFilter,
            },
        }),
        [currentBiddingPage, currentBiddingSearch, currentBiddingFilter]
    );
    const { data: currentBiddingsData, refetch: refetchCurrentBidding } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesCurrentBidding,
        skip: value !== 'currentBidding',
    });

    const refetchCurrentFn = useCallback(
        () => refetchCurrentBidding(variablesCurrentBidding),
        [refetchCurrentBidding, variablesCurrentBidding]
    );

    const setCurrentBiddingActivePage = useCallback(
        (newPage: number) => {
            setCurrentBiddingListContext(state => ({ ...state, currentBiddingPage: newPage }));
        },
        [setCurrentBiddingListContext]
    );

    const currentBiddingSearchOnChange = useCallback(
        (searchValue: string) => {
            setCurrentBiddingListContext(state => ({ ...state, currentBiddingSearch: searchValue }));
        },
        [setCurrentBiddingListContext]
    );

    const currentBiddingCount = currentBiddingsData?.saleTransactions?.count || 0;
    const currentBiddings = currentBiddingsData?.saleTransactions?.items || [];

    const downloadCurrentBiddingsFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search: currentBiddingSearch,
                        searchFields: ['vehicle.number', 'vehicle.make', 'vehicle.model'],
                        stages: [SaleTransactionStage.BiddingOpen],
                        ...currentBiddingFilter,
                    },
                });
            };

            attach(execute());
        },
        [attach, currentBiddingFilter, currentBiddingSearch, downloadSalesTransactionsFile]
    );

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="forAwarding" value={value}>
                    <SalesTransactionsTab
                        count={forAwardingTransactionCount}
                        currentTab={value}
                        downloadExcel={() => downloadForAwardingTransactionsFile(DownloadFileType.Excel)}
                        downloadPdf={() => downloadForAwardingTransactionsFile(DownloadFileType.Pdf)}
                        filter={forAwardingBiddingFilter}
                        page={forAwardingTransactionPage}
                        pageSize={pageSize}
                        refreshFn={refetchAwardingFn}
                        saleTransactions={forAwardingTransactions}
                        search={forAwardingTransactionSearch}
                        searchOnChange={forAwardingTransactionSearchOnChange}
                        setActivePage={setForAwardingTransactionActivePage}
                        setFilterValue={setForAwardingBiddingFilterAndPersist}
                    />
                </TabPanel>
                <TabPanel index="currentBidding" value={value}>
                    <SalesTransactionsTab
                        count={currentBiddingCount}
                        currentTab={value}
                        downloadExcel={() => downloadCurrentBiddingsFile(DownloadFileType.Excel)}
                        downloadPdf={() => downloadCurrentBiddingsFile(DownloadFileType.Pdf)}
                        filter={currentBiddingFilter}
                        page={currentBiddingPage}
                        pageSize={pageSize}
                        refreshFn={refetchCurrentFn}
                        saleTransactions={currentBiddings}
                        search={currentBiddingSearch}
                        searchOnChange={currentBiddingSearchOnChange}
                        setActivePage={setCurrentBiddingActivePage}
                        setFilterValue={setCurrentBiddingFilterAndPersist}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default ApproverBiddingSessions;
