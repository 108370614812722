import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, SaleTransactionStage } from '../../../api';
import ContentWithLabel from '../../../components/ContentWithLabel';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

export type SessionDetailsFormProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const SessionDetailsForm = ({ saleTransaction }: SessionDetailsFormProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={saleTransaction.latestBiddingSession?.sessionName || t('common:blankValue')}
                    label={t('saleTransactionsPage:sessionDetails.bidSessionName')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={
                        saleTransaction.latestBiddingSession?.startPeriod
                            ? t('common:formats.longDateTime', {
                                  date: new Date(saleTransaction.latestBiddingSession.startPeriod),
                              })
                            : t('common:blankValue')
                    }
                    label={t('saleTransactionsPage:sessionDetails.bidOpeningOn')}
                />
            </Grid>
            <Grid xs={12} item>
                <ContentWithLabel
                    content={
                        saleTransaction.latestBiddingSession?.endPeriod
                            ? t('common:formats.longDateTime', {
                                  date: new Date(saleTransaction.latestBiddingSession.endPeriod),
                              })
                            : t('common:blankValue')
                    }
                    label={t('saleTransactionsPage:sessionDetails.bidClosingDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={
                        saleTransaction.stage === SaleTransactionStage.New ||
                        saleTransaction.stage === SaleTransactionStage.Closed
                    }
                    label={t('saleTransactionsPage:sessionDetails.bidNotes')}
                    name="biddingSession.notes"
                    placeholder={t('saleTransactionsPage:sessionDetails.bidNotes')}
                />
            </Grid>
        </Grid>
    );
};

export default SessionDetailsForm;
