import { Validator, defaultMessages } from '@amille/simple-validators';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type ValidatorContext = { t: TFunction };

const useValidator = <TValues = any, TContext = any>(
    validator: Validator<TContext & ValidatorContext>,
    context?: TContext
) => {
    const { t } = useTranslation(['common']);

    const messages = useMemo(
        () => ({
            ...defaultMessages,
            requiredValue: t('common:formErrors.required'),
            passwordMismatch: t('common:formErrors.passwordMismatch'),
            invalidEmail: t('common:formErrors.invalidEmail'),
            invalidPhone: t('common:formErrors.invalidPhone'),
            invalidValue: t('common:formErrors.invalidValue'),
        }),
        [t]
    );

    const enhancedContext = useMemo(() => ({ ...context, t }), [context, t]);

    return useCallback(
        (values: TValues) => validator.validate(values, enhancedContext, { defaultMessages: messages }),
        [validator, messages, enhancedContext]
    );
};

export default useValidator;
