import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import PortalLayout from '../layouts/PortalLayout';
import { SignUpFormValues, SignUpPage, SignUpPageValidationProps, SignUpValidation } from '../pages/SignUp';
import { useRuntimeConfig } from '../runtimeConfig';
import { OtpConfigProps } from './LoginRoute';

type PendingData = SignUpPageValidationProps['validation'];

type LocationState = { mobilePhone?: string };

const SignUpRoute = () => {
    const [pendingData, setPendingData] = useState<PendingData | null>(null);
    const { countryCode } = useRuntimeConfig();
    const [sendOtpByEmail, setSendOtpByEmail] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');

    const { mobilePhone: initialMobilePhone = '' } = useLocation<LocationState>().state || {};

    const initialValues = useMemo(
        (): SignUpFormValues => ({
            fullName: '',
            email: '',
            mobilePhone: { internationalCode: countryCode, number: initialMobilePhone },
            brand: { main: '' },
            password: '',
            confirmPassword: '',
            type: '',
        }),
        [initialMobilePhone, countryCode]
    );
    const toggleOtpType = useCallback(() => setSendOtpByEmail(prev => !prev), [setSendOtpByEmail]);
    const otpConfig: OtpConfigProps = {
        sendByEmail: sendOtpByEmail,
        toggle: toggleOtpType,
        email: otpEmail,
    };
    const setPendingDataWrapperFn = useCallback(
        input => {
            setPendingData(input);
            setOtpEmail(input.email);
        },
        [setPendingData, setOtpEmail]
    );

    const onBack = useMemo(() => {
        if (pendingData?.token) {
            return () => setPendingData({ ...pendingData, token: null });
        }

        return undefined;
    }, [pendingData, setPendingData]);

    return (
        <PortalLayout onBack={onBack}>
            {pendingData?.token ? (
                <SignUpValidation otpConfig={otpConfig} validation={pendingData} />
            ) : (
                <SignUpPage initialValues={pendingData?.values || initialValues} next={setPendingDataWrapperFn} />
            )}
        </PortalLayout>
    );
};

export default SignUpRoute;
