import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import React, { useCallback } from 'react';
import { useCurrentUser } from '../../../Session';
import {
    FullPurchaseTransactionDataFragment,
    PurchaseTransactionStage,
    useRemoveVehicleDiagramCommentMutation,
    UserType,
    VehicleDiagramComment,
} from '../../../api';
import Marker from './Marker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentContainer: {
            display: 'flex',
            marginBottom: '10px',
            gap: 10,
        },
        comment: {
            display: 'flex',
            flex: '1',
            '& p': {
                wordBreak: 'break-all',
            },
        },
        deleteComment: {
            alignSelf: 'center',
        },
        removeIcon: {
            color: '#b23f51',
            cursor: 'pointer',
        },
    })
);

export type VehicleCommentProps = {
    comment: VehicleDiagramComment;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleComment = ({ comment, purchaseTransaction }: VehicleCommentProps) => {
    const vehicleDiagramCommentID = comment.id;
    const purchaseTransactionId = purchaseTransaction.id;

    const [removeComment] = useRemoveVehicleDiagramCommentMutation({
        variables: { purchaseTransactionId, vehicleDiagramCommentID },
    });

    const styles = useStyles();

    const currentUser = useCurrentUser();

    const onRemoveComment = useCallback(() => {
        if (
            purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
            purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled
        ) {
            removeComment();
        }
    }, [purchaseTransaction.stage, removeComment]);

    return (
        <Grid lg={6} xs={12} item>
            <Box className={styles.commentContainer}>
                <Box>
                    <Marker key={comment.index} index={comment.index} isSmall={false} />
                </Box>
                <Box className={styles.comment}>
                    <Typography>{comment.comment}</Typography>
                </Box>
                {(currentUser.type === UserType.Admin || currentUser.type === UserType.ValuationTeam) && (
                    <Box className={styles.deleteComment}>
                        <RemoveCircleIcon className={styles.removeIcon} onClick={onRemoveComment} />
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

export default VehicleComment;
