import { Grid } from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { BiddingEntryDataItem, EditSaleTransactionFormValues } from '../EditSaleTransactions';
import BiddingEntryItem from './BiddingEntryItem';

const BiddingEntriesForm = () => {
    const {
        values: {
            biddingSession: { biddingEntries = [] },
        },
    } = useFormikContext<EditSaleTransactionFormValues>();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <FieldArray
                    name="biddingSession.biddingEntries"
                    render={() =>
                        (biddingEntries || []).map((value: BiddingEntryDataItem, index: number) => (
                            <BiddingEntryItem
                                key={`biddingEntries-${index.toString()}`}
                                biddingEntry={value}
                                index={index + 1}
                                name={`biddingSession.biddingEntries[${index}]`}
                            />
                        ))
                    }
                />
            </Grid>
        </Grid>
    );
};

export default BiddingEntriesForm;
