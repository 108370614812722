import { Box, Button, Dialog, DialogContent, Grid, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullPurchaseTransactionDataFragment,
    useGetUserListByTypeQuery,
    UserType,
    useUpdatePurchaseTransactionForInitValuationMutation,
} from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import { useHandleError } from '../../../../utilities/handleErrors';
import useDeliveryPeriodOptions from '../../../../utilities/useDeliveryPeriodOptions';
import useImportMethodOptions from '../../../../utilities/useImportMethodOptions';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

export type ErrorSendForValuationDialogProps = {
    show?: boolean;
    setHideDialog: () => void;
    setOkDialog: () => void;
    title: string;
    cancelBtn?: string;
    okBtn?: string;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

type SendForValuationFormValues = {
    purchaseTransactionId: string;
    internalRemarks: string;
    vehicle: Pick<FullPurchaseTransactionDataFragment['vehicle'], 'mileage' | 'importMethod'>;
    handover: {
        personResponsible: string;
    } & Pick<FullPurchaseTransactionDataFragment['handover'], 'deliveryPeriod'>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            width: '100%',
        },
        adornmentRoot: {
            '& > p': {
                color: theme.palette.secondary.main,
            },
        },
    })
);

const ErrorSendForValuationDialog = ({
    show,
    setHideDialog,
    setOkDialog,
    title,
    cancelBtn,
    okBtn,
    purchaseTransaction,
}: ErrorSendForValuationDialogProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const [updatePurchaseTransactionForInitValuation] = useUpdatePurchaseTransactionForInitValuationMutation();
    const importMethodOptions = useImportMethodOptions();
    const deliveryPeriods = useDeliveryPeriodOptions();

    const { data } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });
    const valuationTeamUserOptions = useMemo(
        () =>
            (data?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [data]
    );

    const onSubmit = useHandleError(
        async (fields: SendForValuationFormValues) => {
            await updatePurchaseTransactionForInitValuation({
                variables: {
                    purchaseTransactionId: purchaseTransaction.id,
                    fields: {
                        internalRemarks: fields.internalRemarks,
                        vehicle: {
                            importMethod: fields.vehicle.importMethod,
                            mileage: fields.vehicle.mileage,
                        },
                        handover: {
                            personResponsible: fields.handover.personResponsible,
                            deliveryPeriod: fields.handover.deliveryPeriod,
                        },
                    },
                },
            });

            setOkDialog();
        },
        [updatePurchaseTransactionForInitValuation, setOkDialog]
    );
    const initialValues = useMemo(
        () => ({
            purchaseTransactionId: purchaseTransaction.id,
            internalRemarks: purchaseTransaction.internalRemarks,
            handover: {
                ...purchaseTransaction.handover,
                personResponsible: purchaseTransaction.handover?.personResponsible.id,
            },
            vehicle: purchaseTransaction.vehicle,
        }),
        [
            purchaseTransaction.id,
            purchaseTransaction.internalRemarks,
            purchaseTransaction.handover,
            purchaseTransaction.vehicle,
        ]
    );

    const formValidator = validators.compose(
        validators.requiredNumber('vehicle.mileage'),
        validators.requiredString('vehicle.importMethod'),
        validators.requiredNumber('handover.deliveryPeriod'),
        validators.requiredString('handover.personResponsible'),
        validators.requiredString('internalRemarks')
    );
    const validate = useValidator(formValidator);

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="sm"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={styles.dialogContent}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary}>{title}</Typography>
                    </Box>
                    <Box mb={1} mt={1}>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                            {({ handleSubmit }) => (
                                <Form>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedInputField
                                            color="secondary"
                                            label={t(
                                                'purchaseTransactionsPage:transactionDetails.internalRemarkInPopup'
                                            )}
                                            name="internalRemarks"
                                            placeholder={t(
                                                'purchaseTransactionsPage:transactionDetails.internalRemarkInPopup'
                                            )}
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedInputField
                                            color="secondary"
                                            endAdornment={
                                                <InputAdornment classes={{ root: styles.adornmentRoot }} position="end">
                                                    {t('common:shortKilometer')}
                                                </InputAdornment>
                                            }
                                            label={t('purchaseTransactionsPage:vehicle.mileage')}
                                            name="vehicle.mileage"
                                            placeholder={t('purchaseTransactionsPage:vehicle.mileage')}
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedSelectField
                                            color="secondary"
                                            inputProps={{
                                                classes: {
                                                    icon: commonStyles.fillSecondary,
                                                },
                                            }}
                                            label={t('purchaseTransactionsPage:vehicle.importMethod')}
                                            name="vehicle.importMethod"
                                            options={importMethodOptions}
                                            placeholder={t('purchaseTransactionsPage:vehicle.importMethod')}
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedSelectField
                                            color="secondary"
                                            inputProps={{
                                                classes: {
                                                    icon: commonStyles.fillSecondary,
                                                },
                                            }}
                                            label={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                                            name="handover.deliveryPeriod"
                                            options={deliveryPeriods}
                                            placeholder={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                                        />
                                    </Grid>
                                    <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                        <OutlinedSelectField
                                            color="secondary"
                                            inputProps={{
                                                classes: {
                                                    icon: commonStyles.fillSecondary,
                                                },
                                            }}
                                            label={t('purchaseTransactionsPage:handover.personResponsible')}
                                            name="handover.personResponsible"
                                            options={valuationTeamUserOptions}
                                            placeholder={t('purchaseTransactionsPage:handover.personResponsible')}
                                        />
                                    </Grid>
                                    <Box display="flex" mt={3}>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box mr={1}>
                                                <Button
                                                    className={styles.button}
                                                    onClick={setHideDialog}
                                                    size="large"
                                                    variant="outlined"
                                                >
                                                    {cancelBtn || t('common:cancel')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={12} xs={12} item>
                                            <Box>
                                                <Button
                                                    className={styles.button}
                                                    color="secondary"
                                                    onClick={() => handleSubmit()}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    {okBtn || t('common:ok')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorSendForValuationDialog;
