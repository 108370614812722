import { Grid } from '@material-ui/core';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedPasswordField from '../../../../components/fields/OutlinedPasswordField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import {
    businessAdornment,
    emailAdornment,
    passwordAdornment,
    peopleAdornment,
    personAdornment,
    phoneAdornment,
} from '../../../../components/fields/adornments';
import usePortalStyles from '../../../../layouts/PortalLayout/usePortalStyles';
import useCommonStyles from '../../../../useCommonStyles';
import useBrandOptions, { defaultOtherBrandOption } from '../../../../utilities/useBrandOptions';
import useUserTypeOptions from '../../../../utilities/useUserTypeOptions';
import { SignUpFormValues } from './index';

const SignUpFormFields = () => {
    const { t } = useTranslation(['signUpPage']);
    const commonStyles = useCommonStyles();
    const portalStyles = usePortalStyles();
    const { values, setFieldValue } = useFormikContext<SignUpFormValues>();
    const { type: userType, brand } = values;

    useEffect(() => {
        if (userType !== UserType.SaleConsultant) {
            // reset brand
            setFieldValue('brand', { main: '' });
        }

        if (userType !== UserType.Dealer) {
            // remove business
            setFieldValue('business', undefined);
        }
    }, [userType, setFieldValue]);

    useEffect(() => {
        if (brand.main !== defaultOtherBrandOption) {
            // remove other brand
            setFieldValue('brand.other', undefined);
        }
    }, [brand, setFieldValue]);

    const brandOptions = useBrandOptions();
    const userTypeOptions = useUserTypeOptions();

    return (
        <>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="fullName"
                    placeholder={t('signUpPage:fields.fullNamePlaceholder')}
                    startAdornment={personAdornment}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="email"
                    placeholder={t('signUpPage:fields.emailPlaceholder')}
                    startAdornment={emailAdornment}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="mobilePhone.number"
                    placeholder={t('signUpPage:fields.phonePlaceholder')}
                    startAdornment={phoneAdornment}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    name="type"
                    options={userTypeOptions}
                    placeholder={t('signUpPage:fields.userTypePlaceholder')}
                    startAdornment={peopleAdornment}
                />
            </Grid>
            {userType === UserType.SaleConsultant && (
                <>
                    <Grid xs={12} item>
                        <OutlinedSelectField
                            className={cs(portalStyles.inputField, commonStyles.secondary)}
                            inputProps={{
                                classes: {
                                    icon: commonStyles.fillSecondary,
                                },
                            }}
                            name="brand.main"
                            options={brandOptions}
                            placeholder={t('signUpPage:fields.brandPlaceholder')}
                            startAdornment={businessAdornment}
                        />
                    </Grid>
                    {brand.main === defaultOtherBrandOption && (
                        <Grid xs={12} item>
                            <OutlinedInputField
                                className={cs(portalStyles.inputField, commonStyles.secondary)}
                                name="brand.other"
                                placeholder={t('signUpPage:fields.otherBrandPlaceholder')}
                                startAdornment={businessAdornment}
                            />
                        </Grid>
                    )}
                </>
            )}
            <Grid xs={12} item>
                <OutlinedPasswordField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="password"
                    placeholder={t('signUpPage:fields.passwordPlaceholder')}
                    startAdornment={passwordAdornment}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedPasswordField
                    className={cs(portalStyles.inputField, commonStyles.secondary)}
                    name="confirmPassword"
                    placeholder={t('signUpPage:fields.confirmPasswordPlaceholder')}
                    startAdornment={passwordAdornment}
                />
            </Grid>
            {userType === UserType.Dealer && (
                <>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputField, commonStyles.secondary)}
                            name="business.name"
                            placeholder={t('signUpPage:fields.dealerBusinessNamePlaceholder')}
                            startAdornment={businessAdornment}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputField, commonStyles.secondary)}
                            name="business.registrationNumber"
                            placeholder={t('signUpPage:fields.dealerRocPlaceholder')}
                            startAdornment={businessAdornment}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputField, commonStyles.secondary)}
                            name="business.address"
                            placeholder={t('signUpPage:fields.dealerBusinessAddressPlaceholder')}
                            startAdornment={businessAdornment}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default SignUpFormFields;
