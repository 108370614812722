import { Paper, Box, FormControl, Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../useCommonStyles';

export type CreatePurchaseTransactionFooterProps = {
    showForm: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: theme.spacing(10),
        },
    })
);

const CreatePurchaseTransactionFooter = ({ showForm }: CreatePurchaseTransactionFooterProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const { isSubmitting } = useFormikContext();

    return (
        <Box bottom={0} position="fixed" width="100%">
            <Paper className={styles.paper} elevation={3}>
                <Box alignItems="center" display="flex" height="100%" ml={3} mr={3}>
                    <FormControl fullWidth>
                        <Button
                            classes={{ disabled: commonStyles.disabledSecondaryButton }}
                            color="secondary"
                            disabled={isSubmitting || !showForm}
                            type="submit"
                            variant="contained"
                        >
                            {t('common:save')}
                        </Button>
                    </FormControl>
                </Box>
            </Paper>
        </Box>
    );
};

export default CreatePurchaseTransactionFooter;
