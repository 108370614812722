import { Box, Button, FormControl, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useUpdateTransactionToCancelMutation } from '../../../../api';
import { useAlert } from '../../../../components/AlertProvider';
import Dialog from '../../../../components/Dialog';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import { useHandleError } from '../../../../utilities/handleErrors';

export type CancelTransactionDialogProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    open: boolean;
    onClose: () => void;
};

const initialValues = {
    cancelReason: '',
};

const CancelTransactionDialog = ({ purchaseTransaction, open, onClose }: CancelTransactionDialogProps) => {
    const { t } = useTranslation('purchaseTransactionsPage');
    const [updateToCancel] = useUpdateTransactionToCancelMutation();
    const { show } = useAlert();

    const onSubmit = useHandleError(
        async ({ cancelReason }) => {
            await updateToCancel({
                variables: {
                    transactionId: purchaseTransaction.id,
                    cancelReason,
                },
            });
            show('success', t('purchaseTransactionsPage:successfulMessage.updateToCancel'));
            onClose();
        },
        [onClose]
    );

    return (
        <Dialog
            maxWidth="xs"
            onCloseClick={onClose}
            open={open}
            title={t('purchaseTransactionsPage:dialog.titles.cancelTransaction')}
            fullWidth
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <OutlinedInputField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:transactionDetails.cancelReason')}
                                    name="cancelReason"
                                    placeholder={t('purchaseTransactionsPage:transactionDetails.cancelReason')}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <FormControl>
                                <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                                    {t('purchaseTransactionsPage:button.cancelTransaction')}
                                </Button>
                            </FormControl>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CancelTransactionDialog;
