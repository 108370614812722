import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useUpdateTransactionToFinalValuationMutation } from '../../../../api';
import { useAlert } from '../../../../components/AlertProvider';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';

export type AcceptValuationConfirmDialogProps = {
    show?: boolean;
    setHideConfirmDialog: () => void;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
    })
);

const AcceptValuationConfirmDialog = ({
    show,
    setHideConfirmDialog,
    purchaseTransaction,
}: AcceptValuationConfirmDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { show: showFn } = useAlert();

    const [updateToFinalValuation] = useUpdateTransactionToFinalValuationMutation({
        variables: { transactionId: purchaseTransaction.id },
    });

    const onUpdateToFinalValuation = useCallback(() => {
        setHideConfirmDialog();
        updateToFinalValuation();
        showFn('success', t('purchaseTransactionsPage:successfulMessage.valuationAccepted'));
    }, [setHideConfirmDialog, updateToFinalValuation, showFn, t]);

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(commonStyles.secondaryBgColor, styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={commonStyles.primary2}>
                            {t('purchaseTransactionsPage:acceptDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={cs(commonStyles.smallFontSize, commonStyles.primary2)}>
                        {t('purchaseTransactionsPage:acceptDialog.body')}
                    </Typography>
                </Box>

                <Box display="flex" mt={3}>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={setHideConfirmDialog}
                        size="large"
                        variant="outlined"
                    >
                        {t('purchaseTransactionsPage:acceptDialog.button.cancel')}
                    </Button>
                    <Button
                        className={cs(commonStyles.primary2, commonStyles.primary2Border, styles.button)}
                        onClick={onUpdateToFinalValuation}
                        size="large"
                        variant="outlined"
                    >
                        {t('purchaseTransactionsPage:acceptDialog.button.continue')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AcceptValuationConfirmDialog;
