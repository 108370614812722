import { TableCell, TableRow, Box, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FullUserDataFragment, UserType } from '../../../api';

import useCommonStyles from '../../../useCommonStyles';
import useUserTypeOptions from '../../../utilities/useUserTypeOptions';

export type UsersRowProps = {
    user: FullUserDataFragment;
    openEditForm: () => void;
};

const UsersRow = ({ user, openEditForm }: UsersRowProps) => {
    const { t } = useTranslation(['usersPage']);
    const commonStyles = useCommonStyles();
    const userTypes = useUserTypeOptions();
    const getUserTypeFn = useCallback((userType: UserType) => userTypes.find(t => t.value === userType), [userTypes]);

    const getAdditionalInfo = useCallback((userInfo: FullUserDataFragment) => {
        switch (userInfo.__typename) {
            case 'SaleConsultantUser':
                return userInfo.brand;
            case 'DealerUser':
                return (
                    <>
                        {userInfo.business.name}, {userInfo.business.registrationNumber}, {userInfo.business.address}
                    </>
                );
            default:
                return '';
        }
    }, []);

    return (
        <TableRow className={commonStyles.tableRow}>
            <TableCell>{user.fullName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.mobilePhone.number}</TableCell>
            <TableCell>{getUserTypeFn(user.type)?.label}</TableCell>
            <TableCell>{getAdditionalInfo(user)}</TableCell>
            <TableCell>{t('common:formats.longDateTime', { date: new Date(user.registeredAt) })}</TableCell>
            <TableCell>
                {t('common:formats.longDateTime', user.lastActive !== null ? { date: new Date(user.lastActive) } : '')}
            </TableCell>
            <TableCell>
                <Box className={commonStyles.buttonGroup}>
                    <IconButton color="secondary" onClick={openEditForm}>
                        <EditIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default UsersRow;
