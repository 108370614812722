import { Dialog, Slide, Transitions, Link, Box, Typography, Button } from '@material-ui/core';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAwardedDealersQuery } from '../../../api';
import MobileAppBar from '../../../components/MobileAppBar';
import BooleanCheckbox from '../../../components/fields/BooleanCheckbox';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import useSalesTransactionStateFilterOptoins from '../../../utilities/useSalesTransactionStateFilterOptoins';
import useFilterDialogStyles from '../../SaleTransactions/components/useFilterDialogStyles';
import { defaultPurchaseTransactionFilter, SalesTransactionFilter } from './VtTransactions';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (value: SalesTransactionFilter) => void;
    filter: SalesTransactionFilter;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterDialog = ({ open, setOpen, setValues, filter }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterDialogStyles();

    const onSubmit = useCallback(
        (values: SalesTransactionFilter) => {
            setValues(values);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    const { data: awardedDealersData } = useGetAwardedDealersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const awardedDealerOptions = useMemo(
        () => [
            { value: 'all', label: t('saleTransactionsPage:filter.all') },
            ...(awardedDealersData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        ],
        [awardedDealersData, t]
    );

    const statuses = useSalesTransactionStateFilterOptoins();

    return (
        <Dialog TransitionComponent={Transition} open={open} fullScreen>
            <Formik initialValues={filter} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                    <>
                        <MobileAppBar>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setOpen(false)}
                                underline="none"
                            >
                                {t('common:cancel')}
                            </Link>
                            <Box display="flex" justifyContent="center" width="100%">
                                <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                                    {t('common:filter')}
                                </Typography>
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() =>
                                    Object.entries(defaultPurchaseTransactionFilter).forEach(([key, value]) => {
                                        setFieldValue(key, value);
                                    })
                                }
                                underline="none"
                            >
                                {t('common:reset')}
                            </Link>
                        </MobileAppBar>
                        <Form>
                            <Box className={styles.content} display="flex" flexDirection="column">
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('saleTransactionsPage:filter.state')}
                                        name="stage"
                                        options={statuses}
                                    />
                                </Box>

                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.fromCreated')}
                                        maxDate={values?.toCreated || undefined}
                                        name="fromCreated"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.toCreated')}
                                        minDate={values?.fromCreated || undefined}
                                        name="toCreated"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.fromHandover')}
                                        maxDate={values?.toHandover || undefined}
                                        name="fromHandover"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.toHandover')}
                                        minDate={values?.fromHandover || undefined}
                                        name="toHandover"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('saleTransactionsPage:filter.awardedDealer')}
                                        name="awardedDealer"
                                        options={awardedDealerOptions}
                                    />
                                </Box>

                                <Box mt={3} width="100%">
                                    <BooleanCheckbox
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.showArchived')}
                                        name="showArchived"
                                        placeholder={t('saleTransactionsPage:filter.showArchived')}
                                    />
                                </Box>
                            </Box>
                            <Box className={styles.appltFilters}>
                                <Box alignSelf="center" margin="auto" width="100%">
                                    <Button
                                        className={styles.applyButton}
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:filter.applyFilters')}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterDialog;
