import { Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MobileAppBar from '../../../components/MobileAppBar';
import useCommonStyles from '../../../useCommonStyles';

export type EditSaleTransactionHeaderProps = {
    goToView: () => void;
};

const EditSaleTransactionHeader = ({ goToView }: EditSaleTransactionHeaderProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <Link color="secondary" component="a" onClick={goToView} underline="none">
                        {t('common:cancel')}
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={2} width="100%">
                    <Typography className={commonStyles.fontWeightBold} color="secondary">
                        {t('saleTransactionsPage:editTitle')}
                    </Typography>
                </Box>
                <Box ml="auto" zIndex={1}>
                    <Link color="secondary" component="button" type="submit" underline="none">
                        {t('common:save')}
                    </Link>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('saleTransactionsPage:editTitle')}</Typography>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    <Button color="secondary" onClick={goToView} variant="outlined">
                        {t('common:cancel')}
                    </Button>
                    <Button color="secondary" type="submit" variant="contained">
                        {t('common:save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default EditSaleTransactionHeader;
