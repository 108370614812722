import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../../api';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type UsersHeaderProps = {
    search: string;
    searchOnChange: (searchValue: string) => void;
};

const NotificationHeader = ({ search, searchOnChange }: UsersHeaderProps) => {
    const { t } = useTranslation(['notifications', 'common']);
    const commonStyles = useCommonStyles();

    const filterOptions = useMemo(
        () => [
            { value: 'all', label: t('notifications:filters.all') },
            { value: NotificationType.AwardedNotice, label: t('notifications:filters.awardedNotice') },
            { value: NotificationType.HandoverDateChangeNotice, label: t('notifications:filters.handoverDateChange') },
            {
                value: NotificationType.PurchaseTransactionAmendedNotice,
                label: t('notifications:filters.purchaseTransactionAmendedNotice'),
            },
            { value: NotificationType.PendingAcceptanceNotice, label: t('notifications:filters.pendingAcceptance') },
            { value: NotificationType.PendingValuationNotice, label: t('notifications:filters.pendingValuation') },
            { value: NotificationType.PendingRequoteNotice, label: t('notifications:filters.pendingRequote') },
            { value: NotificationType.SignUpApproval, label: t('notifications:filters.signUpApproval') },
            { value: NotificationType.SignUpReject, label: t('notifications:filters.signUpReject') },
            { value: NotificationType.UpcomingBiddingNotice, label: t('notifications:filters.upcomingBidding') },
            { value: NotificationType.UnawardNotice, label: t('notifications:filters.unawardNotice') },
        ],
        [t]
    );

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('notifications:header.title')}</Typography>
            <Box display="flex" ml="auto" width={450}>
                <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
                <OutlinedSelectField
                    color="secondary"
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    name="filter"
                    options={filterOptions}
                />
            </Box>
        </Box>
    );
};

export default NotificationHeader;
