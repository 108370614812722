import { Grid, Box } from '@material-ui/core';
import { PurchaseOrSalesTransactionTabType } from '..';
import { PreviewSaleTransactionDataFragment } from '../../../api';
import { BiddingApprovalTabType } from '../../ApproverBiddingSessions';
import SaleTransactionsItem from './SaleTransactionsItem';

export type SaleTransactionsBodyProps = {
    saleTransactions: PreviewSaleTransactionDataFragment[];
    hideAwardedDealer?: boolean;
    hideAwardedBitAmount?: boolean;
    hideOpenCloseBidDate?: boolean;
    currentTab?: BiddingApprovalTabType | PurchaseOrSalesTransactionTabType;
};

const SaleTransactionsBody = ({
    currentTab,
    saleTransactions,
    hideAwardedDealer,
    hideAwardedBitAmount,
    hideOpenCloseBidDate,
}: SaleTransactionsBodyProps) => (
    <Box mb={3} mt={3}>
        <Grid spacing={4} container>
            {saleTransactions.map(saleTransaction => (
                <SaleTransactionsItem
                    key={saleTransaction.id}
                    currentTab={currentTab}
                    hideAwardedBitAmount={hideAwardedBitAmount}
                    hideAwardedDealer={hideAwardedDealer}
                    hideOpenCloseBidDate={hideOpenCloseBidDate}
                    saleTransaction={saleTransaction}
                />
            ))}
        </Grid>
    </Box>
);

export default SaleTransactionsBody;
