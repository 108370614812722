import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullBiddingDataFragment, UpdateBiddingFields, useUpdateBiddingMutation } from '../../../../api';
import { useAlert } from '../../../../components/AlertProvider';
import { useHandleError } from '../../../../utilities/handleErrors';
import EditBiddingSessionDetailsBody from './components/EditBiddingSessionDetailsBody';
import EditBiddingSessionDetailsFooter from './components/EditBiddingSessionDetailsFooter';
import EditBiddingSessionDetailsHeader from './components/EditBiddingSessionDetailsHeader';

type EditBiddingSessionDetailProps = {
    bidding: FullBiddingDataFragment;
    goToView: () => void;
};

const EditBiddingSessionDetails = ({ bidding, goToView }: EditBiddingSessionDetailProps) => {
    const [updateBidding] = useUpdateBiddingMutation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation(['common']);
    const { show } = useAlert();

    const initialValues: UpdateBiddingFields = useMemo(
        () => ({ sessionName: bidding.sessionName, notes: bidding.notes, saleTransactionId: null, biddingEntries: [] }),
        [bidding]
    );

    const onSubmit = useHandleError(
        async (values: UpdateBiddingFields) => {
            const fields = { ...values };
            await updateBidding({
                variables: { biddingId: bidding.id, fields },
            });

            show('success', t('common:successfulMessage.updatesSaved'));
            goToView();
        },
        [bidding, goToView]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <Form>
                    <EditBiddingSessionDetailsHeader goToView={goToView} />
                    <EditBiddingSessionDetailsBody bidding={bidding} />
                    {isSmall && <EditBiddingSessionDetailsFooter />}
                </Form>
            )}
        </Formik>
    );
};

export default EditBiddingSessionDetails;
