import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { UserType } from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';

export type ValuatedValuationItemProps = {
    name: string;
};

const ValuatedValuationItem = ({ name }: ValuatedValuationItemProps) => {
    const { t } = useTranslation('purchaseTransactionsPage');
    const currentUser = useCurrentUser();

    return (
        <>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={currentUser.type !== UserType.Admin}
                    label={t('purchaseTransactionsPage:valuation.valuatedValuation')}
                    name={`${name}.value`}
                    placeholder={t('purchaseTransactionsPage:valuation.valuatedValuation')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    name={`${name}.valuatedBy.fullName`}
                    placeholder={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    disabled
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.valuatedOn')}
                    name={`${name}.valuatedOn`}
                    placeholder={t('purchaseTransactionsPage:valuation.valuatedOn')}
                    disabled
                />
            </Grid>
        </>
    );
};

export default ValuatedValuationItem;
