import { Grid, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { UserType, PurchaseTransactionStage, useGetUserListByTypeQuery } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useDeliveryPeriodOptions, { DeliveryPeriodType } from '../../../../utilities/useDeliveryPeriodOptions';
import { defaultOtherLocationOption } from '../../../../utilities/useHandoverLocationOptions';
import useImportMethodOptions from '../../../../utilities/useImportMethodOptions';
import { PurchaseTransactionFormValues } from '../../PurchaseTransactionDetail/EditPurchaseTransaction';

export type HandoverFormProps = {
    onCreation?: boolean;
    currentStage?: PurchaseTransactionStage;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adornmentRoot: {
            '& > p': {
                color: theme.palette.secondary.main,
            },
        },
    })
);

const HandoverForm = ({ onCreation, currentStage }: HandoverFormProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common', 'errors']);
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const { values, setFieldValue } = useFormikContext<PurchaseTransactionFormValues>();
    const { handover } = values;
    const styles = useStyles();

    useEffect(() => {
        if (handover.handoverLocationField.main !== defaultOtherLocationOption) {
            // remove other location
            setFieldValue('handover.handoverLocationField.other', '');
        }
    }, [handover.handoverLocationField, setFieldValue]);

    const importMethodOptions = useImportMethodOptions();

    const { data } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });

    const deliveryPeriods = useDeliveryPeriodOptions();

    const valuationTeamUserOptions = useMemo(
        () =>
            (data?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [data]
    );

    useEffect(() => {
        if (values.handover.deliveryPeriod !== DeliveryPeriodType.IndentOrder) {
            setFieldValue('handover.deliveryPeriodIndentOrder', '');
        }
    }, [setFieldValue, values.handover.deliveryPeriod]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={
                        !onCreation &&
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    endAdornment={
                        <InputAdornment classes={{ root: styles.adornmentRoot }} position="end">
                            {t('common:shortKilometer')}
                        </InputAdornment>
                    }
                    label={t('purchaseTransactionsPage:vehicle.mileage')}
                    name="vehicle.mileage"
                    placeholder={t('purchaseTransactionsPage:vehicle.mileage')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !onCreation &&
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:vehicle.importMethod')}
                    name="vehicle.importMethod"
                    options={importMethodOptions}
                    placeholder={t('purchaseTransactionsPage:vehicle.importMethod')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !onCreation &&
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.SaleConsultant &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                    name="handover.deliveryPeriod"
                    options={deliveryPeriods}
                    placeholder={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                />
            </Grid>
            {values.handover.deliveryPeriod === DeliveryPeriodType.IndentOrder && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        label={t('purchaseTransactionsPage:handover.deliveryPeriodIndentOrder')}
                        name="handover.deliveryPeriodIndentOrder"
                        placeholder={t('purchaseTransactionsPage:handover.deliveryPeriodIndentOrder')}
                    />
                </Grid>
            )}
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !onCreation &&
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.SaleConsultant &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:handover.personResponsible')}
                    name="handover.personResponsible"
                    options={valuationTeamUserOptions}
                    placeholder={t('purchaseTransactionsPage:handover.personResponsible')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:owner.ownerName')}
                    name="customer.ownerName"
                    placeholder={t('purchaseTransactionsPage:owner.ownerName')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:owner.modelInterested')}
                    name="customer.modelInterested"
                    placeholder={t('purchaseTransactionsPage:owner.modelInterested')}
                />
            </Grid>
            {!onCreation && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        label={t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                        name="internalRemarks"
                        placeholder={t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default HandoverForm;
