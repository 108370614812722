import React from 'react';
import { PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { SaleTransactionsBody } from '../../SaleTransactions/components';

export type SalesTransactionsTabProps = {
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
};

const SalesTransactionsTab = ({
    saleTransactions,
    page,
    pageSize,
    count,
    setActivePage,
}: SalesTransactionsTabProps) => (
    <>
        <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount hideAwardedDealer />
        <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
    </>
);

export default SalesTransactionsTab;
