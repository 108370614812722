import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';

export type MobileMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomNavigation: {
            backgroundColor: '#000',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: theme.spacing(10),
        },
        BottomNavigationAction: {
            color: '#717073',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(1.5),
            '&.Mui-selected': {
                paddingTop: theme.spacing(1.5),
            },
        },
        bottomNavigationSelected: {
            color: theme.palette.secondary.main,
            '& svg': {
                fill: theme.palette.secondary.main,
            },
        },
    })
);

const MobileMenuTabs = ({ value, setValue, a11yProps }: MobileMenuTabsProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const styles = useStyles();

    return (
        <BottomNavigation
            className={styles.bottomNavigation}
            onChange={(event, value) => setValue(value)}
            value={value}
        >
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<InfoIcon />}
                label={t('biddingSessionsPage:menu.all')}
                value="all"
                showLabel
                {...a11yProps('all')}
            />
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<DriveEtaIcon />}
                label={t('biddingSessionsPage:menu.upcoming')}
                value="upcoming"
                showLabel
                {...a11yProps('upcoming')}
            />
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<DriveEtaIcon />}
                label={t('biddingSessionsPage:menu.current')}
                value="current"
                showLabel
                {...a11yProps('current')}
            />
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<AssignmentIndIcon />}
                label={t('biddingSessionsPage:menu.past')}
                value="past"
                showLabel
                {...a11yProps('past')}
            />
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<AssignmentIndIcon />}
                label={t('biddingSessionsPage:menu.cancelled')}
                value="cancelled"
                showLabel
                {...a11yProps('cancelled')}
            />
        </BottomNavigation>
    );
};

export default MobileMenuTabs;
