import { Box, Grid, Card, CardActionArea, CardContent, Typography, CardMedia } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PurchaseOrSalesTransactionTabType } from '..';
import { cardDefaultImage } from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import {
    PreviewSaleTransactionDataFragment,
    SaleTransactionStage,
    UserType,
    BiddingStatus,
    useGetUserQuery,
} from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import useCommonStyles from '../../../useCommonStyles';
import usePublic from '../../../utilities/usePublic';
import useVehicleLogo from '../../../utilities/useVehicleLogo';
import { BiddingApprovalTabType } from '../../ApproverBiddingSessions';
import RemainBiddingDuration from './RemainBiddingDuration';

export type SaleTransactionsItemProps = {
    saleTransaction: PreviewSaleTransactionDataFragment;
    hideAwardedDealer?: boolean;
    hideAwardedBitAmount?: boolean;
    hideOpenCloseBidDate?: boolean;
    currentTab: BiddingApprovalTabType | PurchaseOrSalesTransactionTabType;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            position: 'relative',
            maxHeight: '170px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        vehicleTitle: {
            color: '#000',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        vehicleNumber: {
            fontSize: '14px',
            color: '#000',
        },
        subHeader: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(2),

            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
        },
        label: {
            marginRight: theme.spacing(1),
        },
        info: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        sessionInfo: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        carImage: {
            height: '170px',
            width: '100%',
            borderRadiusTopRight: theme.spacing(1),
            borderRadiusTopLeft: theme.spacing(1),
        },
        noBoxShadow: {
            boxShadow: 'none',
        },
        transactionStatus: {
            paddingTop: theme.spacing(1),
        },
        statusLogo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        defaultBackground: {
            backgroundColor: theme.palette.primary.main,
        },
        redBackground: {
            backgroundColor: '#ff0000',
        },
        yellowBackground: {
            backgroundColor: '#e6b50d',
        },
    })
);

const SaleTransactionsItem = ({
    currentTab,
    saleTransaction,
    hideAwardedDealer,
    hideAwardedBitAmount,
    hideOpenCloseBidDate,
}: SaleTransactionsItemProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);

    const currentUser = useCurrentUser();
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();

    const defaultImage = usePublic(cardDefaultImage);

    const vehicleLogo = useVehicleLogo(saleTransaction.vehicle.make);

    const getStageValue = useCallback(
        (stage: SaleTransactionStage) => {
            switch (stage) {
                case SaleTransactionStage.BiddingOpen:
                    return t('saleTransactionsPage:filter.biddingOpen');
                case SaleTransactionStage.Closed:
                    return t('saleTransactionsPage:filter.closed');
                case SaleTransactionStage.Completed:
                    return t('saleTransactionsPage:filter.completed');
                case SaleTransactionStage.ForAwarding:
                    return t('saleTransactionsPage:filter.forAwarding');
                case SaleTransactionStage.ForHandover:
                    return t('saleTransactionsPage:filter.forHandover');
                case SaleTransactionStage.New:
                    return t('saleTransactionsPage:filter.new');
                case SaleTransactionStage.PendingApproval:
                    return t('saleTransactionsPage:filter.pendingApproval');
                case SaleTransactionStage.UpcomingBidding:
                    return t('saleTransactionsPage:filter.upcomingBidding');
                case SaleTransactionStage.Pending:
                    return t('saleTransactionsPage:filter.pending');
                default:
                    return '';
            }
        },
        [t]
    );

    const showRemainBiddingDuration =
        currentUser.type === UserType.Dealer && saleTransaction.latestBiddingSession?.status === BiddingStatus.Current;

    const lastBid = saleTransaction.latestBiddingSessionEntry?.lastUserBid?.amount || null;

    const { data: userQuery } = useGetUserQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            id: saleTransaction.awardedBiddingEntry?.dealer.id,
        },
        skip: !saleTransaction.awardedBiddingEntry?.dealer,
    });

    const getDealerBusinessName = useMemo(() => {
        switch (userQuery?.user?.__typename) {
            case 'DealerUser':
                return userQuery?.user?.business?.name;
        }

        return '';
    }, [userQuery?.user]);

    const historyState: LocationHistory = useMemo(
        () => ({
            from: pathname,
            resetSalesTransactionsFilter: false,
            transactionApprovalTab: 'salesTransactions',
            biddingApprovalTab: currentTab as BiddingApprovalTabType,
            myTransactionsTab: currentTab as PurchaseOrSalesTransactionTabType,
        }),
        [pathname, currentTab]
    );

    const cardbackgroundColor = useMemo(() => {
        const {
            vehicle: {
                opcCashRebateAmount,
                opcCashRebateEligibility,
                opcCashRebateEligibilityExpiryDate,
                originalRegistrationDate,
                firstRegistrationDate,
            },
        } = saleTransaction;

        const isOpcValid = opcCashRebateAmount || opcCashRebateEligibility || opcCashRebateEligibilityExpiryDate;
        const isRegistrationDateDifferent = !dayjs(originalRegistrationDate).isSame(firstRegistrationDate, 'day');

        if (isOpcValid) {
            return styles.redBackground;
        }

        return isRegistrationDateDifferent ? styles.yellowBackground : styles.defaultBackground;
    }, [saleTransaction, styles.redBackground, styles.yellowBackground, styles.defaultBackground]);

    return (
        <Grid lg={4} md={6} sm={12} xs={12} item>
            <Card className={styles.noBoxShadow}>
                <CardActionArea
                    onClick={() => {
                        history.replace(pathname, historyState);
                        history.push(`/salesTransactions/${saleTransaction.id}`);
                    }}
                >
                    <CardContent className={styles.content}>
                        <Box className={styles.header}>
                            <CardMedia
                                className={styles.carImage}
                                image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                            />
                            {showRemainBiddingDuration && <RemainBiddingDuration transaction={saleTransaction} />}
                        </Box>

                        <Box>
                            <Box
                                className={cs(styles.subHeader, cardbackgroundColor)}
                                pt={showRemainBiddingDuration ? 5 : 2}
                            >
                                <Box display="flex">
                                    <Typography className={commonStyles.primary2}>
                                        {saleTransaction.vehicle.make}&nbsp;{saleTransaction.vehicle.model}
                                    </Typography>
                                    <Typography className={commonStyles.transactionStatus}>
                                        {getStageValue(saleTransaction.stage)}
                                    </Typography>
                                    <ChevronRightIcon color="secondary" />
                                </Box>
                                {currentUser.type !== UserType.Dealer && (
                                    <Typography className={commonStyles.primary2}>
                                        {saleTransaction.vehicle.number}
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Box alignItems="center" className={styles.body} display="flex">
                            {/* <Box pt={showRemainBiddingDuration ? 6 : 0}>
                                <Box className={cardbackgroundColor}>test</Box>
                            </Box> */}
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    {currentUser.type !== UserType.Dealer && (
                                        <Box mb={1}>
                                            <Typography className={commonStyles.smallFontSize}>
                                                {t('common:formats.longDate', {
                                                    date: new Date(saleTransaction.vehicle.originalRegistrationDate),
                                                })}
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                {saleTransaction.vehicle.mileage.toLocaleString()}
                                                &nbsp;
                                                {t('common:shortKilometer')}
                                            </Typography>
                                        </Box>
                                    )}

                                    <Box display="flex" mt={currentUser.type === UserType.Dealer ? 1 : 0}>
                                        <Typography
                                            className={cs(
                                                commonStyles.smallFontSize,
                                                styles.label,
                                                commonStyles.redText
                                            )}
                                        >
                                            {t('saleTransactionsPage:handover.targetHandoverDate')}:
                                        </Typography>
                                        <Typography className={cs(commonStyles.smallFontSize, commonStyles.redText)}>
                                            {saleTransaction?.handover?.targetHandoverDateTime
                                                ? t('common:formats.longDate', {
                                                      date: new Date(saleTransaction.handover.targetHandoverDateTime),
                                                  })
                                                : t('common:emptyValue')}
                                        </Typography>
                                    </Box>

                                    {currentUser.type !== UserType.Dealer && (
                                        <Box display="flex" mt={1}>
                                            <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                                {t('saleTransactionsPage:label.createdOn')}:
                                            </Typography>
                                            <Typography className={commonStyles.smallFontSize}>
                                                {t('common:formats.longDateTime', {
                                                    date: new Date(saleTransaction.createdAt),
                                                })}
                                            </Typography>
                                        </Box>
                                    )}

                                    {saleTransaction.latestBiddingSession && (
                                        <Box alignItems="center" display="flex">
                                            <Grid className={styles.sessionInfo} spacing={1} container>
                                                <Grid xs={12} item>
                                                    <Box>
                                                        <Typography className={cs(styles.label)} color="secondary">
                                                            {t('saleTransactionsPage:sessionDetails.title')}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                {!hideOpenCloseBidDate && (
                                                    <>
                                                        <Grid xs={12} item>
                                                            <Box display="flex">
                                                                <Typography
                                                                    className={cs(
                                                                        commonStyles.smallFontSize,
                                                                        styles.label
                                                                    )}
                                                                >
                                                                    {t(
                                                                        // eslint-disable-next-line max-len
                                                                        'saleTransactionsPage:sessionDetails.bidOpeningOn'
                                                                    )}
                                                                    :
                                                                </Typography>
                                                                <Typography className={commonStyles.smallFontSize}>
                                                                    {t('common:formats.longDateTime', {
                                                                        date: new Date(
                                                                            // eslint-disable-next-line max-len
                                                                            saleTransaction.latestBiddingSession.startPeriod
                                                                        ),
                                                                    })}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={12} item>
                                                            <Box display="flex">
                                                                <Typography
                                                                    className={cs(
                                                                        commonStyles.smallFontSize,
                                                                        styles.label
                                                                    )}
                                                                >
                                                                    {t(
                                                                        // eslint-disable-next-line max-len
                                                                        'saleTransactionsPage:sessionDetails.bidClosingDate'
                                                                    )}
                                                                    :
                                                                </Typography>
                                                                <Typography className={commonStyles.smallFontSize}>
                                                                    {t('common:formats.longDateTime', {
                                                                        date: new Date(
                                                                            // eslint-disable-next-line max-len
                                                                            saleTransaction.latestBiddingSession.endPeriod
                                                                        ),
                                                                    })}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )}

                                                {currentUser.type === UserType.Dealer && lastBid && (
                                                    <Grid xs={12} item>
                                                        <Box display="flex">
                                                            <Typography
                                                                className={cs(commonStyles.smallFontSize, styles.label)}
                                                            >
                                                                {t('saleTransactionsPage:bidDrawer.lastBid')}:
                                                            </Typography>
                                                            <Typography className={commonStyles.smallFontSize}>
                                                                {lastBid
                                                                    ? `${t(
                                                                          'common:currency'
                                                                      )}${lastBid.toLocaleString()}`
                                                                    : t('common:blankValue')}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )}

                                                {!hideAwardedDealer && (
                                                    <Grid xs={12} item>
                                                        <Box display="flex">
                                                            <Typography
                                                                className={cs(commonStyles.smallFontSize, styles.label)}
                                                            >
                                                                {t('saleTransactionsPage:list.awardedDealer')}
                                                            </Typography>
                                                            <Typography className={commonStyles.smallFontSize}>
                                                                {saleTransaction.awardedBiddingEntry
                                                                    ? getDealerBusinessName
                                                                    : t('common:blankValue')}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )}

                                                {!hideAwardedBitAmount && (
                                                    <Grid xs={12} item>
                                                        <Box display="flex">
                                                            <Typography
                                                                className={cs(commonStyles.smallFontSize, styles.label)}
                                                            >
                                                                {t('saleTransactionsPage:list.awardedBidAmount')}
                                                            </Typography>
                                                            <Typography className={commonStyles.smallFontSize}>
                                                                {/* eslint-disable max-len */}
                                                                {saleTransaction.awardedBiddingEntry?.amount
                                                                    ? `${t(
                                                                          'common:currency'
                                                                      )}${saleTransaction.awardedBiddingEntry.amount.toLocaleString()}`
                                                                    : t('common:blankValue')}
                                                                {/* eslint-disable max-len */}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                            <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default SaleTransactionsItem;
