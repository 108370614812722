import React, { useState, useCallback, useMemo } from 'react';
import { SalesTransactionFilter } from '../..';
import { PreviewSaleTransactionDataFragment, DownloadFileType, SaleTransactionStage } from '../../../../api';
import ListPagination from '../../../../components/ListPagination';
import { useLoading } from '../../../../components/LoadingProvider';
import { SaleTransactionsHeader, SaleTransactionsBody, DownloadSaleListFn } from '../../../SaleTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type SalesTransactionsTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadSalesTransactionsFile: DownloadSaleListFn;
    filter: SalesTransactionFilter;
    setFilterValue: (value: SalesTransactionFilter) => void;
    refreshFn?: () => Promise<unknown>;
};

const SalesTransactionsTab = ({
    search,
    saleTransactions,
    page,
    pageSize,
    count,
    filter,
    searchOnChange,
    setActivePage,
    downloadSalesTransactionsFile,
    setFilterValue,
    refreshFn,
}: SalesTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const { attach } = useLoading();

    const queryFilter = useMemo(
        () => ({
            stages: filter.stage === 'all' ? null : [filter.stage],
            fromCreated: filter.fromCreated,
            toCreated: filter.toCreated,
            fromHandover: filter.fromHandover,
            toHandover: filter.toHandover,
            awardedDealer: filter.awardedDealer === 'all' ? null : filter.awardedDealer,
            showArchived: filter.showArchived,
        }),
        [filter]
    );

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        ...queryFilter,
                    },
                    sort: {
                        stagePriority: SaleTransactionStage.PendingApproval,
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadSalesTransactionsFile, queryFilter, search]
    );

    const setValues = useCallback(
        (value: SalesTransactionFilter) => {
            setFilterValue(value);
        },
        [setFilterValue]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <SaleTransactionsHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                refreshFn={refreshFn}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <SaleTransactionsBody saleTransactions={saleTransactions} hideOpenCloseBidDate />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default SalesTransactionsTab;
