import { Box, Typography, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cardDefaultImage } from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, SaleTransactionStage, UserType, BiddingStatus } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';
import BiddingLiveDuration from './BiddingLiveDuration';

export type VehicleInfoProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        registrationNo: {
            opacity: '0.6',
        },
        carLogo: {
            marginLeft: 'auto',
            height: '3rem',
        },
        carImage: {
            height: theme.spacing(30),
            borderRadius: theme.spacing(1),
        },
        statusLogo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        transactionStatus: {
            paddingTop: theme.spacing(1),
        },
    })
);

const VehicleInfo = ({ saleTransaction }: VehicleInfoProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const carLogo = useVehicleLogo(saleTransaction.vehicle.make);
    const defaultImage = usePublic(cardDefaultImage);
    const currentUser = useCurrentUser();

    const getStageValue = useCallback(
        (stage: SaleTransactionStage) => {
            switch (stage) {
                case SaleTransactionStage.BiddingOpen:
                    return t('saleTransactionsPage:filter.biddingOpen');
                case SaleTransactionStage.Closed:
                    return t('saleTransactionsPage:filter.closed');
                case SaleTransactionStage.Pending:
                    return t('saleTransactionsPage:filter.pending');
                case SaleTransactionStage.Completed:
                    return t('saleTransactionsPage:filter.completed');
                case SaleTransactionStage.ForAwarding:
                    return t('saleTransactionsPage:filter.forAwarding');
                case SaleTransactionStage.ForHandover:
                    return t('saleTransactionsPage:filter.forHandover');
                case SaleTransactionStage.New:
                    return t('saleTransactionsPage:filter.new');
                case SaleTransactionStage.PendingApproval:
                    return t('saleTransactionsPage:filter.pendingApproval');
                case SaleTransactionStage.UpcomingBidding:
                    return t('saleTransactionsPage:filter.upcomingBidding');
                default:
                    return '';
            }
        },
        [t]
    );

    const showBiddingLiveDuration =
        currentUser.type === UserType.Dealer &&
        [BiddingStatus.Upcoming, BiddingStatus.Current].includes(saleTransaction.latestBiddingSession?.status);

    return (
        <Box position="relative">
            {!isSmall && (
                <Box position="relative">
                    <CardMedia
                        className={styles.carImage}
                        image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                    />
                    {showBiddingLiveDuration && <BiddingLiveDuration transaction={saleTransaction} />}
                </Box>
            )}
            <Box display="flex" mt={showBiddingLiveDuration && !isSmall ? 8 : 2}>
                <Box>
                    <Box display="flex">
                        <Box>
                            <Typography className={commonStyles.primary}>
                                {`${saleTransaction.vehicle.make} ${saleTransaction.vehicle.model}`}
                            </Typography>
                            {currentUser.type !== UserType.Dealer && (
                                <Typography className={cs(commonStyles.primary, styles.registrationNo)}>
                                    {saleTransaction.vehicle.number}
                                </Typography>
                            )}
                        </Box>

                        <Box className={styles.statusLogo}>
                            <Typography className={commonStyles.transactionStatus}>
                                {getStageValue(saleTransaction.stage)}
                            </Typography>
                            <Typography className={styles.transactionStatus}>
                                <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default VehicleInfo;
