import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import HandoverForm from '../../CreatePurchaseTransaction/components/HandoverForm';

type HandoverTabProps = {
    stage: PurchaseTransactionStage;
};

const HandoverTab = ({ stage }: HandoverTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('purchaseTransactionsPage:accordionTitle.handover')} defaultExpanded>
                    <HandoverForm stage={stage} />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
