import { Box, Typography, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const ActivityHistoryHeader = () => {
    const { t } = useTranslation(['activityHistoryPage']);
    const commonStyles = useCommonStyles();
    const { isSubmitting } = useFormikContext();

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('activityHistoryPage:title')}</Typography>
            <Box className={commonStyles.buttonGroup} ml="auto">
                <Button color="secondary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                    <GetAppIcon fontSize="small" /> {t('common:exportInExcel')}
                </Button>
            </Box>
        </Box>
    );
};

export default ActivityHistoryHeader;
