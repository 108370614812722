import {
    Grid,
    Box,
    Button,
    FormControl,
    Checkbox,
    FormControlLabel,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewUserDataFragment, useGetUserListByTypeQuery, UserType } from '../../../../../api';
import Dialog from '../../../../../components/Dialog';
import useCommonStyles from '../../../../../useCommonStyles';
import { FormValues } from '../../../CreateBiddingSession';
import CreateSessionDealerListingItem from './CreateSessionDealerListingItem';

export type SessionTransactionsBodyProps = {
    open: boolean;
    onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        items: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
        dialogActions: {
            marginTop: theme.spacing(3),
        },
        cardFormControlLabel: {
            width: '100%',
        },
    })
);

const CreateSessionDealerListings = ({ open, onClose }: SessionTransactionsBodyProps) => {
    const [selectAll, setSelectAll] = useState(false);

    const { t } = useTranslation(['common', 'biddingSessionsPage']);
    const { setFieldValue } = useFormikContext<FormValues>();

    const [{ value: selectedDealers }, , { setValue }] = useField<PreviewUserDataFragment[]>('dealers');

    const styles = useStyles();
    const commonStyles = useCommonStyles();

    const { data } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Dealer,
        },
    });

    const dealers = useMemo(() => data?.users.items || [], [data?.users.items]);

    const onSubmit = useCallback(() => {
        setFieldValue('dealers', selectAll ? dealers : selectedDealers);

        onClose();
    }, [onClose, dealers, selectAll, selectedDealers, setFieldValue]);

    const markAllSelectUnselect = useCallback(() => {
        const isChecked = !selectAll;

        setValue(isChecked ? [...dealers] : []);
        setSelectAll(isChecked);
    }, [dealers, selectAll, setValue]);

    const getCheckboxControl = useCallback(
        () => <Checkbox checked={selectAll} onChange={markAllSelectUnselect} />,
        [markAllSelectUnselect, selectAll]
    );

    return (
        <Dialog maxWidth="md" onCloseClick={onClose} open={open} title={t('common:select')} fullWidth>
            <FormControlLabel control={getCheckboxControl()} label={t('biddingSessionsPage:selectAll')} />
            <Box mb={3}>
                <Table className={commonStyles.table}>
                    <TableHead>
                        <TableRow className={commonStyles.tableRow}>
                            <TableCell />
                            <TableCell>{t('usersPage:headers.fullName')}</TableCell>
                            <TableCell>{t('usersPage:headers.email')}</TableCell>
                            <TableCell>{t('usersPage:headers.mobile')}</TableCell>
                            <TableCell>{t('usersPage:headers.type')}</TableCell>
                            <TableCell>{t('usersPage:headers.additionalInformation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dealers.map(dealer => {
                            const isSelected = selectedDealers.some(selected => selected.id === dealer.id);

                            const onChange = () => {
                                const nextValue = isSelected
                                    ? selectedDealers.filter(transaction => transaction.id !== dealer.id)
                                    : [...selectedDealers, dealer];

                                setValue(nextValue);
                                setSelectAll(nextValue.length === dealers.length);
                            };

                            const checkbox = <Checkbox checked={isSelected} onChange={onChange} />;

                            return (
                                <CreateSessionDealerListingItem key={dealer.id} checkbox={checkbox} dealer={dealer} />
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Grid className={styles.dialogActions} xs={12} item>
                <FormControl fullWidth>
                    <Button color="secondary" onClick={onSubmit} variant="contained">
                        {t('common:select')}
                    </Button>
                </FormControl>
            </Grid>
        </Dialog>
    );
};

export default CreateSessionDealerListings;
