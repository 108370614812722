import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';

export type DesktopMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #C4C4C4',
            marginBottom: theme.spacing(3),
        },
    })
);

const DesktopMenuTabs = ({ value, setValue, a11yProps }: DesktopMenuTabsProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const styles = useStyles();

    return (
        <Tabs
            classes={{ root: styles.root }}
            indicatorColor="secondary"
            onChange={(_, value) => setValue(value)}
            scrollButtons="auto"
            textColor="secondary"
            value={value}
        >
            <Tab
                icon={<InfoIcon />}
                label={t('purchaseTransactionsPage:menu.transaction')}
                value="transaction"
                {...a11yProps('transaction')}
            />
            <Tab
                icon={<DriveEtaIcon />}
                label={t('purchaseTransactionsPage:menu.vehicle')}
                value="vehicle"
                {...a11yProps('vehicle')}
            />
            <Tab
                icon={<DriveEtaIcon />}
                label={t('purchaseTransactionsPage:menu.valuation')}
                value="valuation"
                {...a11yProps('valuation')}
            />
            <Tab
                icon={<AssignmentIndIcon />}
                label={t('purchaseTransactionsPage:menu.handover')}
                value="handover"
                {...a11yProps('handover')}
            />
        </Tabs>
    );
};

export default DesktopMenuTabs;
