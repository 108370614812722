import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { SaleTransactionStage, UserType } from '../../api';

export type MobileMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
    stage: SaleTransactionStage;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomNavigation: {
            backgroundColor: '#000',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: theme.spacing(10),
        },
        BottomNavigationAction: {
            color: '#717073',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(1.5),
            '&.Mui-selected': {
                paddingTop: theme.spacing(1.5),
            },
        },
        bottomNavigationSelected: {
            color: theme.palette.secondary.main,
            '& svg': {
                fill: theme.palette.secondary.main,
            },
        },
    })
);

const MobileMenuTabs = ({ value, setValue, a11yProps, stage }: MobileMenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const styles = useStyles();

    const currentUser = useCurrentUser();

    const showHandoverTab =
        currentUser.type !== UserType.Dealer ||
        [
            SaleTransactionStage.ForHandover,
            SaleTransactionStage.PendingApproval,
            SaleTransactionStage.Completed,
            SaleTransactionStage.Closed,
        ].includes(stage);

    return (
        <BottomNavigation className={styles.bottomNavigation} onChange={(_, value) => setValue(value)} value={value}>
            {currentUser.type !== UserType.Dealer && (
                <BottomNavigationAction
                    className={styles.BottomNavigationAction}
                    classes={{ selected: styles.bottomNavigationSelected }}
                    icon={<InfoIcon />}
                    label={t('saleTransactionsPage:menu.transaction')}
                    value="transaction"
                    showLabel
                    {...a11yProps('transaction')}
                />
            )}
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<DriveEtaIcon />}
                label={t('saleTransactionsPage:menu.vehicle')}
                value="vehicle"
                showLabel
                {...a11yProps('vehicle')}
            />
            <BottomNavigationAction
                className={styles.BottomNavigationAction}
                classes={{ selected: styles.bottomNavigationSelected }}
                icon={<InfoIcon />}
                label={t('saleTransactionsPage:menu.bidding')}
                value="bidding"
                showLabel
                {...a11yProps('bidding')}
            />
            {showHandoverTab && (
                <BottomNavigationAction
                    className={styles.BottomNavigationAction}
                    classes={{ selected: styles.bottomNavigationSelected }}
                    icon={<AssignmentIndIcon />}
                    label={t('saleTransactionsPage:menu.handover')}
                    value="handover"
                    showLabel
                    {...a11yProps('handover')}
                />
            )}
        </BottomNavigation>
    );
};

export default MobileMenuTabs;
