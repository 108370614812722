import { Box, Grid, CardMedia, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { cardDefaultImage } from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import { FullSaleTransactionDataFragment, SaleTransactionStage, UserType, BiddingStatus } from '../../../api';
import { LocationHistory } from '../../../components/LocationHistory';
import TabPanel from '../../../components/TabPanel';
import usePublic from '../../../utilities/usePublic';
import DesktopMenuTabs from '../DesktopMenuTabs';
import MobileMenuTabs from '../MobileMenuTabs';
import BiddingLiveDuration from './components/BiddingLiveDuration';
import BiddingTab from './components/BiddingTab';
import HandoverTab from './components/HandoverTab';
import TransactionTab from './components/TransactionTab';
import VehicleInfo from './components/VehicleInfo';
import VehicleTab from './components/VehicleTab';

export type ViewSaleTransactionBodyProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
        carImage: {
            height: theme.spacing(30),
            borderRadius: theme.spacing(1),
        },
    })
);

const ViewSaleTransactionBody = ({ saleTransaction }: ViewSaleTransactionBodyProps) => {
    const styles = useStyles();
    const currentUser = useCurrentUser();
    const defaultTabName = useMemo(
        () => (currentUser.type !== UserType.Dealer ? 'transaction' : 'vehicle'),
        [currentUser.type]
    );
    const [tabName, setTabName] = useState(defaultTabName);
    const location = useLocation();

    const { refreshSalesTransactionDetail } = (location.state || {}) as LocationHistory;

    // set tab to default once id gets changed and when user clicks on push notification
    useEffect(() => setTabName(defaultTabName), [defaultTabName, saleTransaction.id, refreshSalesTransactionDetail]);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultImage = usePublic(cardDefaultImage);

    const showHandoverTab =
        currentUser.type !== UserType.Dealer ||
        [
            SaleTransactionStage.ForHandover,
            SaleTransactionStage.PendingApproval,
            SaleTransactionStage.Completed,
            SaleTransactionStage.Pending,
            SaleTransactionStage.Closed,
        ].includes(saleTransaction.stage);

    const showBiddingLiveDuration =
        currentUser.type === UserType.Dealer &&
        [BiddingStatus.Upcoming, BiddingStatus.Current].includes(saleTransaction.latestBiddingSession?.status);

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                {isSmall && (
                    <Box position="relative">
                        <CardMedia
                            className={styles.carImage}
                            image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                        />
                        {showBiddingLiveDuration && <BiddingLiveDuration transaction={saleTransaction} />}
                    </Box>
                )}
                <Box className={styles.body}>
                    <Box mt={showBiddingLiveDuration ? 6 : 0}>
                        <Grid spacing={2} container>
                            <Grid lg={5} md={5} sm={12} xs={12} item>
                                <VehicleInfo saleTransaction={saleTransaction} />
                            </Grid>
                            <Grid lg={7} md={7} sm={12} xs={12} item>
                                {!isSmall && (
                                    <DesktopMenuTabs
                                        a11yProps={a11yProps}
                                        setValue={setTabName}
                                        stage={saleTransaction.stage}
                                        value={tabName}
                                    />
                                )}
                                {currentUser.type !== UserType.Dealer && (
                                    <TabPanel index="transaction" value={tabName}>
                                        <TransactionTab saleTransaction={saleTransaction} />
                                    </TabPanel>
                                )}
                                <TabPanel index="vehicle" value={tabName}>
                                    <VehicleTab saleTransaction={saleTransaction} />
                                </TabPanel>
                                <TabPanel index="bidding" value={tabName}>
                                    <BiddingTab saleTransaction={saleTransaction} />
                                </TabPanel>
                                {showHandoverTab && (
                                    <TabPanel index="handover" value={tabName}>
                                        <HandoverTab saleTransaction={saleTransaction} />
                                    </TabPanel>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isSmall && (
                <MobileMenuTabs
                    a11yProps={a11yProps}
                    setValue={setTabName}
                    stage={saleTransaction.stage}
                    value={tabName}
                />
            )}
        </>
    );
};

export default ViewSaleTransactionBody;
