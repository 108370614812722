import { useState, useCallback } from 'react';
import { FullPurchaseTransactionDataFragment, DownloadFileType } from '../../../../api';
import FileViewerProvider from '../../../../components/FileViewerProvider';
import { useLoading } from '../../../../components/LoadingProvider';
import { DownloadDetailFn } from '../../components';
import AcceptValuationConfirmDialog from './AcceptValuationConfirmDialog';
import CancelTransactionDialog from './CancelTransactionDialog';
import CompleteTransactionDrawer from './CompleteTransactionDrawer';
import ViewPurchaseTransactionBody from './ViewPurchaseTransactionBody';
import ViewPurchaseTransactionHeader from './ViewPurchaseTransactionHeader';

export type ViewPurchaseTransactionProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    goToEdition: () => void;
    goToVehicleDetails: () => void;
    downloadPurchaseTransactionDetailFile: DownloadDetailFn;
};

const ViewPurchaseTransaction = ({
    purchaseTransaction,
    goToEdition,
    goToVehicleDetails,
    downloadPurchaseTransactionDetailFile,
}: ViewPurchaseTransactionProps) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [openCompleteDrawer, setOpenCompleteDrawer] = useState(false);
    const [openCancelTransactionDialog, setOpenCancelTransactionDialog] = useState(false);
    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionDetailFile({
                    downloadFileType: type,
                    transactionId: purchaseTransaction.id,
                });
            };

            attach(execute());
        },
        [attach, downloadPurchaseTransactionDetailFile, purchaseTransaction.id]
    );

    return (
        <FileViewerProvider>
            <AcceptValuationConfirmDialog
                purchaseTransaction={purchaseTransaction}
                setHideConfirmDialog={() => setShowConfirmDialog(false)}
                show={showConfirmDialog}
            />
            <CancelTransactionDialog
                onClose={() => setOpenCancelTransactionDialog(false)}
                open={openCancelTransactionDialog}
                purchaseTransaction={purchaseTransaction}
            />
            <CompleteTransactionDrawer
                onClose={() => setOpenCompleteDrawer(false)}
                open={openCompleteDrawer}
                purchaseTransaction={purchaseTransaction}
            />
            <ViewPurchaseTransactionHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                goToEdition={goToEdition}
                purchaseTransaction={purchaseTransaction}
            />
            <ViewPurchaseTransactionBody
                goToVehicleDetails={goToVehicleDetails}
                openCancelTransactionDialog={() => setOpenCancelTransactionDialog(true)}
                openCompleteDrawer={() => setOpenCompleteDrawer(true)}
                purchaseTransaction={purchaseTransaction}
                showConfirmDialog={() => setShowConfirmDialog(true)}
            />
        </FileViewerProvider>
    );
};

export default ViewPurchaseTransaction;
