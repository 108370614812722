import { Grid, Box, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import { LocationHistory } from '../../../../components/LocationHistory';
import TabPanel from '../../../../components/TabPanel';
import { MobileMenuTabs, DesktopMenuTabs } from '../../components';
import HandoverTab from './HandoverTab';
import TransactionTab from './TransactionTab';
import ValuationTab from './ValuationTab';
import VehicleInfo from './VehicleInfo';
import VehicleTab from './VehicleTab';

export type ViewPurchaseTransactionBodyProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    openCancelTransactionDialog: () => void;
    showConfirmDialog: () => void;
    goToVehicleDetails: () => void;
    openCompleteDrawer: () => void;
};

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
        carImage: {
            height: theme.spacing(30),
            marginBottom: theme.spacing(2),
            borderRadius: theme.spacing(1),
        },
    })
);

const ViewPurchaseTransactionBody = ({
    purchaseTransaction,
    openCancelTransactionDialog,
    showConfirmDialog,
    goToVehicleDetails,
    openCompleteDrawer,
}: ViewPurchaseTransactionBodyProps) => {
    const styles = useStyles();
    const [tabName, setTabName] = useState('transaction');
    const location = useLocation();

    const { refreshPurchaseTransactionDetail } = (location.state || {}) as LocationHistory;

    // set tab to default once id gets changed and when user clicks on push notification
    useEffect(() => setTabName('transaction'), [purchaseTransaction.id, refreshPurchaseTransactionDetail]);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                {isSmall && purchaseTransaction.frontPagePhoto && (
                    <CardMedia className={styles.carImage} image={purchaseTransaction.frontPagePhoto.signedUrl} />
                )}
                <Box className={styles.body}>
                    <Grid spacing={2} container>
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <VehicleInfo purchaseTransaction={purchaseTransaction} />
                        </Grid>
                        <Grid lg={7} md={7} sm={12} xs={12} item>
                            {!isSmall && (
                                <DesktopMenuTabs a11yProps={a11yProps} setValue={setTabName} value={tabName} />
                            )}
                            <TabPanel index="transaction" value={tabName}>
                                <TransactionTab purchaseTransaction={purchaseTransaction} />
                            </TabPanel>
                            <TabPanel index="vehicle" value={tabName}>
                                <VehicleTab purchaseTransaction={purchaseTransaction} />
                            </TabPanel>
                            <TabPanel index="valuation" value={tabName}>
                                <ValuationTab
                                    goToVehicleDetails={goToVehicleDetails}
                                    openCancelTransactionDialog={openCancelTransactionDialog}
                                    openCompleteDrawer={openCompleteDrawer}
                                    purchaseTransaction={purchaseTransaction}
                                    showConfirmDialog={showConfirmDialog}
                                />
                            </TabPanel>
                            <TabPanel index="handover" value={tabName}>
                                <HandoverTab purchaseTransaction={purchaseTransaction} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {isSmall && <MobileMenuTabs a11yProps={a11yProps} setValue={setTabName} value={tabName} />}
        </>
    );
};

export default ViewPurchaseTransactionBody;
