import React, { useState, useCallback } from 'react';
import { BiddingApprovalTabType, BiddingFilter } from '..';
import { PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { SaleTransactionsHeader, SaleTransactionsBody } from '../../SaleTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type SalesTransactionsTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    filter: BiddingFilter;
    setFilterValue: (value: BiddingFilter) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPdf: () => void;
    downloadExcel: () => void;
    refreshFn?: () => Promise<unknown>;
    currentTab: BiddingApprovalTabType;
};

const SalesTransactionsTab = ({
    currentTab,
    search,
    refreshFn,
    saleTransactions,
    page,
    pageSize,
    count,
    filter,
    setFilterValue,
    searchOnChange,
    setActivePage,
    downloadPdf,
    downloadExcel,
}: SalesTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const setValues = useCallback(
        (value: BiddingFilter) => {
            setFilterValue(value);
        },
        [setFilterValue]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <SaleTransactionsHeader
                downloadExcel={downloadExcel}
                downloadPdf={downloadPdf}
                refreshFn={refreshFn}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <SaleTransactionsBody
                currentTab={currentTab}
                saleTransactions={saleTransactions}
                hideAwardedBitAmount
                hideAwardedDealer
            />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default SalesTransactionsTab;
