import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const HomePageHeader = () => {
    const { t } = useTranslation(['homePage']);
    const commonStyles = useCommonStyles();

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('homePage:title')}</Typography>
        </Box>
    );
};

export default HomePageHeader;
