import { Container, Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { OTPForm, OTPFormProps, OTPPageFooter, OTPPageBody, OTPPageHeader, OTPPageFooterProps } from './components';

export type { OTPFormValues } from './components';

export type OtpValidationProps = OTPFormProps & OTPPageFooterProps;

const OTPPage = ({ handleSubmit, resendCode, otpConfig }: OtpValidationProps) => {
    const theme = useTheme();

    return (
        <Container maxWidth="xs">
            <Box pt={useMediaQuery(theme.breakpoints.up('sm')) ? 25 : 10}>
                <Grid spacing={2} container>
                    <OTPPageHeader />
                    <OTPPageBody otpConfig={otpConfig} />
                    <OTPForm handleSubmit={handleSubmit} />
                    <OTPPageFooter otpConfig={otpConfig} resendCode={resendCode} />
                </Grid>
            </Box>
        </Container>
    );
};

export default OTPPage;
