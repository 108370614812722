import { FormikProps, withFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FullNotificationDataFragment, NotificationType, useGetNotificationsQuery } from '../../api';
import ListPagination from '../../components/ListPagination';
import NotificationBody from './components/NotificationBody';
import NotificationHeader from './components/NotificationHeader';

type ListContext = {
    search: string;
    page: number;
};

const pageSize = 6;

export type FilterValues = {
    filter: 'all' | NotificationType;
};

const Notifications = ({ values }: FormikProps<FilterValues>) => {
    const [{ page, search }, setListContext] = useState<ListContext>({
        search: '',
        page: 0,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext(state => ({ ...state, search: searchValue }));
        },
        [setListContext]
    );

    const { filter } = values;

    const { data } = useGetNotificationsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: { search, type: filter === 'all' ? null : filter },
        },
    });

    const count = data?.NotificationPage.count || 0;
    const notices: FullNotificationDataFragment[] = data?.NotificationPage?.items || [];

    return (
        <>
            <NotificationHeader search={search} searchOnChange={searchOnChange} />

            <NotificationBody notices={notices} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default withFormik({
    mapPropsToValues: () => ({ filter: 'all' }),
    handleSubmit: () => null,
})(Notifications);
