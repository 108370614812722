import { Box, Button, Dialog, Drawer, Grid, Typography, DialogContent, IconButton, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import cs from 'classnames';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, useAddBiddingEntryMutation } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import useCommonStyles from '../../../../useCommonStyles';
import { useHandleError } from '../../../../utilities/handleErrors';
import usePublic from '../../../../utilities/usePublic';
import BiddingRulesAndRegulations from './BiddingRulesAndRegulations';

export type BiddingDrawerProps = {
    transaction: FullSaleTransactionDataFragment;
    open: boolean;
    onClose: () => void;
    disableBid?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        buttons: {
            display: 'flex',
        },
        button: {
            flex: 1,
        },
        bidHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
        },
        headerTitle: {
            flex: 1,
            paddingTop: '10px',
            textAlign: 'center',
        },
        header: {
            color: theme.palette.secondary.main,
            fontSize: '16px',
        },
        submitContainer: {
            display: 'flex',
            flex: 1,
            '& > button': {
                height: '56px',
            },
        },
        rules: {
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center',
        },
        rulesText: {
            fontSize: '14px',
            color: '#717073',
            opacity: 0.8,
        },
        rulesAndRegulationsPaperAnchorBottom: {
            maxHeight: `calc(100% - ${theme.spacing(20)}px)`,
        },
        confirmDialogContent: {
            padding: 0,
        },
        confirmDialogButton: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
        confirmDialogBody: {
            whiteSpace: 'pre-line',
        },
    })
);

export type FormValues = {
    amount?: number;
};

const BiddingDrawer = ({ transaction, open, onClose, disableBid }: BiddingDrawerProps) => {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [showBiddingRulesAndRegulations, setShowBiddingRulesAndRegulations] = React.useState(false);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const exclamation = usePublic('assets/icons/exclamation.svg');
    const { t } = useTranslation(['saleTransactionsPage', 'common']);

    const [executeBid] = useAddBiddingEntryMutation();

    const { latestBiddingSession, latestBiddingSessionEntry, id: saleTransactionId } = transaction;

    if (!latestBiddingSessionEntry || !latestBiddingSession) {
        throw new Error('This component requires an active bidding session');
    }

    const biddingId = latestBiddingSession.id;

    const onSubmit = useHandleError(
        async ({ amount }: FormValues) => {
            if (!amount) {
                throw new Error('Amount missing in state');
            }

            if (!openConfirmDialog) {
                setOpenConfirmDialog(true);

                return null;
            }

            setOpenConfirmDialog(false);

            return executeBid({
                variables: { biddingId, saleTransactionId, amount },
            }).then(() => {
                onClose();
            });
        },
        [biddingId, saleTransactionId, onClose, executeBid, openConfirmDialog, setOpenConfirmDialog]
    );

    const lastBid = latestBiddingSessionEntry.lastUserBid?.amount || null;

    if (showBiddingRulesAndRegulations) {
        return (
            <Drawer
                anchor="bottom"
                classes={{ paperAnchorBottom: styles.rulesAndRegulationsPaperAnchorBottom }}
                onClose={onClose}
                open={open}
            >
                <BiddingRulesAndRegulations setShowBiddingRulesAndRegulations={setShowBiddingRulesAndRegulations} />
            </Drawer>
        );
    }

    return (
        <Drawer anchor="bottom" onClose={onClose} open={open}>
            <Formik initialValues={{}} onSubmit={onSubmit}>
                {({ isSubmitting, values, submitForm }) => {
                    const { amount } = values;

                    return (
                        <Form className={styles.container}>
                            <Grid spacing={1} container>
                                <Box className={styles.bidHeader}>
                                    <Box className={styles.headerTitle}>
                                        <Typography className={commonStyles.fontWeightBold} color="secondary">
                                            {t('saleTransactionsPage:bidDrawer.title')}
                                        </Typography>
                                    </Box>
                                    <IconButton onClick={onClose}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                                <Grid xs={12} item>
                                    <Box display="flex" mb="12px">
                                        <Typography className={styles.header}>
                                            {t('saleTransactionsPage:bidDrawer.lastBid')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft)} color="secondary">
                                            {lastBid
                                                ? `${t('common:currency')}${lastBid.toLocaleString()}`
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex" mb="12px">
                                        <Typography className={styles.header}>
                                            {t('saleTransactionsPage:bidDrawer.remainingChances')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft)} color="secondary">
                                            {latestBiddingSessionEntry.remainDealerBiddingEntryCount}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {lastBid && (
                                    <Grid xs={12} item>
                                        <Box display="flex" mb="12px">
                                            <Typography className={styles.header}>
                                                {t('saleTransactionsPage:bidDrawer.topBidding')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {latestBiddingSessionEntry.isInTop ? t('common:yes') : t('common:no')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                <Grid xs={12} item>
                                    <Box mb="4px" mt="4px">
                                        <OutlinedInputField
                                            color="secondary"
                                            name="amount"
                                            placeholder={t('saleTransactionsPage:bidDrawer.amountPlaceholder')}
                                            type="number"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box className={styles.submitContainer}>
                                        <Button
                                            color="secondary"
                                            disabled={disableBid}
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                        >
                                            {t('saleTransactionsPage:bidDrawer.submit')}
                                        </Button>
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box className={styles.rules}>
                                        <Link
                                            className={styles.rulesText}
                                            color="secondary"
                                            component="a"
                                            onClick={() => setShowBiddingRulesAndRegulations(true)}
                                            underline="none"
                                        >
                                            {t('saleTransactionsPage:bidDrawer.rulesText')}
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Dialog
                                aria-labelledby="submitted-dialog-title"
                                classes={{ paper: commonStyles.dialog }}
                                maxWidth="xs"
                                onClose={() => setOpenConfirmDialog(false)}
                                open={openConfirmDialog}
                                disableEscapeKeyDown
                                fullWidth
                            >
                                <DialogContent
                                    className={cs(commonStyles.secondaryBgColor, styles.confirmDialogContent)}
                                >
                                    <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                                        <img alt="exclamation-icon" src={exclamation} />
                                        <Box mb={1} mt={1}>
                                            <Typography className={commonStyles.primary2}>
                                                {t('saleTransactionsPage:bidDrawer.bidConfirmation')}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            className={cs(
                                                styles.confirmDialogBody,
                                                commonStyles.smallFontSize,
                                                commonStyles.primary2
                                            )}
                                        >
                                            {t('saleTransactionsPage:bidDrawer.bidConfirmText', {
                                                amount: `${t('common:currency')}${amount?.toLocaleString()}`,
                                                chances: latestBiddingSessionEntry.remainDealerBiddingEntryCount - 1,
                                            })}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" mt={3}>
                                        <Button
                                            className={cs(
                                                commonStyles.primary2,
                                                commonStyles.primary2Border,
                                                styles.confirmDialogButton
                                            )}
                                            onClick={() => setOpenConfirmDialog(false)}
                                            size="large"
                                            variant="outlined"
                                        >
                                            {t('common:cancel')}
                                        </Button>
                                        <Button
                                            className={cs(
                                                commonStyles.primary2,
                                                commonStyles.primary2Border,
                                                styles.confirmDialogButton
                                            )}
                                            onClick={submitForm}
                                            size="large"
                                            variant="outlined"
                                        >
                                            {t('common:confirm')}
                                        </Button>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Form>
                    );
                }}
            </Formik>
        </Drawer>
    );
};

export default BiddingDrawer;
