import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { contactEmail, contactPhone } from '../../../../server/schema/constants';
import { FullNotificationDataFragment, NotificationChannel, NotificationType } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import getNotificationType from '../../../utilities/getNotificationType';

export type NotificationRowProps = {
    notice: FullNotificationDataFragment;
};

const NotificationRow = ({ notice }: NotificationRowProps) => {
    const { t } = useTranslation(['notifications', 'common']);
    const commonStyles = useCommonStyles();

    const getNotificationDetails = useCallback(
        (noticeType: NotificationType): string => {
            switch (noticeType) {
                case NotificationType.AwardedNotice:
                    return t('notifications:details.awardedNotice');

                case NotificationType.HandoverDateChangeNotice:
                    return t('notifications:details.handoverDateChange');

                case NotificationType.PurchaseTransactionAmendedNotice:
                    return t('notifications:details.purchaseTransactionAmentedNotice');

                case NotificationType.PendingAcceptanceNotice:
                    return t('notifications:details.pendingAcceptance');

                case NotificationType.PendingValuationNotice:
                    return t('notifications:details.pendingValuation');

                case NotificationType.PendingRequoteNotice:
                    return t('notifications:details.pendingRequote');

                case NotificationType.SignUpApproval:
                    return t('notifications:details.signUpApproval');

                case NotificationType.SignUpReject:
                    return t('notifications:details.signUpReject', { contactEmail, contactPhone });

                case NotificationType.UpcomingBiddingNotice:
                    return t('notifications:details.upcomingBidding');

                case NotificationType.PurchaseTransactionApprovalNotice:
                    return t('notifications:details.pendingApproval');

                case NotificationType.SalesTransactionApprovalNotice:
                    return t('notifications:details.saleTransactionPendingApproval');

                case NotificationType.PurchaseTransactionValuationAcceptedNotice:
                    return t('notifications:details.valuationAccepted');

                case NotificationType.PurchaseTransactionApprovedNotice:
                case NotificationType.SalesTransactionApprovedNotice:
                    return t('notifications:details.transactionApproved');

                case NotificationType.PurchaseTransactionRejectedNotice:
                    return t('notifications:details.transactionRejected');

                case NotificationType.SalesTransactionRejectedNotice:
                    return t('notifications:details.saleTransactionRejected');

                case NotificationType.UnawardNotice:
                    return t('notifications:details.unawardNotice');

                default:
                    return '';
            }
        },
        [t]
    );

    const getTransactionNo = useCallback((note: FullNotificationDataFragment) => {
        switch (note.__typename) {
            case 'AwardedNoticeNotification':
            case 'UpcomingBiddingNoticeNotification':
                return note.saleTransactions.map(st => st.identifier).join(', ');

            case 'HandoverDateChangeNoticeNotification':
            case 'PurchaseTransactionAmendedNoticeNotification':
            case 'PendingValuationNoticeNotification':
            case 'PendingRequoteNoticeNotification':
            case 'PendingAcceptanceNoticeNotification':
            case 'PurchaseTransactionApprovalNoticeNotification':
            case 'PurchaseTransactionApprovedNoticeNotification':
            case 'PurchaseTransactionValuationAcceptedNoticeNotification':
            case 'PurchaseTransactionRejectedNoticeNotification':
                return note.purchaseTransaction.identifier;

            case 'SalesTransactionApprovalNoticeNotification':
            case 'SalesTransactionApprovedNoticeNotification':
            case 'SalesTransactionRejectedNoticeNotification':
            case 'UnawardNoticeNotification':
                return note.salesTransaction.identifier;

            default:
                return '';
        }
    }, []);

    const getChannels = useCallback(
        () =>
            notice.channels.map(channel => {
                switch (channel) {
                    case NotificationChannel.Email:
                        return t('notifications:channel.email');
                    case NotificationChannel.Push:
                        return t('notifications:channel.pushNotification');
                    case NotificationChannel.Sms:
                        return t('notifications:channel.sms');
                    default:
                        return '';
                }
            }),
        [notice.channels, t]
    );

    return (
        <TableRow className={commonStyles.tableRow}>
            <TableCell>{getTransactionNo(notice)}</TableCell>
            <TableCell>
                {t('common:formats.longDateTime', {
                    date: new Date(notice.createdAt),
                })}
            </TableCell>
            <TableCell>{notice.user.mobilePhone.number}</TableCell>
            <TableCell>{getNotificationType(notice.type)}</TableCell>
            <TableCell>{getChannels().join(', ')}</TableCell>
            <TableCell>{getNotificationDetails(notice.type)}</TableCell>
        </TableRow>
    );
};

export default NotificationRow;
