import { AppBar, Toolbar, Drawer, IconButton, CardMedia, Paper, Link, useMediaQuery, Box } from '@material-ui/core';
import { MuiThemeProvider, useTheme, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import cs from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useRuntimeConfig } from '../../runtimeConfig';
import usePublic from '../../utilities/usePublic';
import basicTheme from './basicTheme';
import { DesktopDrawerHeader } from './components/Desktop';
import { MobileDrawerHeader, MobileDrawerFooter, MobileDrawerBody } from './components/Mobile';
import ProfileMenu from './components/ProfileMenu';
import useBasicStyles from './useBasicStyles';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        drawerContainer: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        appVersion: {
            marginTop: 'auto',
            color: '#fff',
            marginBottom: '0.5rem',
            alignSelf: 'center',
            fontSize: '1rem',
            fontFamily: 'Lato',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        mobile: {
            color: '#717073',
        },
    })
);

export type BasicLayoutProps = {
    children: ReactElement | ReactNode | null;
    onBack?: () => void;
};

const BasicLayout = ({ children, onBack }: BasicLayoutProps): ReactElement => {
    const classes = useBasicStyles();
    const { t } = useTranslation(['common']);
    const theme = useTheme();
    const styles = useStyles();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { version: appVersion } = useRuntimeConfig();

    const [open, setOpen] = React.useState(true);

    const eurokarsPreOwned = usePublic('assets/images/eurokarsPreOwned.svg');

    return (
        <MuiThemeProvider theme={basicTheme}>
            <div className={classes.root}>
                <AppBar
                    className={cs(classes.appBar, classes.lightBoxShadow, { [classes.appBarShift]: open })}
                    elevation={0}
                    position="fixed"
                >
                    <Toolbar className={classes.toolBar}>
                        <IconButton
                            className={classes.menuIcon}
                            color="secondary"
                            edge="start"
                            onClick={() => setOpen(!open)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {isSmall ? <CardMedia className={classes.logo} image={eurokarsPreOwned} /> : <ProfileMenu />}
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={cs(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: cs(classes.drawerPaper, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    variant="permanent"
                >
                    {isSmall ? (
                        <Box className={styles.drawerContainer}>
                            <MobileDrawerHeader setOpen={setOpen} />
                            <MobileDrawerBody setOpen={setOpen} />
                            <Box className={cs(styles.appVersion, styles.mobile)}>
                                <span>{`${t('common:appVersion')}: ${appVersion}`}</span>
                            </Box>
                            <MobileDrawerFooter open={open} />
                        </Box>
                    ) : (
                        <>
                            <DesktopDrawerHeader open={open} />
                            <Box className={styles.appVersion}>
                                <span>{`${t('common:appVersion')}: ${appVersion}`}</span>
                            </Box>
                        </>
                    )}
                </Drawer>
                <div className={classes.content}>
                    {onBack && (
                        <Paper className={cs(classes.backPaper, classes.lightBoxShadow)} elevation={0}>
                            <Link className={cs(classes.linkBack)} component="a" onClick={onBack}>
                                <ArrowBackIosIcon fontSize="small" /> {t('common:portalBackButton')}
                            </Link>
                        </Paper>
                    )}
                    <main className={classes.main}>{children}</main>
                </div>
            </div>
        </MuiThemeProvider>
    );
};

export default BasicLayout;
