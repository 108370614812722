import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { DownloadFileType, PreviewPurchaseTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { useLoading } from '../../../components/LoadingProvider';
import {
    DownloadListFn,
    PurchaseTransactionsBody,
    PurchaseTransactionsFooter,
    PurchaseTransactionsHeader,
} from '../../PurchaseTransactions/components';
import { PurchaseOrSalesTransactionTabType } from '../../SaleTransactions';
import FilterDialog from './PurchaseFilterDialog';
import FilterMenu from './PurchaseFilterMenu';
import { PurchaseTransactionFilter } from './VtTransactions';

export type PurchaseHandoverTabProps = {
    search: string;
    purchaseTransactions: PreviewPurchaseTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPurchaseTransactionsFile: DownloadListFn;
    filter: PurchaseTransactionFilter;
    setFilterValue: (value: PurchaseTransactionFilter) => void;
    refreshFn?: () => Promise<unknown>;
    currentTab?: PurchaseOrSalesTransactionTabType;
};

const PurchaseHandoverTab = ({
    count,
    currentTab,
    downloadPurchaseTransactionsFile,
    page,
    pageSize,
    purchaseTransactions,
    refreshFn,
    search,
    filter,
    searchOnChange,
    setActivePage,
    setFilterValue,
}: PurchaseHandoverTabProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const { attach } = useLoading();

    const queryFilter = useMemo(
        () => ({
            stages: filter.stage === 'all' ? null : [filter.stage],
            fromCreated: filter.fromCreated,
            toCreated: filter.toCreated,
            fromHandover: filter.fromHandover,
            toHandover: filter.toHandover,
            showArchived: filter.showArchived,
        }),
        [filter]
    );

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        ...queryFilter,
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadPurchaseTransactionsFile, queryFilter, search]
    );

    const setValues = useCallback(
        (value: PurchaseTransactionFilter) => {
            setFilterValue(value);
        },
        [setFilterValue]
    );

    return (
        <>
            <FilterDialog filter={filter} open={showFilterDialog} setOpen={setShowFilterDialog} setValues={setValues} />
            <FilterMenu filter={filter} open={showFilterMenu} setOpen={setShowFilterMenu} setValues={setValues} />
            <PurchaseTransactionsHeader
                downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                refreshFn={refreshFn}
                search={search}
                searchOnChange={searchOnChange}
                setShowFilterDialog={setShowFilterDialog}
                setShowFilterMenu={setShowFilterMenu}
            />
            <PurchaseTransactionsBody currentTab={currentTab} purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <PurchaseTransactionsFooter />}
        </>
    );
};

export default PurchaseHandoverTab;
