import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import cs from 'classnames';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    imageFileExtensions,
    maxUploadFileSize,
    maxValuationPhotos,
    pdfFileExtension,
} from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import {
    NameOnlyFileDataFragment,
    FullPurchaseTransactionDataFragment,
    useRemoveValuationPhotoMutation,
    useRemoveVpaAttachmentMutation,
    UserType,
    useUpdatePurchaseTransactionForApprovalRequestMutation,
    useUpdatePurchaseTransactionFrontPagePhotoMutation,
    useUploadValuationPhotoMutation,
    useUploadVpaAttachmentMutation,
} from '../../../../api';
import { AttachmentButton, FrontPhotoManager } from '../../../../components/attachments';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import OutlinedTimePickerField from '../../../../components/fields/OutlinedTimePickerField';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import { diffUploads } from '../../../../utilities/file';
import { useHandleError } from '../../../../utilities/handleErrors';
import useGeneralConditionOptions from '../../../../utilities/useGeneralConditionOptions';
import useHandoverLocationOptions, {
    defaultOtherLocationOption,
} from '../../../../utilities/useHandoverLocationOptions';
import useValidator from '../../../../utilities/useValidator';
import useYesNoOptions from '../../../../utilities/useYesNoOptions';
import validators from '../../../../utilities/validators';

export type ErrorRequestForApprovalDialogProps = {
    show?: boolean;
    setHideDialog: () => void;
    setOkDialog: (updatedPurchaseTransaction: FullPurchaseTransactionDataFragment) => void;
    title: string;
    cancelBtn?: string;
    okBtn?: string;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

type RequestForApprovalEditFormValues = {
    handover: {
        handoverLocationField: { main: string; other?: string };
        vpaAttachments: (NameOnlyFileDataFragment | File)[];
        targetHandoverDate?: Date;
        targetHandoverTime?: Date;
        targetSetOfKeys?: number;
        targetHandbook?: boolean;
    };
    vehicle: {
        purchaseAgreementNumber: string;
    };
    valuation: {
        photos: (NameOnlyFileDataFragment | File)[];
        generalCondition?: string;
    };
    frontPagePhoto?: NameOnlyFileDataFragment | File;
};

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            width: '100%',
        },
    })
);

const ErrorRequestForApprovalDialog = ({
    show,
    setHideDialog,
    setOkDialog,
    title,
    cancelBtn,
    okBtn,
    purchaseTransaction,
}: ErrorRequestForApprovalDialogProps) => {
    const generalConditionOptions = useGeneralConditionOptions();

    const [removeValuationPhotoMutation] = useRemoveValuationPhotoMutation();
    const [removeVpaAttachmentMutation] = useRemoveVpaAttachmentMutation();
    const [uploadValuationPhotoMutation] = useUploadValuationPhotoMutation();
    const [uploadVpaAttachmentMutation] = useUploadVpaAttachmentMutation();
    const [updatePurchaseTransactionFrontPagePhotoMutation] = useUpdatePurchaseTransactionFrontPagePhotoMutation();
    const [updatePurchaseTransactionForApprovalRequestMutation] =
        useUpdatePurchaseTransactionForApprovalRequestMutation();

    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const handoverLocationOptions = useHandoverLocationOptions();
    const yesNoOptions = useYesNoOptions();
    const currentUser = useCurrentUser();
    const {
        handover: { handoverLocation, vpaAttachment, targetSetOfKeys, targetHandbook, targetHandoverDateTime },
        vehicle: { purchaseAgreementNumber },
        valuation: { photos, generalCondition },
        frontPagePhotoSourceId,
    } = purchaseTransaction;

    const handoverLocationOption = useMemo(
        () => handoverLocationOptions.find(option => option.value === handoverLocation),
        [handoverLocationOptions, handoverLocation]
    );

    const handover = useMemo(
        () => ({
            handoverLocationField: {
                main: handoverLocationOption ? handoverLocationOption.value : defaultOtherLocationOption,
                other: !handoverLocationOption ? handoverLocation : '',
            },
            vpaAttachments: vpaAttachment ? [vpaAttachment] : [],
            targetSetOfKeys,
            targetHandbook,
            targetHandoverDate: targetHandoverDateTime ? dayjs(targetHandoverDateTime).toDate() : null,
            targetHandoverTime: targetHandoverDateTime ? dayjs(targetHandoverDateTime).toDate() : null,
        }),
        [
            vpaAttachment,
            handoverLocationOption,
            handoverLocation,
            targetSetOfKeys,
            targetHandbook,
            targetHandoverDateTime,
        ]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.requiredValue('handover.handoverLocationField.main'),
                validators.validAttachments('handover.vpaAttachments', t('common:formErrors.required')),
                validators.validAttachments('valuation.photos', t('common:formErrors.required')),
                validators.requiredString('vehicle.purchaseAgreementNumber'),
                validators.requiredDate('handover.targetHandoverDate'),
                validators.requiredValue('handover.targetHandoverTime'),
                validators.requiredNumber('handover.targetSetOfKeys'),
                validators.requiredValue('handover.targetHandbook'),
                validators.requiredStringOptionValues(
                    'valuation.generalCondition',
                    generalConditionOptions.map(option => option.value)
                ),
                validators.only(
                    ({
                        handover: {
                            handoverLocationField: { main },
                        },
                    }) => main === defaultOtherLocationOption,
                    validators.requiredString('handover.handoverLocationField.other')
                )
            ),
        [generalConditionOptions, t]
    );
    const validate = useValidator(formValidator);
    const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;
    const allowedPdfImageExtensions = [pdfFileExtension, ...imageFileExtensions];

    const initialValues = useMemo(
        () => ({
            handover,
            vehicle: {
                purchaseAgreementNumber,
            },
            valuation: {
                photos,
                generalCondition,
            },
            frontPagePhoto: photos.find(document => document.id === frontPagePhotoSourceId),
        }),
        [photos, frontPagePhotoSourceId, purchaseAgreementNumber, handover, generalCondition]
    );

    const onSubmit = useHandleError(
        async (values: RequestForApprovalEditFormValues) => {
            const { handover: handoverFormData, ...others } = values;

            const photosDiff = diffUploads(others.valuation.photos, photos);
            const vpaAttachmentsDiff = diffUploads(handoverFormData.vpaAttachments, handover.vpaAttachments);
            const { frontPagePhoto } = others;

            await Promise.all([
                ...photosDiff.removedUploads.map(async file => {
                    await removeValuationPhotoMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...vpaAttachmentsDiff.removedUploads.map(async file => {
                    await removeVpaAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...photosDiff.newUploads.map(async file => {
                    await uploadValuationPhotoMutation({
                        variables: {
                            purchaseTransactionId: purchaseTransaction.id,
                            file,
                            isFrontPagePhoto: frontPagePhoto === file,
                        },
                    });
                }),
                ...vpaAttachmentsDiff.newUploads.map(async file => {
                    await uploadVpaAttachmentMutation({
                        variables: { purchaseTransactionId: purchaseTransaction.id, file },
                    });
                }),
            ]);

            let targetHandoverDateTime: Date = null;
            if (handoverFormData.targetHandoverDate && handoverFormData.targetHandoverTime) {
                targetHandoverDateTime = dayjs(handoverFormData.targetHandoverDate)
                    .set('hour', dayjs(handoverFormData.targetHandoverTime).hour())
                    .set('minute', dayjs(handoverFormData.targetHandoverTime).minute())
                    .toDate();
            }
            const {
                data: { purchaseTransaction: updatedPurchaseTransaction },
            } = await updatePurchaseTransactionForApprovalRequestMutation({
                variables: {
                    purchaseTransactionId: purchaseTransaction.id,
                    fields: {
                        handover: {
                            handoverLocation:
                                handoverFormData.handoverLocationField.other ||
                                handoverFormData.handoverLocationField.main,
                            targetHandoverDateTime,
                            targetHandbook: handoverFormData.targetHandbook,
                            targetSetOfKeys: handoverFormData.targetSetOfKeys,
                        },
                        valuation: {
                            generalCondition: others.valuation.generalCondition,
                        },
                        vehicle: {
                            purchaseAgreementNumber: others.vehicle.purchaseAgreementNumber,
                        },
                    },
                },
            });

            if (!(frontPagePhoto instanceof File) && frontPagePhoto?.id !== frontPagePhotoSourceId) {
                await updatePurchaseTransactionFrontPagePhotoMutation({
                    variables: { purchaseTransactionId: purchaseTransaction.id, frontPagePhotoId: frontPagePhoto?.id },
                });
            }
            setOkDialog(updatedPurchaseTransaction);
        },
        [setOkDialog, handover.vpaAttachments, photos, frontPagePhotoSourceId]
    );

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="sm"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={cs(styles.dialogContent)}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={1} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.leftTextAlign)}>
                            {title}
                        </Typography>
                    </Box>
                    <Box mb={1} mt={1}>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                            {({
                                handleSubmit,
                                setFieldValue,
                                values: {
                                    handover: { handoverLocationField },
                                },
                            }) => {
                                useEffect(() => {
                                    if (handoverLocationField.main !== defaultOtherLocationOption) {
                                        // remove other location
                                        setFieldValue('handover.handoverLocationField.other', '');
                                    }
                                }, [handoverLocationField, setFieldValue]);

                                return (
                                    <Form>
                                        <Grid spacing={2} container>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedInputField
                                                    color="secondary"
                                                    label={t(
                                                        'purchaseTransactionsPage:vehicle.purchaseAgreementNumber'
                                                    )}
                                                    name="vehicle.purchaseAgreementNumber"
                                                    placeholder={t(
                                                        'purchaseTransactionsPage:vehicle.purchaseAgreementNumber'
                                                    )}
                                                />
                                            </Grid>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedSelectField
                                                    color="secondary"
                                                    inputProps={{
                                                        classes: {
                                                            icon: commonStyles.fillSecondary,
                                                        },
                                                    }}
                                                    label={t(
                                                        'purchaseTransactionsPage:handover.targetHandoverLocation'
                                                    )}
                                                    name="handover.handoverLocationField.main"
                                                    options={handoverLocationOptions}
                                                    placeholder={t(
                                                        'purchaseTransactionsPage:handover.targetHandoverLocation'
                                                    )}
                                                />
                                            </Grid>
                                            {handoverLocationField.main === defaultOtherLocationOption && (
                                                <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                    <OutlinedInputField
                                                        color="secondary"
                                                        label={t('purchaseTransactionsPage:handover.otherLocation')}
                                                        name="handover.handoverLocationField.other"
                                                        placeholder={t(
                                                            'purchaseTransactionsPage:handover.otherLocation'
                                                        )}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedDatePickerField
                                                    color="secondary"
                                                    disabled={
                                                        currentUser.type !== UserType.Admin &&
                                                        currentUser.type !== UserType.ValuationTeam &&
                                                        currentUser.type !== UserType.Approver
                                                    }
                                                    label={t('purchaseTransactionsPage:handover.targetHandoverDate')}
                                                    name="handover.targetHandoverDate"
                                                    placeholder={t(
                                                        'purchaseTransactionsPage:handover.targetHandoverDate'
                                                    )}
                                                />
                                            </Grid>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedTimePickerField
                                                    color="secondary"
                                                    disabled={
                                                        currentUser.type !== UserType.Admin &&
                                                        currentUser.type !== UserType.SaleConsultant &&
                                                        currentUser.type !== UserType.ValuationTeam &&
                                                        currentUser.type !== UserType.Approver
                                                    }
                                                    label={t('purchaseTransactionsPage:handover.targetHandoverTime')}
                                                    name="handover.targetHandoverTime"
                                                    placeholder={t(
                                                        'purchaseTransactionsPage:handover.targetHandoverTime'
                                                    )}
                                                />
                                            </Grid>

                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedInputField
                                                    color="secondary"
                                                    disabled={currentUser.type === UserType.SaleConsultant}
                                                    label={t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                                                    name="handover.targetSetOfKeys"
                                                    placeholder={t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedSelectField
                                                    color="secondary"
                                                    disabled={currentUser.type === UserType.SaleConsultant}
                                                    inputProps={{
                                                        classes: {
                                                            icon: commonStyles.fillSecondary,
                                                        },
                                                    }}
                                                    label={t('purchaseTransactionsPage:handover.targetHandbook')}
                                                    name="handover.targetHandbook"
                                                    options={yesNoOptions}
                                                    placeholder={t('purchaseTransactionsPage:handover.targetHandbook')}
                                                />
                                            </Grid>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <OutlinedSelectField
                                                    color="secondary"
                                                    disabled={
                                                        currentUser.type !== UserType.Admin &&
                                                        currentUser.type !== UserType.ValuationTeam &&
                                                        currentUser.type !== UserType.Approver
                                                    }
                                                    inputProps={{
                                                        classes: {
                                                            icon: commonStyles.fillSecondary,
                                                        },
                                                    }}
                                                    label={t('purchaseTransactionsPage:valuation.generalCondition')}
                                                    name="valuation.generalCondition"
                                                    options={generalConditionOptions}
                                                    placeholder={t(
                                                        'purchaseTransactionsPage:valuation.generalCondition'
                                                    )}
                                                />
                                            </Grid>

                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <FrontPhotoManager
                                                    attachmentFieldName="valuation.photos"
                                                    name="frontPagePhoto"
                                                >
                                                    {renderPrefix => (
                                                        <AttachmentButton
                                                            allowedExtensions={[...imageFileExtensions]}
                                                            buttonText={t('common:upload')}
                                                            icon={<PublishIcon fontSize="small" />}
                                                            label={t('purchaseTransactionsPage:valuation.photos')}
                                                            max={maxValuationPhotos}
                                                            maxSize={maxFileSizeInKB}
                                                            name="valuation.photos"
                                                            renderPrefix={renderPrefix}
                                                            uploadFileType="pick_image"
                                                            multiple
                                                        />
                                                    )}
                                                </FrontPhotoManager>
                                            </Grid>
                                            <Grid className={commonStyles.leftTextAlign} xs={12} item>
                                                <AttachmentButton
                                                    allowedExtensions={allowedPdfImageExtensions}
                                                    buttonText={t('common:upload')}
                                                    icon={<PublishIcon fontSize="small" />}
                                                    label={t('purchaseTransactionsPage:handover.vpaAttachment')}
                                                    max={1}
                                                    maxSize={maxFileSizeInKB}
                                                    name="handover.vpaAttachments"
                                                    uploadFileType="pick_file"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" mt={3}>
                                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                                <Box mr={1}>
                                                    <Button
                                                        className={styles.button}
                                                        onClick={setHideDialog}
                                                        size="large"
                                                        variant="outlined"
                                                    >
                                                        {cancelBtn || t('common:cancel')}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                                <Box>
                                                    <Button
                                                        className={styles.button}
                                                        color="secondary"
                                                        onClick={() => handleSubmit()}
                                                        size="large"
                                                        variant="contained"
                                                    >
                                                        {okBtn || t('common:ok')}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ErrorRequestForApprovalDialog;
