import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../Session';
import { useGetSaleTransactionQuery, UserType } from '../../api';
import { LocationHistory } from '../../components/LocationHistory';
import useDidUpdateEffect from '../../utilities/useDidUpdateEffect';
import EditSaleTransaction from './EditSaleTransactions';
import ViewSaleTransaction from './ViewSaleTransaction';
import { DownloadSalesTransactionDetailFileProvider } from './components';

export type SaleTransactionDetailProps = {
    id: string;
};

const SaleTransactionDetail = ({ id }: SaleTransactionDetailProps) => {
    const [onEdition, setOnEdition] = useState(false);

    const currentUser = useCurrentUser();
    const location = useLocation();
    const { refreshSalesTransactionDetail } = (location.state || {}) as LocationHistory;

    const { data, refetch } = useGetSaleTransactionQuery({
        fetchPolicy: 'cache-and-network',
        variables: { transactionId: id },
    });

    useDidUpdateEffect(() => {
        if (refreshSalesTransactionDetail) {
            refetch({ transactionId: id });
        }
    }, [refetch, id, refreshSalesTransactionDetail]);

    const saleTransaction = data?.saleTransaction;

    if (!saleTransaction) {
        return null;
    }

    if (onEdition) {
        return <EditSaleTransaction goToView={() => setOnEdition(false)} saleTransaction={saleTransaction} />;
    }

    return (
        <DownloadSalesTransactionDetailFileProvider>
            {downloadFile => (
                <ViewSaleTransaction
                    downloadSalesTransactionDetailFile={downloadFile}
                    goToEdition={() => {
                        if (currentUser.type !== UserType.Dealer) {
                            setOnEdition(true);
                        }
                    }}
                    onEdition={onEdition}
                    saleTransaction={saleTransaction}
                />
            )}
        </DownloadSalesTransactionDetailFileProvider>
    );
};

export default SaleTransactionDetail;
