import { Grid, Typography, Box, FormControl, Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    FullSaleTransactionDataFragment,
    SaleTransactionStage,
    useCloneSaleTransactionCreateNewMutation,
    useGetUserQuery,
    UserType,
    useUpdateSaleTransactionToPendingApprovalMutation,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import { useAlert } from '../../../../components/AlertProvider';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import FileList from '../../../../components/attachments/FileList';
import useCommonStyles from '../../../../useCommonStyles';
import ErrorRequestForApprovalDialog from './ErrorRequestForApprovalDialog';
import ErrorSelectAwardeeDialog from './ErrorSelectAwardeeDialog';

export type HandoverTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeStageGridItem: {
            [theme.breakpoints.up('sm')]: {
                order: 1,
            },
        },
    })
);

const HandoverTab = ({ saleTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const currentUser = useCurrentUser();

    const { data: userQuery } = useGetUserQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            id: saleTransaction.awardedBiddingEntry?.dealer.id,
        },
        skip: !saleTransaction.awardedBiddingEntry?.dealer,
    });

    const getDealerBusinessName = useMemo(() => {
        switch (userQuery?.user?.__typename) {
            case 'DealerUser':
                return userQuery?.user?.business?.name;
        }

        return '';
    }, [userQuery?.user]);

    const { show } = useAlert();

    const [updateToPendingApproval] = useUpdateSaleTransactionToPendingApprovalMutation();
    const [cloneSaleTransaction] = useCloneSaleTransactionCreateNewMutation({
        variables: { salesTransactionId: saleTransaction.id },
    });

    const [showRequestForApprovalConfirmDialog, setShowRequestForApprovalConfirmDialog] = useState(false);
    const [showCreateNewConfirmDialog, setShowCreateNewConfirmDialog] = useState(false);
    const [showCreateManualSalesConfirmDialog, setShowCreateManualSalesConfirmDialog] = useState(false);
    const [showRequestApprovalEditDialog, setShowRequestApprovalEditDialog] = useState(false);
    const [showSelectAwardeeEditDialog, setShowSelectAwardeeEditDialog] = useState(false);

    const onUpdateToPendingApproval = useCallback(
        (
            alwaysShowPopup?: boolean,
            vsaAttachment?: FullSaleTransactionDataFragment['handover']['vsaAttachment'],
            handoverLocation?: string
        ) => {
            const updatedVsaAttachment = vsaAttachment || saleTransaction.handover.vsaAttachment;
            if (!alwaysShowPopup && updatedVsaAttachment) {
                updateToPendingApproval({
                    variables: {
                        transactionId: saleTransaction.id,
                        handoverLocation,
                    },
                });
                show('success', t('saleTransactionsPage:successfulMessage.requestApproval'));
            } else {
                setShowRequestApprovalEditDialog(true);
            }
        },
        [saleTransaction.handover.vsaAttachment, t, updateToPendingApproval, show, saleTransaction.id]
    );

    const onCloneSaleTransaction = useCallback(() => {
        cloneSaleTransaction();
        show('success', t('saleTransactionsPage:successfulMessage.createNewST'));
    }, [show, cloneSaleTransaction, t]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.handoverDetails')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.personResponsible')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.handover?.personResponsible?.fullName}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.targetHandoverDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.handover?.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover?.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.handoverLocation')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.handover.handoverLocation
                                        ? saleTransaction.handover.handoverLocation
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.actualHandoverDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.handover.actualHandoverDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover.actualHandoverDate),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>

                        {saleTransaction.awardedBiddingEntry && !saleTransaction?.handover.manualSalesDealer && (
                            <>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.secondary2)}>
                                            {t('saleTransactionsPage:handover.awardedDealer')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft)}>
                                            {getDealerBusinessName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.secondary2)}>
                                            {t('saleTransactionsPage:handover.awardedBidAmount')}
                                        </Typography>
                                        <Typography className={cs(commonStyles.autoMarginLeft)}>
                                            {saleTransaction.awardedBiddingEntry.amount
                                                ? `${t(
                                                      'common:currency'
                                                  )}${saleTransaction.awardedBiddingEntry.amount.toLocaleString()}`
                                                : t('common:blankValue')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {currentUser.type !== UserType.Dealer && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={cs(commonStyles.secondary2)}>
                                        {t('saleTransactionsPage:handover.vsaNo')}
                                    </Typography>
                                    <Typography>
                                        {saleTransaction.handover.vehicleSalesAgreementNo
                                            ? saleTransaction.handover.vehicleSalesAgreementNo
                                            : t('common:blankValue')}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Box>
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.handoverRemarks')}
                                </Typography>
                                <Typography>
                                    {saleTransaction.handover.remarks
                                        ? saleTransaction.handover.remarks
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>

                        {saleTransaction.cloned &&
                            saleTransaction?.handover.manualSalesDealer &&
                            !!saleTransaction?.handover.manualSalesPrice && (
                                <>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={cs(commonStyles.secondary2)}>
                                                {t('saleTransactionsPage:handover.manualSalesDealer')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {saleTransaction?.handover.manualSalesDealer}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography className={cs(commonStyles.secondary2)}>
                                                {t('saleTransactionsPage:handover.manualSalesPrice')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {`${t(
                                                    'common:currency'
                                                )}${saleTransaction.handover.manualSalesPrice.toLocaleString()}`}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                        {currentUser.type !== UserType.Dealer && (
                            <Grid xs={12} item>
                                <Box>
                                    <Typography className={cs(commonStyles.secondary2)}>
                                        {t('saleTransactionsPage:handover.vsaAttachment')}
                                    </Typography>
                                    {saleTransaction.handover.vsaAttachment ? (
                                        <FileList files={[saleTransaction.handover.vsaAttachment]} />
                                    ) : (
                                        <Typography>{t('common:blankValue')}</Typography>
                                    )}
                                </Box>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Box>
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:handover.otherAttachments')}
                                </Typography>
                                {saleTransaction.handover.attachments.length > 0 ? (
                                    <FileList files={saleTransaction.handover.attachments} />
                                ) : (
                                    <Typography>{t('common:blankValue')}</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>

            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {saleTransaction.stage === SaleTransactionStage.ForHandover && (
                        <>
                            <Grid className={styles.changeStageGridItem} lg={12} md={12} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => setShowRequestForApprovalConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.requestApproval')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorRequestForApprovalDialog
                                saleTransaction={saleTransaction}
                                setHideDialog={() => setShowRequestApprovalEditDialog(false)}
                                setOkDialog={(vsaAttachment, handoverLocation) => {
                                    setShowRequestApprovalEditDialog(false);
                                    onUpdateToPendingApproval(false, vsaAttachment, handoverLocation);
                                }}
                                show={showRequestApprovalEditDialog}
                                title="Please fill below field(s):"
                            />
                        </>
                    )}

                    <ConfirmDialog
                        body={t('saleTransactionsPage:confirmMessage.requestApproval.body')}
                        setContinueConfirmDialog={() => {
                            onUpdateToPendingApproval(true);
                            setShowRequestForApprovalConfirmDialog(false);
                        }}
                        setHideConfirmDialog={() => setShowRequestForApprovalConfirmDialog(false)}
                        show={showRequestForApprovalConfirmDialog}
                        title={t('saleTransactionsPage:confirmMessage.requestApproval.title')}
                    />
                </>
            )}

            {[UserType.Admin, UserType.ValuationTeam, UserType.Approver].includes(currentUser.type) && (
                <>
                    {saleTransaction.stage === SaleTransactionStage.Pending && (
                        <>
                            <Grid className={styles.changeStageGridItem} xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => setShowCreateNewConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.createNew')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <Grid className={styles.changeStageGridItem} xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => {
                                            setShowCreateManualSalesConfirmDialog(true);
                                        }}
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.manualSales')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ConfirmDialog
                                body={t('saleTransactionsPage:confirmMessage.createNewST.body')}
                                setContinueConfirmDialog={() => {
                                    onCloneSaleTransaction();
                                    setShowCreateNewConfirmDialog(false);
                                }}
                                setHideConfirmDialog={() => setShowCreateNewConfirmDialog(false)}
                                show={showCreateNewConfirmDialog}
                                title={t('saleTransactionsPage:confirmMessage.createNewST.title')}
                            />

                            <ConfirmDialog
                                body={t('saleTransactionsPage:confirmMessage.manualSalesST.body')}
                                setContinueConfirmDialog={() => {
                                    setShowSelectAwardeeEditDialog(true);
                                    setShowCreateManualSalesConfirmDialog(false);
                                }}
                                setHideConfirmDialog={() => setShowCreateManualSalesConfirmDialog(false)}
                                show={showCreateManualSalesConfirmDialog}
                                title={t('saleTransactionsPage:confirmMessage.manualSalesST.title')}
                            />

                            <ErrorSelectAwardeeDialog
                                saleTransaction={saleTransaction}
                                setHideDialog={() => setShowSelectAwardeeEditDialog(false)}
                                setOkDialog={() => {
                                    setShowSelectAwardeeEditDialog(false);
                                    show('success', t('saleTransactionsPage:successfulMessage.manualSalesST'));
                                }}
                                show={showSelectAwardeeEditDialog}
                                title="Please fill below field(s):"
                                isManualSales
                            />
                        </>
                    )}

                    <ConfirmDialog
                        body={t('saleTransactionsPage:confirmMessage.requestApproval.body')}
                        setContinueConfirmDialog={() => {
                            onUpdateToPendingApproval(true);
                            setShowRequestForApprovalConfirmDialog(false);
                        }}
                        setHideConfirmDialog={() => setShowRequestForApprovalConfirmDialog(false)}
                        show={showRequestForApprovalConfirmDialog}
                        title={t('saleTransactionsPage:confirmMessage.requestApproval.title')}
                    />
                </>
            )}
        </Grid>
    );
};

export default HandoverTab;
