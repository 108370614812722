import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

type ruleProp = {
    description: string;
    subRules: string[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1),
            },
        },
        subRules: {
            listStyleType: 'none',

            '& > li': {
                display: 'flex',

                '&:before': {
                    content: '"-"',
                    textIndent: '-20px',
                },
            },
        },
    })
);

const BiddingRulesAndRegulationsBody = () => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const rules: ruleProp[] = t('saleTransactionsPage:biddingRulesAndRegulation.rules', { returnObjects: true });
    const styles = useStyles();

    return (
        <Box pb={2} pl={1} pr={4} pt={9}>
            <ol>
                {rules.map((rule, index) => (
                    <li key={`rule-${index.toString()}`} className={styles.listItem}>
                        <Typography align="justify">{rule.description}</Typography>
                        {rule.subRules && (
                            <ul className={styles.subRules}>
                                {rule.subRules.map((subRule, subRuleIndex) => (
                                    <li key={`rule-${index.toString()}-subRule-${subRuleIndex.toString()}`}>
                                        <Typography align="justify">{subRule}</Typography>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ol>
        </Box>
    );
};

export default BiddingRulesAndRegulationsBody;
