import { Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MobileAppBar from '../../../../components/MobileAppBar';
import useCommonStyles from '../../../../useCommonStyles';

export type CreatePurchaseTransactionHeaderProps = {
    showForm: boolean;
};

const CreatePurchaseTransactionHeader = ({ showForm }: CreatePurchaseTransactionHeaderProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { isSubmitting } = useFormikContext();

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <Link color="secondary" component="a" onClick={history.goBack} underline="none">
                        {t('common:cancel')}
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={2} width="100%">
                    <Typography color="secondary">{t('purchaseTransactionsPage:createTitleMobile')}</Typography>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.title} display="flex">
            <Typography>{t('purchaseTransactionsPage:createTitle')}</Typography>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    <Button color="secondary" onClick={history.goBack} size="large" variant="outlined">
                        {t('common:cancel')}
                    </Button>
                    <Button
                        classes={{ disabled: commonStyles.disabledSecondaryButton }}
                        color="secondary"
                        disabled={isSubmitting || !showForm}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('common:save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CreatePurchaseTransactionHeader;
