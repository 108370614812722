import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser } from '../../Session';
import {
    PurchaseTransactionStage,
    useGetPurchaseTransactionsQuery,
    useGetSaleTransactionsQuery,
    SaleTransactionStage,
} from '../../api';
import { LocationHistory } from '../../components/LocationHistory';
import TabPanel from '../../components/TabPanel';
import { DownloadPurchaseTransactionsFileProvider } from '../PurchaseTransactions/components';
import { PurchaseOrSalesTransactionTabType } from '../SaleTransactions';
import { DownloadSalesTransactionsFileProvider } from '../SaleTransactions/components';
import { MenuTabs, PurchaseTransactionsTab, SalesTransactionsTab } from './components';

const a11yProps = (index: string) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

type PurchaseTransactionListContext = {
    purchaseTransactionSearch: string;
    purchaseTransactionPage: number;
};

type SalesTransactionListContext = {
    salesTransactionSearch: string;
    salesTransactionPage: number;
};

const pageSize = 9;

export type PurchaseTransactionFilter = {
    fromCreated: Date | null;
    toCreated: Date | null;
    fromHandover: Date | null;
    toHandover: Date | null;
    stage: 'all' | PurchaseTransactionStage;
    showArchived: boolean;
};

export const defaultPurchaseTransactionFilter: PurchaseTransactionFilter = {
    stage: 'all',
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    showArchived: false,
};

export type SalesTransactionFilter = {
    stage: 'all' | SaleTransactionStage;
    fromCreated: Date | null;
    toCreated: Date | null;
    fromHandover: Date | null;
    toHandover: Date | null;
    awardedDealer: string;
    showArchived: boolean;
};

export const defaultSalesTransactionFilter: SalesTransactionFilter = {
    stage: 'all',
    fromCreated: null,
    toCreated: null,
    fromHandover: null,
    toHandover: null,
    awardedDealer: 'all',
    showArchived: false,
};

const TransactionApprovals = () => {
    const currentUser = useCurrentUser();
    const {
        resetPurchaseTransactionsFilter = true,
        resetSalesTransactionsFilter = true,
        transactionApprovalTab = 'purchaseTransactions',
    } = (useLocation().state || {}) as LocationHistory;
    const [value, setValue] = useState<PurchaseOrSalesTransactionTabType>(transactionApprovalTab);

    const defaultPurchaseTransactionFilterLS = useMemo(
        () =>
            !resetPurchaseTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`TransactionApprovalsPurchaseTransactionsListLS-${currentUser.id}`)
                  ) as PurchaseTransactionFilter) || defaultPurchaseTransactionFilter
                : defaultPurchaseTransactionFilter,
        [resetPurchaseTransactionsFilter, currentUser.id]
    );

    useEffect(() => {
        if (resetPurchaseTransactionsFilter) {
            localStorage.removeItem(`TransactionApprovalsPurchaseTransactionsListLS-${currentUser.id}`);
        }
    }, [resetPurchaseTransactionsFilter, currentUser.id]);

    const [purchaseTransactionFilter, setPurchaseTransactoinFilter] = useState<PurchaseTransactionFilter>(
        defaultPurchaseTransactionFilterLS
    );

    const setPurchaseTransactionFilterAndPersist = useCallback(
        (data: PurchaseTransactionFilter) => {
            setPurchaseTransactoinFilter(data);
            localStorage.setItem(
                `TransactionApprovalsPurchaseTransactionsListLS-${currentUser.id}`,
                JSON.stringify(data)
            );
        },
        [setPurchaseTransactoinFilter, currentUser.id]
    );

    const [{ purchaseTransactionPage, purchaseTransactionSearch }, setPurchaseTransactionListContext] =
        useState<PurchaseTransactionListContext>({ purchaseTransactionSearch: '', purchaseTransactionPage: 0 });

    const queryPurchaseTransactionFilter = useMemo(
        () => ({
            stages: purchaseTransactionFilter.stage === 'all' ? null : [purchaseTransactionFilter.stage],
            fromCreated: purchaseTransactionFilter.fromCreated,
            toCreated: purchaseTransactionFilter.toCreated,
            fromHandover: purchaseTransactionFilter.fromHandover,
            toHandover: purchaseTransactionFilter.toHandover,
            showArchived: purchaseTransactionFilter.showArchived,
        }),
        [purchaseTransactionFilter]
    );

    const variablesPt = useMemo(
        () => ({
            page: { limit: pageSize, offset: purchaseTransactionPage * pageSize },
            filter: {
                search: purchaseTransactionSearch,
                ...queryPurchaseTransactionFilter,
            },
            sort: {
                stagePriority: PurchaseTransactionStage.PendingApproval,
            },
        }),
        [purchaseTransactionPage, purchaseTransactionSearch, queryPurchaseTransactionFilter]
    );

    const { data: purchaseTransactionsData, refetch: refetchPt } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesPt,
    });

    const refetchPtList = useCallback(() => refetchPt(variablesPt), [refetchPt, variablesPt]);

    const setPurchaseTransactionActivePage = useCallback(
        (newPage: number) => {
            setPurchaseTransactionListContext(state => ({ ...state, purchaseTransactionPage: newPage }));
        },
        [setPurchaseTransactionListContext]
    );

    const purchaseTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseTransactionListContext(state => ({ ...state, purchaseTransactionSearch: searchValue }));
        },
        [setPurchaseTransactionListContext]
    );

    const purchaseTransactionCount = purchaseTransactionsData?.purchaseTransactions?.count || 0;
    const purchaseTransactions = purchaseTransactionsData?.purchaseTransactions?.items || [];

    const [{ salesTransactionPage, salesTransactionSearch }, setSalesTransactionListContext] =
        useState<SalesTransactionListContext>({ salesTransactionSearch: '', salesTransactionPage: 0 });

    const defaultSalesTransactionFilterLS = useMemo(
        () =>
            !resetSalesTransactionsFilter
                ? (JSON.parse(
                      localStorage.getItem(`TransactionApprovalsSaleTransactionsListLS-${currentUser.id}`)
                  ) as SalesTransactionFilter) || defaultSalesTransactionFilter
                : defaultSalesTransactionFilter,
        [resetSalesTransactionsFilter, currentUser.id]
    );

    useEffect(() => {
        if (resetSalesTransactionsFilter) {
            localStorage.removeItem(`TransactionApprovalsSaleTransactionsListLS-${currentUser.id}`);
        }
    }, [resetSalesTransactionsFilter, currentUser.id]);

    const [salesTransactionFilter, setSalesTransactionFilter] = useState<SalesTransactionFilter>(
        defaultSalesTransactionFilterLS
    );

    const setSaleTransactionFilterAndPersist = useCallback(
        (data: SalesTransactionFilter) => {
            setSalesTransactionFilter(data);
            localStorage.setItem(`TransactionApprovalsSaleTransactionsListLS-${currentUser.id}`, JSON.stringify(data));
        },
        [setSalesTransactionFilter, currentUser.id]
    );

    const querySalesTransactionFilter = useMemo(
        () => ({
            fromCreated: salesTransactionFilter.fromCreated,
            toCreated: salesTransactionFilter.toCreated,
            fromHandover: salesTransactionFilter.fromHandover,
            toHandover: salesTransactionFilter.toHandover,
            awardedDealer: salesTransactionFilter.awardedDealer === 'all' ? null : salesTransactionFilter.awardedDealer,
            stages: salesTransactionFilter.stage === 'all' ? null : [salesTransactionFilter.stage],
            showArchived: salesTransactionFilter.showArchived,
        }),
        [salesTransactionFilter]
    );

    const variablesSt = useMemo(
        () => ({
            page: { limit: pageSize, offset: salesTransactionPage * pageSize },
            filter: {
                search: salesTransactionSearch,
                ...querySalesTransactionFilter,
            },
            sort: {
                stagePriority: SaleTransactionStage.PendingApproval,
            },
        }),
        [salesTransactionPage, salesTransactionSearch, querySalesTransactionFilter]
    );

    const { data: salesTransactionsData, refetch: refetchSt } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: variablesSt,
    });

    const refetchStList = useCallback(() => refetchSt(variablesSt), [refetchSt, variablesSt]);

    const setSalesTransactionActivePage = useCallback(
        (newPage: number) => {
            setSalesTransactionListContext(state => ({ ...state, salesTransactionPage: newPage }));
        },
        [setSalesTransactionListContext]
    );

    const salesTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setSalesTransactionListContext(state => ({ ...state, salesTransactionSearch: searchValue }));
        },
        [setSalesTransactionListContext]
    );

    const salesTransactionCount = salesTransactionsData?.saleTransactions?.count || 0;
    const salesTransactions = salesTransactionsData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="purchaseTransactions" value={value}>
                    <DownloadPurchaseTransactionsFileProvider>
                        {downloadFile => (
                            <PurchaseTransactionsTab
                                count={purchaseTransactionCount}
                                downloadPurchaseTransactionsFile={downloadFile}
                                filter={purchaseTransactionFilter}
                                page={purchaseTransactionPage}
                                pageSize={pageSize}
                                purchaseTransactions={purchaseTransactions}
                                refreshFn={refetchPtList}
                                search={purchaseTransactionSearch}
                                searchOnChange={purchaseTransactionSearchOnChange}
                                setActivePage={setPurchaseTransactionActivePage}
                                setFilterValue={setPurchaseTransactionFilterAndPersist}
                            />
                        )}
                    </DownloadPurchaseTransactionsFileProvider>
                </TabPanel>
                <TabPanel index="salesTransactions" value={value}>
                    <DownloadSalesTransactionsFileProvider>
                        {downloadFile => (
                            <SalesTransactionsTab
                                count={salesTransactionCount}
                                downloadSalesTransactionsFile={downloadFile}
                                filter={salesTransactionFilter}
                                page={salesTransactionPage}
                                pageSize={pageSize}
                                refreshFn={refetchStList}
                                saleTransactions={salesTransactions}
                                search={salesTransactionSearch}
                                searchOnChange={salesTransactionSearchOnChange}
                                setActivePage={setSalesTransactionActivePage}
                                setFilterValue={setSaleTransactionFilterAndPersist}
                            />
                        )}
                    </DownloadSalesTransactionsFileProvider>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default TransactionApprovals;
