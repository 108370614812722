import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    imageFileExtensions,
    maxHandoverAttachments,
    maxUploadFileSize,
    pdfFileExtension,
} from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { UserType, useGetUserListByTypeQuery, PurchaseTransactionStage } from '../../../../api';
import { AttachmentButton } from '../../../../components/attachments';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import OutlinedTimePickerField from '../../../../components/fields/OutlinedTimePickerField';
import useCommonStyles from '../../../../useCommonStyles';
import useDeliveryPeriodOptions, { DeliveryPeriodType } from '../../../../utilities/useDeliveryPeriodOptions';
import useHandoverLocationOptions, {
    defaultOtherLocationOption,
} from '../../../../utilities/useHandoverLocationOptions';
import useYesNoOptions from '../../../../utilities/useYesNoOptions';
import { PurchaseTransactionFormValues } from '../../PurchaseTransactionDetail/EditPurchaseTransaction';

export type HandoverFormProps = {
    onCreation?: boolean;
    onEdition?: boolean;
    stage?: PurchaseTransactionStage;
};

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const HandoverForm = ({ onCreation, onEdition, stage }: HandoverFormProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common', 'errors']);
    const commonStyles = useCommonStyles();
    const { values, setFieldValue } = useFormikContext<PurchaseTransactionFormValues>();
    const { handover } = values;
    const yesNoOptions = useYesNoOptions();
    const currentUser = useCurrentUser();

    const handoverLocationOptions = useHandoverLocationOptions();

    useEffect(() => {
        if (handover.handoverLocationField.main !== defaultOtherLocationOption) {
            // remove other location
            setFieldValue('handover.handoverLocationField.other', '');
        }
    }, [handover.handoverLocationField, setFieldValue]);

    const { data } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });

    const deliveryPeriods = useDeliveryPeriodOptions();

    const valuationTeamUserOptions = useMemo(
        () =>
            (data?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [data]
    );

    useEffect(() => {
        if (values.handover.deliveryPeriod !== DeliveryPeriodType.IndentOrder) {
            setFieldValue('handover.deliveryPeriodIndentOrder', '');
        }
    }, [setFieldValue, values.handover.deliveryPeriod]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !onCreation &&
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.SaleConsultant &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:handover.personResponsible')}
                    name="handover.personResponsible"
                    options={valuationTeamUserOptions}
                    placeholder={t('purchaseTransactionsPage:handover.personResponsible')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !(
                            currentUser.type === UserType.SaleConsultant &&
                            stage === PurchaseTransactionStage.InitialValuation
                        )
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                    name="handover.deliveryPeriod"
                    options={deliveryPeriods}
                    placeholder={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                />
            </Grid>
            {values.handover.deliveryPeriod === DeliveryPeriodType.IndentOrder && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        label={t('purchaseTransactionsPage:handover.deliveryPeriodIndentOrder')}
                        name="handover.deliveryPeriodIndentOrder"
                        placeholder={t('purchaseTransactionsPage:handover.deliveryPeriodIndentOrder')}
                    />
                </Grid>
            )}
            {!onCreation && (
                <>
                    <Grid xs={12} item>
                        <OutlinedDatePickerField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:handover.targetHandoverDate')}
                            name="handover.targetHandoverDate"
                            placeholder={t('purchaseTransactionsPage:handover.targetHandoverDate')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedTimePickerField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.SaleConsultant &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:handover.targetHandoverTime')}
                            name="handover.targetHandoverTime"
                            placeholder={t('purchaseTransactionsPage:handover.targetHandoverTime')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedSelectField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.SaleConsultant &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            inputProps={{
                                classes: {
                                    icon: commonStyles.fillSecondary,
                                },
                            }}
                            label={t('purchaseTransactionsPage:handover.targetHandoverLocation')}
                            name="handover.handoverLocationField.main"
                            options={handoverLocationOptions}
                            placeholder={t('purchaseTransactionsPage:handover.targetHandoverLocation')}
                        />
                    </Grid>
                </>
            )}

            {handover.handoverLocationField.main === defaultOtherLocationOption && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        disabled={
                            !onCreation &&
                            currentUser.type !== UserType.Admin &&
                            currentUser.type !== UserType.SaleConsultant &&
                            currentUser.type !== UserType.ValuationTeam &&
                            currentUser.type !== UserType.Approver
                        }
                        label={t('purchaseTransactionsPage:handover.otherLocation')}
                        name="handover.handoverLocationField.other"
                        placeholder={t('purchaseTransactionsPage:handover.otherLocation')}
                    />
                </Grid>
            )}
            {!onCreation && (
                <>
                    <Grid xs={12} item>
                        <OutlinedDatePickerField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:handover.actualHandoverDate')}
                            name="handover.actualHandoverDate"
                            placeholder={t('purchaseTransactionsPage:handover.actualHandoverDate')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:vehicle.purchaseAgreementNumber')}
                            name="vehicle.purchaseAgreementNumber"
                            placeholder={t('purchaseTransactionsPage:vehicle.purchaseAgreementNumber')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:vehicle.saleAgreementNumber')}
                            name="vehicle.saleAgreementNumber"
                            placeholder={t('purchaseTransactionsPage:vehicle.saleAgreementNumber')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={currentUser.type === UserType.SaleConsultant}
                            label={t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                            name="handover.targetSetOfKeys"
                            placeholder={t('purchaseTransactionsPage:handover.targetSetOfKeys')}
                            type="number"
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedSelectField
                            color="secondary"
                            disabled={currentUser.type === UserType.SaleConsultant}
                            inputProps={{
                                classes: {
                                    icon: commonStyles.fillSecondary,
                                },
                            }}
                            label={t('purchaseTransactionsPage:handover.targetHandbook')}
                            name="handover.targetHandbook"
                            options={yesNoOptions}
                            placeholder={t('purchaseTransactionsPage:handover.targetHandbook')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={
                                currentUser.type !== UserType.Admin || stage !== PurchaseTransactionStage.Completed
                            }
                            label={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                            name="vehicle.setOfKeys"
                            placeholder={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                            type="number"
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedSelectField
                            color="secondary"
                            disabled={
                                currentUser.type !== UserType.Admin || stage !== PurchaseTransactionStage.Completed
                            }
                            inputProps={{
                                classes: {
                                    icon: commonStyles.fillSecondary,
                                },
                            }}
                            label={t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                            name="vehicle.ownerHandbookCollected"
                            options={yesNoOptions}
                            placeholder={t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            disabled={
                                !onCreation &&
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            label={t('purchaseTransactionsPage:handover.handoverRemarks')}
                            name="handover.remarks"
                            placeholder={t('purchaseTransactionsPage:handover.handoverRemarks')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <AttachmentButton
                            allowedExtensions={allowedExtensions}
                            buttonText={t('common:upload')}
                            disabled={
                                currentUser.type !== UserType.Admin &&
                                currentUser.type !== UserType.ValuationTeam &&
                                currentUser.type !== UserType.Approver
                            }
                            icon={<PublishIcon fontSize="small" />}
                            label={t('purchaseTransactionsPage:handover.vpaAttachment')}
                            max={1}
                            maxSize={maxFileSizeInKB}
                            name="handover.vpaAttachments"
                            uploadFileType="pick_file"
                        />
                    </Grid>

                    {/* SC is not allowed */}
                    {!onCreation && currentUser.type !== UserType.SaleConsultant && (
                        <Grid xs={12} item>
                            <AttachmentButton
                                allowedExtensions={allowedExtensions}
                                buttonText={t('common:upload')}
                                disabled={
                                    currentUser.type !== UserType.Admin &&
                                    currentUser.type !== UserType.ValuationTeam &&
                                    currentUser.type !== UserType.Approver
                                }
                                icon={<PublishIcon fontSize="small" />}
                                label={t('purchaseTransactionsPage:handover.otherAttachments')}
                                max={maxHandoverAttachments}
                                maxSize={maxFileSizeInKB}
                                name="handover.attachments"
                                uploadFileType="pick_file"
                                multiple
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default HandoverForm;
