import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import BiddingEntriesForm from '../../Forms/BiddingEntriesForm';
import SessionDetailsForm from '../../Forms/SessionDetailsForm';

export type SessionDetailsTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const SessionDetailsTab = ({ saleTransaction }: SessionDetailsTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:accordionTitle.sessionDetails')} defaultExpanded>
                    <SessionDetailsForm saleTransaction={saleTransaction} />
                </Accordion>
            </Grid>

            {/* only Admin can edit bidding entries */}
            {currentUser.type === UserType.Admin && (
                <Grid xs={12} item>
                    <Accordion title={t('saleTransactionsPage:sessionDetails.topBids.title')} defaultExpanded>
                        <BiddingEntriesForm />
                    </Accordion>
                </Grid>
            )}
        </Grid>
    );
};

export default SessionDetailsTab;
