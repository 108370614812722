import { Box, Button, Grid, Typography, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    UserType,
    FullSaleTransactionDataFragment,
    SaleTransactionStage,
    useUpdateSaleTransactionToForHandoverMutation,
    useUpdateSaleTransactionToCompletedMutation,
    useUpdateSaleTransactionToPendingMutation,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import { useAlert } from '../../../../components/AlertProvider';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import useCommonStyles from '../../../../useCommonStyles';
import BiddingBlock from './BiddingBlock';
import ErrorSelectAwardeeDialog from './ErrorSelectAwardeeDialog';
import SelectBidDialog from './SelectBidDialog';
import TopBids from './TopBids';

export type BiddingTabTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeStageGridItem: {
            [theme.breakpoints.up('sm')]: {
                order: 1,
            },
        },
        closeTransactionLabel: {
            color: '#A5A5A5',
        },
        orderUnset: {
            order: 'unset',
        },
    })
);

const BiddingTab = ({ saleTransaction }: BiddingTabTabProps) => {
    const [openSelectAwardee, setOpenSelectAwardee] = useState(false);

    const { show } = useAlert();
    const [showUpdateToNotToAwardPendingConfirmDialog, setShowUpdateToNotToAwardPendingConfirmDialog] = useState(false);
    const [showUpdateToUnAwardPendingConfirmDialog, setShowUpdateToUnAwardPendingConfirmDialog] = useState(false);
    const [showApproveConfirmDialog, setShowApproveConfirmDialog] = useState(false);
    const [showRejectConfirmDialog, setShowRejectConfirmDialog] = useState(false);
    const [showSelectAwardeeEditDialog, setShowSelectAwardeeEditDialog] = useState(false);

    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const styles = useStyles();

    const variables = { transactionId: saleTransaction.id };
    const [updateToHandover] = useUpdateSaleTransactionToForHandoverMutation({ variables });
    const [updateToCompleted] = useUpdateSaleTransactionToCompletedMutation({ variables });
    const [updateToPending] = useUpdateSaleTransactionToPendingMutation({
        variables: {
            transactionId: saleTransaction.id,
            stage: saleTransaction.stage,
        },
    });

    // reject
    const onUpdateToHandover = useCallback(() => {
        updateToHandover();
        setShowRejectConfirmDialog(false);
        show('success', t('saleTransactionsPage:successfulMessage.reject'));
    }, [updateToHandover, setShowRejectConfirmDialog, show, t]);

    // approve
    const onUpdateToCompleted = useCallback(() => {
        updateToCompleted();
        setShowApproveConfirmDialog(false);
        show('success', t('saleTransactionsPage:successfulMessage.approve'));
    }, [updateToCompleted, setShowApproveConfirmDialog, show, t]);

    const onUpdateToNotToAwardPending = useCallback(() => {
        updateToPending();
        setShowUpdateToNotToAwardPendingConfirmDialog(false);
        show('success', t('saleTransactionsPage:successfulMessage.pending'));
    }, [updateToPending, setShowUpdateToNotToAwardPendingConfirmDialog, show, t]);

    const onUpdateToUnawardPending = useCallback(() => {
        updateToPending();
        setShowUpdateToUnAwardPendingConfirmDialog(false);
        show('success', t('saleTransactionsPage:successfulMessage.pending'));
    }, [updateToPending, setShowUpdateToUnAwardPendingConfirmDialog, show, t]);

    const checkValidToSelectAwardee = useCallback(
        (alwaysShowPopup?: boolean) => {
            let isValidToSelectAwardee = true;
            const fields: string[] = [];

            if (!saleTransaction.handover.personResponsible) {
                isValidToSelectAwardee = false;
                fields.push(t('saleTransactionsPage:handover.personResponsible'));
            }

            if (!saleTransaction.handover.targetHandoverDateTime) {
                isValidToSelectAwardee = false;
                fields.push(t('saleTransactionsPage:handover.targetHandoverDate'));
                fields.push(t('saleTransactionsPage:handover.targetHandoverTime'));
            }

            if (!saleTransaction.handover.vehicleSalesAgreementNo) {
                isValidToSelectAwardee = false;
                fields.push(t('saleTransactionsPage:handover.vsaNo'));
            }

            if (!alwaysShowPopup && isValidToSelectAwardee) {
                setOpenSelectAwardee(true);
            } else {
                setShowSelectAwardeeEditDialog(true);
            }
        },
        [
            saleTransaction.handover.personResponsible,
            saleTransaction.handover.targetHandoverDateTime,
            saleTransaction.handover.vehicleSalesAgreementNo,
            t,
        ]
    );

    const { latestBiddingSession, latestBiddingSessionEntry } = saleTransaction;

    const lastBid = latestBiddingSessionEntry?.lastUserBid?.amount || null;

    const showSelectAwardee =
        saleTransaction.stage === SaleTransactionStage.ForAwarding &&
        [UserType.Admin, UserType.Approver].includes(currentUser.type);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion title={t('saleTransactionsPage:sessionDetails.title')} defaultExpanded>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:sessionDetails.bidSessionName')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {saleTransaction.latestBiddingSession?.sessionName || t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:sessionDetails.bidOpeningOn')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {latestBiddingSession
                                        ? t('common:formats.longDateTime', {
                                              date: new Date(saleTransaction.latestBiddingSession.startPeriod),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex" justifyContent="space-between">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:sessionDetails.bidClosingDate')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {latestBiddingSession
                                        ? t('common:formats.longDateTime', {
                                              date: new Date(saleTransaction.latestBiddingSession.endPeriod),
                                          })
                                        : t('common:blankValue')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex" justifyContent="column">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('saleTransactionsPage:sessionDetails.bidNotes')}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <Typography>
                                    {!saleTransaction.latestBiddingSession?.notes
                                        ? t('saleTransactionsPage:sessionDetails.nil')
                                        : saleTransaction.latestBiddingSession.notes}
                                </Typography>
                            </Box>
                        </Grid>
                        {currentUser.type === UserType.Dealer &&
                            ![
                                SaleTransactionStage.New,
                                SaleTransactionStage.UpcomingBidding,
                                SaleTransactionStage.Closed,
                            ].includes(saleTransaction.stage) && (
                                <>
                                    <Grid xs={12} item>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography color="secondary">
                                                {t('saleTransactionsPage:bidDrawer.lastBid')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)} color="secondary">
                                                {lastBid
                                                    ? `${t('common:currency')}${lastBid.toLocaleString()}`
                                                    : t('common:blankValue')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography color="secondary">
                                                {t('saleTransactionsPage:bidDrawer.remainingChances')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)} color="secondary">
                                                {latestBiddingSessionEntry.remainDealerBiddingEntryCount}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box display="flex">
                                            <Typography color="secondary">
                                                {t('saleTransactionsPage:bidDrawer.topBidding')}
                                            </Typography>
                                            <Typography className={cs(commonStyles.autoMarginLeft)}>
                                                {latestBiddingSessionEntry.isInTop ? t('common:yes') : t('common:no')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </Accordion>
            </Grid>
            {showSelectAwardee && (
                <Grid xs={12} item>
                    <Accordion title={t('saleTransactionsPage:sessionDetails.awardeeTitle')} defaultExpanded>
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Box display="flex" justifyContent="column">
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => checkValidToSelectAwardee(true)}
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:sessionDetails.selectAwardee')}
                                    </Button>
                                </Box>
                            </Grid>

                            <ErrorSelectAwardeeDialog
                                saleTransaction={saleTransaction}
                                setHideDialog={() => setShowSelectAwardeeEditDialog(false)}
                                setOkDialog={() => {
                                    setShowSelectAwardeeEditDialog(false);
                                    setOpenSelectAwardee(true);
                                }}
                                show={showSelectAwardeeEditDialog}
                                title="Please fill below field(s):"
                            />
                        </Grid>
                    </Accordion>
                </Grid>
            )}

            <SelectBidDialog
                onClose={() => setOpenSelectAwardee(false)}
                open={openSelectAwardee}
                transaction={saleTransaction}
            />

            {![
                SaleTransactionStage.ForHandover,
                SaleTransactionStage.PendingApproval,
                SaleTransactionStage.Completed,
            ].includes(saleTransaction.stage) && (
                <>
                    <TopBids saleTransaction={saleTransaction} />
                    <BiddingBlock transaction={saleTransaction} />
                </>
            )}

            {/* eslint-disable max-len */}
            {currentUser.type === UserType.Admin && [SaleTransactionStage.ForAwarding].includes(saleTransaction.stage) && (
                <Grid lg={12} md={12} sm={12} xs={12} item>
                    <FormControl fullWidth>
                        <Button
                            classes={{ label: styles.closeTransactionLabel }}
                            onClick={() => setShowUpdateToNotToAwardPendingConfirmDialog(true)}
                            variant="outlined"
                        >
                            {t('saleTransactionsPage:button.notToAward')}
                        </Button>
                    </FormControl>
                </Grid>
            )}

            {currentUser.type === UserType.Admin && [SaleTransactionStage.ForHandover].includes(saleTransaction.stage) && (
                <Grid lg={12} md={12} sm={12} xs={12} item>
                    <FormControl fullWidth>
                        <Button
                            classes={{ label: styles.closeTransactionLabel }}
                            onClick={() => setShowUpdateToUnAwardPendingConfirmDialog(true)}
                            variant="outlined"
                        >
                            {t('saleTransactionsPage:button.unaward')}
                        </Button>
                    </FormControl>
                </Grid>
            )}
            {/* eslint-disable max-len */}

            {
                // eslint-disable-next-line max-len
                [UserType.Approver, UserType.Admin].includes(currentUser.type) &&
                    saleTransaction.stage === SaleTransactionStage.PendingApproval && (
                        <>
                            <Grid
                                className={cs(styles.changeStageGridItem, styles.orderUnset)}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                item
                            >
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        onClick={() => setShowApproveConfirmDialog(true)}
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.approve')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        color="secondary"
                                        onClick={() => setShowRejectConfirmDialog(true)}
                                        variant="outlined"
                                    >
                                        {t('saleTransactionsPage:button.reject')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )
            }

            <ConfirmDialog
                body={t('saleTransactionsPage:confirmMessage.approve.body')}
                setContinueConfirmDialog={onUpdateToCompleted}
                setHideConfirmDialog={() => setShowApproveConfirmDialog(false)}
                show={showApproveConfirmDialog}
                title={t('saleTransactionsPage:confirmMessage.approve.title')}
            />
            <ConfirmDialog
                body={t('saleTransactionsPage:confirmMessage.reject.body')}
                setContinueConfirmDialog={onUpdateToHandover}
                setHideConfirmDialog={() => setShowRejectConfirmDialog(false)}
                show={showRejectConfirmDialog}
                title={t('saleTransactionsPage:confirmMessage.reject.title')}
            />

            <ConfirmDialog
                body={t('saleTransactionsPage:confirmMessage.notToAward.body')}
                setContinueConfirmDialog={onUpdateToNotToAwardPending}
                setHideConfirmDialog={() => setShowUpdateToNotToAwardPendingConfirmDialog(false)}
                show={showUpdateToNotToAwardPendingConfirmDialog}
                title={t('saleTransactionsPage:confirmMessage.notToAward.title')}
            />

            <ConfirmDialog
                body={t('saleTransactionsPage:confirmMessage.unaward.body')}
                setContinueConfirmDialog={onUpdateToUnawardPending}
                setHideConfirmDialog={() => setShowUpdateToUnAwardPendingConfirmDialog(false)}
                show={showUpdateToUnAwardPendingConfirmDialog}
                title={t('saleTransactionsPage:confirmMessage.unaward.title')}
            />
        </Grid>
    );
};

export default BiddingTab;
