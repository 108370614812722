import { Button, Grid, Chip, Box, FormControl } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import { FormValues } from '../../CreateBiddingSession';
import CreateSessionDealerListings from '../CreateBiddingSession/components/CreateSessionDealerListings';
import CreateSessionVehicleListings from '../CreateBiddingSession/components/CreateSessionVehicleListings';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        chooseVehicleListingBtn: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        itemTitle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '140px',
            marginLeft: '10px',
            color: '#717073',
        },
        gridMargin: {
            marginBottom: '24px',
            '&:first-child': {
                marginTop: '24px',
            },
        },
        selectedItems: {
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            marginTop: '16px',
            marginBottom: '24px',
        },
    })
);

const CreateSessionForm = () => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const { dealers, saleTransactions, startPeriod, endPeriod } = values;

    const [open, setOpen] = useState(false);
    const [openDealerList, setOpenDealerList] = useState(false);

    const styles = useStyles();

    useEffect(() => {
        if (!startPeriod || !endPeriod || new Date(startPeriod).getTime() >= new Date(endPeriod).getTime()) {
            setFieldValue('endPeriod', null);
        }
    }, [endPeriod, setFieldValue, startPeriod]);

    return (
        <Grid spacing={2} container>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.bidSessionName')}
                </Grid>
                <Grid xs={10} item>
                    <OutlinedInputField
                        color="secondary"
                        name="sessionName"
                        placeholder={t('biddingSessionsPage:session.bidSessionName')}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.bidNotes')}
                </Grid>
                <Grid xs={10} item>
                    <OutlinedInputField
                        color="secondary"
                        name="notes"
                        placeholder={t('biddingSessionsPage:session.enterDescription')}
                        multiline
                    />
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.vehicleListings')}
                </Grid>
                <Grid xs={10} item>
                    <FormControl fullWidth>
                        <Button
                            className={styles.chooseVehicleListingBtn}
                            color="secondary"
                            onClick={() => setOpen(true)}
                            type="button"
                            variant="outlined"
                        >
                            {saleTransactions.length > 0
                                ? t('biddingSessionsPage:button:vehicleListings', { count: saleTransactions.length })
                                : t('biddingSessionsPage:button:chooseVehicleListings')}
                            <ChevronRightIcon color="secondary" />
                        </Button>
                        {saleTransactions.length > 0 && (
                            <Box className={styles.selectedItems}>
                                {saleTransactions.map(saleTransaction => (
                                    <Chip color="secondary" label={saleTransaction.vehicle.number} variant="outlined" />
                                ))}
                            </Box>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.dealerListings')}
                </Grid>
                <Grid xs={10} item>
                    <FormControl fullWidth>
                        <Button
                            className={styles.chooseVehicleListingBtn}
                            color="secondary"
                            onClick={() => setOpenDealerList(true)}
                            type="button"
                            variant="outlined"
                        >
                            {dealers.length > 0
                                ? t('biddingSessionsPage:button:dealerListings', { count: dealers.length })
                                : t('biddingSessionsPage:button:chooseDealerListings')}
                            <ChevronRightIcon color="secondary" />
                        </Button>
                        {dealers.length > 0 && (
                            <Box className={styles.selectedItems}>
                                {dealers.map(dealer => (
                                    <Chip color="secondary" label={dealer.fullName} variant="outlined" />
                                ))}
                            </Box>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.startPeriod')}
                </Grid>
                <Grid xs={3} item>
                    <OutlinedDatePickerField
                        color="secondary"
                        name="startPeriod"
                        placeholder={t('biddingSessionsPage:session.startPeriod')}
                        disablePast
                    />
                </Grid>
            </Grid>
            <Grid className={styles.gridMargin} spacing={2} container>
                <Grid className={styles.itemTitle} xs={2} item>
                    {t('biddingSessionsPage:session.endPeriod')}
                </Grid>
                <Grid xs={3} item>
                    <OutlinedDatePickerField
                        color="secondary"
                        disabled={!startPeriod}
                        minDate={startPeriod ? dayjs(startPeriod).add(1, 'day') : ''}
                        name="endPeriod"
                        placeholder={t('biddingSessionsPage:session.endPeriod')}
                    />
                </Grid>
            </Grid>

            <CreateSessionVehicleListings onClose={() => setOpen(false)} open={open} />
            <CreateSessionDealerListings onClose={() => setOpenDealerList(false)} open={openDealerList} />
        </Grid>
    );
};

export default CreateSessionForm;
