import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import ManualInformationForm from './ManualInformationForm';

const ManualInformationAccordion = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return (
        <Accordion title={t('purchaseTransactionsPage:accordionTitle.manualInformation')} defaultExpanded>
            <ManualInformationForm onCreation />
        </Accordion>
    );
};

export default ManualInformationAccordion;
