import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../../components/Accordion';
import CreateSessionForm from '../../Forms/CreateSessionForm';

const CreateSessionAccordion = () => {
    const { t } = useTranslation(['biddingSessionsPage']);

    return (
        <Accordion title={t('biddingSessionsPage:accordionTitle.biddingSession')} defaultExpanded>
            <CreateSessionForm />
        </Accordion>
    );
};

export default CreateSessionAccordion;
