import { FormControl, Box, FormLabel, Button, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import React, { ReactNode, useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NameOnlyFileDataFragment } from '../../api';
import { UploadType } from '../../flutterInterface/actions/uploadFile';
import useCommonStyles from '../../useCommonStyles';
import { getExtension, getAcceptExtensions } from '../../utilities/file';
import { useAlert } from '../AlertProvider';
import { useFlutter } from '../FlutterProvider';
import { useLoading } from '../LoadingProvider';
import FileList, { FileListProps } from './FileList';

export type AttachmentButtonProps = {
    name: string;
    label?: string;
    buttonText: string;
    icon?: ReactNode | null;
    max?: number;
    maxSize?: number;
    allowedExtensions?: string[];
    disabled?: boolean;
    multiple?: boolean;
    renderPrefix?: FileListProps['renderPrefix'];
    uploadFileType?: UploadType;
};

const AttachmentButton = ({
    name,
    label,
    buttonText,
    icon,
    max,
    maxSize,
    allowedExtensions,
    renderPrefix,
    disabled,
    multiple,
    uploadFileType = 'pick_image',
}: AttachmentButtonProps) => {
    const { t } = useTranslation(['errors']);
    const { show } = useAlert();
    const [{ value: attachments }, , { setValue }] = useField<(File | NameOnlyFileDataFragment)[]>(name);
    const commonStyles = useCommonStyles();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [, meta] = useField({ name });
    const { deviceFingerPrint, uploadFile, uploadImage } = useFlutter();
    const { attach } = useLoading();

    const hasError = !!meta.error && meta.touched;

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let nextValues = attachments;

            Array.from(event.currentTarget.files).forEach(file => {
                if (max !== undefined && nextValues.length >= max) {
                    // limit reached
                    return;
                }

                if (maxSize !== undefined && file.size > maxSize) {
                    // display error message
                    show('error', t('errors:largeFileSize', { fileSize: maxSize / 1000 / 1000 }));

                    return;
                }

                if (allowedExtensions !== undefined) {
                    const extension = getExtension(file.name).toLowerCase();

                    if (!allowedExtensions.some(allowedExtension => allowedExtension === extension)) {
                        // display error message
                        show('error', t('errors:invalidFileType'));

                        return;
                    }
                }

                // add the new file
                nextValues = [...nextValues, file];
            });

            // update value
            setValue(nextValues);

            // then reset value
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
        },
        [setValue, attachments, maxSize, max, show, t, allowedExtensions]
    );

    const accept = useMemo(
        () => (allowedExtensions ? getAcceptExtensions(allowedExtensions).join(',') : ''),
        [allowedExtensions]
    );

    const onUploadFile = useCallback(() => {
        // show file pick (default is pick_file) for mobile device
        if (deviceFingerPrint) {
            const uploadFn = uploadFileType === 'pick_image' ? uploadImage : uploadFile;
            const execute = async () => {
                const data = await uploadFn({
                    allowedMultiple: multiple,
                    allowedExtensions,
                });
                setValue([...attachments, ...data]);
            };

            attach(execute());
        } else {
            fileInputRef.current.click();
        }
    }, [
        fileInputRef,
        multiple,
        allowedExtensions,
        attach,
        deviceFingerPrint,
        uploadFile,
        uploadImage,
        setValue,
        attachments,
        uploadFileType,
    ]);

    return (
        <FormControl fullWidth>
            <input
                ref={fileInputRef}
                accept={accept}
                multiple={multiple}
                onChange={onChange}
                style={{ display: 'none' }}
                type="file"
            />
            {label && <FormLabel className={commonStyles.formLabel}>{label}</FormLabel>}
            {attachments.length > 0 && (
                <Box mb={1}>
                    <FileList
                        files={attachments}
                        removeFile={!disabled ? file => setValue(attachments.filter(item => item !== file)) : null}
                        renderPrefix={renderPrefix}
                    />
                </Box>
            )}
            <Button
                color="secondary"
                disabled={disabled || (max !== undefined && attachments.length >= max)}
                onClick={onUploadFile}
                variant="contained"
            >
                {icon ? (
                    <>
                        {icon} {buttonText}
                    </>
                ) : (
                    buttonText
                )}
            </Button>
            <FormHelperText error={hasError}>{hasError ? meta.error : ''}</FormHelperText>
        </FormControl>
    );
};

export default AttachmentButton;
