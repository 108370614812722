import { Grid, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ValuatedValuation, NameOnlyUserDataFragment } from '../../../../api';
import useViewTransactionStyles from './useViewTransactionStyles';

export type ValuatedValuationItemProp = {
    valuatedValuation: Omit<ValuatedValuation, 'valuatedBy'> & { valuatedBy?: NameOnlyUserDataFragment };
};

const ValuatedValuationItem = ({ valuatedValuation }: ValuatedValuationItemProp) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const viewTransactionStyles = useViewTransactionStyles();

    return (
        <>
            <Grid xs={12} item>
                <Box display="flex">
                    <Typography color="secondary">
                        {t('purchaseTransactionsPage:valuation.valuatedValuation')}
                    </Typography>
                    <Typography className={viewTransactionStyles.content}>
                        {valuatedValuation?.value
                            ? `${t('common:currency')}${valuatedValuation.value.toLocaleString()}`
                            : t('common:blankValue')}
                    </Typography>
                </Box>
            </Grid>
            <Grid xs={12} item>
                <Box display="flex">
                    <Typography className={viewTransactionStyles.label}>
                        {t('purchaseTransactionsPage:valuation.valuatedBy')}
                    </Typography>
                    <Typography className={viewTransactionStyles.content}>
                        {valuatedValuation?.valuatedBy?.fullName
                            ? valuatedValuation.valuatedBy.fullName
                            : t('common:blankValue')}
                    </Typography>
                </Box>
            </Grid>
            <Grid xs={12} item>
                <Box display="flex">
                    <Typography className={viewTransactionStyles.label}>
                        {t('purchaseTransactionsPage:valuation.valuatedOn')}
                    </Typography>
                    <Typography className={viewTransactionStyles.content}>
                        {valuatedValuation?.valuatedOn
                            ? t('common:formats.longDateTime', {
                                  date: new Date(valuatedValuation.valuatedOn),
                              })
                            : t('common:blankValue')}
                    </Typography>
                </Box>
            </Grid>
        </>
    );
};

export default ValuatedValuationItem;
