import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 300;

const useBasicStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
            backgroundColor: '#fff',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                backgroundColor: '#F4F4F4',
            },
        },

        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            width: `calc(100% - ${theme.spacing(12) + 1}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: theme.palette.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#000',
                width: '0',
                height: theme.spacing(8),
            },
        },

        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                width: '100%',
            },
        },

        menuButton: {
            marginRight: 36,
        },

        hide: {
            display: 'none',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                width: 0,
            },
        },

        drawerPaper: {
            backgroundColor: '#000',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#fff',
            },
        },

        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('sm')]: {
                width: 0,
            },
        },

        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(12) + 1,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },

        toolBar: {
            height: theme.spacing(10),
        },

        logo: {
            height: theme.spacing(4),
            backgroundSize: 'contain',
            margin: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: theme.spacing(3),
                position: 'absolute',
                margin: 0,
            },
        },

        content: {
            flexGrow: 1,
            marginTop: theme.spacing(10),
        },

        main: {
            padding: theme.spacing(3),
            paddingRight: theme.spacing(4),
        },

        divider: {
            backgroundColor: '#2b2b2b',
        },

        profileMenuButton: {
            marginLeft: 'auto',
        },

        profilePicture: {
            marginRight: theme.spacing(1.5),
        },

        profileMenuPaper: {
            width: theme.spacing(40),
            paddingRight: theme.spacing(3),
            paddingTop: '0.75rem',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderRadius: '0.5rem',
            '&:before': {
                content: "''",
                borderLeft: '0.5rem solid transparent',
                borderRight: '0.5rem solid transparent',
                borderBottom: '0.75rem solid black',
                position: 'absolute',
                top: '0',
                left: '80%',
            },
        },

        profileMenuList: {
            background: '#000',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            boxShadow:
                '0px 5px 5px -3px rgb(0 0 0 / 20%),' +
                '0px 8px 10px 1px rgb(0 0 0 / 14%),' +
                '0px 3px 14px 2px rgb(0 0 0 / 12%)',
            borderRadius: '0.5rem',
        },

        profileMenuItem: {
            color: theme.palette.secondary.contrastText,
            paddingBottom: '0.75rem !important',
            paddingTop: '0.75rem !important',
            paddingLeft: '1.5rem !important',
            '&:first-child': {
                borderRadius: '0.5rem 0 0 0',
            },
            '&:last-child': {
                backgroundColor: '#292929',
                marginLeft: '0',
                borderRadius: '0 0.5rem 0.5rem 0',
                justifyContent: 'center',
                color: theme.palette.secondary.main,
                '&:hover': {
                    backgroundColor: '#292929',
                },
            },
        },

        profileMenuIcon: {
            marginRight: '0.5rem',
        },

        selectedProfileMenuItem: {
            color: theme.palette.secondary.main,
            paddingLeft: '1rem !important',
            borderLeft: '0.5rem solid',
        },

        backPaper: {
            height: theme.spacing(6),
        },

        lightBoxShadow: {
            boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 6%)',
        },

        linkBack: {
            color: '#000',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            height: '100%',
            marginLeft: theme.spacing(3),
            fontSize: '1rem',
        },

        title: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(5),
        },

        menuIcon: {
            zIndex: theme.zIndex.drawer + 1,
        },

        profilePhoto: {
            width: `${theme.spacing(13)}px !important`,
            height: `${theme.spacing(13)}px !important`,
            [theme.breakpoints.down('sm')]: {
                position: 'absolute !important',
                top: '-50px',
                border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.contrastText}`,
            },
        },
    })
);

export default useBasicStyle;
