import { Typography, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export type ContentWithLabelProps = {
    label: string;
    content: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#717073',
        },
        content: {
            marginLeft: 'auto',
            textAlign: 'right',
            wordBreak: 'break-word',
        },
    })
);

const ContentWithLabel = ({ label, content }: ContentWithLabelProps) => {
    const styles = useStyles();

    return (
        <Box display="flex">
            <Typography className={styles.label}>{label}</Typography>
            <Typography className={styles.content}>{content}</Typography>
        </Box>
    );
};

export default ContentWithLabel;
