import { CardMedia, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import usePublic from '../../../utilities/usePublic';

const useStyles = makeStyles({
    media: {
        height: '13rem',
        backgroundSize: 'Contain',
        marginBottom: '1rem',
    },
});

const OTPPageHeader = () => {
    const styles = useStyles();
    const otpValidation = usePublic('assets/images/otpValidation.svg');

    return (
        <Grid xs={12} item>
            <CardMedia className={styles.media} image={otpValidation} />
        </Grid>
    );
};

export default OTPPageHeader;
