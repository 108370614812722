import { makeStyles } from '@material-ui/core/styles';

const usePortalStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: '#000',
    },

    linkBack: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        zIndex: 1,
        marginRight: 'auto',
    },

    toolBar: {
        borderBottom: '0.1rem solid #2b2b2b',
        display: 'flex',
        justifyContent: 'center',
    },

    signUpLabel: {
        fontFamily: 'Alegreya Sans !important',
        width: '100%',
        color: 'rgb(255, 255, 255, 0.1)',
        textAlign: 'center',
        fontSize: '4rem',
        fontWeight: 'bold',
        position: 'absolute',
        top: '8.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '6.5rem',
            top: '12rem',
        },
    },

    logo: {
        height: '3.5rem',
        backgroundSize: 'Contain',
        [theme.breakpoints.down('sm')]: {
            height: '2.3rem',
        },
    },

    carMedia: {
        height: '12.5rem',
        marginTop: '1rem',
        backgroundSize: 'Cover',
        transform: 'scale(1.1)',
        [theme.breakpoints.up('sm')]: {
            height: '15rem',
            transform: 'scale(1.2)',
        },
    },

    inputField: {
        backgroundColor: '#2E2E2E99',
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#B49759',
            '-webkit-box-shadow': '0 0 0px 1000px #1B1B1B inset',
        },
    },

    biometricButton: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.spacing(1),
        marginLeft: '1rem',
        '& img': {
            height: '1.5rem',
        },
    },

    loginButton: {
        height: '100%',
    },

    welcomeTitle: {
        color: theme.palette.secondary.contrastText,
        fontSize: '0.75rem',
        fontWeight: 'lighter',
        opacity: '0.5',
    },
}));

export default usePortalStyles;
